import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
  },
  ball: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: palette.primary.main,
    animation: '$bounce 0.7s infinite ease-in-out'
  },
  ball2: {
    animationDelay: '0.3s'
  },
  ball3: {
    animationDelay: '0.5s'
  },
  '@keyframes bounce': {
    '0%, 100%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-12px)' }
  }
}));

const AIloader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loaderContainer}>
      <Box className={`${classes.ball}`} />
      <Box className={`${classes.ball} ${classes.ball2}`} />
      <Box className={`${classes.ball} ${classes.ball3}`} />
    </Box>
  );
};

export default AIloader;
