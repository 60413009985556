import { Box, styled, Typography } from '@material-ui/core';
import {
  SAIComparisonTable,
  SAIDIalogContainer
} from '@setvi/shared/components';
import { AIContentBox } from '@setvi/shared/components/ai/dialog-container/styled';
import { Product } from '@setvi/shared/interfaces';
import { useQuery } from '@tanstack/react-query';
import { recommendProductsMutation } from 'Services';

import { DialogLoader, Error } from '../../../components';

const Title = styled(Typography)(({ theme: { spacing } }) => ({
  fontSize: 20,
  fontWeight: 'bold',
  textDecoration: 'underline',
  marginBottom: spacing(1)
}));

interface RecommendationAnalysisProps {
  products: Product[];
  purpose: string;
}

const textStyle = {
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.5
};

export const RecommendationAnalysis = ({
  products,
  purpose
}: RecommendationAnalysisProps) => {
  const { data, isLoading } = useQuery(
    recommendProductsMutation({
      purpose,
      products: products.map(i => ({
        name: i.name || '',
        manufacturer: i.vendor.name || '',
        manufacturerSku: i.manufacturerSku || ''
      }))
    })
  );

  return (
    <SAIDIalogContainer
      title="AI Recommendation Analysis"
      description="To ensure AI delivers the most accurate and effective recommendation
    analysis, please specify the purpose, application, or type of busniess
    you are ariming to recommend. The more detailed the information, the
    better the recommendations will align with your goals.">
      <AIContentBox display="flex" flexDirection="column" gridGap="16px">
        {isLoading ? (
          <DialogLoader />
        ) : (
          <>
            {data?.error || !data ? (
              <Error value={data?.error} />
            ) : (
              <>
                {data.briefDescription && (
                  <Box>
                    <Title>Recommendation Analysis</Title>
                    <Box
                      style={textStyle}
                      dangerouslySetInnerHTML={{
                        __html: data.briefDescription
                      }}
                    />
                  </Box>
                )}
                <SAIComparisonTable
                  features={data.table.map((i: any) => {
                    const { feature, ...rest } = i;
                    return {
                      name: feature,
                      values: Object.keys(rest).map(j => i[j as keyof typeof i])
                    };
                  })}
                  products={products.map(i => i.name)}
                />
                {data.finalRecommendation && (
                  <Box>
                    <Title>Final Recommendation</Title>
                    <Box
                      style={textStyle}
                      dangerouslySetInnerHTML={{
                        __html: data.finalRecommendation
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </AIContentBox>
    </SAIDIalogContainer>
  );
};
