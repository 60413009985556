import { useCallback, useState } from 'react';
import { Box } from '@material-ui/core';

import { SButton, SText } from '@setvi/shared/components';
import { AIIcon } from '@setvi/shared/components/sicons';

import { SButtonProps } from '@setvi/shared/components/sbutton';
import { TextColor } from '@setvi/shared/enums';
import { AIGradientBgColor, StyledMenu, StyledMenuItem } from './styled';

interface Items {
  icon: JSX.Element;
  flag: boolean;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

interface AIButtonProps {
  title?: string;
  buttonVariant?: SButtonProps['variant'];
  items: Items[];
}

const AIButton = ({
  items,
  title = 'AI Tools',
  buttonVariant = 'contained'
}: AIButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Get items with flag set to true
  const currItems = items?.filter(i => i?.flag);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (currItems?.length === 1) currItems[0]?.onClick?.();
      else setAnchorEl(event.currentTarget);
    },
    [currItems]
  );

  if (!currItems?.length) return null;

  return (
    <Box onClick={e => e.stopPropagation()}>
      <SButton
        variant={buttonVariant}
        style={{
          background: buttonVariant === 'contained' && AIGradientBgColor
        }}
        onClick={handleOpenMenu}
        startIcon={
          <AIIcon
            htmlColor={buttonVariant === 'contained' ? '#fff' : TextColor.Blue}
          />
        }>
        {title}
      </SButton>

      {!!anchorEl && (
        <StyledMenu
          open
          keepMounted
          elevation={3}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          {currItems?.map(item => (
            <StyledMenuItem
              key={item?.text}
              onClick={() => {
                item?.onClick?.();
                setAnchorEl(null);
              }}
              disabled={item?.disabled}>
              {item?.icon}
              <SText weight="medium">{item?.text}</SText>
            </StyledMenuItem>
          ))}
        </StyledMenu>
      )}
    </Box>
  );
};

export default AIButton;
