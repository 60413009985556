import { useRef } from 'react';
import { Product, ProductDetailsTabsIds } from '@setvi/shared/interfaces';
import {
  SEmptyState,
  SVirtualTable,
  TableLoader
} from '@setvi/shared/components';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Customer } from 'Services/MongoRealm/Types';
import { ActionBar } from 'componentsV2/product/action-bar';
import { useSimilarProducts, useSimilarProductsColumns } from './hooks';
import { rowKeyGetter } from '../associated-products';

export interface SimilarProductsProps {
  product: Product;
  selectedProducts: Product[];
  scrollParentRef: any;
  selectedCustomer: Customer;
  readOnly: boolean;
  canSelectProducts: boolean;
  closeDialog?: () => void;
  handleProductSelect: (isChecked: boolean, product: Product[]) => void;
  openProductDetails?: (
    product: Product,
    defaultOpenedTab?: ProductDetailsTabsIds
  ) => void;
  handleEmail: () => void;
  handleSelected: () => void;
  handleCloseActionBar: () => void;
}

export const SimilarProducts = ({
  product,
  readOnly = false,
  scrollParentRef,
  selectedCustomer,
  selectedProducts,
  canSelectProducts,
  closeDialog,
  handleEmail,
  handleSelected,
  openProductDetails,
  handleProductSelect,
  handleCloseActionBar
}: SimilarProductsProps) => {
  const { products, isLoading } = useSimilarProducts({
    product,
    selectedCustomer
  });
  const { columns } = useSimilarProductsColumns({
    selectedCustomer,
    openProductDetails
  });
  const tableRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: products?.length || 0,
    estimateSize: () => 66,
    getScrollElement: () => scrollParentRef.current,
    overscan: 5,
    scrollMargin: tableRef.current?.offsetTop ?? 0
  });

  const openModal = (prod: Product) => {
    closeDialog?.();
    openProductDetails(prod);
  };

  if (!products?.length && !isLoading)
    return (
      <SEmptyState
        imageSrc="/images/product-placeholder.svg"
        title="No Similar Products Available"
      />
    );

  return (
    <>
      <div ref={tableRef}>
        <SVirtualTable
          list={products}
          options={{ scrollMargin: virtualizer.options.scrollMargin }}
          vList={virtualizer?.getVirtualItems()}
          height={virtualizer?.getTotalSize()}
          columns={columns}
          row={{
            onClick: openModal,
            ...(!readOnly && {
              checkbox: (prod: Product) => {
                const checked = selectedProducts?.some(
                  i => rowKeyGetter(i) === rowKeyGetter(prod)
                );
                return {
                  checked,
                  disabled: !canSelectProducts && !checked,
                  tooltip:
                    !canSelectProducts &&
                    !checked &&
                    'The maximum number of product selected',
                  onChange: e => handleProductSelect(e.target.checked, [prod])
                };
              }
            }),
            rowStyleGetter: () => ({ maxHeight: 'fit-content' })
          }}
          keyGetter={i => i.sku}
        />
        {isLoading && <TableLoader columns={columns} />}
      </div>

      {handleEmail && selectedProducts?.length > 0 && (
        <ActionBar
          selectedProducts={selectedProducts}
          buttonLabel="Email"
          handleEmail={handleEmail}
          handleSelected={handleSelected}
          handleClose={handleCloseActionBar}
        />
      )}
    </>
  );
};
