import { useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { Box, Divider, Grid, withStyles } from '@material-ui/core';
import { sendMessageToSlackChannel } from '@setvi/shared/services';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { SBreadcrumbs, SButton, SGallery } from '@setvi/shared/components';
import { Customer } from 'Services/MongoRealm/Types';
import {
  Product,
  ProductCategoryBreadcrumb,
  ProductDetailsTabsIds
} from '@setvi/shared/interfaces';
// import {
//   getProductStock,
//   getProductStockByCustomer
// } from 'pages/products/utils';
// import { ProductDetailsRow } from './details-row';
// import { Detail } from './details-row/detail';
import { distances } from '@setvi/shared/styles';
import { useDialog } from '@setvi/shared/providers';
import { SlackMessageTypes } from '@setvi/shared/enums';

import { ROUTES } from 'enumsV2';
import { RealmProvider } from 'Providers/MongoRealmProvider';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useCatalogConfig } from 'pages/products/hooks/useCatalogConfig';
import { InvalidConfiguration } from 'pages/products/components/invalid-configuration';
import Feedback, {
  FEEDBACK_DIALOG,
  FeedbackValues
} from 'componentsV2/ai/feedback';

import { Tabs, ProductShowcase } from './components';
import {
  AssociatedProducts,
  AssociatedResource,
  ProductInfo,
  SalesEnablement,
  SimilarProducts,
  Warehouse
} from './subpages';

const TabContentWrapper = withStyles({
  root: {
    padding: 12,
    flex: 1
  }
})(Box);

interface DetailsContentProps {
  product: Product;
  selectedProducts?: Product[];
  selectedCustomer?: Customer;
  defaultOpenedTab?: ProductDetailsTabsIds;
  client: any;
  canSelectProducts?: boolean;
  readOnly?: boolean;
  hideProductPrice?: boolean;
  handleProductSelect?: (isChecked: boolean, product: Product[]) => void;
  updateProduct?: (product: Product, props: Partial<Product>) => void;
  captureResourceAnalytics?: () => void;
  openProductDetails?: (
    product: Product,
    defaultOpenedTab?: ProductDetailsTabsIds
  ) => void;
  closeDialog?: () => void;
  handleEmail?: () => void;
  handleSelected?: () => void;
  handleCloseActionBar?: () => void;
}

export const DetailsContent = ({
  product,
  selectedProducts,
  selectedCustomer,
  defaultOpenedTab = ProductDetailsTabsIds.PRODUCT_INFO,
  client,
  canSelectProducts,
  hideProductPrice = false,
  readOnly = false,
  handleProductSelect,
  updateProduct,
  captureResourceAnalytics,
  openProductDetails,
  closeDialog,
  handleEmail,
  handleSelected,
  handleCloseActionBar
}: DetailsContentProps) => {
  const flags = useFlags();
  const { user } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] =
    useState<ProductDetailsTabsIds>(defaultOpenedTab);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const { configuration, invalidConfiguration } = useCatalogConfig();
  const { openDialog, closeDialog: closeFeedbackDialog } = useDialog();

  const { mutateAsync: sendFeedback, isLoading: isFeedbackLoading } =
    useMutation(sendMessageToSlackChannel());

  const handleFeedbackClick = async ({ feedback, rating }: FeedbackValues) => {
    const body = {
      _id: process.env.SLACK_CHANNEL_ID,
      type: SlackMessageTypes.SalesEnablement,
      ...(rating ? { rating } : {}),
      feedback,
      user: {
        email: user?.Email,
        userId: user?.ID,
        company: user?.CompanyName,
        fullName: user?.FullName
      },
      results: {
        type: 'AI Generated',
        data: [product],
        value: ''
      }
    };

    const res = await sendFeedback(body);

    if (!res) return;

    closeFeedbackDialog(FEEDBACK_DIALOG);
    enqueueSnackbar('Feedback sent successfully', {
      variant: 'success'
    });
  };

  // const productDetails: Detail[] = useMemo(
  //   () => [
  //     {
  //       label: 'Inventory',
  //       value: selectedCustomer
  //         ? getProductStockByCustomer(product.inventory, selectedCustomer)
  //         : getProductStock(product.inventory) || '-'
  //     },
  //     {
  //       label: 'Other Details',
  //       value: '-'
  //     },
  //     {
  //       label: 'Other 1',
  //       value: '-'
  //     },
  //     {
  //       label: 'Other 2',
  //       value: '-'
  //     }
  //   ],
  //   [product, selectedCustomer]
  // );
  const parentRef = useRef<HTMLDivElement>(null);

  const images = useMemo(
    () =>
      product?.images?.length > 0
        ? product.images
            .map(image => image?.path)
            // Removing duplicates because in some cases the same image url is added multiple
            // times which causes issues when navigating through the gallery
            .filter((v, i, a) => a?.indexOf(v) === i)
        : ['/images/product-placeholder.svg'],
    [product]
  );

  const tabs = configuration.productDetails?.tabs || [];

  if (invalidConfiguration) return <InvalidConfiguration />;

  return (
    <>
      {selectedImage && (
        <SGallery
          fullScreen
          images={images}
          defaultOpenedImage={selectedImage}
          opened={!!selectedImage}
          onClose={() => setSelectedImage('')}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        minHeight={0}
        overflow="hidden auto"
        // @ts-ignore
        ref={parentRef}>
        {product?.categories?.[0]?.breadcrumbs && (
          <Box
            style={{ padding: `${distances.px.base} ${distances.px.large}` }}>
            <SBreadcrumbs<ProductCategoryBreadcrumb>
              {...(!readOnly && {
                onBreadcrumbClick: breadcrumb =>
                  window.open(
                    `${ROUTES.PRODUCTS_LIST_VIEW}&categoryId=${breadcrumb?._id}`,
                    '_blank'
                  )
              })}
              parseBreadcrumb={breadcrumb => breadcrumb?.name}
              items={product?.categories?.[0]?.breadcrumbs}
              fontWeight={400}
            />
          </Box>
        )}
        <Divider />
        <Grid
          container
          spacing={3}
          style={{ margin: 0, width: '100%', padding: '0 8px' }}>
          <Grid item xs={4}>
            <SGallery
              onImageClick={img => {
                product.images.length > 0 && setSelectedImage(img);
              }}
              images={images}
            />
          </Grid>
          <Grid item xs={8}>
            <ProductShowcase
              product={product}
              selectedCustomer={selectedCustomer}
              hideProductPrice={hideProductPrice}
              client={client}
              priceType={configuration.price}
              productDetails={configuration.productDetails}
              updateProduct={updateProduct}
              onStockClick={() => setActiveTab(ProductDetailsTabsIds.WAREHOUSE)}
            />
          </Grid>
        </Grid>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr={2}>
          <Tabs currentTab={activeTab} tabs={tabs} onTabChange={setActiveTab} />
          {activeTab === ProductDetailsTabsIds.SALES_ENABLEMENT && (
            <SButton
              variant="outlined"
              onClick={() =>
                openDialog(
                  <Feedback
                    onClose={() => closeFeedbackDialog(FEEDBACK_DIALOG)}
                    handleSubmit={handleFeedbackClick}
                    loading={isFeedbackLoading}
                  />,
                  {
                    id: FEEDBACK_DIALOG
                  }
                )
              }>
              Send Feedback
            </SButton>
          )}
        </Box>
        <Divider />
        {activeTab === ProductDetailsTabsIds.PRODUCT_INFO && (
          <TabContentWrapper>
            <ProductInfo product={product} client={client} />
          </TabContentWrapper>
        )}
        {activeTab === ProductDetailsTabsIds.WAREHOUSE && (
          <TabContentWrapper>
            <Warehouse
              product={product}
              selectedCustomer={selectedCustomer}
              configColumns={
                configuration.productDetails.tabs.find(
                  i => i.id === ProductDetailsTabsIds.WAREHOUSE
                )?.columns
              }
            />
          </TabContentWrapper>
        )}
        {activeTab === ProductDetailsTabsIds.ASSOCIATED_RESOURCE && (
          <TabContentWrapper>
            <AssociatedResource
              product={product}
              readOnly={readOnly}
              captureResourceAnalytics={captureResourceAnalytics}
              closeDialog={closeDialog}
            />
          </TabContentWrapper>
        )}
        {activeTab === ProductDetailsTabsIds.ASSOCIATED_PRODUCTS && (
          <TabContentWrapper>
            <RealmProvider>
              <AssociatedProducts
                product={product}
                selectedProducts={selectedProducts}
                canSelectProducts={canSelectProducts}
                readOnly={readOnly}
                handleProductSelect={handleProductSelect}
                openProductDetails={openProductDetails}
                closeDialog={closeDialog}
                handleEmail={handleEmail}
                handleSelected={handleSelected}
                handleCloseActionBar={handleCloseActionBar}
              />
            </RealmProvider>
          </TabContentWrapper>
        )}
        {activeTab === ProductDetailsTabsIds.SIMILAR_PRODUCTS && (
          <TabContentWrapper>
            <SimilarProducts
              product={product}
              selectedProducts={selectedProducts}
              canSelectProducts={canSelectProducts}
              scrollParentRef={parentRef}
              closeDialog={closeDialog}
              readOnly={readOnly}
              openProductDetails={openProductDetails}
              handleProductSelect={handleProductSelect}
              selectedCustomer={selectedCustomer}
              handleEmail={handleEmail}
              handleSelected={handleSelected}
              handleCloseActionBar={handleCloseActionBar}
            />
          </TabContentWrapper>
        )}
        {flags?.salesEnablement &&
          activeTab === ProductDetailsTabsIds.SALES_ENABLEMENT && (
            <Box flex={1} overflow="hidden">
              <SalesEnablement product={product} />
            </Box>
          )}
      </Box>
    </>
  );
};
