import * as Yup from 'yup';

import { CampaignFormLimit, LINK_VALIDATION } from '@setvi/shared/enums';
import { linkAddress } from '@setvi/shared/utils';

export const nameValidation = Yup.object({
  Name: Yup.string()
    .trim()
    .required('Company Campaign Name is required.')
    .max(CampaignFormLimit.Name)
});

export const resourceNameValidation = Yup.object({
  Name: Yup.string()
    .trim()
    .required('Resource Name is required.')
    .max(CampaignFormLimit.Name)
});

export const resourceLinkValidation = Yup.object({
  WebLinkUrl: Yup.string()
    .max(
      LINK_VALIDATION.MAX_LINK_ADDRESS,
      `Limit is ${LINK_VALIDATION.MAX_LINK_ADDRESS} characters`
    )
    .matches(linkAddress, 'Enter correct link address!')
    .required('Link address is required')
});

export const descriptionValidation = Yup.object({
  Description: Yup.string().max(CampaignFormLimit.Description)
});

export const requiredReadingValidation = Yup.object({
  Description: Yup.string().max(CampaignFormLimit.RequiredReadingForm),
  DocumentName: Yup.string().max(CampaignFormLimit.RequiredReadingForm)
});

export const switchValidation = Yup.object({
  Required: Yup.boolean()
});

export const validUntilDateValidation = Yup.object({
  ValidUntilDate: Yup.date()
    .min(new Date(), 'Date is too early')
    .when('Required', Required => {
      if (Required) return Yup.date().required('Date is required');
      return Yup.date();
    })
});

export const checkboxValidation = Yup.object({
  Checkbox: Yup.boolean()
});

export const subjectValidation = Yup.object({
  Subject: Yup.string()
    .trim()
    .max(
      CampaignFormLimit.Subject,
      'Exceeded character limit for Email Subject'
    )
    .required('Email Subject is required')
});

export const bodyValidation = Yup.object({
  HtmlBody: Yup.string()
    .trim()
    .max(CampaignFormLimit.Body, 'Exceeded character limit for Email Body')
    .required('Email Body is required')
});

export const dateValidation = Yup.object({
  Date: Yup.date()
    .min(new Date(), 'Date is too early')
    .max(new Date(2100, 0, 1), 'Date is too late')
    .when('Required', Required => {
      if (Required) return Yup.date().required('Date is required');
      return Yup.date();
    })
});
