import { Box, styled } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { ProductInventory } from '@setvi/shared/interfaces';
import { Customer } from 'Services/MongoRealm/Types';

export interface LocationProps {
  row: ProductInventory;
  getWarehouseLocation: (warehouse: ProductInventory['warehouse']) => string;
  selectedCustomer: Customer;
}

const Badge = styled(Box)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.primary.main,
  color: palette.common.white,
  borderRadius: spacing(4),
  padding: '2px 4px',
  fontSize: 12,
  minWidth: 'max-content',
  marginLeft: spacing(2)
}));

export const Location = ({
  row,
  selectedCustomer,
  getWarehouseLocation
}: LocationProps) => (
  <Box display="flex" alignItems="center">
    <SText weight="bold" title={getWarehouseLocation(row.warehouse)} />
    {row.warehouseId === selectedCustomer?.warehouse?.code && (
      <Badge>Home DC</Badge>
    )}
  </Box>
);
