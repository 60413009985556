import { styled, Typography, TypographyProps } from '@material-ui/core';

export const SAITextColor =
  'linear-gradient(155deg, #001222 2%, #003A6C  25%,  #3896E0  85%, #1E96FC  100%)';

const Title = styled(Typography)({
  width: 'fit-content',
  fontWeight: 'bold',
  background: SAITextColor,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent'
});

interface AITitle extends TypographyProps {
  children: React.ReactNode;
}

export const SAITitle = ({ children, ...rest }: AITitle) => (
  <Title {...rest}>{children} </Title>
);
