import { MONGO_REALM_COLLECTIONS } from '@setvi/shared/services';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { Customer } from 'Services/MongoRealm/Types';
import { Product } from '@setvi/shared/interfaces';
import { useCallback, useEffect, useState } from 'react';
import {
  CompanyProductUserType,
  CompanySettingsTypeID
} from '@setvi/shared/services/react-query/query/user/types';
import { useSnackbar } from 'notistack';
import { DetailsDialogProps } from '..';

export const useProductDetails = ({
  selectedProduct,
  selectedCustomerId,
  client,
  userType
}: Pick<
  DetailsDialogProps,
  'selectedCustomerId' | 'client' | 'userType' | 'selectedProduct'
>) => {
  const [product, setProduct] = useState<Product>();
  const [customer, setCustomer] = useState<Customer>();
  const [isLoading, setIsLoading] = useState(true);
  const { companyData } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const fetchProduct = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await client
        .db(
          companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]
            ?.Value
        )
        .collection(MONGO_REALM_COLLECTIONS.PRODUCTS)
        .findOne({ _id: selectedProduct._id });

      const getProductPrice = () => {
        if (userType === CompanyProductUserType.Manufacturer)
          return response.price;

        if (selectedProduct.price !== null) return selectedProduct.price;

        return null;
      };

      setProduct({
        ...response,
        price: getProductPrice(),
        ...(selectedProduct.cost !== null ? { cost: selectedProduct.cost } : {})
      });
    } catch (e) {
      enqueueSnackbar('Error occured while fetching product data.', {
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [client, companyData, enqueueSnackbar, selectedProduct, userType]);

  const fetchCustomer = useCallback(
    async (customerId: Customer['_id']) => {
      setIsLoading(true);
      try {
        const response: Customer = await client
          .db(
            companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]
              ?.Value
          )
          .collection(MONGO_REALM_COLLECTIONS.ACCOUNTS)
          .findOne({ _id: customerId });

        setCustomer(response);
      } catch (e) {
        enqueueSnackbar('Error occured while fetching customer data', {
          variant: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    },
    [client, companyData.Settings, enqueueSnackbar]
  );

  const handleProdctPrice = (newValues: Partial<Product>) =>
    setProduct({ ...product, ...newValues });

  useEffect(() => {
    fetchProduct();
    if (selectedCustomerId) fetchCustomer(selectedCustomerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  return { product, customer, isLoading, handleProdctPrice };
};
