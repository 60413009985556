export enum MONGO_REALM_COLLECTIONS {
  ANALYTICS = 'analytics',
  ACCOUNTS = 'accounts',
  ACCOUNT_REVENUE = 'accountRevenue',
  ADDRESSES = 'addresses',
  ADDRESS_REVENUE = 'addressRevenue',
  CATEGORIES = 'categories',
  CATEGORY_TREE = 'categoryTree',
  ORDERS = 'orders',
  PRODUCTS = 'products',
  QUOTES = 'quotes',
  RECEIPT_CALENDAR = 'receiptCalendar',
  USERS = 'users',
  PROMOTIONS = 'promotions'
}

export enum MONGO_REALM_DBS {
  TEST = 'test',
  COVETRUS_DEVELOPMENT = 'covetrus-development'
}
