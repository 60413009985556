import { Box, withStyles } from '@material-ui/core';
import {
  SelectedOptions,
  SelectedOptionsProps
} from './components/selected-options';

const SelectedOptionsContainer = withStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))(Box);

export const ActionBar = (props: SelectedOptionsProps) => (
  <SelectedOptionsContainer>
    <SelectedOptions {...props} />
  </SelectedOptionsContainer>
);
