export enum ROUTES {
  HOME = '/',
  HOME_FEATURED = '/?tab=featured',
  HOME_RECENTLY_UPLOADED = '/?tab=recently-uploaded',
  HOME_FAVORITES = '/?tab=favorites',

  FAVORITES = '/resources/favorites',

  CAMPAIGNS = '/campaigns',
  MY_CAMPAIGNS = '/campaigns/my',
  MY_CAMPAIGNS_SENT = '/campaigns/my?tab=active',
  MY_CAMPAIGNS_CREATE = '/campaigns/my/create',
  MY_CAMPAIGNS_CREATE_RECIPIENTS = '/campaigns/my/create?tab=selectRecipients&tableTab=1',
  MY_CAMPAIGNS_CREATE_CONTACTS = '/campaigns/my/create?tab=selectRecipients&tableTab=2',
  MY_CAMPAIGNS_CREATE_GROUPS = '/campaigns/my/create?tab=selectRecipients&tableTab=3',
  MY_CAMPAIGNS_DETAIL = '/campaigns/my/:id',
  MY_CAMPAIGNS_DETAIL_ALL_RECIPIENTS = '/campaigns/my/:id?tab=all-recipients',
  MY_CAMPAIGNS_DETAIL_ENGAGEMENT_IN_PROGRESS = '/campaigns/my/:id?tab=engagement-in-progress',
  MY_CAMPAIGNS_DETAIL_ENGAGED = '/campaigns/my/:id?tab=engaged',
  MY_CAMPAIGNS_DETAIL_INTERESTED = '/campaigns/my/:id?tab=interested',
  MY_CAMPAIGNS_DETAIL_NOT_INTERESTED = '/campaigns/my/:id?tab=not-interested',
  MY_CAMPAIGNS_DETAIL_FAILED = '/campaigns/my/:id?tab=failed',
  MY_CAMPAIGNS_DETAIL_FOLLOW_UP = '/campaigns/my/:id/followUp',
  MY_CAMPAIGNS_CAMPAIGNS = '/campaigns/my?tab=sent',
  MY_CAMPAIGNS_DRAFTS = '/campaigns/my?tab=drafts',
  MY_CAMPAIGNS_ARCHIVED = '/campaigns/my?tab=archived',
  COMPANY_CAMPAIGNS = '/campaigns/company',
  COMPANY_CAMPAIGNS_ALL = '/campaigns/company?tab=all',

  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  VERIFY_PASSWORD = '/verify-password',
  VERIFY_EMAIL = '/verify-email',

  RESOURCES = '/resources',

  SALES = '/sales',

  /* ORDERS */
  ORDERS = '/sales/orders',
  ORDERS_CREATE = '/sales/orders/create',
  ORDER_DETAILS = '/sales/orders/order/:orderId?',

  ORDERS_BACKORDERS = '/sales/orders/backorders',
  CUSTOMER_BACKORDER_LIST = '/sales/orders/backorders/account/:accountId',
  BACKORDERS_CUSTOMER_ORDER_ITEMS_LIST = '/sales/orders/backorders/account/:accountId/:orderId',
  ORDERS_RECENTLY_DELIVERED_DETAILS = '/sales/orders/recently-delivered/:orderId',

  INVOICES = '/sales/orders/invoices',
  INVOICES_DETAILS = '/sales/orders/invoices/:invoiceId',

  REORDER_INTELIGANCE = '/sales/orders/reorder-intelligence',
  REORDER_INTELIGANCE_DETAILS = '/sales/orders/reorder-intelligence/:customerId/:shipId',
  /* ORDERS */

  QUOTES = '/sales/quotes',
  QUOTES_PENDING_APPROVAL = '/sales/quotes/pending-quotes',
  QUOTE_DETAILS = '/sales/quotes/:quoteId',
  QUOTE_DETAILS_REVIEW = '/sales/quotes/:quoteId/review',
  QUOTE_CREATE = '/sales/quotes/create/:quoteId',
  QUOTE_ADD_FROM_CATALOG = '/sales/quotes/:quoteId/add-from-catalog',
  QUOTE_CREATE_ADD_FROM_CATALOG = '/sales/quotes/create/:quoteId/add-from-catalog',
  QUOTE_CREATE_ADD_FROM_RFQ = '/sales/quotes/create/:quoteId/add-from-rfq',
  QUOTE_CREATE_REVIEW = '/sales/quotes/create/:quoteId/review',

  AUTO_RFQ = '/sales/auto-request-for-quote',
  AUTO_RFQ_CREATE = '/sales/auto-request-for-quote/create',
  AUTO_RFQ_EDIT = '/sales/auto-request-for-quote/edit/:id',

  WORKSPACES = '/workspaces',
  WORKSPACES_CONTACTS_GROUPS = '/workspaces/contacts-and-groups',
  WORKSPACES_CONTACTS = '/workspaces/contacts-and-groups/contacts',
  WORKSPACES_GROUPS = '/workspaces/contacts-and-groups/groups',
  WORKSPACES_GROUPS_DETAIL = '/workspaces/contacts-and-groups/groups/:groupId',
  WORKSPACES_FILES = '/workspaces/files',
  WORKSPACES_PRESENTATIONS = '/workspaces/presentations',
  WORKSPACES_TEMPLATES = '/workspaces/templates',
  WORKSPACES_PRESENTATION_DETAILS = '/workspaces/presentations/:id',
  WORKSPACES_TEMPLATE_DETAILS = '/workspaces/templates/:id',
  WORKSPACES_SNIPPETS = '/workspaces/snippets',
  WORKSPACES_SNIPPETS_MYSNIPPETS = '/workspaces/snippets?tab=my',
  WORKSPACES_SNIPPETS_TEAMSNIPPETS = '/workspaces/snippets?tab=my',
  WORKSPACES_DIGITAL_ROOMS = '/workspaces/digital-rooms',

  EMAIL_QUEUE = '/email-queue',

  OUTBOX = '/outbox',
  OUTBOX_EMAILS = '/outbox/emails',
  OUTBOX_EMAIL_DETAILS_MULTIPLE = '/outbox/emails/:emailId',
  OUTBOX_EMAIL_DETAILS_MULTIPLE_OVERVIEW = '/outbox/emails/:emailId/overview',
  OUTBOX_EMAIL_DETAILS_MULTIPLE_EMAIL_DETAILS = '/outbox/emails/:emailId/email-details',
  OUTBOX_EMAIL_DETAILS_SINGLE = '/outbox/emails/:emailId/recipient/:recipientId',
  OUTBOX_GET_LINK = '/outbox/get-link',
  OUTBOX_OUTLOOK = '/outbox/outlook',
  OUTBOX_OUTLOOK_DETAILS = '/outbox/outlook/:id',
  OUTBOX_OUTLOOK_VIEWS = '/outbox/outlook/:id/views',
  OUTBOX_CAMPAIGN = '/outbox/campaign',

  SETTINGS = '/settings',
  SETTINGS_GENERAL = '/settings?tab=general',
  SETTINGS_CHANGE_PASSWORD = '/settings?tab=change-password',
  SETTINGS_HELP_AND_FEEDBACK = '/settings?tab=help-and-feedback',
  SETTINGS_NOTIFICATIONS = '/settings?tab=notifications',
  // Do not change the value of SETTINGS_INTEGRATIONS because of Nylas connecting process
  SETTINGS_INTEGRATIONS = '/settings?tab=integration',
  SETTINGS_SIGNATURE = '/settings?tab=signature',

  REPORTING = '/report',
  REPORTING_DETAILS = '/report/:id',

  THOUGHTSPOT_LIVEBOARD = '/liveboard',
  THOUGHTSPOT_LIVEBOARD_DETAILS = '/liveboard/:liveboardId',

  ANALYITICS = '/analytics',

  ADMIN = '/admin',
  ADMIN_CAMPAIGNS = '/admin/campaigns',
  ADMIN_CAMPAIGNS_ACTIVE = '/admin/campaigns?tab=active',
  ADMIN_CAMPAIGNS_DRAFTS = '/admin/campaigns?tab=drafts',
  ADMIN_CAMPAIGNS_ARCHIVED = '/admin/campaigns?tab=archived',
  ADMIN_CAMPAIGNS_DETAILS = '/admin/campaigns/details/:id',
  ADMIN_CAMPAIGNS_DETAILS_RECIPIENTS = '/admin/campaigns/details/:id/recipients/:groupId',
  ADMIN_CAMPAIGNS_DETAILS_USER_CAMPAIGNS = '/admin/campaigns/details/:campaignId/user-campaigns/:id',
  ADMIN_CAMPAIGNS_CREATE = '/admin/campaigns/create',
  ADMIN_CAMPAIGNS_EDIT = '/admin/campaigns/edit/:id',

  ADMIN_CAMPAIGNS_CREATE_GENERAL = '?tab=general',
  ADMIN_CAMPAIGNS_CREATE_ASSIGNE = '?tab=assignes',
  ADMIN_CAMPAIGNS_CREATE_REQUIRED_READING = '?tab=required-reading',
  ADMIN_CAMPAIGNS_CREATE_OUTBOUND_EMAIL = '?tab=outbound-email',
  ADMIN_CAMPAIGNS_CREATE_CONFIRM = '?tab=confirm',

  ADMIN_RESOURCES = '/admin/resources',
  ADMIN_RESOURCES_CREATE_CATEGORY = '/admin/resources/create-category',
  ADMIN_RESOURCES_EDIT_CATEGORY = '/admin/resources/edit-category/:id',
  ADMIN_RESOURCES_ADD_SINGLE = '/admin/resources/add-single',
  ADMIN_RESOURCES_EDIT_SINGLE = '/admin/resources/edit-single/:id',
  ADMIN_RESOURCES_ADD_BULK = '/admin/resources/add-bulk',
  ADMIN_RESOURCES_ADD_ZIP = '/admin/resources/add-zip',

  ADMIN_USERS = '/admin/users',
  ADMIN_USERS_CREATE = '/admin/users/create',
  ADMIN_USERS_EDIT = '/admin/users/edit/:id',

  HOME_SCREEN = '/admin/home-screen',
  ADD_BANNER = '/admin/home-screen/add-banner',
  EDIT_BANNER = '/admin/home-screen/edit-banner/:id',
  ADD_FEATURED_RESOURCE = '/admin/home-screen/add-featured-resource',
  EDIT_FEATURED_RESOURCE = '/admin/home-screen/edit-featured-resource/:id',
  ADMIN_AI_SALES_ENABLEMENT = '/admin/ai-sales-enablement',

  PRODUCTS = '/catalog',
  PRODUCTS_LIST_VIEW = '/catalog?view=list',
  PRODUCTS_GRID_VIEW = '/catalog?view=grid',
  PRODUCT_DETAILS = '/catalog/:id',

  VET_SUITE = '/vet-suite',
  DIGITAL_SHOWROOM_VETSUITE = '/workspaces/digital-rooms/vet-suite',
  DIGITAL_SHOWROOM_RFQ = '/workspaces/digital-rooms/rfq-digital-showroom',
  DIGITAL_SHOWROOM_TELABIO_ONE = '/workspaces/digital-rooms/telabio-one',

  NOT_FOUND = '*'
}
