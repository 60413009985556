import { CatalogConfig } from '@setvi/shared/interfaces';
import { CompanySettingsTypeID } from '@setvi/shared/services/react-query/query/user/types';
import { useAppContext } from 'Providers/AppProvider/AppContext';

export const useCatalogConfig = () => {
  const { companyData } = useAppContext();
  const config: CatalogConfig =
    companyData.Settings[CompanySettingsTypeID.CatalogConfig]?.Value;

  const invalidConfiguration = Object.keys(config || {})?.length === 0;

  return { configuration: config, invalidConfiguration };
};
