import { AxiosMethods, AutoRFQApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { RfqBody, SaveAutoRfqItemsBody, UpdateRfqBody } from './types';

export enum AutoRFQMutationsKey {
  CREATE = 'CREATE_AUTO_RFQ',
  UPDATE = 'UPDATE_AUTO_RFQ',
  SAVE_AUTO_RFQ_ITEMS = 'SAVE_AUTO_RFQ_ITEMS',
  UPDATE_AUTO_RFQ_ITEMS = 'UPDATE_AUTO_RFQ_ITEMS',
  DELETE = 'DELETE_AUTO_RFQ',
  FETCH = 'FETCH_AUTO_RFQ_ITEMS'
}

export const createAutoRfqMutation = () => ({
  mutationKey: [AutoRFQMutationsKey.CREATE],
  mutationFn: (body: RfqBody) =>
    axiosHelper({
      endpoint: AutoRFQApi.Create,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_API,
      body
    })
});

export const updateAutoRfqMutation = () => ({
  mutationKey: [AutoRFQMutationsKey.UPDATE],
  mutationFn: ({ id, body }: UpdateRfqBody) =>
    axiosHelper({
      endpoint: AutoRFQApi.Update.replace(':id', id),
      method: AxiosMethods.PUT,
      baseURL: process.env.REVOPS_API,
      body
    })
});

export const saveAutoRfqItemsMutation = () => ({
  mutationKey: [AutoRFQMutationsKey.SAVE_AUTO_RFQ_ITEMS],
  mutationFn: ({ rfqId, products }: SaveAutoRfqItemsBody) =>
    axiosHelper({
      endpoint: AutoRFQApi.SaveItems.replace(':id', rfqId),
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_API,
      body: {
        products
      }
    })
});

export const updateAutoRfqItemsMutation = () => ({
  mutationKey: [AutoRFQMutationsKey.UPDATE_AUTO_RFQ_ITEMS],
  mutationFn: ({ rfqId, products }: SaveAutoRfqItemsBody) =>
    axiosHelper({
      endpoint: AutoRFQApi.UpdateItems.replace(':id', rfqId),
      method: AxiosMethods.PUT,
      baseURL: process.env.REVOPS_API,
      body: {
        products
      }
    })
});

export const deleteRfqMutation = () => ({
  mutationKey: [AutoRFQMutationsKey.DELETE],
  mutationFn: (id: string | number) =>
    axiosHelper({
      endpoint: AutoRFQApi.DeleteSingle.replace(':id', id.toString()),
      baseURL: process.env.REVOPS_API,
      method: AxiosMethods.DELETE
    })
});
