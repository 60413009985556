import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { debounce } from 'lodash';

import { TextColor } from '@setvi/shared/enums';
import { SearchIcon, AIIcon } from '@setvi/shared/components/sicons';

import { AdvancedSearchProps, IconPosition } from '..';
import { Loader } from '../style';

const iconColor = TextColor.Grey;

export interface SearchType {
  label: string;
  value: string;
  icon?: JSX.Element;
}

export const searchTypes = [
  {
    label: 'Search',
    value: 'regular',
    icon: <SearchIcon style={{ fontSize: '1.15rem' }} />
  },
  {
    label: 'Ask AI',
    value: 'ai',
    icon: <AIIcon fontSize="small" />
  }
];

export const useAdvancedSearch = ({
  searchedValue,
  focusAiSearch,
  defaultValue,
  iconPosition,
  loading,

  onChange,
  setActiveSearch
}: Partial<AdvancedSearchProps>) => {
  const [value, setValue] = useState(defaultValue || '');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [focusEl, setFocusEl] = useState<null | HTMLElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((val: string) => onChange(val, 'regular'), 500),
    [onChange]
  );

  const handleInputClear = useCallback(() => {
    setValue('');
    onChange('', 'regular');
    handleChange.cancel();
  }, [handleChange, onChange]);

  const endIcon = useMemo(() => {
    if (loading) return <Loader size={18} />;
    if (iconPosition === IconPosition.end && !value) return null;
    if (value)
      return (
        <IconButton size="small" onClick={handleInputClear}>
          <Close fontSize="small" style={{ color: iconColor }} />
        </IconButton>
      );
    return null;
  }, [handleInputClear, iconPosition, loading, value]);

  useEffect(
    () => {
      if (searchedValue !== undefined && value !== searchedValue) {
        // ! This line gives bug when use searchedValue and onChange in the same time
        // handleChange(searchedValue);
        setValue(searchedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedValue]
  );

  const triggerMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(prev => (!prev ? event.currentTarget : null));
    },
    []
  );

  const onMenuItemClick = (item: { label: string; value: string }) => {
    setAnchorEl(null);
    setFocusEl(null);
    setActiveSearch(item);
    setValue('');
  };

  useEffect(() => {
    if (focusAiSearch) {
      setActiveSearch(searchTypes[1]);
      setValue('');

      const searchField = document.getElementsByClassName(
        'MuiInputBase-root MuiOutlinedInput-root MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal'
      );
      if (searchField?.[0]) setFocusEl(searchField?.[0] as HTMLElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusAiSearch]);

  return {
    focusEl,
    value,
    endIcon,
    anchorEl,
    handleChange,
    setValue,
    setFocusEl,
    triggerMenu,
    setAnchorEl,
    onMenuItemClick
  };
};
