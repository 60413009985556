import { Product } from '@setvi/shared/interfaces';
import { User } from '@setvi/shared/services/react-query/query/user/types';

export interface Products {
  items: Product[];
  pagination: Pagination[];
}

export interface CustomClass {
  dynamicAttributes: DynamicAttribute;
}

export interface DynamicAttribute {
  attribute: RecordType;
}

export interface RecordType {
  value: string | boolean | number | string[];
  label: string;
  type: CustomPropertyType;
  specification: boolean;
}

export enum CustomPropertyType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Link = 'link',
  HTML = 'html',
  LabeledImageArray = 'LabeledImageArray',
  ArrayString = 'arrayString'
}

export interface ProductImage {
  _id: string;
  path: string;
  size: string;
  name: string;
}

export interface ProductInventory {
  _id: string;
  productId: string;
  siteId: string;
  warehouseId: string;
  availableOnHand: number;
  availableOrderedQuantity: number;
  onHandQuantity: number;
  onOrderQuantity: number;
  orderedQuantity: number;
  warehouse: ProductWarehouse | null;
}

export interface ProductWarehouse {
  _id: string;
  code: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zip: string;
}

export interface Vendor {
  _id: string;
  vendorId: string;
  name: string;
}

export interface Pagination {
  totalDocs: number;
  page: number;
  totalPages: number;
}

export interface Categories {
  _id: string;
  categories: Category[];
}

export interface Category {
  _id: string;
  name: string;
  parentId: string;
  breadcrumbs: Breadcrumb[];
  children: Category[];
}

export interface Breadcrumb {
  _id: string;
  name: string;
}

export interface GetProductPrice {
  message: string;
  pricelists: Pricelists;
}

export interface Pricelists {
  pricelist: Pricelist[];
}

export interface Pricelist {
  sku: string;
  bulkpriceList: BulkpriceList[];
}

export interface BulkpriceList {
  price: number;
  quantity: number;
}

export interface Customers {
  items: Customer[];
}

export interface Customer {
  _id: string;
  name: string;
  accountNumber: string;
  addresses: Address[];
  billingAddress: string;
  billingStreet: string;
  billingCity: string;
  billingState: string;
  billingCountry: string;
  billingPostalCode: string;
  companyType: string;
  contacts: CustomerContact[];
  custom: CustomerCustomAttributes;
  description: string;
  email: string;
  fax: string;
  industry: string;
  isDeleted: boolean;
  mandatoryPo: string;
  parentId: string;
  phone: string;
  photoUrl: string;
  receiptCalendar?: string;
  shippingAddress: string;
  shippingStreet: string;
  shippingCity: string;
  shippingState: string;
  shippingCountry: string;
  shippingPostalCode: string;
  warehouse: Warehouse;
  parentAccount: ParentAccount;
}

export interface CustomerContact {
  _id: string;
  title: string | null;
  salutation: string | null;
  phone: string | null;
  name: string | null;
  email: string | null;
  accountId: string;
}

export interface CustomerCustomAttributes {
  cvetAccountId: StandardStringObject;
  clientCommunicationsProvider: StandardStringObject;
  customerPainPoints: ShortArrayObject;
  diagnosticProvider: StandardStringObject;
  email: StandardStringObject;
  goals: ShortArrayObject;
  implementationContact: StandardStringObject;
  isGPMCustomer: StandardBooleanObject;
  keyDecisionMakers: StandardArrayObject;
  migratedToVrxPro: StandardBooleanObject;
  numberOfVets: StandardNumberObject;
  paymentProcessingProvider: StandardStringObject;
  paymentType: StandardArrayObject;
  pimsUsed: StandardStringObject;
  phone: StandardStringObject;
  technologyChampion: StandardStringObject;
  usingCovetrusPaymentProcessing: ConditionalCustomObject;
  usingCovetrusRapport: ConditionalCustomObject;
  usingZoetisForDiagnostics: ConditionalCustomObject;
  usingZoetisForParasticides: ConditionalCustomObject;
  usingZoetisForVaccines: ConditionalCustomObject;
  workHours: StandardStringObject;
  website: StandardStringObject;
  legalName: StandardStringObject;
  lastUpdatedBy: LastUpdatedBy | undefined;
}

export interface ShortArrayObject {
  label: string;
  type: string;
  userValue: ShortArrayObjectType[];
  value: ShortArrayObjectType[];
}

export interface ShortArrayObjectType {
  _id: string;
  name?: string;
  description?: string;
}

export interface ConditionalCustomObject {
  category: string;
  label: string;
  type: string;
  userValue: ConditionalCustomObjectValue;
  value: ConditionalCustomObjectValue;
}

export interface ConditionalCustomObjectValue {
  condition: boolean;
  value: string;
}

export interface StandardStringObject {
  label: string;
  value: string;
  type: string;
  userValue?: string;
  category?: string;
}

export interface StandardBooleanObject {
  label: string;
  value: boolean;
  type: string;
  category: string;
  userValue: boolean;
}

export interface StandardNumberObject {
  label: string;
  value: number;
  type: string;
  category: string;
  userValue: number;
}

export interface StandardArrayObject {
  label: string;
  value: any[];
  type: string;
  userValue: any[];
  category: string;
}

export interface LastUpdatedBy {
  _id: User['ID'];
  name: User['FullName'];
  date: number;
}

interface Warehouse {
  _id: string;
  name: string;
  code: string;
}

interface ParentAccount {
  _id: string;
  name: string;
  accountNumber: string;
}

export interface Address {
  _id: string;
  name: string;
  accountId: string;
  city: string;
  country: string;
  isDeleted: boolean;
  locationId: string;
  postalCode: string;
  state: string;
  street: string;
  street1: string;
  street2: string;
  shipToExtension?: string;
  receiptCalendar?: string;
}

export interface Promotion {
  _id: string;
  promotionId: string;
  description: string;
  startDate: string;
  endDate: string;
  lastModifiedDate: string;
  name: string;
  promotionItems: PromotionItem[];
}

export interface PromotionItemImage {
  _id: string;
  size: string;
  productId: string;
  path: string;
}

export interface PromotionItem {
  _id: string;
  quantity: number;
  image: PromotionItemImage;
  name: string;
  sku: string;
}
