/* eslint-disable no-param-reassign */
import parse from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';
import { Link, LinkItemItems, PreviewLink } from '../../interfaces';
import { contentEditableTag } from '../regex';
import { selectedProductLinkClassName } from '../../components/seditor/hooks';
import { ResourceType } from '../../enums';

export const parseHtmlFEtoBC = (value: string) => {
  const result = document.createElement('div'); // Allow whitespace in email body
  result.setAttribute(
    'style',
    'display: inherit; white-space: normal !important; line-height: normal !important;'
  ); // Set default styles for email body

  // Remove guard texts from embeds
  result.innerHTML = value.replace(/\u200A/g, '').replace(/\u200B/g, '');

  const links = result.querySelectorAll('a');

  Array.from(links).forEach(link => {
    if (link.classList.contains(selectedProductLinkClassName)) {
      link.href = link.getAttribute('data-product-href');
      link.removeAttribute('data-product-href');
      return;
    }

    // Added this so that regular link can be opened in email
    if (link.id) {
      link.href = link.id;
      link.innerHTML = link.innerHTML.trim();
      link.removeAttribute('data-preview-href');
    }
  });

  const variables = result.getElementsByClassName('mce-mergetag');
  Array.from(variables).map(variable => {
    variable.innerHTML = variable.innerHTML.trim();
    return variable;
  });

  const emailBodyElements = [
    'p',
    'ol',
    'ul',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6'
  ];
  emailBodyElements?.forEach(emailBodyElement => {
    const elements = result.getElementsByTagName(emailBodyElement);
    Array.from(elements)?.forEach(element => {
      // eslint-disable-next-line no-param-reassign
      (element as HTMLElement).style.margin = '0';
    });
  });

  return result.outerHTML;
};

export const resourceWithChildResources = [
  ResourceType.Powerpoint,
  ResourceType.Presentation,
  ResourceType.KeyNote,
  ResourceType.PhotoAlbum
];

export const getPreviewLink = (link: Link): PreviewLink => ({
  Name: link.Name,
  ParentID: link.ParentID,
  Items: link?.Item?.Items?.map((linkItem: LinkItemItems) =>
    resourceWithChildResources.includes(linkItem.ResourceTypeID) &&
    linkItem.Children?.length > 0
      ? linkItem.Children.map(c => ({
          ObjectId: c.ID,
          ObjectType: c.ObjectType
        }))
      : {
          ObjectId: linkItem.ID,
          ObjectType: linkItem.ObjectType
        }
  ).flat()
});

export const previewLinks = (value: string, links: PreviewLink[]) =>
  links.reduce(
    (preValue, link: Link) =>
      preValue.replace(link.Placeholder, generateLinkViewerUrl(link)),
    value
  );

export const parseHtmlForPreview = (
  value: string,
  replaceVariableElement?: boolean
) => {
  const result = document.createElement('div');
  result.innerHTML = value.replace(/\uFEFF/g, '');

  const variables = result.getElementsByClassName('variable');
  Array.from(variables).map(variableElement => {
    const trimmed = variableElement.innerHTML.replace(/\u200B/g, '').trim();

    if (!replaceVariableElement) {
      // eslint-disable-next-line no-param-reassign
      variableElement.innerHTML = trimmed;
      return variableElement;
    }

    const spanElement = document.createElement('span');
    // eslint-disable-next-line no-param-reassign
    spanElement.innerHTML = trimmed;

    variableElement?.parentNode?.replaceChild(spanElement, variableElement);

    return spanElement;
  });

  return result.innerHTML;
};

// Remove empty link embeds, editor sometimes leaves
// an empty tag when deleting a link embed
export const removeEmptyLinkEmbeds = (value: string) => {
  let result = value;

  const linkMatches = value.match(
    /<a class="immutableLink"[^>]*>([\s\S]*?)<\/a>/g
  );
  linkMatches?.map(linkMatch => {
    const linkText = linkMatch
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/\u200B/g, '')
      .replace(/\uFEFF/g, '')
      .trim();
    if (!linkText?.length) {
      result = result.replace(linkMatch, '');
    }
    return linkMatch;
  });

  return result;
};

// Remove embed wrapper tags, initialValue to editor
// should NOT have this span tag
export const removeEmbedWrapperTags = (value: string) => {
  let result = value;

  const matches = value.match(/<span [^>]*>([\s\S]*?)<\/span>/g);
  matches?.map(match => {
    const toReplace = match.replace(contentEditableTag, '').trim();
    result = result.replace(match, toReplace);
    return match;
  });

  return result;
};

export const cleanEmbeds = (value: string) =>
  removeEmbedWrapperTags(removeEmptyLinkEmbeds(value));

export const verifyLinksInDom = (
  value: string,
  currentLink: Link[],
  allLinks?: Link[]
): Link[] => {
  let foundedLinks: string[] = [];

  parse(value, {
    replace: domNode => {
      const typedDomNode = domNode as Element;

      if (
        typedDomNode.attribs &&
        typedDomNode.attribs.class === 'immutableLink'
      ) {
        const linkPlaceholder = (typedDomNode as Element).attribs.href;

        if (linkPlaceholder && foundedLinks.indexOf(linkPlaceholder) === -1) {
          foundedLinks = [...foundedLinks, linkPlaceholder];
        }
      }
    }
  });

  let links: Link[] = [];

  // Look in campaignMeta.Links first
  links = currentLink.filter(
    link => foundedLinks.indexOf(link.Placeholder) !== -1
  );

  // Then check allLinks for links that are in body but not in campaignMeta.Links
  links = [
    ...links,
    ...allLinks.filter(
      addedLink =>
        foundedLinks.indexOf(addedLink.Placeholder) !== -1 &&
        links.findIndex(link => link.Placeholder === addedLink.Placeholder) ===
          -1
    )
  ];

  return links;
};
