import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useSelected } from '@setvi/shared/hooks';
import algoliasearch from 'algoliasearch';
import { Settings } from '@algolia/client-search';
import { useSnackbar } from 'notistack';
import { Category, Customer } from 'Services/MongoRealm/Types';
import {
  CompanyProductUserType,
  CompanySettingsTypeID
} from '@setvi/shared/services/react-query/query/user/types';
import { useCategories } from 'pages/products/components/categories/useCategories';
import { Product } from '@setvi/shared/interfaces';
import { useSearchParams } from 'react-router-dom';
import { useProductsPrices } from 'pages/products/api';
import { StringParam, useQueryParam } from 'use-query-params';
import { useMutation } from '@tanstack/react-query';
import { getProductsByText } from 'Services';
import { searchTypes } from 'pages/products/components/advanced-search/hook';

export const CATEGORY_FILTER = 'categories.breadcrumbs._id:';

export const getFilters = (id: Category['_id']) =>
  id ? `${CATEGORY_FILTER}${id}` : '';

export interface NormalizeFacetProps {
  facets: readonly string[];
  facetsValues: Record<string, Record<string, number>>;
}

export const sortValues = (values: FacetValue[]) =>
  values.sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  });

export const sortFullList = (
  name: string,
  values: FacetValue[],
  selectedFacets: string[]
) => {
  const selected: FacetValue[] = [];
  const unselected: FacetValue[] = [];
  const selectedValues = selectedFacets
    .filter(value => value.split(':')[0].includes(name))
    .map(value => {
      const isValueWithImageUrl = value.includes('https://');
      if (value.split(':')[0].includes(name))
        return isValueWithImageUrl
          ? `${value.split(':')[1]}:${value.split(':')[2]}`
          : value.split(':')[1];
      return null;
    });

  values.forEach(value => {
    if (selectedValues.includes(value.value)) {
      selected.push(value);
    } else {
      unselected.push(value);
    }
  });

  return [...sortValues(selected), ...sortValues(unselected)];
};

// FIXME: This can be removed when we extend facets state to contain parent facet with values
export const buildFacets = (facets: string[]) => {
  const groupedFacets = facets.reduce(
    (acc: { [key: string]: string[] }, curr) => {
      const [key, value] = curr.split(':');
      let val = value;

      if (key === 'custom.product_certifications.value') {
        val += `:${curr.split(':')[2]}`;
      }

      return {
        ...acc,
        [key]: acc[key] ? [...acc[key], `${key}:${val}`] : [`${key}:${val}`]
      };
    },
    {}
  );
  return Object.entries(groupedFacets).map(([key]) => groupedFacets[key]);
};

export const findCategoryRecursive = (
  categories: Category[],
  categoryId: string
): Category | null => {
  for (let i = 0; i < categories.length; i += 1) {
    if (categories[i]._id === categoryId) {
      return categories[i];
    }
    if (categories[i].children) {
      const found = findCategoryRecursive(categories[i].children, categoryId);
      if (found) return found;
    }
  }
  return null;
};

const custom = {
  _15for15: 'custom.15_for_15_product',
  countriesForSale: 'custom.countries_certified_for_sale'
};

export const customerAccountNumber = 'customerAccountNumber';

interface useAlgoliaProductsProps {
  hitsPerPage?: number;
  selectedCustomer?: Customer;
  userType?: CompanyProductUserType;
  canFetchPrices?: boolean;
  fetchProductsByDefault?: boolean;
}

export type FacetValue = {
  value: string;
  count: number;
};
export type FormattedFacet = {
  name: string;
  values: FacetValue[];
};

enum SearchType {
  Regular = 'regular',
  AI = 'ai'
}

export const useAlgoliaProducts = ({
  hitsPerPage = 20,
  selectedCustomer,
  userType,
  canFetchPrices,
  fetchProductsByDefault = true
}: useAlgoliaProductsProps) => {
  const [searchParams] = useSearchParams();
  const { companyData, user: appUser } = useAppContext();
  const { categories } = useCategories();
  const { getPrices } = useProductsPrices();

  const { mutateAsync: fetchByText, isLoading: isLoadingByText } =
    useMutation(getProductsByText());

  const productAppId = useMemo(
    () =>
      companyData.Settings[CompanySettingsTypeID.AlgoliaProductAppId]?.Value,
    [companyData]
  );
  const apiKey = useMemo(
    () =>
      companyData.Settings[CompanySettingsTypeID.AlgoliaProductSearchApiKey]
        ?.Value,
    [companyData]
  );
  const productIndex = useMemo(
    () =>
      companyData.Settings[CompanySettingsTypeID.AlgoliaProductIndex]?.Value,
    [companyData]
  );
  const client = useMemo(
    () => algoliasearch(productAppId, apiKey),
    [productAppId, apiKey]
  );
  const index = useMemo(
    () => client.initIndex(productIndex),
    [client, productIndex]
  );

  const selectedCustomerAccountNumber = useMemo(
    () =>
      selectedCustomer?.accountNumber ||
      searchParams.get(customerAccountNumber),
    [searchParams, selectedCustomer?.accountNumber]
  );

  const externalMiddlebyUser = appUser.ExternalUserDomainId !== 0;

  const { handleSelect: onSelectFacet, selected: selectedFacets } =
    useSelected<string>({
      dataKeyGetter: el => el
    });
  const [categoryId] = useQueryParam('categoryId', StringParam);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<Category>(null);
  const [filters, setFilters] = useState<string>('');
  const [isLoading, setIsloading] = useState<boolean>(!!categoryId);
  const [productPricesLoading, setProductPricesLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [facets, setFacets] = useState<FormattedFacet[]>([]);
  const [page, setPage] = useState<number>(0);
  const [settings, setSettings] = useState<Settings | undefined>();
  const [activeSearch, setActiveSearch] = useState(searchTypes[0]);

  const { enqueueSnackbar } = useSnackbar();

  const normalizeFacets = useCallback(
    ({ facets: facetResponse, facetsValues }: NormalizeFacetProps) => {
      // !FIXME This condition is currently solution on the FE side, should remove it and fix it on the BC side when possible
      // Hide these 2 facet filters for Middleby external users
      const list = externalMiddlebyUser
        ? facetResponse.filter(
            i => ![custom._15for15, custom.countriesForSale].includes(i)
          )
        : facetResponse;

      return list
        .map(facet => ({
          name: facet,
          values: sortValues(
            Object.keys(facetsValues[facet] || {}).map(key => ({
              value: key,
              count: facetsValues[facet][key]
            }))
          )
        }))
        .filter(({ values }) => values.length);
    },
    [externalMiddlebyUser]
  );

  const fetchProducts = useCallback(
    async ({
      query = searchQuery,
      selectedFilters = filters,
      startingPage = page,
      selectedFacetFilters = selectedFacets,
      latestSelectedFacetsKey = ''
    } = {}) => {
      const isFetchMore = startingPage > 0;

      if (!isFetchMore) setIsloading(true);

      // !FIXME This condition is currently solution on the FE side, should remove it and fix it on the BC side when possible
      // Always use these 3 facet filters as default for Middleby external users
      const selectedFacetsList = externalMiddlebyUser
        ? [
            `${custom.countriesForSale}:United States`,
            `${custom.countriesForSale}:United States of America`,
            `${custom.countriesForSale}:Canada`,
            ...selectedFacetFilters
          ]
        : selectedFacetFilters;

      try {
        const response = await index.search<Product>(query, {
          hitsPerPage,
          facets: ['*'],
          facetFilters: buildFacets(selectedFacetsList),
          filters: selectedFilters,
          page: startingPage
        });

        let prod: Product[] =
          response?.hits.map(hit => ({
            ...hit,
            price:
              userType === CompanyProductUserType.Manufacturer
                ? hit.price
                : null
          })) || [];

        if (!!prod.length && canFetchPrices && selectedCustomerAccountNumber) {
          const prices = await getPrices(
            response.hits,
            selectedCustomerAccountNumber
          );

          prod =
            response?.hits.map(hit => {
              const product = prices.find(i => i.sku === hit.sku);
              return {
                ...hit,
                price: product ? product.price : null,
                ...(typeof product?.cost === 'number'
                  ? { cost: product.cost }
                  : {}),
                // @ts-ignore Incorrect response type on getPrices API
                isPriceEditable: product?.isEditable
              };
            }) || [];
        }

        setProducts(prevProducts => [...prevProducts, ...prod]);
        setPage(p => p + 1);
        setIsLastPage(response.hits.length < hitsPerPage);

        const normalaziedFacets = normalizeFacets({
          facets: response.renderingContent.facetOrdering.facets.order,
          facetsValues: response.facets
        });

        setFacets(prevFacets =>
          // FIXME: It should be better if we keep the whole facets in the state with label an value
          normalaziedFacets.map(normFacet => {
            if (
              selectedFacetFilters?.length &&
              prevFacets?.length &&
              normFacet?.name === latestSelectedFacetsKey
            ) {
              const lastSelected = prevFacets.find(
                prevFacet => prevFacet.name === latestSelectedFacetsKey
              );

              return {
                ...lastSelected,
                values: sortFullList(
                  lastSelected?.name,
                  lastSelected?.values,
                  selectedFacetsList
                )
              };
            }
            return normFacet;
          })
        );
      } catch (e) {
        enqueueSnackbar('Error occurred while searching products', {
          variant: 'error'
        });
      } finally {
        setIsloading(false);
      }
    },
    [
      enqueueSnackbar,
      externalMiddlebyUser,
      filters,
      getPrices,
      hitsPerPage,
      index,
      normalizeFacets,
      page,
      searchQuery,
      selectedCustomerAccountNumber,
      selectedFacets,
      canFetchPrices,
      userType
    ]
  );

  const getIndexSettings = useCallback(() => {
    index.getSettings().then(setSettings);
  }, [index]);

  const searchProducts = async (
    query: string,
    type: SearchType = SearchType.Regular
  ) => {
    if (type === SearchType.AI) {
      const { isSuccess, result } = await fetchByText({ text: query });

      if (isSuccess && result) {
        const { matchedItems } = result;
        const items = matchedItems.flatMap(
          ({
            matchedInternalProducts
          }: {
            matchedInternalProducts: Product[];
          }) => matchedInternalProducts
        );

        if (items?.length) {
          setProducts(items);
        }
      }
    } else {
      setSelectedCategory(null);
      setFilters('');
      onSelectFacet(false, selectedFacets);
      setSearchQuery(query);
      setPage(0);
      setProducts([]);
      fetchProducts({
        query,
        selectedFilters: '',
        startingPage: 0,
        selectedFacetFilters: []
      });
    }
  };

  const fetchMore = () => {
    if (isLastPage || activeSearch === searchTypes[1]) return;

    const latestSelectedFacetsKey =
      selectedFacets[selectedFacets.length - 1]?.split(':')?.[0] || '';

    fetchProducts({ latestSelectedFacetsKey });
  };

  const fetchProductsByFacet = (isChecked: boolean, facet: string[]) => {
    onSelectFacet(isChecked, facet);

    const currentFacets = isChecked
      ? [...selectedFacets, ...facet]
      : selectedFacets.filter(f => !facet.includes(f));

    setPage(0);
    setProducts([]);
    fetchProducts({
      startingPage: 0,
      selectedFacetFilters: currentFacets,
      latestSelectedFacetsKey:
        facet.length === 1 && !!currentFacets.length
          ? facet[0]?.split(':')[0]
          : ''
    });
  };

  const handleGetAlgoliaProductPrice = useCallback(
    async (
      productList: Product[],
      accountNumber: Customer['accountNumber']
    ) => {
      setProductPricesLoading(true);
      const prices = await getPrices(productList, accountNumber);
      setProducts(prevProducts =>
        prevProducts.map(item => {
          const obtainedPrice = prices?.find(i => i.sku === item.sku)?.price;
          const obtainedCost = prices?.find(i => i.sku === item.sku)?.cost;
          const obtainedIsPriceEditable = prices?.find(
            i => i.sku === item.sku
            // @ts-ignore Incorrect response type on getPrices API
          )?.isEditable;

          return {
            ...item,
            ...(typeof obtainedPrice === 'number'
              ? { price: obtainedPrice }
              : {}),
            ...(typeof obtainedCost === 'number' ? { cost: obtainedCost } : {}),
            isPriceEditable: obtainedIsPriceEditable
          };
        })
      );
      setProductPricesLoading(false);
    },
    [getPrices]
  );

  const handleUpdateAlgoliaProductPrice = useCallback(
    (product: Product, newValues: Partial<Product>) => {
      setProducts(prevProducts =>
        prevProducts.map(prod =>
          prod._id === product._id ? { ...prod, ...newValues } : prod
        )
      );
    },
    []
  );

  const getInitialFacets = useCallback(async () => {
    const response = await index.search('', {
      hitsPerPage,
      facets: ['*']
    });
    const normalaziedFacets = normalizeFacets({
      facets: response.renderingContent.facetOrdering.facets.order,
      facetsValues: response.facets
    });
    setFacets(normalaziedFacets);
  }, [hitsPerPage, index, normalizeFacets]);

  const fetchProductsByCategory = (id: Category['_id']) => {
    // Currently if user click on "All Categories" in the breadcrumb list, we clear the selected category state and fetch init values for products, facets and settings
    if (id === null) {
      setSelectedCategory(null);
      getInitialFacets();
      getIndexSettings();
      setProducts([]);
      setFilters('');
      setSearchQuery('');
      setPage(0);
      fetchProducts({
        query: '',
        selectedFilters: '',
        startingPage: 0
      });
      return;
    }

    // FIXME: find better way to get category
    const category = findCategoryRecursive(categories, id);
    setSelectedCategory(category);
    setFilters(getFilters(category._id));
    setPage(0);
    setProducts([]);
    setSearchQuery('');
    fetchProducts({
      selectedFilters: getFilters(category._id),
      query: '',
      startingPage: 0
    });
  };

  useEffect(() => {
    getIndexSettings();
    if (!categoryId && fetchProductsByDefault) fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categoryId && categories.length) fetchProductsByCategory(categoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, categoryId]);

  useEffect(() => {
    setProducts(prevProducts =>
      prevProducts.map(item => ({ ...item, price: null }))
    );
  }, [selectedCustomer]);

  const refetchProducts = () => {
    setPage(0);
    setProducts([]);
    fetchProducts({
      startingPage: 0
    });
  };

  return {
    searchQuery,
    attributesForFaceting: settings?.attributesForFaceting || [],
    productPricesLoading,
    isLoading: isLoading || isLoadingByText,
    products,
    facets,
    selectedFacets,
    selectedCategory,
    activeSearch,
    setActiveSearch,
    searchProducts,
    fetchProductsByCategory,
    fetchMore,
    refetchProducts,
    onSelectFacet: fetchProductsByFacet,
    handleGetAlgoliaProductPrice,
    handleUpdateAlgoliaProductPrice
  };
};
