import { Product } from '@setvi/shared/interfaces';
import { MONGO_REALM_COLLECTIONS } from '@setvi/shared/services';
import { CompanySettingsTypeID } from '@setvi/shared/services/react-query/query/user/types';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useRealmContext } from 'Providers/MongoRealmProvider/context';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  product: Product;
}

export const useAssociatedProducts = ({ product }: Props) => {
  const [associatedProducts, setAssociatedProducts] = useState<Product[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { client } = useRealmContext();
  const { companyData } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const fetchAssociatedProducts = useCallback(async () => {
    try {
      const response = await client
        .db(
          companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]
            ?.Value
        )
        .collection(MONGO_REALM_COLLECTIONS.PRODUCTS)
        .find({ sku: { $in: product.productAssociations } });

      setAssociatedProducts(response);
    } catch (error) {
      enqueueSnackbar('Error occured while fetching associated products.', {
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    client,
    companyData.Settings,
    enqueueSnackbar,
    product.productAssociations
  ]);

  useEffect(() => {
    fetchAssociatedProducts();
  }, [fetchAssociatedProducts]);

  return {
    associatedProducts,
    isLoading
  };
};
