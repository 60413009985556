import { Box } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';
import { STextProps } from '@setvi/shared/components/sui/stext';
import { useState } from 'react';
import { Price } from '../products-list/useProductColumns/contents/price';

interface PriceButtonProps {
  price: number;
  highlight?: boolean;
  prefix?: string;
  weight?: STextProps['weight'];
  size?: STextProps['size'];
  customLoader?: JSX.Element;
  onGetPrice: () => Promise<void>;
}

export const PriceButton = ({
  price,
  highlight = false,
  prefix = '',
  weight,
  size,
  customLoader,
  onGetPrice
}: PriceButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleGetPrice = async () => {
    setLoading(true);
    try {
      await onGetPrice();
    } finally {
      setLoading(false);
    }
  };

  if (price || (typeof price === 'number' && price === 0))
    return (
      <Box display="flex" alignItems="center">
        <Price
          value={price}
          prefix={prefix}
          weight={weight}
          size={size}
          highlight={highlight}
        />
      </Box>
    );

  if (loading && customLoader) return customLoader;

  return (
    <SButton
      loading={loading}
      onClick={e => {
        e.stopPropagation();
        handleGetPrice();
      }}
      style={{ width: 'max-content' }}
      variant="outlined">
      Get Price
    </SButton>
  );
};
