import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { SButton, SText } from '@setvi/shared/components';

import { Container, Footer, StyledChip } from './styled';

export enum EQUIPMENT_TYPE {
  PIZZA = 'PIZZA'
}

export interface EquipmentCardProps {
  type: EQUIPMENT_TYPE;
  title: string;
  tag: string;
  onClick: (t: EQUIPMENT_TYPE) => void;
}

const EquipmentCard = ({ title, type, tag, onClick }: EquipmentCardProps) => (
  <Container>
    <SText title={title} maxLines={3} />

    <Footer>
      <StyledChip label={tag} />
      <SButton
        variant="text"
        endIcon={<ArrowForwardIcon />}
        onClick={() => onClick(type)}
        style={{
          fontWeight: 'normal',
          textDecoration: 'underline'
        }}>
        Use This
      </SButton>
    </Footer>
  </Container>
);

export default EquipmentCard;
