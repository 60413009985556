import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

import { SETVIImage, SHighlightText, SText } from '@setvi/shared/components';
import {
  ProductPriceType,
  Product as ProductType
} from '@setvi/shared/interfaces';
import { distances } from '@setvi/shared/styles';
import { TextColor } from '@setvi/shared/enums';
import { PriceButton } from 'pages/products/components/price-button';
import { getProductStatus } from 'componentsV2/product/product-status';
import { Customer } from 'Services/MongoRealm/Types';
import { Price } from '../../../useProductColumns/contents';

export const useProductsStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: distances.px.base,
    borderBottom: `1px solid ${TextColor.LightGrey}`
  },
  wrapper: {
    paddingBottom: spacing(2),
    marginBottom: spacing(2),
    borderBottom: '1px solid #E6E7E9',
    display: 'flex',
    flexDirection: 'column',
    gap: 6
  },
  img: {
    width: 50,
    height: 37,
    borderRadius: 2
  },
  removeButton: {
    cursor: 'pointer'
  }
}));

interface ProductProps {
  product: ProductType;
  searchQuery?: string;
  priceLoading?: boolean;
  priceType: ProductPriceType;
  selectedCustomer: Customer;
  removeProduct: (product: ProductType) => void;
  getProductPrice: (product: ProductType[]) => Promise<void>;
}

export const Product = ({
  product,
  searchQuery,
  priceType,
  priceLoading,
  selectedCustomer,
  removeProduct,
  getProductPrice
}: ProductProps) => {
  const classes = useProductsStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box display="flex" gridGap={12} alignItems="center">
          <SETVIImage
            className={classes.img}
            src={
              product.imageUrl ||
              product.images[0]?.path ||
              '/images/product-placeholder.svg'
            }
            alt={product.name}
          />
          <SHighlightText
            value={product.name}
            searchQuery={searchQuery}
            maxLines={2}
            size="sm"
            weight="semibold"
          />
        </Box>
        <Box display="flex" gridGap={4}>
          <SHighlightText
            value={`SKU: ${product?.sku}`}
            searchQuery={searchQuery}
            fontColor={TextColor.Grey}
            size="sm"
          />
          <SText
            fontColor={TextColor.Grey}
            size="sm"
            title={getProductStatus(product)}
          />
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <SHighlightText
          value={
            product?.vendor?.name
              ? `MFG: ${product?.vendor?.name}`
              : 'Not Available'
          }
          searchQuery={searchQuery}
          size="sm"
        />
        {priceType !== ProductPriceType.List && (
          <SHighlightText
            value={product.manufacturerSku || '-'}
            searchQuery={searchQuery}
            fontColor={TextColor.Grey}
            size="sm"
          />
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row-reverse">
        <RemoveCircleIcon
          color="error"
          className={classes.removeButton}
          onClick={() => removeProduct(product)}
        />
        {priceType === ProductPriceType.Full && selectedCustomer && (
          <>
            {priceLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Price value={product.price} weight="semibold" />
            )}
          </>
        )}
        {priceType === ProductPriceType.List && (
          <Price prefix="List: " value={product.price} weight="semibold" />
        )}
        {priceType === ProductPriceType.Get && selectedCustomer && (
          <Box height={40} display="flex" alignItems="center">
            <PriceButton
              weight="semibold"
              price={product.price}
              customLoader={<CircularProgress size={20} />}
              onGetPrice={() => getProductPrice([product])}
            />
          </Box>
        )}
        {priceType === ProductPriceType.GetFull && selectedCustomer && (
          <Box height={40} display="flex" alignItems="center">
            <PriceButton
              weight="semibold"
              price={product.price}
              customLoader={<CircularProgress size={20} />}
              onGetPrice={() => getProductPrice([product])}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
