import { Box, IconButton, Tooltip, withStyles } from '@material-ui/core';
import { SButton, SText } from '@setvi/shared/components';

export const Container = withStyles({
  root: {
    gap: 16,
    zIndex: 2,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 32px',
    background: '#FAFAFB',
    borderRadius: 65,
    width: 'fit-content',
    height: 'fit-content',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    bottom: 20,
    left: 0,
    right: 0,
    margin: 'auto'
  }
})(Box);

export const DeselectButton = withStyles(() => ({
  root: {
    textDecoration: 'underline !important',
    fontWeight: 'normal',
    fontSize: 15
  }
}))(SButton);

export const ActionMenu = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12
  }
}))(Box);

export const StyledIcon = withStyles(() => ({
  root: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E2E2EA',
    borderRadius: 8
  }
}))(IconButton);

export const NumberWrapper = withStyles(({ palette }) => ({
  root: {
    background: palette.primary.main,
    borderRadius: '50%',
    color: '#fff',
    width: 25,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12
  }
}))(Box);

export const SelectedWrapper = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  }
}))(SText);

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
    maxWidth: 600
  }
}))(Tooltip);
