import { useState } from 'react';
import {
  Product,
  ProductPriceType,
  RealmApp,
  RealmUser
} from '@setvi/shared/interfaces';

import { Customer } from 'Services/MongoRealm/Types';
import {
  Box,
  Divider,
  IconButton,
  styled,
  Typography
} from '@material-ui/core';
import { SAIDIalogContainer, SBackButton } from '@setvi/shared/components';
import CloseIcon from '@material-ui/icons/Close';
import { useDialog } from '@setvi/shared/providers';
import { AIDIalogContainer } from '@setvi/shared/components/ai/dialog-container/styled';
import { Result } from './components';
import SearchForm, { SearchFormTabs } from './components/form';

const ResultDialogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3)
}));

export interface ProductMatchProps {
  handleEmail: (products: Product[]) => void;
  onClose: () => void;
  tabs: string[];
  priceType: ProductPriceType;
  priceLoading?: boolean;
  selectedCustomer: Customer;
  mongoUser: RealmUser;
  mongoClient: RealmApp;
  defaultType?: SearchFormTabs;
  title?: string;
  description?: string;
}

enum View {
  INIT = 'init',
  RESULT = 'result'
}
const contentWidth = '70vw';
const contentHeight = '90vh';

const ProductMatch = ({
  onClose,
  tabs,
  priceType,
  selectedCustomer,
  priceLoading,
  defaultType = SearchFormTabs.URL,
  title,
  description,
  ...rest
}: ProductMatchProps) => {
  const [view, setView] = useState(View.INIT);
  const [type, setType] = useState<SearchFormTabs>(SearchFormTabs.EQUIPMENT);
  const [values, setValues] = useState({
    url: '',
    text: '',
    image: '',
    imageText: '',
    equipment: ''
  });

  const { closeDialog } = useDialog();

  if (view === View.INIT)
    return (
      <SAIDIalogContainer
        width={contentWidth}
        height={contentHeight}
        title={title}
        headerDivider={false}
        description={description}>
        {view === View.INIT && (
          <Box
            px={4}
            display="flex"
            flexDirection="column"
            overflow="hidden"
            height="100%">
            <SearchForm
              expanded
              type={defaultType}
              tabs={tabs}
              values={values}
              height="90%"
              onSubmit={(searchType: SearchFormTabs) => {
                setView(View.RESULT);
                setType(searchType);
              }}
              setValues={setValues}
            />
          </Box>
        )}
      </SAIDIalogContainer>
    );

  return (
    <AIDIalogContainer
      width={contentWidth}
      height={contentHeight}
      style={{ backgroundColor: '#fff' }}>
      <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
        <ResultDialogHeader>
          <Box display="flex" alignItems="center" gridGap="8px">
            <SBackButton size="small" onClick={() => setView(View.INIT)} />
            <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
              AI Product Match
            </Typography>
          </Box>
          <Box alignSelf="flex-start" mt={1}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </ResultDialogHeader>
        <Divider />
        <Result
          tabs={tabs}
          type={type}
          values={values}
          onClose={onClose}
          setValues={setValues}
          setType={setType}
          priceLoading={priceLoading}
          selectedCustomer={selectedCustomer}
          priceType={priceType}
          {...rest}
        />
      </Box>
    </AIDIalogContainer>
  );
};

export default ProductMatch;
