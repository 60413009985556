import { Input } from 'rsuite';
import { useState, useRef, useEffect, useMemo } from 'react';

import { AIIcon } from '@setvi/shared/components/sicons';
import { SText } from '@setvi/shared/components';

import { Actions, Container, SubmitButton } from './styled';

const MAX_LENGTH = 500;

interface AITextareaProps {
  onSubmit: (val: string) => void;
}

const AITextarea = ({ onSubmit }: AITextareaProps) => {
  const [text, setText] = useState('');
  const [height, setHeight] = useState(40); // Initial height
  const textRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const textHeight = textRef.current.scrollHeight;

      // textRef.current.style.height = 'auto'; // Reset height
      setHeight(!text?.length ? 40 : textHeight); // Set new height
    }
  }, [text]);

  const hasError = useMemo(() => text?.length >= MAX_LENGTH, [text?.length]);

  return (
    <Container error={hasError}>
      <Actions error={hasError}>
        {hasError && (
          <SText
            size="sm"
            fontColor="red"
            title={`You have reached the maximum character limit (${MAX_LENGTH}).`}
          />
        )}

        <SubmitButton
          type="submit"
          disabled={!text?.trim()}
          onClick={() => {
            onSubmit(text);
            setText('');
          }}
          startIcon={<AIIcon htmlColor="#fff" />}>
          Ask
        </SubmitButton>
      </Actions>
      <Input
        as="textarea"
        ref={textRef}
        value={text}
        placeholder="Ask Anything..."
        onChange={value => setText(value)}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent new line in textarea
            onSubmit(text);
            setText('');
          }
        }}
        maxLength={MAX_LENGTH}
        style={{
          width: '100%',
          resize: 'none',
          overflow: 'hidden',
          border: 'none',
          outline: 'none',
          background: 'white',
          height: `${height}px`
        }}
      />
    </Container>
  );
};

export default AITextarea;
