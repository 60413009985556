import { Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';

interface FullContentProps {
  product: Product;
}
export const FullContent = ({ product }: FullContentProps) => (
  <Box style={{ width: '100%' }}>
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px'
      }}>
      <SText
        title={`Sell: ${
          Number.isFinite(product.price)
            ? product.price.toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency'
              })
            : '-'
        }`}
      />
      <SText
        title={`Cost: ${
          product.cost > 0
            ? product.cost.toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency'
              })
            : '-'
        }`}
      />
    </Box>
    <Box style={{ display: 'flex' }}>
      <SText
        title={`GP: ${
          product?.price > product?.cost
            ? (product.price - product.cost).toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency'
              })
            : '-'
        }`}
      />
      {product?.price > product?.cost && (
        <SText
          style={{ marginLeft: '6px' }}
          weight="normal"
          fontColor="#23A566"
          title={`(${product.price > 0 ? (((product.price - product.cost) / product.price) * 100).toFixed(2).toString() : 0}%)`}
        />
      )}
    </Box>
  </Box>
);
