import { AxiosMethods } from '@setvi/shared/enums';
import { Product } from '@setvi/shared/interfaces';
import { axiosHelper } from '@setvi/shared/services';
import { useMutation } from '@tanstack/react-query';
import { Customer } from 'Services/MongoRealm/Types';
import { enqueueSnackbar } from 'notistack';

export const getProductsPrices = () => ({
  mutationFn: (body: any) =>
    axiosHelper({
      endpoint: '/pricing/get',
      method: AxiosMethods.POST,
      body,
      baseURL: process.env.REVOPS_API
    })
});

export const useProductsPrices = () => {
  const { mutationFn } = getProductsPrices();
  const mutation = useMutation(mutationFn);

  const getPrices = async (
    productList: Product[],
    accountNumber: Customer['accountNumber']
  ) => {
    try {
      const products = productList.map(product => ({
        sku: product.sku,
        quantity: 1
      }));

      // Incorrect type of response
      const response: { pricing: Product[] } = await mutation.mutateAsync({
        accountNumber,
        products
      });

      return response?.pricing?.map(product => ({
        ...product,
        price: Number(product.sell)
      }));
    } catch (error) {
      enqueueSnackbar('Error occured while fetching products price', {
        variant: 'error'
      });
      return null;
    }
  };

  return { getPrices, isLoading: mutation.isLoading };
};
