import { Box, Divider } from '@material-ui/core';
import { STabs } from '@setvi/shared/components';
import {
  ProductDetailsTab,
  ProductDetailsTabsIds
} from '@setvi/shared/interfaces';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const TabNames = {
  [ProductDetailsTabsIds.WAREHOUSE]: 'Warehouse',
  [ProductDetailsTabsIds.ASSOCIATED_RESOURCE]: 'Associated Resources',
  [ProductDetailsTabsIds.ASSOCIATED_PRODUCTS]: 'Options and Accessories',
  [ProductDetailsTabsIds.SIMILAR_PRODUCTS]: 'Similar Products',
  [ProductDetailsTabsIds.PRODUCT_INFO]: 'Product Info',
  [ProductDetailsTabsIds.SALES_ENABLEMENT]: 'Sales Enablement'
};

interface ProductDetailsTabsProps {
  tabs: ProductDetailsTab[];
  currentTab: ProductDetailsTabsIds;
  onTabChange: (tab: ProductDetailsTabsIds) => void;
}

export const Tabs = ({
  currentTab,
  tabs = [],
  onTabChange
}: ProductDetailsTabsProps) => {
  const flags = useFlags();

  const list = tabs
    .map(tab => ({
      label: TabNames[tab.id] || '-',
      value: tab.id,
      visible: () => {
        if (tab.id === ProductDetailsTabsIds.SIMILAR_PRODUCTS)
          return flags.productsSimilarProducts;

        if (tab.id === ProductDetailsTabsIds.SALES_ENABLEMENT)
          return flags.salesEnablement;

        return true;
      }
    }))
    .filter(i => i.visible());

  return (
    <>
      <Box display="flex" alignItems="center">
        <STabs
          tabsList={[
            ...list,
            ...(flags.salesEnablement
              ? [
                  {
                    label: TabNames[ProductDetailsTabsIds.SALES_ENABLEMENT],
                    value: ProductDetailsTabsIds.SALES_ENABLEMENT,
                    visible: () => true
                  }
                ]
              : [])
          ]}
          currentTab={currentTab}
          handleTabChange={onTabChange}
          divider={false}
        />
      </Box>
      <Divider />
    </>
  );
};
