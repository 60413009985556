import { Box } from '@material-ui/core';
import { SInfiniteTable } from '@setvi/shared/components/s-table/infinite';
import { Customer } from 'Services/MongoRealm/Types';
import { Product, ProductColumn } from '@setvi/shared/interfaces';
import { SEmptyState } from '@setvi/shared/components';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import { useWarehouseColumns } from './useWarehouseColumns';

interface WarehouseProps {
  product: Product;
  selectedCustomer?: Customer;
  configColumns: ProductColumn[];
}

export const Warehouse = ({
  configColumns = [],
  product,
  selectedCustomer
}: WarehouseProps) => {
  const { warehouseColumns } = useWarehouseColumns({
    selectedCustomer,
    configColumns
  });

  if (product?.dropship)
    return (
      <Box mt={3}>
        <Alert severity="info" icon={<InfoIcon />}>
          This item is Dropshipped. It will be directly shipped to the customer.
          Stock availability may not be available for dropshipped items.
        </Alert>
      </Box>
    );

  if (product?.inventory?.length === 0)
    return (
      <Box height="100%">
        <SEmptyState
          title="No Inventory"
          subTitle="There are no products available on inventory"
        />
      </Box>
    );

  return (
    <Box flex={1}>
      <SInfiniteTable
        list={product.inventory}
        columns={warehouseColumns}
        keyGetter={i => i._id}
      />
    </Box>
  );
};
