import { useMemo } from 'react';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { ROUTES } from 'enumsV2';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
// import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import {
  Home,
  Resources,
  Workspaces,
  CampaignIcon,
  Outbox,
  SalesIcon,
  OrdersIcon,
  QuotesIcon
} from 'Components/Shared/Icons';
import { CompanyModuleId } from '@setvi/shared/services/react-query/query/user/types';
import { TextColor } from '@setvi/shared/enums';

import { useAppContext } from 'Providers/AppProvider/AppContext';

import { useThoughtspot } from 'pages/thoughtspot/useThoughtspot';
import { MenuItemProps } from '../..';

enum FLAGS {
  HOME = 'home',
  CAMPAIGNS = 'campaigns',
  EMAIL_CAMPAIGNS = 'emailCampaigns',
  COMPANY_CAMPAIGNS = 'companyCampaigns',
  RESOURCES = 'resources',
  WORKSPACES = 'workspaces',
  OUTBOX = 'outbox',
  COUNTACTSGROUPS = 'contactsGroups',
  MYFILES = 'myFiles',
  MYPRESENTATIONS = 'myPresentations',
  SNIPPETS = 'snippets',
  PRODUCTS = 'revopsProducts',
  VET_SUITE = 'vetsuite',
  ORDERS = 'orders',
  QUOTES = 'quotes',
  DIGITAL_SHOWROOM = 'digitalShowroom',
  AUTO_RFQ = 'autoRfq',
  THOUGHTSPOTLIVEBOARDS = 'thoughtspotLiveboards',
  DIGITAL_SHOWROOM_VETSUITE = 'digitalShowroomVetsuite',
  DIGITAL_SHOWROOM_RFQ = 'digitalShowroomRfq',
  DIGITAL_SHOWROOM_TELA_BIO_ONE = 'digitalShowroomTelabioOne'
}

export const useUserMenuItems = () => {
  const { liveboards } = useThoughtspot();
  const { companyData } = useAppContext();

  const userMenuItems: MenuItemProps[] = useMemo(
    () => [
      {
        title: 'Home',
        flag: FLAGS.HOME,
        route: ROUTES.HOME,
        icon: <Home />
      },
      {
        title: 'Sales',
        icon: <SalesIcon />,
        // ! This is not needed. Orders/Quotes and AutoRFQ are not dependent on each other
        // flag: FLAGS.ORDERS || FLAGS.AUTO_RFQ,
        // moduleId: CompanyModuleId.Orders,
        baseRoute: ROUTES.SALES,
        list: [
          {
            title: 'Orders',
            icon: <OrdersIcon />,
            flag: FLAGS.ORDERS,
            route: ROUTES.ORDERS
          },
          {
            title: 'Quotes',
            icon: <QuotesIcon />,
            flag: FLAGS.QUOTES,
            route: ROUTES.QUOTES
          },
          {
            title: 'RFQ',
            flag: FLAGS.AUTO_RFQ,
            // moduleId: CompanyModuleId.Orders,
            route: ROUTES.AUTO_RFQ,
            baseRoute: ROUTES.SALES,
            icon: <FindInPageOutlinedIcon htmlColor={TextColor.Grey} />
          }
        ]
      },
      {
        title: 'Resources',
        moduleId: CompanyModuleId.Resources,
        flag: FLAGS.RESOURCES,
        route: ROUTES.RESOURCES,
        baseRoute: ROUTES.RESOURCES,
        icon: <Resources />
      },
      {
        title: 'Catalog',
        moduleId: CompanyModuleId.Products,
        route: ROUTES.PRODUCTS_LIST_VIEW,
        baseRoute: ROUTES.PRODUCTS,
        flag: FLAGS.PRODUCTS,
        icon: <LocalMallOutlinedIcon />
      },
      {
        title: 'Workspaces',
        icon: <Workspaces />,
        flag: FLAGS.WORKSPACES,
        baseRoute: ROUTES.WORKSPACES,
        list: [
          {
            title: 'Contacts & Groups',
            flag: FLAGS.COUNTACTSGROUPS,
            route: ROUTES.WORKSPACES_CONTACTS_GROUPS
          },
          ...(companyData?.MyResourcesAllowed
            ? [
                {
                  title: 'My Files',
                  flag: FLAGS.MYFILES,
                  route: ROUTES.WORKSPACES_FILES
                }
              ]
            : []),
          {
            title: 'My Presentations',
            moduleId: CompanyModuleId.Presentations,
            flag: FLAGS.MYPRESENTATIONS,
            route: ROUTES.WORKSPACES_PRESENTATIONS
          },
          {
            title: 'Snippets',
            flag: FLAGS.SNIPPETS,
            route: ROUTES.WORKSPACES_SNIPPETS
          },
          {
            title: 'Digital Rooms',
            childrenFlags: [
              FLAGS.DIGITAL_SHOWROOM_VETSUITE,
              FLAGS.DIGITAL_SHOWROOM_RFQ,
              FLAGS.DIGITAL_SHOWROOM_TELA_BIO_ONE
            ],
            route: ROUTES.WORKSPACES_DIGITAL_ROOMS
          }
        ]
      },
      // {
      //   title: 'Sales',
      //   icon: <SalesIcon />,
      //   baseRoute: ROUTES.SALES,
      //   list: [
      //     {
      //       title: 'Orders',
      //       flag: FLAGS.ORDERS,
      //       moduleId: CompanyModuleId.Orders,
      //       route: ROUTES.ORDERS,
      //       baseRoute: ROUTES.SALES,
      //       icon: <LocalShippingOutlinedIcon htmlColor={TextColor.Grey} />
      //     },
      //     {
      //       title: 'RFQ',
      //       flag: FLAGS.AUTO_RFQ,
      //       // moduleId: CompanyModuleId.Orders,
      //       route: ROUTES.AUTO_RFQ,
      //       baseRoute: ROUTES.SALES,
      //       icon: <FindInPageOutlinedIcon htmlColor={TextColor.Grey} />
      //     }
      //   ]
      // },
      {
        title: 'Campaigns',
        icon: <CampaignIcon />,
        baseRoute: ROUTES.CAMPAIGNS,
        list: [
          {
            title: 'Email Campaigns',
            flag: FLAGS.EMAIL_CAMPAIGNS,
            route: ROUTES.MY_CAMPAIGNS
          },
          {
            title: 'Company Campaigns',
            flag: FLAGS.COMPANY_CAMPAIGNS,
            route: ROUTES.COMPANY_CAMPAIGNS
          }
        ]
      },
      {
        title: 'Outbox',
        flag: FLAGS.OUTBOX,
        route: ROUTES.OUTBOX,
        baseRoute: ROUTES.OUTBOX,
        icon: <Outbox />
      },
      {
        title: 'VetSuite',
        flag: FLAGS.VET_SUITE,
        route: ROUTES.VET_SUITE,
        baseRoute: ROUTES.VET_SUITE,
        icon: <FeaturedPlayListOutlinedIcon />,
        list: [
          {
            title: 'Discovery Capture',
            flag: FLAGS.VET_SUITE,
            route: ROUTES.VET_SUITE,
            baseRoute: ROUTES.VET_SUITE
          },
          {
            title: 'VetSuite Dashboard',
            flag: FLAGS.DIGITAL_SHOWROOM_VETSUITE,
            route: ROUTES.DIGITAL_SHOWROOM_VETSUITE,
            baseRoute: ROUTES.VET_SUITE
          }
        ]
      },
      ...(liveboards?.length > 0
        ? [
            {
              title: 'Reporting',
              baseRoute: ROUTES.THOUGHTSPOT_LIVEBOARD,
              icon: <AssessmentOutlinedIcon />,
              flag: FLAGS.THOUGHTSPOTLIVEBOARDS,
              list: liveboards.map(liveboard => ({
                title: liveboard.metadata_name,
                route: ROUTES.THOUGHTSPOT_LIVEBOARD_DETAILS.replace(
                  ':liveboardId',
                  liveboard.metadata_id.toString()
                )
              }))
            }
          ]
        : [])
    ],
    [companyData?.MyResourcesAllowed, liveboards]
  );

  return { userMenuItems };
};
