import { useMemo } from 'react';
import { Field, FieldProps, Formik } from 'formik';

import {
  SButton,
  SFormikRadio,
  SFormikSelect,
  SFormikTextfield
} from '@setvi/shared/components';
import { useCustomErrors } from '@setvi/shared/hooks';
import { AIIcon } from '@setvi/shared/components/sicons';
import SLabel from '@setvi/shared/components/sui/slabel';

import {
  VALIDATORS,
  pizzaSchema,
  sizeValidation,
  hoursValidation,
  locationValidation,
  commentsValidation
} from './utils';
import {
  Footer,
  Container,
  SubmitButton,
  StyledSlider,
  inputStyle
} from './styled';
import {
  mehanicalRequirements,
  locations,
  pizzaHours,
  pizzaSizes
} from '../../dummy';
import { SearchFormProps, SearchFormTabs } from '../../..';

const PizzaForm = ({
  values,
  disableButton,
  onSubmit,
  setValues
}: Partial<SearchFormProps>) => {
  const { customErrors, customValidate } = useCustomErrors({
    schemas: pizzaSchema,
    values: JSON.parse(values?.equipment || '{}')
  });

  const initialValues = useMemo(() => {
    const parsedValues = JSON.parse(values?.equipment || '{}');

    return {
      location: parsedValues?.location || '',
      size: parsedValues?.size || '',
      hours: parsedValues?.hours || '',
      requirements: parsedValues?.requirements || '',
      comments: parsedValues?.comments || ''
    };
  }, [values]);

  const isDisabled = (
    v: { [key: string]: string | number },
    db: boolean,
    c?: { [key: string]: any }
  ): boolean =>
    db ||
    !v?.location ||
    !v?.size ||
    !v?.hours ||
    !v?.requirements ||
    c?.location ||
    c?.size ||
    c?.hours ||
    c?.comments;

  return (
    <Formik
      onSubmit={c => {
        onSubmit?.(SearchFormTabs.EQUIPMENT, JSON.stringify(c));
        setValues?.({
          ...values,
          equipment: JSON.stringify(c)
        });
      }}
      validateOnChange={false}
      initialValues={initialValues}>
      {({ values: formikValues, resetForm }) => (
        <Container>
          <SFormikSelect
            name="location"
            required
            label="Where will the Pizza Oven be used?"
            placeholder="Select location"
            options={
              locations?.map(item => ({
                ...item,
                value: item?.label
              })) || []
            }
            style={inputStyle}
            onChange={(value: string) =>
              customValidate({
                schema: locationValidation,
                value,
                name: 'location'
              })
            }
            customErrors={customErrors}
          />

          <Field name="size">
            {({ meta, form }: FieldProps) => (
              <>
                <SLabel
                  title="Max Pizza size in inches, you would need to cook?"
                  variant="body2"
                  required
                />
                <StyledSlider
                  step={1}
                  min={9}
                  max={20}
                  defaultValue={9}
                  marks={[
                    pizzaSizes[0],
                    {
                      value: meta.value,
                      label: `${meta.value}”`
                    },
                    pizzaSizes[1]
                  ]}
                  value={meta.value}
                  valueLabelDisplay="auto"
                  aria-labelledby="size-slider"
                  getAriaValueText={value => `${value}”`}
                  onChange={(_, value) => {
                    form.setFieldValue('size', value);

                    customValidate({
                      schema: sizeValidation,
                      value,
                      name: 'size'
                    });
                  }}
                />
              </>
            )}
          </Field>

          <Field name="hours">
            {({ meta, form }: FieldProps) => (
              <>
                <SLabel
                  title="Number of Pizza's you would need to cook in an hour?"
                  variant="body2"
                  required
                />
                <StyledSlider
                  step={5}
                  min={0}
                  max={100}
                  defaultValue={0}
                  marks={[
                    pizzaHours[0],
                    {
                      value: meta.value,
                      label: `${meta.value}`
                    },
                    pizzaHours[1]
                  ]}
                  value={meta.value}
                  valueLabelDisplay="auto"
                  aria-labelledby="hours-slider"
                  getAriaValueText={value => `${value}”`}
                  onChange={(_, value) => {
                    form.setFieldValue('hours', value);

                    customValidate({
                      schema: hoursValidation,
                      value,
                      name: 'hours'
                    });
                  }}
                />
              </>
            )}
          </Field>

          <SFormikRadio
            row
            required
            name="requirements"
            label="Gas or Electric?"
            options={mehanicalRequirements}
          />

          <SFormikTextfield
            name="comments"
            placeholder="Type your comments..."
            label="Additional Comments"
            maxLength={VALIDATORS.COMMENTS}
            onChange={(value: string) => {
              customValidate({
                schema: commentsValidation,
                value,
                name: 'comments'
              });
            }}
            InputProps={{
              style: inputStyle
            }}
            multiline
            customErrors={customErrors}
          />

          <Footer>
            <SButton
              variant="outlined"
              color="default"
              onClick={() => {
                resetForm();
                setValues?.({
                  ...values,
                  equipment: ''
                });
              }}>
              Cancel
            </SButton>

            <SubmitButton
              type="submit"
              disabled={isDisabled(formikValues, disableButton, customErrors)}
              startIcon={<AIIcon htmlColor="#fff" />}>
              Find
            </SubmitButton>
          </Footer>
        </Container>
      )}
    </Formik>
  );
};

export default PizzaForm;
