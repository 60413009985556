export enum OrdersApi {
  search = '/order/search',
  orderDetails = '/order/:id',
  orderItems = '/order-items/:orderId',
  saveOrderItems = '/order-items/save/:orderId',
  submitOrder = '/order/submit/:orderId',
  create = '/order/create',
  update = '/order/update',
  orderHistory = '/order-items/account/:accountNumber',
  backorderSearch = '/order/backorders/search',
  backorders = '/order/backorders/account/:accountId',
  invoiceDetails = '/invoice/:id',
  invoiceItems = '/invoice/:id/products'
}
