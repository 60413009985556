import { Box } from '@material-ui/core';
import { SETVIImage, SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import styled from 'styled-components';
import { STextProps } from '../../sui/stext';

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gridGap: 8
});

interface SAILogoProps {
  logoDimension?: number;
  fontSize?: STextProps['size'];
}

export const SAILogo = ({
  logoDimension = 8,
  fontSize = 'sm'
}: SAILogoProps) => (
  <LogoContainer>
    <SETVIImage
      src="/images/logo.png"
      alt="Setvi Logo"
      height={logoDimension}
      width={logoDimension}
    />
    <SText size={fontSize} weight="semibold">
      SETVI
    </SText>
    <SText size={fontSize} weight="medium" fontColor={TextColor.Grey}>
      Intelligence
    </SText>
  </LogoContainer>
);
