import { Theme, makeStyles } from '@material-ui/core';
import { TextColor } from '@setvi/shared/enums';
import { distances } from '@setvi/shared/styles';
import { CardSizes } from '../../card';
import { IconPosition } from '../enums';

const cornerIconSpace = 10;

export const useResourceCardStyles = makeStyles<Theme, { size: CardSizes }>(
  ({ spacing }) => ({
    wrapper: {
      justifyContent: 'space-between',
      '&:hover #favorite-button': {
        opacity: 1
      }
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(2),
      gap: spacing(1),
      height: '100%'
    },
    img: {
      width: '100%',
      height: '100%',
      aspectRatio: '1.34/1',
      objectFit: 'cover',
      objectPosition: 'center'
    },
    desc: {
      width: '90%',
      color: TextColor.Grey
    },
    action: {
      alignSelf: 'flex-end'
    },
    btn: {
      padding: 6,
      borderRadius: '50%'
    },
    arrow: {
      fontSize: 14
    },
    cornerIcon: {
      position: 'absolute'
    },
    textContainer: {
      padding: distances.px.base,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1
    },
    [IconPosition.topRight]: {
      top: cornerIconSpace,
      right: cornerIconSpace
    },
    [IconPosition.topLeft]: {
      top: cornerIconSpace,
      left: cornerIconSpace
    },
    [IconPosition.bottomRight]: {
      bottom: cornerIconSpace,
      right: cornerIconSpace
    },
    [IconPosition.bottomLeft]: {
      bottom: cornerIconSpace,
      left: cornerIconSpace
    }
  })
);
