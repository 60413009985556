import { Accordion } from 'rsuite';

import { SText } from '@setvi/shared/components';

import { GrayBox } from '../../styled';

interface FAQProps {
  data: {
    question: string;
    answer: string;
  }[];
}

const FAQ = ({ data }: FAQProps) => (
  <Accordion>
    {data?.map(({ question, answer }, i) => (
      <Accordion.Panel header={`${i + 1}. ${question}`}>
        <GrayBox>
          <SText title={answer} noLineClamp />
        </GrayBox>
      </Accordion.Panel>
    ))}
  </Accordion>
);

export default FAQ;
