import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import { GetOrderHistoryQuery } from './types';
import { axiosHelper } from '../../../axios-instance';
import { AxiosMethods, OrdersApi, AccountApi } from '../../../../enums';
import { CustomerReorderProducts, Order } from '../../../../interfaces';

enum OrdersQueryKey {
  ORDER_HISTORY = 'orderHistory',
  CUSTOMERS = 'customers',
  CREATE_ORDER = 'createOrder',
  SUBMIT_ORDER = 'submitOrder'
}

const dynamicAccountNumberId = ':accountNumber';
const dynamicOrderId = ':orderId';

interface GetOrderHistoryQueryParams {
  accountNumber: string;
  searchStr: string;
  limit: number;
  offset: number;
}

export const getOrderHistoryQuery = (
  params: GetOrderHistoryQueryParams
): UseInfiniteQueryOptions<GetOrderHistoryQuery> => ({
  queryKey: [OrdersQueryKey.ORDER_HISTORY, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      baseURL: process.env.REVOPS_API,
      endpoint: OrdersApi.orderHistory.replace(
        dynamicAccountNumberId,
        params.accountNumber
      ),
      method: AxiosMethods.POST,
      body: {
        searchStr: params.searchStr,
        limit: params.limit,
        offset: data.pageParam || params.offset
      }
    }),
  enabled: !!params.accountNumber,
  getNextPageParam: (lastPage, allPages) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.data?.length, 0);
    if (total >= lastPage?.total) return undefined;
    return allPages.length * params?.limit;
  }
});

interface GetCustomersQueryParams {
  searchStr: string;
  limit: number;
  offset: number;
}

export const getCustomersQuery = (params: GetCustomersQueryParams) => ({
  queryKey: [OrdersQueryKey.CUSTOMERS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      baseURL: process.env.REVOPS_API,
      endpoint: AccountApi.getAccounts,
      method: AxiosMethods.POST,
      body: {
        searchStr: params.searchStr,
        limit: params.limit,
        offset: data.pageParam || params.offset
      }
    }),
  getNextPageParam: (lastPage: any, allPages: any) => {
    const total = allPages?.reduce(
      (acc: any, cur: any) => acc + cur?.data?.length,
      0
    );
    if (total >= lastPage?.total) return undefined;
    return allPages.length * params?.limit;
  }
});

export const downloadBackorderCSV = (accoundID: string) => ({
  queryKey: ['file_downloader', accoundID],
  queryFn: () =>
    axiosHelper({
      endpoint: `/order/backorders/csv/${accoundID}`,
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_API
    }),
  enabled: false
});

export const createOrder = () => ({
  mutationFn: (body: any) =>
    axiosHelper({
      baseURL: process.env.REVOPS_API,
      endpoint: OrdersApi.create,
      method: AxiosMethods.POST,
      body
    })
});

interface SaveOrderItem {
  productId: string;
  quantity: number;
  sell: number;
  cost: number;
}

export const saveOrderItems = (orderId: string) => ({
  mutationFn: (products: SaveOrderItem[]) =>
    axiosHelper({
      baseURL: process.env.REVOPS_API,
      endpoint: OrdersApi.saveOrderItems.replace(dynamicOrderId, orderId),
      method: AxiosMethods.POST,
      body: products
    })
});

export const submitOrder = (orderId: string) => ({
  mutationFn: () =>
    axiosHelper({
      baseURL: process.env.REVOPS_API,
      endpoint: OrdersApi.submitOrder.replace(dynamicOrderId, orderId),
      method: AxiosMethods.POST
    })
});

export const updateOrder = () => ({
  mutationFn: (order: Order) =>
    axiosHelper({
      baseURL: process.env.REVOPS_API,
      endpoint: OrdersApi.update,
      method: AxiosMethods.POST,
      body: order
    })
});

interface CustomerReorderProductsResponse {
  products: CustomerReorderProducts[];
  totalItems: number;
  isSuccess: boolean;
  isCancelled: boolean;
}
export interface CustomersReorderProps {
  customerId?: string;
  shipId?: string;
  search?: string;
  isOverdue?: boolean;
  page?: number;
  pageSize?: number;
}

export const customersReorder = ({
  customerId = undefined,
  shipId = undefined,
  search = null,
  isOverdue = true,
  page = 1,
  pageSize = 20
}: CustomersReorderProps): UseInfiniteQueryOptions<CustomerReorderProductsResponse> => ({
  queryKey: ['customers-reorders', customerId, shipId, isOverdue],
  queryFn: ({ pageParam, signal }) => {
    const controller = new AbortController();

    const promise = axiosHelper({
      endpoint: `/api/1.0/products/reorder/paginated`,
      params: {
        CustomerId: customerId,
        ShipId: shipId,
        Search: search,
        IsOverdue: isOverdue.toString(),
        Page: pageParam || page,
        PageSize: pageSize.toString()
      },
      method: AxiosMethods.GET,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      signal: controller.signal
    });

    signal?.addEventListener('abort', () => {
      controller.abort();
    });

    return promise;
  },
  getNextPageParam: (lastPage, allPages) => {
    const loadedPagesCount = allPages.reduce(
      (acc, item) => acc + item.products.length,
      0
    );

    return loadedPagesCount < lastPage.totalItems
      ? allPages.length + 1
      : undefined;
  },
  cacheTime: 0,
  refetchOnWindowFocus: false
});

export interface CustomerReorderProductsProps {
  customerId: string;
  shipId: string;
  isOverdue: boolean;
}

export const customersReorderProducts = ({
  customerId = null,
  shipId = null,
  isOverdue = true
}: CustomerReorderProductsProps): UseQueryOptions<
  CustomerReorderProducts[]
> => ({
  queryKey: ['customer-reorders-details', customerId, shipId, isOverdue],
  queryFn: ({ signal }) => {
    const controller = new AbortController();

    const promise = axiosHelper({
      endpoint: `/api/1.0/products/reorder`,
      params: {
        CustomerId: customerId,
        ShipId: shipId,
        IsOverdue: isOverdue.toString()
      },
      method: AxiosMethods.GET,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      signal: controller.signal
    });

    signal?.addEventListener('abort', () => {
      controller.abort();
    });

    return promise.then(res => res.products);
  },
  enabled: !!customerId && !!shipId
});

export interface CustomerReorderSummaryProps {
  customerId: string;
  shipId: string;
}

export const customersReorderSummary = ({
  customerId = null,
  shipId = null
}: CustomerReorderSummaryProps): UseQueryOptions<{ [key: string]: any }> => ({
  queryKey: ['customer-reorders-details', customerId, shipId],
  queryFn: ({ signal }) => {
    const controller = new AbortController();

    const promise = axiosHelper({
      endpoint: `/api/1.0/products/reorder/summary`,
      params: {
        CustomerId: customerId,
        ShipId: shipId
      },
      method: AxiosMethods.GET,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      signal: controller.signal
    });

    signal?.addEventListener('abort', () => {
      controller.abort();
    });

    return promise.then(res => res.summary);
  },
  enabled: !!customerId && !!shipId
});

export const ignoreReorderProducts = () => ({
  mutationFn: ({
    products,
    reason
  }: {
    products: CustomerReorderProducts[];
    reason: string;
  }) => {
    const body = products.reduce(
      (acc, product) => ({
        customerIds: [...acc.customerIds, product.accountId],
        shipToIds: [...acc.shipToIds, product.shipToId],
        productSkus: [...acc.productSkus, product.productKey],
        ignores: [...acc.ignores, true],
        ignoreReasons: [...acc.ignoreReasons, reason]
      }),
      {
        customerIds: [],
        shipToIds: [],
        productSkus: [],
        ignores: [],
        ignoreReasons: []
      }
    );

    return axiosHelper({
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      endpoint: `/api/1.0/products/reorder/update`,
      method: AxiosMethods.POST,
      body
    });
  }
});
