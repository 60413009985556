import { CompanySettingsTypeID } from '@setvi/shared/services/react-query/query/user/types';
import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useRealmContext } from 'Providers/MongoRealmProvider/context';
import { MONGO_REALM_COLLECTIONS } from 'Services/MongoRealm';
import { Category } from 'Services/MongoRealm/Types';

export const useCategories = () => {
  const { companyData } = useAppContext();
  const { client } = useRealmContext();

  const dbName =
    companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]?.Value;

  const isSysco = dbName === 'sysco-development';

  const { data, isLoading } = useQuery<Category[]>({
    queryFn: () =>
      client
        ?.db(
          companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]
            ?.Value
        )
        .collection(MONGO_REALM_COLLECTIONS.CATEGORY_TREE)
        .aggregate([]),
    select: (response: any) => response?.[0]?.categories,
    staleTime: 300000,
    enabled: !isSysco
  });

  return {
    isLoading,
    categories: data || []
  };
};
