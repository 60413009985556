// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rct-tree-root ul {
    padding-inline-start: 0 !important; /* Reset the default padding */
    margin: 0; /* Optional: Reset margin */
  }`, "",{"version":3,"sources":["webpack://./../../shared/components/streeV2/style.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC,EAAE,8BAA8B;IAClE,SAAS,EAAE,2BAA2B;EACxC","sourcesContent":[".rct-tree-root ul {\n    padding-inline-start: 0 !important; /* Reset the default padding */\n    margin: 0; /* Optional: Reset margin */\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
