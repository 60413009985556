import { CSSProperties } from 'react';
import { Box } from '@material-ui/core';

import { distances } from '@setvi/shared/styles';
import { SETVIImage, SText } from '@setvi/shared/components';
import { GetResourceTypeIcon, GetResourceTypeName } from '@setvi/shared/utils';
import { TextColor } from '@setvi/shared/enums';

interface TypeIconProps {
  ResourceTypeName?: string;
  ResourceTypeID?: number;
  textStyle?: CSSProperties;
  name?: boolean;
}

const TypeIcon = ({
  name = true,
  textStyle,
  ResourceTypeID,
  ResourceTypeName
}: TypeIconProps) => {
  const generateIcon = () =>
    ResourceTypeID ? GetResourceTypeIcon(ResourceTypeID) : '';

  const getFileName = () =>
    ResourceTypeName || GetResourceTypeName(ResourceTypeID);

  return (
    <Box display="flex" alignItems="center">
      <SETVIImage width={20} src={generateIcon()} alt="File Type" />
      {name && (
        <SText
          title={getFileName()}
          size="sm"
          fontColor={TextColor.DarkGrey}
          style={{
            marginLeft: distances.px.small,
            ...textStyle
          }}
        />
      )}
    </Box>
  );
};

export default TypeIcon;
