import { useEffect, useMemo, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { AIIcon, ResourceIcon } from '@setvi/shared/components/sicons';
import { SFormikTextfield } from '@setvi/shared/components';
import SLabel from '@setvi/shared/components/sui/slabel';
import { useCustomErrors } from '@setvi/shared/hooks';
import { useDialog } from '@setvi/shared/providers';

import { useProductMatchStore } from 'componentsV2/ask-ai/store';

import {
  Header,
  ClearButton,
  SubmitButton,
  InputContainer,
  LabelContainer,
  FormikContainer,
  Placeholder,
  inputStyle,
  RankingButton
} from '../styled';
import { SearchFormProps, SearchFormTabs } from '..';
import { Ranking } from '../../result/components';
import { Actions } from '../image/styled';

export enum VALIDATORS {
  TEXT = 2048
}

export const textValidation = Yup.object({
  text: Yup.string().max(
    VALIDATORS.TEXT,
    `Text must be at most ${VALIDATORS.TEXT} characters.`
  )
});

export const schema = [
  {
    name: 'text',
    schema: textValidation
  }
];

const MIN_ROWS = 4;
const ROW_SIZE = 21;
const AREA_PADDING = 37;

const ProductSpecsInput = ({
  values,
  disableButton,
  onSubmit,
  setValues
}: SearchFormProps) => {
  const flags = useFlags();

  const { openDialog, closeDialog } = useDialog();
  const { rankings, setRankings } = useProductMatchStore();

  const ref = useRef(null);
  const [rows, setRows] = useState(MIN_ROWS);

  useEffect(() => {
    if (ref?.current) {
      const height = ref?.current?.clientHeight;
      const currRows = Math.floor((height - AREA_PADDING) / ROW_SIZE);
      setRows(currRows > MIN_ROWS ? currRows : MIN_ROWS);
    }
  }, [ref]);

  const { customErrors, customValidate } = useCustomErrors({
    schemas: schema,
    values: { text: values?.text }
  });

  const initialValues = useMemo(
    () => ({
      text: values?.text
    }),
    [values?.text]
  );

  const onPlaceholderClick = () => {
    const textarea = ref?.current?.querySelector('textarea');
    if (textarea) textarea?.focus();
  };

  return (
    <FormikContainer>
      <Formik
        onSubmit={() => onSubmit?.(SearchFormTabs.TEXT, values?.text)}
        validateOnChange={false}
        initialValues={initialValues}>
        {({ values: formikValues, resetForm, submitForm }) => (
          <InputContainer
            // @ts-ignore
            ref={ref}>
            <SFormikTextfield
              name={SearchFormTabs.TEXT}
              placeholder=""
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  submitForm();
                }
              }}
              label={
                <Header>
                  <LabelContainer>
                    <ResourceIcon
                      color="primary"
                      style={{
                        width: 16
                      }}
                    />
                    <SLabel title="Enter Product Specs" variant="body2" />
                  </LabelContainer>

                  <ClearButton
                    variant="text"
                    disabled={disableButton}
                    hide={!formikValues?.text}
                    onClick={() => {
                      setValues(prev => ({ ...prev, text: '' }));
                      resetForm({
                        values: { text: '' }
                      });
                    }}>
                    Clear
                  </ClearButton>
                </Header>
              }
              maxLength={VALIDATORS.TEXT}
              onChange={(value: string) => {
                customValidate({
                  schema: textValidation,
                  value,
                  name: SearchFormTabs.TEXT
                });
                setValues(prev => ({ ...prev, text: value }));
              }}
              multiline
              minRows={rows}
              maxRows={rows}
              InputProps={{
                style: inputStyle,
                ...(!formikValues?.text
                  ? {
                      startAdornment: (
                        <Placeholder onClick={onPlaceholderClick}>
                          <p
                            style={{
                              textDecoration: 'underline'
                            }}>
                            Example:
                          </p>
                          <p>
                            Zep Neutral pH Floor Cleaner cleans and restores
                            shine to floor surfaces. Utilize this floor cleaner
                            in Step 4 of the Zep 4-Step Pro Floor Maintenance
                            System. Concentrate works great on marble, granite,
                            vinyl and stone floors and can be applied using an
                            automatic scrubber or a mop and bucket.
                          </p>

                          <ul
                            style={{
                              marginTop: 8
                            }}>
                            <li>
                              For use on interior vinyl floors, asphalt tile,
                              rubber, sealed concrete, marble, granite, natural
                              stone and more
                            </li>
                            <li>Not for use on wood floors</li>
                          </ul>
                        </Placeholder>
                      )
                    }
                  : {}),
                endAdornment: (
                  <Actions>
                    {!!flags?.aiRanking && (
                      <RankingButton
                        variant="outlined"
                        onClick={() =>
                          openDialog(
                            <Ranking
                              onClose={() => closeDialog('ranking')}
                              rankings={rankings}
                              onConfirm={r => {
                                setRankings(r);
                                closeDialog('ranking');
                              }}
                            />,
                            {
                              id: 'ranking'
                            }
                          )
                        }>
                        Rankings
                      </RankingButton>
                    )}

                    <SubmitButton
                      type="submit"
                      disabled={
                        !formikValues?.text?.trim() ||
                        // @ts-ignore
                        !!customErrors?.text ||
                        disableButton
                      }
                      startIcon={<AIIcon htmlColor="#fff" />}>
                      Find
                    </SubmitButton>
                  </Actions>
                )
              }}
              customErrors={customErrors}
            />
          </InputContainer>
        )}
      </Formik>
    </FormikContainer>
  );
};

export default ProductSpecsInput;
