import { Box, makeStyles } from '@material-ui/core';
import { SETVIImage } from '@setvi/shared/components';
import Stext from '@setvi/shared/components/sui/stext';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useColoredPillStyles = makeStyles(() => ({
  pill: {
    borderRadius: '20px',
    display: 'inline-flex',
    padding: '2px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  image: {
    width: '16px',
    height: '16px'
  }
}));

interface ColoredPillProps {
  title: string;
  color: string;
  imageUrl?: string;
  onClick?: () => void;
}

export const ColoredPill = ({
  title,
  color,
  imageUrl,
  onClick
}: ColoredPillProps) => {
  const classes = useColoredPillStyles();
  return (
    <Box
      className={classes.pill}
      onClick={() => onClick?.()}
      style={{ backgroundColor: color }}>
      {imageUrl && (
        <SETVIImage className={classes.image} src={imageUrl} alt="Pill Image" />
      )}
      <Box mx={1}>
        <Stext size="xs" title={title} />
      </Box>
      {onClick && <ChevronRightIcon fontSize="small" />}
    </Box>
  );
};
