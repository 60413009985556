import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SText } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';

interface CostProps {
  value: Product['cost'];
  loading?: boolean;
}

export const Cost = ({ value, loading }: CostProps) => {
  if (loading) return <Skeleton />;

  if (!value || value <= 0 || !Number(value)) return '-';

  return (
    <Box display="flex" flexDirection="column">
      <SText
        title={`${value?.toLocaleString('en-US', {
          currency: 'USD',
          style: 'currency'
        })}`}
      />
    </Box>
  );
};
