import { Box, Menu, MenuItem, styled } from '@material-ui/core';

export const AIGradientBgColor =
  'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(1),

  '& .MuiPaper-root': {
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.main}`,

    '& ul': {
      padding: 0
    }
  }
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  gridGap: theme.spacing(2)
}));

export const GradientIcon = styled(Box)({
  width: 32,
  height: 32,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: AIGradientBgColor
});
