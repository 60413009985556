import { Box, styled } from '@material-ui/core';

export const LoadingContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const LoadingContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 12,
  width: 300,
  height: 300
});

export const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 12,
  width: '100%',
  height: '100%'
});

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: 18
});

export const Description = styled(Box)({
  padding: 12,
  background: '#F0F0F4',
  borderRadius: 12
});

export const TableContainer = styled(Box)({
  flex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  scrollBehavior: 'smooth'
});

export const Precentage = styled(Box)(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  borderRadius: 12,
  width: 75,
  maxWidth: 75,
  fontSize: 13,
  height: 24,
  gap: 4,
  color: '#4D4D4D',
  padding: theme.spacing(0, 2),
  cursor: 'default',

  '& p': {
    margin: theme.spacing('2px', 0, 0, '2px')
  }
}));

export const IsStock = styled(Box)(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  borderRadius: 12,
  width: 50,
  maxWidth: 50,
  fontSize: 13,
  height: 24,
  gap: 4,
  color: '#5D7083',
  padding: theme.spacing(0, 2),
  cursor: 'default',
  backgroundColor: 'transparent',
  border: '1px solid #AFBAC6'
}));
