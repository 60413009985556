import * as Yup from 'yup';

export enum VALIDATORS {
  COMMENTS = 2048
}

export const locationValidation = Yup.object({
  location: Yup.string()
});

export const sizeValidation = Yup.object({
  size: Yup.number()
});

export const hoursValidation = Yup.object({
  hours: Yup.number()
});

export const commentsValidation = Yup.object({
  comments: Yup.string().max(
    VALIDATORS.COMMENTS,
    `Comments must be at most ${VALIDATORS.COMMENTS} characters.`
  )
});

export const pizzaSchema = [
  {
    name: 'location',
    schema: locationValidation
  },
  {
    name: 'size',
    schema: sizeValidation
  },
  {
    name: 'hours',
    schema: hoursValidation
  },
  {
    name: 'comments',
    schema: commentsValidation
  }
];
