import { Box, styled } from '@material-ui/core';

export const AIDIalogContainer = styled(Box)({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fafafb'
});

export const AIHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing(2, 3, 2, 4)
}));

export const AIContentBox = styled(Box)(({ theme: { spacing } }) => ({
  backgroundColor: '#fff',
  padding: spacing(2),
  margin: spacing(3, 3, 0, 3),
  borderRadius: 8,
  flex: 1,
  border: `1px solid #e0e0e0`
}));

export const AIFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(3, 0)
}));
