import * as Yup from 'yup';

export enum VALIDATORS {
  TEXT = 2048
}

export const textValidation = Yup.object({
  text: Yup.string().max(
    VALIDATORS.TEXT,
    `Text must be at most ${VALIDATORS.TEXT} characters.`
  )
});

export const schema = [
  {
    name: 'text',
    schema: textValidation
  }
];
