import { CompanySettingsTypeID } from '@setvi/shared/services/react-query/query/user/types';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { MONGO_REALM_COLLECTIONS } from 'Services/MongoRealm';
import { Product } from '@setvi/shared/interfaces';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

export const useProductSpecifications = (product: Product, client: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const { companyData, user: appUser } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const externalMiddlebyUser = appUser?.ExternalUserDomainId !== 0;

  const fetchProduct = useCallback(
    async (productId: Product['_id']) => {
      setIsLoading(true);
      try {
        const response = await client
          .db(
            companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]
              ?.Value
          )
          .collection(MONGO_REALM_COLLECTIONS.PRODUCTS)
          .findOne({ _id: productId });

        if (externalMiddlebyUser) {
          delete response.custom['15_for_15_product'];
        }

        setCurrentProduct(response);
      } catch (e) {
        enqueueSnackbar('Error occured while fetching product data.', {
          variant: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    },
    [client, companyData, externalMiddlebyUser, enqueueSnackbar]
  );

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (product) fetchProduct(product._id);
  }, [fetchProduct, product]);

  return {
    isLoading,
    specifications: Object.values(currentProduct?.custom || {}).filter(
      el => el.value !== '' && el.specification && el.label
    )
  };
};
