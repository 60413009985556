import { UseQueryOptions } from '@tanstack/react-query';

export enum ThoughtspotQueryKey {
  USER_DETAILS = 'userDetails',
  USER_LIVEBOARDS = 'userLiveboards'
}

export const getThoughspotUserFetch = (email: string) =>
  fetch(
    'https://prod-setviafthoughtspot.azurewebsites.net/api/AuthHttpTriggerFunction',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        APIKey:
          'B3i1JV4zZwoCKHmTlPr2gitMHHsLXaZomLRMlAaWVSeVmS3SQmZZAxO8HrTU2CJ0'
      },
      body: JSON.stringify({ username: email })
    }
  ).then(response => response.json());

export const getThoughspotUser = (email: string): UseQueryOptions<any> => ({
  queryKey: [ThoughtspotQueryKey.USER_DETAILS, email],
  queryFn: () => getThoughspotUserFetch(email),
  cacheTime: 0,
  staleTime: 300000
});

export const getUserMetadata = ({
  type = 'LIVEBOARD',
  thoughspotUserAccessToken
}: {
  type: string;
  thoughspotUserAccessToken: string;
}): UseQueryOptions<any[]> => ({
  queryKey: [ThoughtspotQueryKey.USER_LIVEBOARDS, thoughspotUserAccessToken],
  queryFn: () =>
    fetch('https://setvi.thoughtspot.cloud/api/rest/2.0/metadata/search', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${thoughspotUserAccessToken}`
      },
      body: JSON.stringify({
        dependent_object_version: 'V1',
        include_auto_created_objects: false,
        include_dependent_objects: false,
        dependent_objects_record_size: 50,
        include_details: false,
        include_headers: true,
        include_hidden_objects: false,
        include_incomplete_objects: false,
        include_visualization_headers: false,
        record_offset: 0,
        record_size: 100,
        include_stats: false,
        metadata: [{ type }]
      })
    }).then(response => response.json()),
  enabled: !!thoughspotUserAccessToken,
  cacheTime: 0
});
