import { create } from 'zustand';

interface Rankings {
  enablePrivateLabelRanking: boolean;
  enableStockProductRanking: boolean;
  enableVendorRanking: boolean;
}

interface StoreStates {
  rankings: Rankings;
}

interface Store extends StoreStates {
  setRankings: (newRankings: Rankings) => void;
  reset: () => void;
}

const initalState: StoreStates = {
  rankings: {
    enablePrivateLabelRanking: true,
    enableStockProductRanking: true,
    enableVendorRanking: true
  }
};

export const useProductMatchStore = create<Store>(set => ({
  ...initalState,

  setRankings: newRankings => set({ rankings: newRankings }),
  reset: () => set({ ...initalState })
}));
