import { SBase } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';
import { PromotionContent } from './content';
import { usePromotionDetails } from './details/usePromotionDetails';

interface PromotionProps {
  product: Product;
  client: any;
  onClose: () => void;
}

export const PromotionDialog = ({
  product,
  client,
  onClose
}: PromotionProps) => {
  const { promotions, activePromotion, loading, setActivePromotion } =
    usePromotionDetails({
      product,
      client
    });

  return (
    <SBase
      height="80vh"
      width="80vw"
      loading={loading}
      header={{
        title: promotions.length > 1 ? 'Promotions' : activePromotion?.name,
        onClose
      }}>
      <PromotionContent
        promotions={promotions}
        activePromotion={activePromotion}
        setActivePromotion={setActivePromotion}
        loading={loading}
      />
    </SBase>
  );
};
