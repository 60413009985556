import { useEffect, useState, useRef } from 'react';
import { styled, Button } from '@material-ui/core';
import { SText } from '@setvi/shared/components';

interface ExpandableTextProps {
  text: string;
  prefix?: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderRadius: 4,
  minHeight: 'fit-content'
}));

const ExpandableText = ({ prefix, text }: ExpandableTextProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref, setWidth]);

  // get text that will be fited in 2 two lines of the box
  const getTwoLinesText = () => {
    if (width === 0) return '';

    const words = text.split(' ');
    let twoLinesText = '';
    let currentWidth = 0;

    for (let i = 0; i < words.length; i += 1) {
      const word = words[i];
      const wordWidth = word.length * 9; // 9px is the average width of a character

      const prefixWidth = prefix?.length * 9;
      const twoSentanceWidth = 2 * width;
      if (currentWidth + wordWidth > twoSentanceWidth - prefixWidth) {
        return twoLinesText;
      }

      twoLinesText += `${word} `;
      currentWidth += wordWidth;
    }

    return twoLinesText;
  };

  const hasMore = getTwoLinesText()?.length < text.length;

  // Function to get displayed text with inline button
  const getDisplayedText = () => {
    if (!hasMore) return text;

    if (!expanded) {
      return (
        <>
          {getTwoLinesText()}
          <StyledButton
            variant="text"
            color="primary"
            onClick={() => setExpanded(true)}>
            Read More
          </StyledButton>
        </>
      );
    }

    return (
      <>
        {text}
        <StyledButton
          variant="text"
          color="primary"
          onClick={() => setExpanded(false)}>
          Show Less
        </StyledButton>
      </>
    );
  };

  return (
    <div ref={ref}>
      <SText noLineClamp weight="medium">
        {prefix && <b>{prefix} </b>}
        {getDisplayedText()}
      </SText>
    </div>
  );
};

export default ExpandableText;
