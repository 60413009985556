import { Box } from '@material-ui/core';

import { Accordions } from '../accordions';
import { SalesEnablementItemProps } from '../../hook';
import { ContentContainer, QuestionTitle } from '../../styled';

const Content = ({ isFAQ, content }: Partial<SalesEnablementItemProps>) => {
  if (isFAQ) return <Accordions accordions={content} />;

  return content?.map(item => (
    <ContentContainer key={item?.id} mb={3}>
      <QuestionTitle>{item?.name}</QuestionTitle>
      <Box ml={4} fontSize={16}>
        {item?.content && (
          <div
            key={item.name}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: item?.content }}
          />
        )}
      </Box>
    </ContentContainer>
  ));
};

export default Content;
