import { Box } from '@material-ui/core';

import { HeaderContainer } from './style';
import SButton from '../../../../sbutton';
import { SSearchInput } from '../../../ssearch';
import SText from '../../../../sui/stext';
import { TextColor } from '../../../../../enums';

export interface SelectMenuHeaderProps {
  title?: string;
  search?: boolean;
  clearButton?: boolean;
  placeholder?: string;
  defaultSearchValue?: string;
  searchValue?: string;
  handleClear?: () => void;
  handleChange?: (value: string) => void;
}

const SelectMenuHeader = ({
  title,
  search = true,
  clearButton = false,
  placeholder,
  defaultSearchValue,
  searchValue,
  handleClear,
  handleChange
}: SelectMenuHeaderProps) => (
  <Box width="100%">
    <HeaderContainer>
      {Boolean(title) && (
        <SText title={title} weight="bold" fontColor={TextColor.Black} />
      )}
      {clearButton && handleClear && (
        <SButton
          variant="text"
          onClick={() => {
            handleClear();
          }}
          style={{ padding: 0, fontWeight: 300 }}>
          Clear
        </SButton>
      )}
    </HeaderContainer>
    {search && handleChange && (
      <Box mt={2} mb={2}>
        <SSearchInput
          width="100%"
          searchedValue={searchValue}
          defaultValue={defaultSearchValue}
          placeholder={placeholder}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      </Box>
    )}
  </Box>
);

export default SelectMenuHeader;
