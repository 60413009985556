import { Box, styled } from '@material-ui/core';

export const Container = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  background:
    'linear-gradient(155deg, #001222 2%, #003A6C  25%,  #3896E0  85%, #1E96FC  100%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  gap: 6
}));

export const PDFName = styled(Box)(({ theme }) => ({
  gap: 8,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.primary.main}`
}));
