import { SText } from '@setvi/shared/components';
import { ProductInventory } from '@setvi/shared/interfaces';

export interface QuantityProps {
  row: ProductInventory;
  property:
    | 'orderedQuantity'
    | 'onOrderQuantity'
    | 'onHandQuantity'
    | 'onTransferQuantity'
    | 'availableOnHand';
}

export const Quantity = ({ row, property }: QuantityProps) =>
  Object.prototype.hasOwnProperty.call(row, property) ? (
    <SText weight="bold" title={row[property].toString()} />
  ) : (
    '-'
  );
