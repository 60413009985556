import { Box, Divider } from '@material-ui/core';
import { SInfiniteTable } from '@setvi/shared/components/s-table/infinite';
import { SEmptyState, SNavigateButton, SText } from '@setvi/shared/components';
import { Promotion } from 'Services/MongoRealm/Types';
import { usePromotionDetailsTable } from './usePromotionDetailsTable';
import { PromotionDetaolsLoader } from './loader';
import { PromotionList } from '../list';

export interface PromotionDetailsProps {
  promotions: Promotion[];
  activePromotion: Promotion;
  setActivePromotion: (promotion: Promotion) => void;
  loading: boolean;
}

export const PromotionDetails = ({
  promotions,
  activePromotion,
  loading,
  setActivePromotion
}: PromotionDetailsProps) => {
  const { promotionDetailsColumns } = usePromotionDetailsTable();

  if (loading) return <PromotionDetaolsLoader />;

  if (!promotions)
    return (
      <SEmptyState
        title="No Promotion Details Found"
        subTitle="There are no promotions related to this product available at the moment."
      />
    );

  if (promotions.length > 1 && !activePromotion)
    return (
      <PromotionList
        promotions={promotions}
        onPromotionClick={setActivePromotion}
      />
    );

  return (
    <>
      {promotions.length > 1 && (
        <>
          <Box display="flex" alignItems="center" my={2}>
            <SNavigateButton onClick={() => setActivePromotion(null)} />
            <Box ml={2}>
              <SText size="xl" weight="bold" title={activePromotion.name} />
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <Box overflow="auto" mt={2}>
        <SText title="Description" size="sm" />
        <p>{activePromotion?.description}</p>
        <Box my={2}>
          <Divider />
          <Box display="flex" alignItems="center" my={3}>
            <SText title="Valid from" />
            <Box ml={2}>
              <SText title={activePromotion?.startDate} weight="bold" />
            </Box>
            <Box ml={2}>
              <SText title="Valid until" />
            </Box>
            <Box ml={2}>
              <SText title={activePromotion?.endDate} weight="bold" />
            </Box>
          </Box>
          <Divider />
        </Box>
        <SInfiniteTable
          list={activePromotion?.promotionItems?.slice(0, 40)}
          columns={promotionDetailsColumns}
          keyGetter={i => i._id}
        />
      </Box>
    </>
  );
};
