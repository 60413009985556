import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { sendMessageToSlackChannel } from '@setvi/shared/services';
import { SlackChannels, SlackMessageTypes } from '@setvi/shared/enums';
import {
  CompanyProductUserType,
  Product,
  ProductDetailsTabsIds
} from '@setvi/shared/interfaces';
import { useDialog } from '@setvi/shared/providers';
import { useSelected } from '@setvi/shared/hooks';

import { COMPANIES } from 'Enums/Companies';
import { useProductMatchStore } from 'componentsV2/ask-ai/store';
import { FEEDBACK_DIALOG, FeedbackValues } from 'componentsV2/ai/feedback';
import { MONGO_REALM_COLLECTIONS } from 'Services/MongoRealm';
import {
  getProductsByImageUrl,
  getProductsByText,
  getProductsByUrl
} from 'Services';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { PromotionDialog } from 'pages/products/components/promotion';
import { ProductActionType, captureAnalytics } from 'pages/products/utils';
import CompanySettingsTypeID from 'pages/products/components/facets/custom-refinment-list/useFacet';

import { ResultProps } from '..';
import { SearchFormTabs } from '../../form';

export const useResults = ({
  setType,
  type,
  values,
  mongoClient
}: Partial<ResultProps>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { companyData, user } = useAppContext();
  const { openDialog, closeDialog } = useDialog();

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [detailsTab, setDetailsTab] = useState(
    ProductDetailsTabsIds.PRODUCT_INFO
  );
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [title, setTitle] = useState('');

  const { rankings } = useProductMatchStore();

  const { selected, handleSelect } = useSelected<Product>({
    dataKeyGetter: product => product._id
  });

  // We will filter by manufacturer or distributor in future
  // const isSinger = [COMPANIES.SINGER_DEV, COMPANIES.SINGER_PROD].includes(
  //   companyData.ID
  // );

  const userType = useMemo(
    () => companyData.Settings[38]?.Value as CompanyProductUserType,
    [companyData]
  );

  const { mutateAsync: fetchByUrl, isLoading: isLoadingByUrl } =
    useMutation(getProductsByUrl());

  const { mutateAsync: fetchByText, isLoading: isLoadingByText } =
    useMutation(getProductsByText());

  const { mutateAsync: fetchByImageUrl, isLoading: isLoadingByImageUrl } =
    useMutation(getProductsByImageUrl());

  const { mutateAsync: sendFeedback, isLoading: isFeedbackLoading } =
    useMutation(sendMessageToSlackChannel());

  const apiLoading = useMemo(() => {
    switch (type) {
      case 'url':
        return isLoadingByUrl;
      case 'image':
        return isLoadingByImageUrl;
      default:
        return isLoadingByText;
    }
  }, [type, isLoadingByUrl, isLoadingByText, isLoadingByImageUrl]);

  // const handlePrices = async (productsList: Product[]) => {
  //   const response = await getProductPrices({
  //     skus: productsList?.map(({ sku }) => sku),
  //     loba: accNumber,
  //     quantity: 1,
  //     user: mongoUser
  //   });
  //
  //   const mergedProducts = await productsList.map(product => {
  //     const price = response.find(item => item.sku === product.sku)?.price;
  //
  //     return { ...product, price };
  //   });
  //
  //   setProducts(mergedProducts);
  //   setLoading(false);
  // };

  const onSubmit = async (currType: SearchFormTabs, currValue: string) => {
    setType(currType);
    setLoading(true);
    if (drawerOpen) setDrawerOpen(false);
    if (selected?.length) handleSelect(false, selected);

    const body = {
      ...rankings,
      description: '',
      text: '',
      imageUrl: '',
      url: ''
    };

    if (currType === 'equipment') {
      const equipmentValues = JSON.parse(currValue || '{}');
      body.text = ` Looking for a new pizza oven for a ${equipmentValues?.location} that can cook a maximum pizza size of ${equipmentValues?.size} inches, we will be making max ${equipmentValues?.hours} pizzas an hour and we have a ${equipmentValues.requirements} outlet. ${equipmentValues?.comments?.trim() ? `${equipmentValues?.comments}.` : ''} Can you recommend something?`;
    }

    if (currType === 'image') {
      body.imageUrl = currValue;
      body.description = values?.imageText;
    }

    if (currType === 'url') {
      body.url = currValue;
    }

    if (currType === 'text') {
      body.text = currValue;
    }

    const apis = {
      url: fetchByUrl,
      text: fetchByText,
      equipment: fetchByText,
      image: fetchByImageUrl
    };

    const currApi = apis[currType];

    try {
      const { isSuccess, result } = await currApi(body as any);

      if (isSuccess && result) {
        const { matchedItems } = result;
        const items = matchedItems.flatMap(
          ({
            matchedInternalProducts
          }: {
            matchedInternalProducts: Product[];
          }) => matchedInternalProducts
        );

        if (items?.length) {
          // This will be used when we add customer input
          // if (isSinger) {
          //   setLoading(true);
          //   handlePrices(items);
          // } else setProducts(items);

          setProducts(items);
          setDescription(result?.matchedItems?.[0]?.description || '');
          setTitle(result?.matchedItems?.[0]?.productName || '');
        }

        setEmpty(!items.length);
        setLoading(false);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      setEmpty(true);
    }
  };

  useEffect(() => {
    onSubmit(type, values[type]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultAnalytics = {
    client: mongoClient,
    db: companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]
      ?.Value,
    collection: MONGO_REALM_COLLECTIONS.ANALYTICS,
    user
  };

  const onProductClick = (product: Product, tab: 'stock' | 'product') => {
    setSelectedProduct(product);
    setDialogOpened(true);

    setDetailsTab(
      tab === 'stock'
        ? ProductDetailsTabsIds.WAREHOUSE
        : ProductDetailsTabsIds.PRODUCT_INFO
    );
  };

  const isManufacturer = [
    COMPANIES.MIDDLEBY_DEV,
    COMPANIES.MIDDLEBY_PROD
  ].includes(companyData.ID);

  const handlePromotion = useCallback(
    (product: Product) => {
      openDialog(
        <PromotionDialog
          product={product}
          client={mongoClient}
          onClose={closeDialog}
        />
      );
    },
    [openDialog, closeDialog, mongoClient]
  );

  const openProductDetails = (
    product: Product,
    defaultOpenedTab?: ProductDetailsTabsIds
  ) => {
    setSelectedProduct(product);
    setDialogOpened(true);
    if (defaultOpenedTab) setDetailsTab(defaultOpenedTab);

    captureAnalytics({
      ...defaultAnalytics,
      productSKU: product.sku,
      actionType: ProductActionType.DetailsView
    });
  };

  const onFeedbackSubmit = async ({ feedback, rating }: FeedbackValues) => {
    const body = {
      _id: SlackChannels.GeneralFeedback,
      type: SlackMessageTypes.ProductMatch,
      ...(rating ? { rating } : {}),
      feedback,
      user: {
        email: user?.Email,
        userId: user?.ID,
        company: user?.CompanyName,
        fullName: user?.FullName
      },
      results: {
        type,
        data: products,
        value: values[type]
      }
    };

    const res = await sendFeedback(body);

    if (!res) return;

    closeDialog(FEEDBACK_DIALOG);
    enqueueSnackbar('Feedback sent successfully', {
      variant: 'success'
    });
  };

  return {
    type,
    empty,
    title,
    loading: apiLoading || loading,
    products,
    selected,
    userType,
    drawerOpen,
    detailsTab,
    description,
    dialogOpened,
    isManufacturer,
    selectedProduct,
    defaultAnalytics,
    isFeedbackLoading,

    openProductDetails,
    setSelectedProduct,
    onFeedbackSubmit,
    setDialogOpened,
    handlePromotion,
    onProductClick,
    setDrawerOpen,
    handleSelect,
    onSubmit
  };
};
