import {
  Resource,
  PresentationItem,
  TemplateItem,
  LinkItemItems,
  AlgoliaHit,
  AlgoliaHitBreadcrumb,
  FileExtension
} from '@setvi/shared/interfaces';
import {
  ResourceType,
  ResourceIcon,
  ResourceTypeMime,
  ResourceName,
  LinkObjectType
} from '../../enums';

// THIS IS TAKEN FROM BACKEND
export const ResourceExtensions = {
  Extensions:
    '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp4,.zip,.rtf,.mov,.3g2,.3gp,.jpeg,.jpg,.gif,.png,.m4v,.flv,.f4v,.m2v,.mp3,.j2c',
  ExtPdf: '.pdf',
  ExtWord: '.doc,.docx',
  ExtSpreadsheet: '.xls,.xlsx',
  ExtPresentation: '.ppt,.pptx',
  ExtVideo: '.mp4,.mov,.m4v,.flv,.f4v,.3g2,.m2v,.3gp,.mp3',
  ExtDemoApp: '.zip',
  ExtImage: '.jpeg,.jpg,.gif,.png'
};

// TODO: Remove all these remapping functions since they only add unnecessary complexity and problems which are propagated to the rest of the codebase
export const getLinkItemFromResource = (item: Resource): LinkItemItems => ({
  ID: item.ID,
  ResourceID: item.ResourceID,
  Name: item.Name,
  ThumbURL: item.ThumbURLWithSas || item.ThumbURL,
  ResourceTypeID: item.ResourceTypeID,
  ResourceTypeName: item.ResourceTypeName,
  LastChangedDate: item.ResourceLastChangedDate,
  IsShareable: item.IsShareable,
  CategoryID: item?.CategoryID,
  ObjectType:
    item?.ObjectType ||
    (item.Key === 'child'
      ? LinkObjectType.ResourceChild
      : LinkObjectType.Resources),
  // @ts-ignore fix for preselected documents in required reading
  Children: (item.ChildResources || item.Children)?.map(child => ({
    ID: child.ID,
    ItemID: child.ItemID,
    CategoryID: item?.CategoryID,
    ResourceID: child.ResourceID.toString(),
    Name: child.Material,
    ThumbURL: child.ThumbURLWithSas,
    ResourceTypeID: item.ResourceTypeID,
    ResourceTypeName: item.ResourceTypeName,
    LastChangedDate: item.ResourceLastChangedDate,
    IsShareable: item.IsShareable,
    ObjectType: LinkObjectType.ResourceChild
  }))
});

export const getLinkItemFromPresentationItem = (
  item: PresentationItem
): LinkItemItems => ({
  ID: item.ID,
  ItemID: item.ItemID,
  Name: item.Name,
  ThumbURL: item.ThumbURLWithSas,
  ResourceTypeID: item.ResourceTypeID,
  ResourceTypeName: item.ResourceTypeName,
  LastChangedDate: null,
  IsShareable: item.IsShareable,
  Children: [],
  ObjectType: LinkObjectType.Presentation
});

export const getLinkItemFromTemplateItem = (
  item: TemplateItem
): LinkItemItems => ({
  ID: item.ID,
  ItemID: item.ItemID,
  Name: item.Name,
  ThumbURL: item.ThumbURLWithSas,
  ResourceTypeID: item.ResourceTypeID,
  ResourceTypeName: item.ResourceTypeName,
  LastChangedDate: null,
  IsShareable: item.IsShareable,
  Children: [],
  ObjectType: LinkObjectType.Presentation
});

export const GetResourceTypeIcon = (resourceTypeID: number) =>
  ResourceIcon[ResourceType[resourceTypeID] as keyof typeof ResourceIcon];

export const GetResourceTypeIdFromMime = (mimeType: string) => {
  const key = Object.values(ResourceTypeMime).find((v: string) =>
    mimeType.includes(v)
  );

  return Object.keys(ResourceType)[
    Object.values(ResourceTypeMime).indexOf(key)
  ];
};

export const GetResourceTypeName = (resourceTypeID: number) =>
  ResourceName[ResourceType[resourceTypeID] as keyof typeof ResourceName];

export const getResourceFromAlgoliaHit = (
  hit: AlgoliaHit,
  sasToken: string
): Resource => ({
  ID: hit.id,
  ItemID: hit.id.toString(),
  Name: hit.name,
  ThumbURL: `${hit.thumbURL}${sasToken && sasToken}`,
  ResourceID: hit.resourceID,
  ResourceTypeID: hit.resourceTypeID,
  ResourceTypeName: hit.resourceTypeName,
  ResourceLastChangedDate: hit.resourceLastChangedDate,
  IsShareable: hit.isShareable,
  IsFavorite: false,
  CategoryName: hit.categoryName,
  CategoryID: hit.categoryID,
  Comment: hit.comment,
  CompanyID: hit.companyID,
  FileName: hit.fileName,
  DownloadURLWithSas: `${hit.downloadURL}${sasToken}`,
  isInternallyDownloadable: hit.isInternallyDownloadable,
  IsDownloadable: hit.isInternallyDownloadable,
  Pages: hit.pages,
  PageNumber: hit.pageNumber,
  BoxV2Id: hit?.boxV2Id,
  Breadcrumbs: hit.breadcrumbs.map((breadcrumb: AlgoliaHitBreadcrumb) => ({
    ID: breadcrumb.id,
    Name: breadcrumb.name,
    ResourceCategoryClass: breadcrumb.resourceCategoryClass
  })),
  ChildResources: []
});

// We pass file or resource and get type of file/resource
export const getFileExtension = (type: any) => {
  let extension = type;

  if (typeof type === 'string') {
    if (
      [
        ResourceTypeMime.Video,
        ResourceTypeMime.Audio,
        ResourceTypeMime.Images
      ].some(value => type.includes(value))
    )
      extension = type?.split('/')[0];

    const mimeType =
      Object.keys(ResourceTypeMime).find(
        key =>
          ResourceTypeMime[key as keyof typeof ResourceTypeMime] === extension
      ) || null;

    if (type === ResourceTypeMime.WordDocument2007)
      return ResourceType.WordDocument;

    return ResourceType[mimeType as keyof typeof ResourceType];
  }

  return extension || 0;
};

interface IFile extends File {
  filename?: string;
  handle?: string;
  mimetype?: string;
}

export const filterFilesByExtension = (
  files: IFile[],
  allowedExtensions: string[]
): {
  accepted: IFile[];
  rejected: IFile[];
} => {
  const rejected: IFile[] = [];
  const accepted: IFile[] = [];

  for (const file of files) {
    const currName = file?.filename || file?.name;
    const extension = currName?.toLowerCase().split('.').pop() as FileExtension;
    if (allowedExtensions.includes(`.${extension}`)) accepted.push(file);
    else rejected.push(file);
  }

  return {
    accepted,
    rejected
  };
};
