import React, { useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { distances } from '@setvi/shared/styles';

import SLabel from '../../../sui/slabel';

const useStyles = makeStyles(theme => ({
  paperBox: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'transparent'
  },
  title: {
    marginBottom: distances.px.small
  },
  required: {
    color: '#d72638'
  },
  inputWrapper: {
    boxShadow: 'none',
    position: 'relative',
    borderRadius: '8px'
  },
  input: {
    minHeight: '48px',
    height: 'auto',
    borderRadius: '8px'
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '14px',
    marginTop: distances.px.xsmall,
    textAlign: 'right'
  },
  multiline: {
    '& .MuiInputBase-input': {
      alignSelf: 'flex-start'
    }
  },
  subtitle: {
    color: 'gray',
    fontSize: 14
  }
}));

interface FormikTextfieldProps {
  name: string;
  label?: string | JSX.Element;
  placeholder: string;
  defaultValue?: string;
  required?: boolean;
  type?: string;
  formatRegex?: (value: string) => string;
  labelClass?: any;
  inputClass?: any;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
  multiline?: boolean;
  onChange?: (value: string) => void;
  customErrors?: any;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
  InputProps?: any;
  subtitle?: JSX.Element | string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SFormikTextfield = ({
  name,
  label,
  placeholder,
  required,
  type = 'text',
  labelClass,
  inputClass,
  disabled,
  startIcon,
  endIcon,
  multiline = false,
  defaultValue,
  formatRegex,
  onChange,
  maxLength,
  customErrors,
  InputProps,
  minRows = 6,
  maxRows = 6,
  subtitle,
  onKeyDown
}: FormikTextfieldProps) => {
  const classes = useStyles();

  const formatValue = useCallback(
    (v: string) => {
      if (!formatRegex || type !== 'phone') return v;

      return formatRegex(v);
    },
    [formatRegex, type]
  );

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Box className={classes.paperBox}>
          {!!label && typeof label === 'object' && label}

          {!!label && typeof label === 'string' ? (
            <Box className={classes.title}>
              <SLabel
                title={label}
                className={clsx(labelClass)}
                variant="body2"
                required={required}
              />

              {!!subtitle && (
                <Typography className={classes.subtitle}>{subtitle}</Typography>
              )}
            </Box>
          ) : null}

          <Box className={classes.inputWrapper}>
            <TextField
              id={`standard-multiline-flexible-${name}`}
              name={field.name}
              type={type}
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={formatValue(meta.value)}
              multiline={multiline}
              minRows={minRows}
              maxRows={maxRows}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let newValue = event.target.value;

                if (type === 'phone')
                  newValue = newValue.replace(/[^0-9]/g, '');

                form.setFieldValue(name, newValue);
                onChange?.(newValue);
              }}
              autoComplete="off"
              autoFocus={false}
              className={clsx(classes.input, inputClass)}
              disabled={disabled}
              error={
                (meta.touched && Boolean(meta.error)) ||
                Boolean(customErrors?.[name])
              }
              helperText={meta.error || customErrors?.[name]}
              InputProps={{
                startAdornment: startIcon && (
                  <InputAdornment position="start">{startIcon}</InputAdornment>
                ),
                endAdornment: endIcon && (
                  <InputAdornment position="start">{endIcon}</InputAdornment>
                ),
                ...InputProps
              }}
              variant="outlined"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={
                maxLength && {
                  maxLength: maxLength + 1
                }
              }
              onKeyDown={onKeyDown}
            />
          </Box>
        </Box>
      )}
    </Field>
  );
};

export default SFormikTextfield;
