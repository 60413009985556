import { SText } from '@setvi/shared/components';
import { ColumnsType } from '@setvi/shared/types';
import { RecordType } from '@setvi/shared/interfaces';
import { useMemo } from 'react';
import { SpecificationComponent } from './components';

export const useSpecificationColumns = () => {
  const specificationColumns: ColumnsType = useMemo(
    () => [
      {
        id: 'label',
        label: 'Label',
        width: '30%',
        cellContent: (row: RecordType) => <SText title={row.label} />
      },
      {
        id: 'value',
        label: 'Value',
        width: '70%',
        cellContent: (row: RecordType) => (
          <SpecificationComponent recordType={row} />
        )
      }
    ],
    []
  );

  return { specificationColumns };
};
