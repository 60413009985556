import { Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { Product } from '@setvi/shared/interfaces';
import { Stock } from 'pages/products/components/stock';
import { Customer } from 'Services/MongoRealm/Types';

interface StockContentProps {
  product: Product;
  selectedCustomer: Customer;
  onClick: (product: Product) => void;
}

export const StockContent = ({
  product,
  selectedCustomer,
  onClick
}: StockContentProps) => (
  <Box>
    <Stock
      inventory={product.inventory}
      isDropship={product.dropship}
      selectedCustomer={selectedCustomer}
      onClick={() => onClick(product)}
    />
    {!!product?.custom?.leadTime && (
      <SText
        title={`Lead time: ${product?.custom?.leadTime} Days`}
        size="sm"
        fontColor={TextColor.Grey}
      />
    )}
  </Box>
);
