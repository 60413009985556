import { Tooltip } from '@material-ui/core';
import { SCheckbox } from '@setvi/shared/components';

export interface CustomCheckboxProps<T> {
  item: T;
  tooltipText?: string;
  selectedItems: T[];

  overLimit?: (row: T) => boolean;
  rowKeyGetter: (data: T) => string | number;
  selectDisabled?: (row: T) => boolean;
  onSelectItem: (isChecked: boolean, data: T[]) => void;
}

const CustomCheckbox = <T,>({
  item,
  tooltipText,
  selectedItems,

  selectDisabled,
  rowKeyGetter,
  onSelectItem,
  overLimit
}: CustomCheckboxProps<T>) => {
  const isSelected = selectedItems?.some(
    c => rowKeyGetter(c) === rowKeyGetter(item)
  );

  const isOverLimit = overLimit?.(item) && !isSelected;

  const isDisabled = selectDisabled?.(item) || isOverLimit;

  return (
    <Tooltip
      title={tooltipText}
      disableHoverListener={!(isDisabled && tooltipText?.length > 0)}
      arrow>
      <span onClick={e => e.stopPropagation()}>
        <SCheckbox
          color="primary"
          checked={isSelected}
          onChange={e => onSelectItem(e.target.checked, [item])}
          onClick={e => e.stopPropagation()}
          disabled={isDisabled}
        />
      </span>
    </Tooltip>
  );
};

export default CustomCheckbox;
