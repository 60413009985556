import { useMemo, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { SButton, SEmptyState, SSearchInput } from '@setvi/shared/components';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import {
  ProductPriceType,
  Product as ProductType
} from '@setvi/shared/interfaces';
import { Email } from 'Components/Shared/Icons';
import { distances } from '@setvi/shared/styles';
import { Customer } from 'Services/MongoRealm/Types';
import { Product } from './product';

const border = '1px solid #E6E7E9';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: 360,
    height: '100%',
    overflowY: 'hidden'
  },
  searchContainer: {
    padding: distances.px.base,
    borderBottom: border
  },
  buttonContainer: {
    width: '100%',
    padding: spacing(4, 3),
    borderTop: border
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 128px)',
    justifyContent: 'space-between'
  }
}));

interface ProductDrawerContentProps {
  products: ProductType[];
  buttonLabel: string;
  priceType: ProductPriceType;
  selectedCustomer: Customer;
  priceLoading?: boolean;
  handleRemoveProduct: (product: ProductType) => void;
  getProductPrice: (product: ProductType[]) => Promise<void>;
  handleEmail: () => void;
  handleClose: () => void;
}

export const ProductDrawerContent = ({
  products,
  buttonLabel,
  priceType,
  selectedCustomer,
  priceLoading,
  handleEmail,
  handleClose,
  handleRemoveProduct,
  getProductPrice
}: ProductDrawerContentProps) => {
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const list = useMemo(() => {
    if (!search.trim().length) return products;

    return products.filter(
      i =>
        i?.name.toLowerCase()?.includes(search.toLowerCase()) ||
        i?.sku.toLowerCase()?.includes(search.toLowerCase()) ||
        (i?.vendor?.name || '').toLowerCase()?.includes(search.toLowerCase()) ||
        (i?.manufacturerSku || '').toLowerCase().includes(search?.toLowerCase())
    );
  }, [products, search]);

  return (
    <Box className={classes.container}>
      <Header
        title={`${products.length} Product${products.length > 1 ? 's' : ''} Selected`}
        handleClose={handleClose}
      />
      <Box className={classes.searchContainer}>
        <SSearchInput
          defaultValue={search}
          onChange={val => setSearch(val)}
          placeholder="Search selected products..."
          width="100%"
        />
      </Box>
      <Box className={classes.content}>
        <Box overflow="auto" height="100%">
          {list.length > 0 ? (
            list.map(product => (
              <Product
                key={product._id}
                product={product}
                searchQuery={search}
                priceType={priceType}
                priceLoading={priceLoading}
                selectedCustomer={selectedCustomer}
                removeProduct={handleRemoveProduct}
                getProductPrice={getProductPrice}
              />
            ))
          ) : (
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="center">
              <SEmptyState
                title="No Results found"
                imageSrc="/images/product-placeholder.svg"
              />
            </Box>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          <SButton
            fullWidth
            onClick={handleEmail}
            startIcon={<Email fill="fff" />}>
            {buttonLabel}
          </SButton>
        </Box>
      </Box>
    </Box>
  );
};
