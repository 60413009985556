import { Box, styled } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';
import { SalesEnablementItemProps } from '../../hook';

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 220,
  minWidth: 220,
  gridGap: '4px'
});

const Button = styled(SButton)({
  display: 'flex',
  justifyContent: 'flex-start'
});

interface SidebarProps {
  items: SalesEnablementItemProps[];
  activeItem: string;
  setActiveItem: (item: string) => void;
}

export const Sidebar = ({ items, activeItem, setActiveItem }: SidebarProps) => (
  <Wrapper>
    {items?.map(({ _id, name, loading }) => (
      <Button
        key={name}
        variant="text"
        fullWidth
        disabled={loading}
        loading={loading}
        color={activeItem === _id ? 'primary' : 'default'}
        onClick={() => setActiveItem(_id)}>
        {name}
      </Button>
    ))}
  </Wrapper>
);
