import React, { memo, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { STabs } from '@setvi/shared/components';

import { BoxProps } from '@material-ui/core';
import { Container, Content } from './styled';
import ProductSpecsInput from './specs';
import WebUrlInput from './url';
import Equipment from './equipment';
import ImageInput from './image';

export enum SearchFormTabs {
  URL = 'url',
  TEXT = 'text',
  EQUIPMENT = 'equipment',
  IMAGE = 'image'
}

enum TabLabels {
  URL = 'Web Link',
  TEXT = 'Match Product Specs',
  EQUIPMENT = 'Equipment Finder',
  IMAGE = 'Image'
}

enum TabLabelsShort {
  URL = 'By Link',
  TEXT = 'By Type',
  EQUIPMENT = 'By Finder',
  IMAGE = 'By Image'
}

export interface SearchFormProps {
  tabs?: string[];
  type?: SearchFormTabs;
  values?: {
    url: string;
    text: string;
    equipment: string;
    image: string;
    imageText: string;
  };
  expanded?: boolean;
  disableButton?: boolean;
  height?: BoxProps['height'];
  onSubmit: (type: SearchFormTabs, val: string) => void;
  setValues: React.Dispatch<
    React.SetStateAction<{
      url: string;
      text: string;
      equipment: string;
      image: string;
      imageText: string;
    }>
  >;
}

const SearchFormMemo = ({
  tabs,
  type,
  values,
  height,
  expanded,
  disableButton,
  onSubmit,
  setValues
}: SearchFormProps) => {
  const flags = useFlags();
  const [currentTab, setCurrentTab] = useState<SearchFormTabs>(
    (type as SearchFormTabs) || SearchFormTabs.URL
  );

  const tabsList = useMemo(
    () => [
      ...(tabs?.includes('url')
        ? [
            {
              label: expanded ? TabLabels.URL : TabLabelsShort.URL,
              value: SearchFormTabs.URL
            }
          ]
        : []),
      ...(flags.equipmentFinder && tabs?.includes('equipment')
        ? [
            {
              label: expanded ? TabLabels.EQUIPMENT : TabLabelsShort.EQUIPMENT,
              value: SearchFormTabs.EQUIPMENT
            }
          ]
        : []),
      ...(tabs?.includes('text')
        ? [
            {
              label: expanded ? TabLabels.TEXT : TabLabelsShort.TEXT,
              value: SearchFormTabs.TEXT
            }
          ]
        : []),
      ...(tabs?.includes('image')
        ? [
            {
              label: expanded ? TabLabels.IMAGE : TabLabelsShort.IMAGE,
              value: SearchFormTabs.IMAGE
            }
          ]
        : [])
    ],
    [expanded, flags.equipmentFinder, tabs]
  );

  const variant = useMemo(() => {
    if (expanded) return 'standard';

    return tabsList?.length > 3 ? 'scrollable' : 'fullWidth';
  }, [expanded, tabsList?.length]);

  const showTabs = useMemo(() => {
    if (expanded) return true;

    return tabsList?.length > 1;
  }, [expanded, tabsList]);

  return (
    <Container height={height}>
      {showTabs && (
        <STabs
          variant={variant}
          tabsList={tabsList}
          currentTab={currentTab}
          handleTabChange={setCurrentTab}
        />
      )}

      <Content
        sx={{
          width: expanded ? '100%' : '90%',
          marginTop: showTabs ? 0 : 4
        }}>
        {currentTab === SearchFormTabs.URL && (
          <WebUrlInput
            values={values}
            onSubmit={onSubmit}
            setValues={setValues}
            disableButton={disableButton}
          />
        )}
        {flags.equipmentFinder && currentTab === SearchFormTabs.EQUIPMENT && (
          <Equipment
            values={values}
            expanded={expanded}
            disableButton={disableButton}
            onSubmit={onSubmit}
            setValues={setValues}
          />
        )}
        {currentTab === SearchFormTabs.TEXT && (
          <ProductSpecsInput
            values={values}
            onSubmit={onSubmit}
            setValues={setValues}
            disableButton={disableButton}
          />
        )}
        {currentTab === SearchFormTabs.IMAGE && (
          <ImageInput
            values={values}
            onSubmit={onSubmit}
            setValues={setValues}
            disableButton={disableButton}
          />
        )}
      </Content>
    </Container>
  );
};

const SearchForm = memo(SearchFormMemo);
export default SearchForm;
