import { CustomPropertyType, RecordType } from 'Services/MongoRealm/Types';
import { SText, SETVIImage } from '@setvi/shared/components';
import { Box } from '@material-ui/core';
import { Link } from './link';
import { BooleanValue } from './boolean';
import { ArrayStringValue } from './array-string';

interface SpecificationComponentProps {
  recordType: RecordType;
}

export const SpecificationComponent = ({
  recordType
}: SpecificationComponentProps) => {
  if (recordType.type === CustomPropertyType.Link)
    return <Link href={recordType.value as string}>{recordType.value}</Link>;

  if (recordType.type === CustomPropertyType.Boolean)
    return <BooleanValue value={recordType.value as boolean} />;

  if (recordType.type === CustomPropertyType.String)
    return (
      <SText
        noLineClamp
        weight="bold"
        style={{ wordBreak: 'break-word' }}
        title={recordType.value as string}
      />
    );

  if (recordType.type === CustomPropertyType.HTML) {
    /* eslint-disable-next-line */
    return <div dangerouslySetInnerHTML={{ __html: recordType.value }}></div>;
  }

  if (recordType.type === CustomPropertyType.ArrayString)
    return <ArrayStringValue values={recordType.value as Array<string>} />;

  if (recordType.type === CustomPropertyType.LabeledImageArray) {
    const values = recordType.value as unknown as {
      label: string;
      value: string;
    }[];

    return (
      <>
        {values.map(item => (
          <Box display="flex" alignItems="center" mb={1}>
            <SETVIImage
              style={{ height: '35px', marginRight: '10px' }}
              src={item.value.split(';')[2]}
              alt={item.label}
            />
            <SText title={item.label} />
          </Box>
        ))}
      </>
    );
  }

  return (
    <SText noLineClamp weight="bold" title={JSON.stringify(recordType.value)} />
  );
};
