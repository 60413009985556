export enum ResourceType {
  Unknown = 0,
  Product = 1,
  TextDocument = 2,
  PdfDocument = 3,
  WordDocument = 4,
  Video = 5,
  Audio = 6,
  Images = 7,
  WebPage = 8,
  YoutubeVideos = 9,
  VimeoVideos = 10,
  Spreadsheet = 11,
  Presentation = 12,
  HTML5 = 13,
  PhotoAlbum = 14,
  KeyNote = 15,
  Slides = 16,
  PptVar = 17,
  PdfSinglePage = 18,
  JSON = 19,
  CSV = 20,
  App = 21,
  Powerpoint = 22
}
