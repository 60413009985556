import { Box, makeStyles } from '@material-ui/core';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { SText } from '@setvi/shared/components';
import clsx from 'clsx';
import { Product } from '@setvi/shared/interfaces';

const useProductStatusStyles = makeStyles(theme => ({
  container: {
    border: '1px solid #E6E7E9',
    borderRadius: 20,
    padding: theme.spacing(2),
    cursor: 'pointer',
    userSelect: 'none'
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`
  }
}));

const ProductStatusMap = {
  active: {
    icon: (
      <CheckCircleOutlineOutlinedIcon fontSize="small" htmlColor="#23A566" />
    ),
    label: 'Active'
  },
  inactive: {
    icon: <QueryBuilderOutlinedIcon fontSize="small" htmlColor="#FF9F29" />,
    label: 'Inactive'
  },
  discontinued: {
    icon: <CancelOutlinedIcon fontSize="small" htmlColor="#FC5A5A" />,
    label: 'Discontinued'
  }
};

export const getProductStatus = (product: Product): string => {
  let status = 'Inactive';

  if (product?.discontinued) {
    status = 'Discontinued';
  } else if (product?.active) {
    status = 'Active';
  }

  return status;
};

interface ProductStatusProps {
  status: keyof typeof ProductStatusMap;
  isButton?: boolean;
  isSelected?: boolean;
  onClick?: (status: keyof typeof ProductStatusMap) => void;
}

export const ProductStatus = ({
  status,
  isButton,
  isSelected,
  onClick
}: ProductStatusProps) => {
  const styles = useProductStatusStyles();
  return (
    <Box
      onClick={() => onClick?.(status)}
      display="inline-flex"
      alignItems="center"
      className={clsx({
        [styles.container]: isButton,
        [styles.selected]: isSelected
      })}>
      {ProductStatusMap[status].icon}
      <Box mx={2}>
        <SText size="sm" title={ProductStatusMap[status].label} />
      </Box>
    </Box>
  );
};
