import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import { STabs } from '@setvi/shared/components';
import { ResourceType } from '@setvi/shared/enums';

import { Container, Content, LoadContainer, LoadContent } from './styled';
import { Chat, FAQ, Overview } from './tabs';
import { useAskPdf } from './hook';

export interface AskPdfProps {
  id: string;
  url: string;
  name: string;
  typeId: ResourceType;
  thumbnail: string;
}

export interface ConversationProps {
  question: string;
  answer: string;
}

export enum Tabs {
  AskT = 'Ask Questions',
  OverviewT = 'PDF Overview',
  FAQT = 'FAQ'
}

const AskPdf = ({ id, url, name, typeId, thumbnail }: AskPdfProps) => {
  const {
    tab,
    faq,
    loading,
    overview,
    tabsList,
    questions,
    initLoading,
    conversation,

    setTab,
    onSubmit
  } = useAskPdf({ url });

  if (initLoading)
    return (
      <LoadContainer>
        <LoadContent>
          <DotLottieReact
            loop
            autoplay
            height={1}
            src="/images/lottie/processing.lottie"
          />
        </LoadContent>
      </LoadContainer>
    );

  return (
    <Container>
      <STabs currentTab={tab} tabsList={tabsList} handleTabChange={setTab} />

      <Content>
        {tab === Tabs.AskT && (
          <Chat
            id={id}
            name={name}
            loading={loading}
            typeId={typeId}
            thumbnail={thumbnail}
            questions={questions}
            conversation={conversation}
            onSubmit={onSubmit}
          />
        )}
        {tab === Tabs.OverviewT && <Overview overview={overview} />}
        {tab === Tabs.FAQT && <FAQ data={faq} />}
      </Content>
    </Container>
  );
};

export default AskPdf;
