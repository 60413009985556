import { Theme, makeStyles } from '@material-ui/core';
import { TextColor } from '@setvi/shared/enums';
import { CardSizes } from 'componentsV2/cards/grid/card';
import { IconPosition } from '../../enums';

const cornerIconSpace = 10;

export const useStyles = makeStyles<Theme, { size: CardSizes }>(
  ({ spacing }) => ({
    wrapper: {
      justifyContent: 'space-between',
      '&:hover #favorite-button': {
        opacity: 1
      },
      height: '100%'
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(2),
      gap: spacing(1),
      height: '100%',
      minHeight: 67
    },
    img: {
      width: '',
      height: 186
    },
    desc: {
      width: '97%',
      color: TextColor.Grey
    },
    action: {
      alignSelf: 'flex-end'
    },
    arrow: {
      fontSize: 14
    },
    cornerIcon: {
      position: 'absolute'
    },
    [IconPosition.topRight]: {
      top: cornerIconSpace,
      right: cornerIconSpace
    },
    [IconPosition.topLeft]: {
      top: cornerIconSpace,
      left: cornerIconSpace
    },
    [IconPosition.bottomRight]: {
      bottom: cornerIconSpace,
      right: cornerIconSpace
    },
    [IconPosition.bottomLeft]: {
      bottom: cornerIconSpace,
      left: cornerIconSpace
    }
  })
);
