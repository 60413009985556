import { CSSProperties, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDialog } from '@setvi/shared/providers';
import { AIDIalogContainer, AIFooter, AIHeaderContainer } from './styled';
import { SAILogo } from '../logo';
import { SAITitle } from '../title';
import SETVIImage from '../../setvi-image';
import { TextColor } from '../../../enums';

interface AIDIalogContentProps
  extends Pick<BoxProps, 'width' | 'height' | 'maxWidth' | 'maxHeight'> {
  children: ReactNode;
  title: string;
  description?: string;
  headerDivider?: boolean;
  footerDivider?: boolean;
  containerStyle?: CSSProperties;
  onBack?: () => void;
}

export const SAIDIalogContainer = ({
  children,
  title,
  description,
  headerDivider = true,
  footerDivider = false,
  containerStyle = {},
  width = '70vw',
  height = '90vh',
  onBack,
  ...rest
}: AIDIalogContentProps) => {
  const { closeDialog } = useDialog();

  return (
    <AIDIalogContainer
      height={height}
      width={width}
      style={containerStyle}
      {...rest}>
      <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
        <AIHeaderContainer>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            mt={2}>
            <SAITitle variant="h3">{title}</SAITitle>
            {description && (
              <Typography style={{ color: TextColor.Grey }}>
                {description}
              </Typography>
            )}
          </Box>
          <Box display="flex">
            <SETVIImage width={140} src="/images/SETVIAI.png" alt="AI Image" />
            <IconButton
              aria-label="close"
              size="small"
              style={{ alignSelf: 'flex-start', marginTop: 8 }}
              onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </AIHeaderContainer>

        {headerDivider && <Divider />}
        <Box
          overflow="hidden auto"
          display="flex"
          width="100%"
          flexDirection="column"
          flex={1}>
          {children}
        </Box>
      </Box>
      <Box>
        {footerDivider && <Divider />}
        <AIFooter>
          <SAILogo />
        </AIFooter>
      </Box>
    </AIDIalogContainer>
  );
};
