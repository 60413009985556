import { AxiosMethods, SalesEnablementApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import {
  ComparisonBody,
  RecommendationBody,
  SaveSalesEnablementBody,
  GenerateSalesEnablementBody
} from './types';

export enum SalesEnablementMutationsKey {
  SAVE = 'SAVE_SALES_ENABLEMENT',
  GENERATE = 'GENERATE_SALES_ENABLEMENT',
  COMPARE = 'COMPARE_SALES_ENABLEMENT',
  RECOMMEND = 'RECOMMEND_SALES_ENABLEMENT',
  GENERATE_PROMPT = 'GENERATE_PROMPT_SALES_ENABLEMENT',
  GET_PROMPTS = 'GET_PROMPTS_SALES_ENABLEMENT'
}

export const saveSalesEnablementMutation = () => ({
  mutationKey: [SalesEnablementMutationsKey.SAVE],
  mutationFn: (body: SaveSalesEnablementBody) =>
    axiosHelper({
      endpoint: SalesEnablementApi.SaveSalesEnablement,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body
    })
});

export const generateSalesEnablementMutation = () => ({
  mutationKey: [SalesEnablementMutationsKey.GENERATE],
  mutationFn: ({ promptId, ...rest }: GenerateSalesEnablementBody) =>
    axiosHelper({
      endpoint: SalesEnablementApi.GenerateSalesEnablement.replace(
        ':promptId',
        promptId
      ),
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body: { ...rest }
    })
});

export const compareProductsMutation = (body: ComparisonBody) => ({
  queryKey: [SalesEnablementMutationsKey.COMPARE],
  queryFn: () =>
    axiosHelper({
      endpoint: SalesEnablementApi.Comparison,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body
    }),
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const recommendProductsMutation = (body: RecommendationBody) => ({
  queryKey: [SalesEnablementMutationsKey.RECOMMEND],
  queryFn: () =>
    axiosHelper({
      endpoint: SalesEnablementApi.Recommendation,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body
    }),
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const generatePromptSalesEnablementMutation = () => ({
  mutationKey: [SalesEnablementMutationsKey.GENERATE_PROMPT],
  mutationFn: (prompt: string) =>
    axiosHelper({
      endpoint: SalesEnablementApi.GeneratePrompt,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body: { prompt }
    }),
  cacheTime: 0
});

export const getSalesEnablementProductPromptsMutation = (
  categoryId: string
) => ({
  mutationKey: [SalesEnablementMutationsKey.GET_PROMPTS],
  mutationFn: () =>
    axiosHelper({
      endpoint: SalesEnablementApi.GetSalesEnablementItems.replace(
        ':categoryId',
        categoryId
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_INTELLIGENCE_API
    }),
  cacheTime: 0
});
