import { Badge, withStyles } from '@material-ui/core';
import { STooltip } from '@setvi/shared/components';
import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
}

const StyledBadge = withStyles(() => ({
  badge: {
    position: 'relative',
    transform: 'none',
    backgroundColor: '#572BD3',
    color: '#ffffff'
  }
}))(Badge);

export const PrivateLabel = ({ style }: Props) => (
  <STooltip title="Private Label" style={style}>
    <StyledBadge badgeContent="P" />
  </STooltip>
);
