import { Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { ColumnsType } from '@setvi/shared/types';
import { Promotion } from 'Services/MongoRealm/Types';

export const usePromotionListColumns = () => {
  const promotionListColumns: ColumnsType = [
    {
      id: 'promotion',
      width: '60%',
      label: 'Promotions',
      // eslint-disable-next-line no-underscore-dangle
      cellContent: (row: Promotion) => (
        <Box my={2}>
          <SText title={row.name} />
        </Box>
      )
    },
    {
      id: 'validFrom',
      width: '20%',
      label: 'Valid From',
      cellContent: (row: Promotion) => <SText title={row.startDate} />
    },
    {
      id: 'validUntil',
      width: '20%',
      label: 'Valid Until',
      cellContent: (row: Promotion) => <SText title={row.endDate} />
    }
  ];

  return { promotionListColumns };
};
