import { Box, IconButton, styled } from '@material-ui/core';

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: 12,
  padding: theme.spacing(3)
}));

export const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gridGap: 20,
  padding: theme.spacing(3)
}));

export const Ratings = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  gridGap: 16
});

export const RatingItem = styled(IconButton)({
  padding: 6,
  borderRadius: 12,
  border: '1px solid gray',
  borderColor: ({ active }: { active?: string }) => active || 'gray',

  '& .MuiSvgIcon-root': {
    width: 40,
    height: 40,
    fill: ({ active }: { active?: string }) => active || 'gray',

    '&:hover': {
      animation: 'shake 0.5s ease-in-out'
    }
  }
});

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: 12,
  padding: theme.spacing(3)
}));
