import { AIIcon } from '@setvi/shared/components/sicons';

import AIloader from 'componentsV2/ai/loader';

import { AskPdfProps, ConversationProps } from '../..';
import { AITextarea, Placeholder } from './components';
import {
  AIBox,
  ChatContent,
  Content,
  IconBox,
  Message,
  TextareaBox,
  UserBox
} from './styled';

interface ChatProps extends Partial<AskPdfProps> {
  conversation: ConversationProps[];
  questions: string[];
  loading: boolean;

  onSubmit: (val: string) => void;
}

const Chat = ({
  id,
  name,
  typeId,
  loading,
  questions,
  thumbnail,
  conversation,
  onSubmit
}: ChatProps) => (
  <>
    <Content>
      {!conversation?.length ? (
        <Placeholder
          questions={questions}
          onClick={onSubmit}
          thumbnail={thumbnail}
          typeId={typeId}
          name={name}
          id={id}
        />
      ) : (
        <ChatContent>
          {conversation?.map(({ question, answer }) => (
            <>
              <UserBox>
                <Message>{question}</Message>
              </UserBox>

              <AIBox
                style={{
                  alignItems:
                    loading && !answer?.trim() ? 'center' : 'flex-start'
                }}>
                <IconBox>
                  <AIIcon fontSize="small" />
                </IconBox>
                {loading && !answer?.trim() ? (
                  <AIloader />
                ) : (
                  <Message
                    style={{
                      background: '#F0F0F4'
                    }}>
                    {answer}
                  </Message>
                )}
              </AIBox>
            </>
          ))}
        </ChatContent>
      )}
    </Content>

    <TextareaBox>
      <AITextarea onSubmit={onSubmit} />
    </TextareaBox>
  </>
);

export default Chat;
