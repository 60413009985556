import { useMemo } from 'react';
import { Box, Divider, styled } from '@material-ui/core';

import { Product } from '@setvi/shared/interfaces';
import { AIIcon } from '@setvi/shared/components/sicons';
import { AILoader, SButton, SEmptyState } from '@setvi/shared/components';

import { useSalesEnablement } from './hook';
import { Sidebar, Content } from './components';

import { EmptyContainer, ScrollableWrapper } from './styled';

const Wrapper = styled(Box)({
  padding: 12
});

export interface SalesEnablementProps {
  product: Product;
}

export const SalesEnablement = ({ product }: SalesEnablementProps) => {
  const {
    items,
    activeItem,
    initalLoading,

    setActiveItem,
    handleRegeneration
  } = useSalesEnablement({ product });

  const currenItem = useMemo(
    () => items?.find(item => item._id === activeItem),
    [items, activeItem]
  );

  if (initalLoading)
    return <AILoader width={250} height={250} title="Generating Data..." />;

  return (
    <Box
      display="flex"
      height="100%"
      overflow="hidden"
      style={{ minHeight: 0 }}>
      <Wrapper overflow="hidden auto" minWidth={250}>
        <Sidebar
          items={items}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </Wrapper>

      <Divider orientation="vertical" />

      {!!currenItem?.content?.length && (
        <ScrollableWrapper p={currenItem?.isFAQ ? 0 : 4}>
          <Content isFAQ={currenItem?.isFAQ} content={currenItem?.content} />
        </ScrollableWrapper>
      )}

      {!currenItem?.content?.length && !currenItem?.loading && (
        <EmptyContainer>
          <SEmptyState
            imageSrc="/images/empty/empty-pc-search.png"
            imageAlt="Empty Content"
            title="No Content Found"
            additionalContent={
              <SButton
                variant="outlined"
                startIcon={<AIIcon />}
                onClick={handleRegeneration}>
                Generate Again
              </SButton>
            }
          />
        </EmptyContainer>
      )}

      {currenItem?.loading && (
        <AILoader width={150} height={150} title="Regenerating Data..." />
      )}
    </Box>
  );
};
