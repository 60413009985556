import { Box, Divider } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { Customer } from 'Services/MongoRealm/Types';
import { PriceButton } from 'pages/products/components/price-button';
import { Stock } from 'pages/products/components/stock';
import { ColoredPill } from 'pages/products/components/colored-pill';
import { useDialog } from '@setvi/shared/providers';
import { PromotionDialog } from 'pages/products/components/promotion';
import {
  Product,
  ProductDetails,
  ProductPriceType
} from '@setvi/shared/interfaces';
import { getProductStatus } from 'componentsV2/product/product-status';
import { PrivateLabel } from 'componentsV2/product/private-label';
import { TextColor } from '@setvi/shared/enums';
import { getProductsPrices } from 'pages/products/api';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FullPrice } from './components';

interface ProductShowcaseProps {
  product: Product;
  selectedCustomer: Customer;
  hideProductPrice?: boolean;
  client: any;
  priceType?: ProductPriceType;
  productDetails: ProductDetails;
  updateProduct?: (product: Product, props: Partial<Product>) => void;
  onStockClick?: () => void;
}

export const ProductShowcase = ({
  product,
  selectedCustomer,
  client,
  hideProductPrice = false,
  priceType,
  productDetails,
  updateProduct,
  onStockClick
}: ProductShowcaseProps) => {
  const { openDialog, closeDialog } = useDialog();
  const { mutationFn } = getProductsPrices();
  const mutation = useMutation(mutationFn);
  const { enqueueSnackbar } = useSnackbar();

  const handleGetPrice = async () => {
    try {
      const response = await mutation.mutateAsync({
        accountNumber: selectedCustomer?.accountNumber,
        products: [{ sku: product.sku, quantity: 1 }]
      });

      const { sell, cost } = response?.pricing?.[0];
      const newValues = {
        price: sell,
        ...(Number(cost) ? { cost } : {})
      };

      return updateProduct?.(product, newValues);
    } catch (error) {
      enqueueSnackbar('Error occured while fetching product price', {
        variant: 'error'
      });
    }
  };

  const handlePromotionsPillClick = () => {
    openDialog(
      <PromotionDialog
        product={product}
        client={client}
        onClose={() => closeDialog('promotion-dialog')}
      />,
      {
        id: 'promotion-dialog'
      }
    );
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="12px">
      <Box display="flex">
        {product?.promotions?.length > 0 && (
          <ColoredPill
            color="#FF572233"
            title="Promotions"
            imageUrl="/images/product-promotion.png"
            onClick={handlePromotionsPillClick}
          />
        )}

        {/* <ColoredPill
          color="#A461D833"
          title="Strategic Vendor"
          imageUrl="/images/special-vendor.png"
        /> */}
      </Box>
      <SText maxLines={2} weight="bold" size="xl" title={product?.name} />
      <Box display="flex" alignItems="center" gridGap="8px">
        <Box display="flex" alignItems="center" gridGap="4px">
          <SText
            fontColor={TextColor.DarkGrey}
            title={`SKU: ${product?.sku}`}
          />
          <SText
            fontColor={TextColor.DarkGrey}
            title={`(${getProductStatus(product)})`}
          />
        </Box>
        {productDetails?.stock && (
          <Stock
            inventory={product?.inventory}
            isDropship={product?.dropship}
            selectedCustomer={selectedCustomer}
            onClick={onStockClick}
          />
        )}
      </Box>
      <Divider />
      {product?.vendor?.name && (
        <>
          <Box display="flex" alignItems="center" gridGap="4px">
            <SText
              fontColor={TextColor.DarkGrey}
              title={`MFG: ${product?.vendor?.name} ${product?.manufacturerSku ? `(${product?.manufacturerSku})` : ''}`}
            />
            {product?.privateLabel && <PrivateLabel />}
          </Box>
          <Divider />
        </>
      )}
      {!hideProductPrice && (
        <>
          {priceType === ProductPriceType.List && (
            <SText
              title={product?.price?.toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency'
              })}
            />
          )}
          {priceType === ProductPriceType.Get && !!selectedCustomer && (
            <PriceButton price={product?.price} onGetPrice={handleGetPrice} />
          )}
          {priceType === ProductPriceType.Full && product && (
            <FullPrice
              product={product}
              onGetPrice={handleGetPrice}
              selectedCustomer={!!selectedCustomer}
            />
          )}
          {priceType === ProductPriceType.GetFull && !!selectedCustomer && (
            <>
              {product.price ? (
                <FullPrice
                  product={product}
                  onGetPrice={handleGetPrice}
                  selectedCustomer={!!selectedCustomer}
                />
              ) : (
                <PriceButton
                  price={product?.price}
                  onGetPrice={handleGetPrice}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
