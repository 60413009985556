import { ColumnsType } from '@setvi/shared/types';
import { Customer } from 'Services/MongoRealm/Types';
import {
  ProductColumn,
  ProductDetailsColumnsIds,
  ProductInventory,
  ProductWarehouse
} from '@setvi/shared/interfaces';
import { getWarehouseCellContent } from './columns-content';

const getWarehouseLocation = (warehouse: ProductWarehouse) => {
  if (!warehouse || (!warehouse.city && !warehouse.state)) return 'Unknown';

  return `${warehouse.city && warehouse.city}${
    warehouse.city && warehouse.state ? ',' : ''
  } ${warehouse.state && warehouse.state}`;
};

interface useWarehouseColumnsProps {
  selectedCustomer?: Customer;
  configColumns: ProductColumn[];
}

export const useWarehouseColumns = ({
  selectedCustomer,
  configColumns = []
}: useWarehouseColumnsProps) => {
  const warehouseColumns: ColumnsType = configColumns.map(column => ({
    ...column,
    cellContent: (row: ProductInventory) =>
      getWarehouseCellContent({
        id: column.id as ProductDetailsColumnsIds,
        row,
        selectedCustomer,
        getWarehouseLocation
      })
  }));

  return {
    warehouseColumns
  };
};
