import { lazy } from 'react';

// Home
export const Home = lazy(() => import('pages/home'));

// Campaigns
export const MyCampaignsPage = lazy(
  () => import('Components/Campaigns/MyCampaigns')
);

export const CompanyCampaign = lazy(() => import('Components/CompanyCampaign'));
export const CreateCampaign = lazy(
  () => import('Components/Campaigns/CreateCampaign')
);
export const Details = lazy(() => import('Components/Campaigns/Detail'));
export const FollowUp = lazy(() => import('Components/Campaigns/FollowUp'));

// Sales Enablement
export const Sales = lazy(() => import('pages/sales'));

// Orders
export const OrderEnablement = lazy(() => import('pages/sales/modules/orders'));

export const Orders = lazy(
  () => import('pages/sales/modules/orders/subpages/orders')
);
export const OrderCreate = lazy(
  () => import('pages/sales/modules/orders/components/order-create')
);

export const ReorderIntelligenceModule = lazy(
  () => import('pages/sales/modules/orders/subpages/reorder-intelligence')
);

export const OrderDetails = lazy(
  () =>
    import('pages/sales/modules/orders/subpages/orders/subpages/order-details')
);

export const Backorders = lazy(
  () => import('pages/sales/modules/orders/subpages/backorders')
);

export const CustomerOrders = lazy(
  () =>
    import(
      'pages/sales/modules/orders/subpages/backorders/subpages/customer-orders'
    )
);

export const CustomerOrderItems = lazy(
  () =>
    import(
      'pages/sales/modules/orders/subpages/backorders/subpages/customer-orders/subpages/order-items'
    )
);

export const RecentlyOrderDetails = lazy(
  () =>
    import(
      'pages/sales/modules/orders/subpages/recently-delivered/sub-pages/recently-order-details'
    )
);

export const Invoices = lazy(
  () => import('pages/sales/modules/orders/subpages/invoices')
);

export const InvoiceDetails = lazy(
  () =>
    import(
      'pages/sales/modules/orders/subpages/invoices/subpages/invoice-details'
    )
);

export const Quotes = lazy(
  () => import('pages/sales/modules/quotes/pages/all-quotes')
);

export const PendingApproval = lazy(
  () =>
    import(
      'pages/sales/modules/quotes/pages/all-quotes/subpages/pending-approval'
    )
);

export const QuoteDetails = lazy(
  () =>
    import('pages/sales/modules/quotes/pages/all-quotes/subpages/quote-details')
);

export const QuoteEditReview = lazy(
  () =>
    import(
      'pages/sales/modules/quotes/pages/all-quotes/subpages/quote-details/subpages/review-quote'
    )
);

export const CreateQuote = lazy(
  () => import('pages/sales/modules/quotes/pages/create-quote')
);

export const AddFromCatalog = lazy(
  () =>
    import(
      'pages/sales/modules/quotes/pages/create-quote/subpages/add-from-catalog'
    )
);

export const AddFromRFQ = lazy(
  () =>
    import(
      'pages/sales/modules/quotes/pages/create-quote/subpages/add-from-rfq'
    )
);

export const ReviewQuote = lazy(
  () =>
    import(
      'pages/sales/modules/quotes/pages/create-quote/subpages/review-quote'
    )
);

export const ReorderInteligenceDetails = lazy(
  () =>
    import(
      'pages/sales/modules/orders/subpages/reorder-intelligence/subpages/customer-reorder-details'
    )
);

// Auto RFQ
export const AutoRFQ = lazy(() => import('pages/auto-rfq/pages/list'));
export const CreateAutoRFQ = lazy(() => import('pages/auto-rfq/pages/create'));
export const EditAutoRFQ = lazy(() => import('pages/auto-rfq/pages/edit'));

// Resources
export const Resources = lazy(() => import('Components/Resources'));
export const Favorites = lazy(() => import('Components/Favorites'));

// Workspaces
export const ContactsAndGroups = lazy(
  () => import('pages/contacts-and-groups')
);
export const Contacts = lazy(
  () => import('pages/contacts-and-groups/contacts')
);
export const Groups = lazy(() => import('pages/contacts-and-groups/groups'));
export const MyFiles = lazy(() => import('Components/Workspaces/MyFiles'));
export const MyPresentation = lazy(
  () => import('Components/Workspaces/MyPresentations')
);
export const PresentationDetails = lazy(
  () =>
    import(
      'Components/Workspaces/MyPresentations/Presentations/PresentationDetails'
    )
);
export const TemplateDetails = lazy(
  () =>
    import('Components/Workspaces/MyPresentations/Templates/TemplateDetails')
);
export const WorkspacesSnippets = lazy(
  () => import('pages/workspaces/snippets')
);
export const DigitalRooms = lazy(() => import('pages/digital-rooms'));

// Outbox
export const Outbox = lazy(() => import('pages/outbox'));
export const OutlookDetails = lazy(
  () => import('pages/outbox/subpages/outlook/outlook-details')
);
export const OutlookViews = lazy(
  () => import('pages/outbox/subpages/outlook/outlook-views')
);
export const MultipleRecipientEmailDetails = lazy(
  () => import('pages/outbox/subpages/emails/email-details/multiple-recipient')
);
export const SingleRecipientEmailDetails = lazy(
  () => import('pages/outbox/subpages/emails/email-details/single-recipient')
);
export const Emails = lazy(() => import('pages/outbox/subpages/emails'));
export const OutboxMyCampaigns = lazy(
  () => import('pages/outbox/subpages/campaigns')
);
export const Outlook = lazy(() => import('pages/outbox/subpages/outlook'));
export const OutboxGetLink = lazy(
  () => import('pages/outbox/subpages/get-links')
);
export const Overview = lazy(
  () =>
    import(
      'pages/outbox/subpages/emails/email-details/multiple-recipient/subpages/overview'
    )
);
export const EmailDetails = lazy(
  () =>
    import(
      'pages/outbox/subpages/emails/email-details/multiple-recipient/subpages/email-details'
    )
);
export const OutlookEmailDetails = lazy(
  () => import('pages/outbox/subpages/outlook/outlook-email-details')
);

// Reporting
export const ReportingDetails = lazy(() => import('pages/reporting-details'));
export const ThoughtspotLiveboard = lazy(() => import('pages/thoughtspot'));

// Vet Suite
export const VetSuite = lazy(() => import('pages/vet-suite'));

export const VetSuiteDigitalShowroom = lazy(
  () => import('pages/vet-suite-digital-showroom')
);

export const RequestForQuote = lazy(
  () => import('pages/digital-rooms/pages/request-for-quotes')
);
export const TelaBioOne = lazy(
  () => import('pages/digital-rooms/pages/telabio-one')
);

// Admin Resources
export const EmailQueue = lazy(() => import('pages/email-queue'));
export const AdminResources = lazy(() => import('pages/admin/pages/resources'));

export const CreateCategory = lazy(
  () => import('pages/admin/pages/resources/pages/category/pages/create')
);
export const EditCategory = lazy(
  () => import('pages/admin/pages/resources/pages/category/pages/edit')
);
export const AddSingleResource = lazy(
  () => import('pages/admin/pages/resources/pages/form/pages/add/single')
);
export const EditSingleResource = lazy(
  () => import('pages/admin/pages/resources/pages/form/pages/edit/single')
);
export const AddBulkResource = lazy(
  () => import('pages/admin/pages/resources/pages/form/pages/add/bulk')
);
export const AddZipResource = lazy(
  () => import('pages/admin/pages/resources/pages/form/pages/add/zip')
);

// Admin Campaigns
export const AdminCampaigns = lazy(() => import('pages/admin/pages/campaigns'));
export const AdminCampaignDetails = lazy(
  () => import('pages/admin/pages/campaigns/pages/details')
);
export const AdminCampaignDetailsContent = lazy(
  () => import('pages/admin/pages/campaigns/pages/details/pages/main')
);
export const AdminCampaignDetailsRecipients = lazy(
  () => import('pages/admin/pages/campaigns/pages/details/pages/recipients')
);

export const AdminCampaignsTable = lazy(
  () => import('pages/admin/pages/campaigns/pages/table')
);
export const CreateAdminCampaign = lazy(
  () => import('pages/admin/pages/campaigns/pages/create')
);
export const UpdateAdminCampaign = lazy(
  () => import('pages/admin/pages/campaigns/pages/update')
);

// Admin Users
export const AdminUsers = lazy(() => import('pages/admin/pages/users'));
export const AdminUsersHome = lazy(
  () => import('pages/admin/pages/users/pages/home')
);
export const AdminUserCreate = lazy(
  () => import('pages/admin/pages/users/pages/create')
);
export const AdminUserEdit = lazy(
  () => import('pages/admin/pages/users/pages/edit')
);

// Admin AI Sales Enablement
export const AdminAISalesEnablement = lazy(
  () => import('pages/admin/pages/ai-sales-enablement')
);

// Admin Home Screen(Banners)
export const HomeScreen = lazy(() => import('pages/admin/pages/home-screen'));
export const ManageHomeScreen = lazy(
  () => import('pages/admin/pages/home-screen/pages/home')
);
export const AddBanner = lazy(
  () =>
    import(
      'pages/admin/pages/home-screen/pages/home/pages/home-banners/pages/add'
    )
);
export const EditBanner = lazy(
  () =>
    import(
      'pages/admin/pages/home-screen/pages/home/pages/home-banners/pages/edit'
    )
);
export const AddFeaturedResource = lazy(
  () =>
    import(
      'pages/admin/pages/home-screen/pages/home/pages/featured-resources/pages/add'
    )
);
export const EditFeaturedResource = lazy(
  () =>
    import(
      'pages/admin/pages/home-screen/pages/home/pages/featured-resources/pages/edit'
    )
);

// Settings
export const Settings = lazy(() => import('pages/settings'));
export const Analytics = lazy(() => import('Components/Analytics'));

// Authentication
export const Authenticate = lazy(() => import('pages/login'));

// Products
export const Products = lazy(() => import('pages/products'));
export const ProductDetails = lazy(
  () => import('pages/products/components/details-page')
);

// Email Verification
export const EmailVerification = lazy(() => import('Components/EmailVerify'));
