import {
  Box,
  CircularProgress,
  ClickAwayListener,
  InputBase,
  useTheme
} from '@material-ui/core';
import { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFlags } from 'launchdarkly-react-client-sdk';

import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { SButton } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { AIIcon } from '@setvi/shared/components/sicons';
import SLabel from '@setvi/shared/components/sui/slabel';

import { useProductMatchStore } from 'componentsV2/ask-ai/store';

import {
  Input,
  Image,
  Actions,
  Container,
  RemoveImage,
  SubmitButton,
  InputContent,
  InputPlaceholder
} from './styled';
import { SearchFormProps, SearchFormTabs } from '..';
import { Ranking } from '../../result/components';
import { LabelContainer } from '../styled';
import { useImageInput } from './hook';
import DNDOverlay from './overlay';

export const acceptedFileTypes: Record<string, string[]> = {
  'image/*': ['.jpeg', '.jpg', '.png']
};

const ImageInput = ({
  values,
  disableButton,
  onSubmit,
  setValues
}: SearchFormProps) => {
  const flags = useFlags();
  const inputRef = useRef(null);

  const { openDialog, closeDialog } = useDialog();
  const { rankings, setRankings } = useProductMatchStore();

  const { palette } = useTheme();
  const { focus, loading, onDrop, setFocus, onRemoveImage, onDropRejected } =
    useImageInput({
      setValues
    });

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected,
    noKeyboard: true,
    noClick: true,
    maxFiles: 1,
    disabled: loading,
    accept: acceptedFileTypes
  });

  return (
    <ClickAwayListener onClickAway={() => setFocus(false)}>
      <Container>
        <LabelContainer>
          <CropOriginalRoundedIcon color="primary" />
          <SLabel title="Upload or Drop Image" variant="body2" />
        </LabelContainer>
        <Input
          onClick={() => {
            setFocus(true);
            // focus on textarea
            const textarea = inputRef.current?.querySelector('textarea');
            if (textarea) {
              textarea.focus();
            }
          }}
          sx={{
            borderColor: focus ? palette.primary.main : palette.grey[200]
          }}>
          <InputContent {...getRootProps()}>
            <input {...getInputProps()} />

            <DNDOverlay visible={isDragActive} />

            {(values?.image || loading) && (
              <Box position="relative" width="fit-content">
                {loading ? (
                  <InputPlaceholder
                    width={120}
                    height={120}
                    justifyContent="center"
                    alignItems="center">
                    <CircularProgress />
                  </InputPlaceholder>
                ) : (
                  <>
                    <Image
                      width={120}
                      height={120}
                      alt="Placeholder"
                      src={values?.image}
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                    <RemoveImage
                      onClick={e => {
                        e.stopPropagation();
                        onRemoveImage();
                      }}>
                      <CloseRoundedIcon htmlColor="#696974" />
                    </RemoveImage>
                  </>
                )}
              </Box>
            )}

            {!values?.image && !values?.imageText && !loading && (
              <InputPlaceholder>
                <div>
                  <span
                    style={{
                      textDecoration: 'underline'
                    }}>
                    Example 1:
                  </span>{' '}
                  Upload or Drop Image + Text Description
                </div>
                <img width={80} alt="Placeholder" src="/images/glasses.png" />
                Find me a glass like in this
              </InputPlaceholder>
            )}

            <InputBase
              fullWidth
              multiline
              maxRows={3}
              ref={inputRef}
              value={values?.imageText}
              onChange={e => {
                setValues({ ...values, imageText: e.target.value });
              }}
            />
          </InputContent>

          <Actions>
            <SButton
              variant="text"
              onClick={open}
              disabled={loading}
              startIcon={
                <CropOriginalRoundedIcon
                  color={loading ? 'disabled' : 'primary'}
                  style={{
                    width: 25,
                    height: 25
                  }}
                />
              }>
              Upload Image
            </SButton>

            {!!flags?.aiRanking && (
              <SButton
                variant="outlined"
                onClick={() =>
                  openDialog(
                    <Ranking
                      onClose={() => closeDialog('ranking')}
                      rankings={rankings}
                      onConfirm={r => {
                        setRankings(r);
                        closeDialog('ranking');
                      }}
                    />,
                    {
                      id: 'ranking'
                    }
                  )
                }>
                Rankings
              </SButton>
            )}

            <SubmitButton
              type="submit"
              disabled={disableButton || !values?.image}
              onClick={() => onSubmit?.(SearchFormTabs.IMAGE, values?.image)}
              startIcon={<AIIcon htmlColor="#fff" />}>
              Find
            </SubmitButton>
          </Actions>
        </Input>
      </Container>
    </ClickAwayListener>
  );
};

export default ImageInput;
