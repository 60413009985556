import { ColumnsType } from '@setvi/shared/types';
import { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { SETVIImage, SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { Stock, StockColor } from 'pages/products/components/stock';
import { Product } from '@setvi/shared/interfaces';
import { SimilarProductsProps } from '../..';

const imgDimension = '48px';

export const useSimilarProductsColumns = ({
  selectedCustomer,
  openProductDetails
}: Pick<SimilarProductsProps, 'selectedCustomer' | 'openProductDetails'>) => {
  const getDollarAmount = (value: number) =>
    value.toLocaleString('en-US', {
      currency: 'USD',
      style: 'currency'
    });

  const columns: ColumnsType = useMemo(
    () => [
      {
        id: 'productName',
        label: 'Product Name',
        maxWidth: '40%',
        cellContent: (product: Product) => (
          <Box display="flex" alignItems="center">
            <>
              <SETVIImage
                style={{
                  width: imgDimension,
                  minWidth: imgDimension,
                  height: imgDimension,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  border: '1px solid #E2E2EA',
                  objectFit: 'contain'
                }}
                src={
                  product.imageUrl ||
                  product?.images?.[0]?.path ||
                  '/images/product-placeholder.svg'
                }
                alt={product?.name}
              />
            </>
            <Box ml={4} display="flex" flexDirection="column" gridGap={4}>
              <SText title={product?.name || '-'} maxLines={2} />
              <SText
                style={{ color: TextColor.Grey }}
                title={product?.sku ? `SKU: ${product?.sku}` : '-'}
                size="sm"
              />
            </Box>
          </Box>
        )
      },
      {
        id: 'manufacturer',
        maxWidth: '220px',
        minWidth: '180px',
        label: 'Manufacturer & SKU',
        cellContent: ({ vendor, manufacturerSku }: Product) => (
          <Box display="flex" flexDirection="column" gridGap={4}>
            <SText title={vendor?.name || '-'} />
            <SText
              title={manufacturerSku || '-'}
              fontColor={TextColor.Grey}
              size="sm"
            />
          </Box>
        )
      },
      {
        id: 'stock',
        width: '140px',
        label: 'Stock',
        cellContent: (product: Product) => (
          <Stock
            inventory={product?.inventory}
            isDropship={product?.dropship}
            selectedCustomer={selectedCustomer}
            onClick={() => openProductDetails(product)}
          />
        )
      },
      ...(selectedCustomer
        ? [
            {
              id: 'sell',
              maxWidth: '100px',
              minWidth: '80px',
              label: 'Sell',
              cellContent: ({ price }: Product) => (
                <SText
                  title={Number.isFinite(price) ? getDollarAmount(price) : '-'}
                />
              )
            }
          ]
        : []),
      {
        id: 'cost',
        maxWidth: '100px',
        minWidth: '80px',
        label: 'Cost',
        cellContent: ({ cost }: Product) => (
          <SText title={cost > 0 ? getDollarAmount(cost) : '-'} />
        )
      },
      ...(selectedCustomer
        ? [
            {
              id: 'GP',
              maxWidth: '100px',
              minWidth: '80px',
              label: 'GP',
              cellContent: ({ price, cost }: Product) => (
                <Box>
                  {Number.isFinite(cost) && price > cost ? (
                    <>
                      <SText
                        title={
                          Number.isFinite(cost) && price > cost
                            ? getDollarAmount(price - cost)
                            : '-'
                        }
                      />

                      <SText
                        title={`(${(((price - cost) / price) * 100).toFixed(2).toString()}%)`}
                        fontColor={StockColor.green}
                        size="sm"
                      />
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
              )
            }
          ]
        : [])
    ],
    [openProductDetails, selectedCustomer]
  );

  return { columns };
};
