import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import { axiosHelper } from '@setvi/shared/services';
import { AxiosMethods, SalesEnablementAdminApi } from '@setvi/shared/enums';

import {
  AdminSalesEnablementQueryResponse,
  AdminSalesEnablementQueryVariables
} from './Types';

export enum AdminSalesEnablementQueryKeys {
  GET_ALL = 'ALL_SALES_ENABLEMENT',
  GET_BY_ID = 'GET_SALES_ENABLEMENT_BY_ID'
}

export const getAdminSalesEnablementsQuery = (
  params: AdminSalesEnablementQueryVariables
): UseInfiniteQueryOptions<AdminSalesEnablementQueryResponse> => ({
  queryKey: [AdminSalesEnablementQueryKeys.GET_ALL, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.GetSalesEnablement,
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      params: {
        ...params,
        page: data.pageParam || params.page
      }
    }),
  getNextPageParam: (lastPage: AdminSalesEnablementQueryResponse) => {
    const total = lastPage?.total;
    const currentPageNumber = total / params.limit;
    return total < params.limit ? undefined : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getAdminSalesEnablementQuery = (id: number | string) => ({
  queryKey: [AdminSalesEnablementQueryKeys.GET_BY_ID, id],
  queryFn: () =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.GetSalesEnablementById.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_INTELLIGENCE_API
    }),
  refetchOnWindowFocus: false,
  cacheTime: 0
});
