import { Box } from '@material-ui/core';
import { SETVIImage, SText } from '@setvi/shared/components';
import { ColumnsType } from '@setvi/shared/types';
import { useTableStyles } from 'Components/Shared';
import { PromotionItem } from 'Services/MongoRealm/Types';
import { Product } from '@setvi/shared/interfaces';

export const usePromotionDetailsTable = () => {
  const classes = useTableStyles();
  const promotionDetailsColumns: ColumnsType = [
    {
      id: 'product',
      width: '80%',
      label: 'Products',
      cellContent: (row: PromotionItem) => (
        <Box display="flex" alignItems="center">
          <SETVIImage
            className={classes.tableImage}
            errorImage="/images/product-placeholder.svg"
            src={row?.image?.path || '/images/product-placeholder.svg'}
            alt={row.name}
          />
          <Box ml={2}>
            <SText title={row.name} />
          </Box>
        </Box>
      )
    },
    {
      id: 'sku',
      width: '20%',
      label: 'SKU',
      cellContent: (row: Product) => <SText title={row.sku} />
    }
  ];

  return { promotionDetailsColumns };
};
