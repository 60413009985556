import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

interface IMutationTrackerProps {
  // Mutation key used to find specific mutation
  key: string;
}

export const useMutationTracker = ({ key }: IMutationTrackerProps) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe(() => {
      const allCurrentMutations = queryClient.getMutationCache().getAll();
      const currentMutationLoading = !!allCurrentMutations?.find(
        m =>
          m?.options?.mutationKey?.includes(key) &&
          m?.state?.status === 'loading'
      );

      setIsLoading(currentMutationLoading);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient]);

  return { isLoading };
};
