import { User } from '@setvi/shared/services/react-query/query/user/types';
import { Customer } from 'Services/MongoRealm/Types';
import { Product } from '@setvi/shared/interfaces';

const removeWords = ['Custom'];

export const getProductStock = (inventory: Product['inventory']): number =>
  inventory?.reduce((acc, curr) => acc + (curr?.availableOnHand || 0), 0) || 0;

export const getProductStockByCustomer = (
  inventory: Product['inventory'],
  customer: Customer
) =>
  inventory?.find(
    // eslint-disable-next-line no-underscore-dangle
    warehouse => warehouse.warehouseId === customer?.warehouse?.code
  )?.availableOnHand || 0;

export const getInStock = (
  inventory: Product['inventory'],
  customer: Customer
) => {
  const productStock = getProductStock(inventory);
  const stockForCustomer = getProductStockByCustomer(inventory, customer);
  const stock = customer ? stockForCustomer : productStock;

  return !!stock;
};

export const getNumOfProductsInComposeMail = (values: any) => {
  const parser = new DOMParser();

  const parsedHTML = parser.parseFromString(values.body, 'text/html');

  const selectedProductsList =
    parsedHTML.getElementsByClassName('selected-product');

  return selectedProductsList.length;
};

export const transformAlgoliaFacetLabel = (label: string) => {
  // Splits label on capital letters, dots and underscores
  const words = label.split(/(?=[A-Z])|\.|_/);

  const filteredWords = words.filter(
    i => !removeWords.some(j => j.toLocaleLowerCase() === i.toLocaleLowerCase())
  );

  // Capitalizes first letter of each word and lowercases the rest and adds a space between words
  const transformedLabel = filteredWords
    .map(
      word =>
        (word[0] || '').toUpperCase() + (word.slice(1) || '').toLowerCase()
    )
    .join(' ')
    .trim();
  return transformedLabel;
};

export enum AppType {
  WebApp = 'WebApp',
  Outlook = 'Outlook'
}

export enum ProductActionType {
  DetailsView = 'ProductDetails',
  AssetView = 'View Asset',
  AddProductToEmail = 'Add Products to Email'
}

interface CaptureAnalyticsProps {
  actionType: ProductActionType;
  client: any;
  appType?: AppType;
  db: string;
  collection: string;
  user: User;
  productSKU?: Product['sku'];
}

export const captureAnalytics = async ({
  client,
  db,
  actionType,
  appType = AppType.WebApp,
  collection,
  user,
  productSKU
}: CaptureAnalyticsProps) => {
  client
    .db(db)
    ?.collection(collection)
    .insertOne({
      appType,
      actionType,
      companyId: user.CompanyID,
      module: 'Catalog',
      ...(productSKU && { productSKU }),
      timestamp: Date.now(),
      userId: user.ID
    });
};

export const valueToMonetary = (
  value: number,
  options?: { [key: string]: string }
) =>
  value > 0 && Number.isFinite(value)
    ? `${value.toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency',
        ...options
      })}`
    : '-';
