import { Box } from '@material-ui/core';
import {
  AdminCampaignsIcon,
  AdminHomeScreenIcon,
  AdminResourcesIcon,
  AdminUsersIcon,
  AIIcon,
  ManageIcon
} from '@setvi/shared/components/sicons';

import { ROUTES } from 'enumsV2';
import { MenuItemProps } from '../..';

enum FLAGS {
  RESOURCES = 'adminResources',
  COMPANY_CAMPAIGNS = 'adminCompanyCampaign',
  USERS = 'adminUsers',
  HOME_SCREEN = 'adminHomeScreen',
  SALES_ENABLEMENT = 'salesEnablement'
}

export const AdminLabel = ({
  collapsedDrawer
}: {
  collapsedDrawer: boolean;
}) => (
  <Box
    bgcolor="#F1F1F5"
    fontWeight={700}
    color="#92929D"
    py={2}
    px={3}
    sx={{
      textAlign: collapsedDrawer ? 'center' : 'left'
    }}>
    ADMIN
  </Box>
);

export const adminMenuItems: MenuItemProps[] = [
  {
    title: 'Manage',
    icon: <ManageIcon />,
    baseRoute: ROUTES.ADMIN,
    list: [
      {
        title: 'Resources',
        divider: true,
        icon: <AdminResourcesIcon fontSize="small" />,
        flag: FLAGS.RESOURCES,
        route: ROUTES.ADMIN_RESOURCES,
        baseRoute: ROUTES.ADMIN_RESOURCES
      },
      {
        title: 'Users',
        divider: true,
        icon: <AdminUsersIcon />,
        flag: FLAGS.USERS,
        route: ROUTES.ADMIN_USERS,
        baseRoute: ROUTES.ADMIN_USERS
      },
      {
        title: 'Campaigns',
        divider: true,
        icon: <AdminCampaignsIcon />,
        flag: FLAGS.COMPANY_CAMPAIGNS,
        route: ROUTES.ADMIN_CAMPAIGNS,
        baseRoute: ROUTES.ADMIN_CAMPAIGNS
      },
      {
        title: 'AI Sales Enablement',
        divider: true,
        icon: <AIIcon />,
        flag: FLAGS.SALES_ENABLEMENT,
        route: ROUTES.ADMIN_AI_SALES_ENABLEMENT,
        baseRoute: ROUTES.HOME_SCREEN
      },
      {
        title: 'Home Screen',
        icon: <AdminHomeScreenIcon />,
        flag: FLAGS.HOME_SCREEN,
        route: ROUTES.HOME_SCREEN,
        baseRoute: ROUTES.HOME_SCREEN
      }
    ]
  }
];
