import { AxiosMethods, RFQApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum RFQueryKey {
  PRODUCTS = 'PRODUCTS'
}

export const getProductsByJobId = (jobId: string) => ({
  queryKey: [RFQueryKey.PRODUCTS, jobId],
  queryFn: () =>
    axiosHelper({
      endpoint: RFQApi.GetProductsById,
      method: AxiosMethods.GET,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      headers: {
        Accept: 'text/plain'
      },
      params: { jobId }
    })
});
