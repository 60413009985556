import { Box, makeStyles } from '@material-ui/core';

export const getReadableFeatures = (feature: string) =>
  // Split the camelCase or PascalCase string into words
  feature
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add a space between lowercase and uppercase
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Handle cases like "BTU"
    .replace(/Seling/, 'Selling') // Fix common typos
    .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter

export const useAITableStyles = makeStyles(({ spacing }) => ({
  table: {
    borderCollapse: 'collapse',
    backgroundColor: '#e8e8e8',
    borderRadius: 8,
    border: '1px solid red',
    overflow: 'hidden',
    width: '100%',
    '& td, th': {
      border: '1px solid #e8e8e8',
      textAlign: 'left',
      padding: spacing(2)
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#fff'
    }
  }
}));

interface SAIComparisonTableProps {
  features: {
    name: string;
    values: string[];
  }[];
  products: string[];
}

export const SAIComparisonTable = ({
  features,
  products
}: SAIComparisonTableProps) => {
  const classes = useAITableStyles();

  return (
    <table className={classes.table}>
      <tr>
        {['Features', ...products].map(i => (
          <th key={i}>{i}</th>
        ))}
      </tr>
      {features.map(i => (
        <tr key={i.name}>
          <td>
            <Box dangerouslySetInnerHTML={{ __html: i.name }} />
          </td>
          {products.map((_, index) => (
            <td>
              <Box dangerouslySetInnerHTML={{ __html: i.values[index] }} />
            </td>
          ))}
        </tr>
      ))}
    </table>
  );
};
