import { Box, styled, Typography } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import {
  SAIComparisonTable,
  SAIDIalogContainer,
  SText,
  useAITableStyles
} from '@setvi/shared/components';
import { AIContentBox } from '@setvi/shared/components/ai/dialog-container/styled';
import { Product } from '@setvi/shared/interfaces';
import { compareProductsMutation } from 'Services';

import { DialogLoader, Error } from '../components';
import { Explanation } from './explanation';

const Title = styled(Typography)({
  fontSize: 20,
  fontWeight: 'bold',
  textDecoration: 'underline'
});

export const FinalRecommendation = styled(Box)(({ theme: { spacing } }) => ({
  backgroundColor: '#ededed',
  borderRadius: 6,
  padding: spacing(2)
}));

interface CompareDialogContentProps {
  products: Product[];
}

export const CompareDialogContent = ({
  products
}: CompareDialogContentProps) => {
  const tableStyle = useAITableStyles();
  const { data, isLoading } = useQuery(
    compareProductsMutation({
      products: products.map(i => ({
        name: i.name || '',
        manufacturer: i.vendor.name || '',
        manufacturerSku: i.manufacturerSku || ''
      }))
    })
  );

  const getDialogDescription = () => {
    const names = products.map(product => product.name);
    return `For Products: ${names.join(' vs. ')}`;
  };

  return (
    <SAIDIalogContainer
      title="AI Comparison Analysis"
      description={getDialogDescription()}>
      <AIContentBox>
        {isLoading ? (
          <DialogLoader />
        ) : (
          <>
            {data?.error || !data ? (
              <Error value={data?.error} />
            ) : (
              <>
                <Title style={{ marginBottom: 4 }}>When to Choose</Title>
                <table className={tableStyle.table}>
                  <tr>
                    {['Ideal for', 'Option'].map(i => (
                      <th key={i}>{i}</th>
                    ))}
                  </tr>
                  {data.whenToChooseProduct.map((i: any) => (
                    <tr key={i.name}>
                      <td>{i.ideal}</td>
                      <td>{i.option}</td>
                    </tr>
                  ))}
                </table>
                <Box my={3}>
                  <Title style={{ marginBottom: 4 }}>AI Comparison</Title>
                  <SAIComparisonTable
                    features={data.table.map((i: any) => {
                      const { feature, ...rest } = i;
                      return {
                        name: feature,
                        values: Object.keys(rest).map(
                          j => i[j as keyof typeof i]
                        )
                      };
                    })}
                    products={products.map(i => i.name)}
                  />
                </Box>
                {data.scenarioBasedRecommendations && (
                  <>
                    <Title>Scenario Based Recommendations</Title>
                    {data.scenarioBasedRecommendations.map(
                      ({ title, choose, why }: any, index: number) => (
                        <Box key={title} my={2} ml={4}>
                          <Box display="flex" alignItems="center" gridGap="4px">
                            <Typography>{index + 1}.</Typography>
                            <SText
                              style={{ textDecoration: 'underline' }}
                              title={title || 'Title Not Provided'}
                              weight="medium"
                            />
                          </Box>
                          <Box ml={4}>
                            <Explanation
                              key={choose}
                              prefix="Choose"
                              value={choose}
                            />
                            <Explanation
                              key={choose}
                              prefix="Why"
                              value={why}
                            />
                          </Box>
                        </Box>
                      )
                    )}
                  </>
                )}
                {data.finalRecommendation && (
                  <FinalRecommendation>
                    <Title>Final Recommendation</Title>
                    <Box ml={4} mt={2}>
                      <Typography>{data.finalRecommendation}</Typography>
                    </Box>
                  </FinalRecommendation>
                )}
              </>
            )}
          </>
        )}
      </AIContentBox>
    </SAIDIalogContainer>
  );
};
