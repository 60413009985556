import { Anthropic } from '@anthropic-ai/sdk';
import { useEffect, useState } from 'react';

const anthropic = new Anthropic({
  apiKey:
    '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',
  dangerouslyAllowBrowser: true
});

interface Delta {
  type: string;
  text: string;
}

interface ClaudeMessagesProps {
  fileBase: string;
  tokens?: number;
  prompt: string;
}

export const useClaudeMessages = ({
  fileBase,
  tokens,
  prompt
}: Partial<ClaudeMessagesProps>) => {
  const [stream, setStream] = useState('');
  const [loading, setLoading] = useState(false);

  const asyncResponse = async ({
    fileBase: data,
    tokens: max_tokens = 512,
    prompt: text
  }: ClaudeMessagesProps) => {
    setLoading(true);
    setStream('');

    const streamResponse = await anthropic.messages.create({
      model: 'claude-3-5-sonnet-20241022',
      max_tokens,
      stream: true,
      messages: [
        {
          content: [
            {
              type: 'document',
              source: {
                media_type: 'application/pdf',
                type: 'base64',
                data
              },
              cache_control: { type: 'ephemeral' }
            },
            {
              type: 'text',
              text
            }
          ],
          role: 'user'
        }
      ]
    });

    let finalMessage = '';
    for await (const messageStream of streamResponse) {
      if (messageStream.type === 'content_block_delta') {
        const response = messageStream.delta as Delta;

        finalMessage += response?.text || '';

        setStream(prev => (prev || '') + (response?.text || ''));
      }
    }

    setLoading(false);
    return finalMessage;
  };

  useEffect(() => {
    if (fileBase && prompt) {
      asyncResponse({
        fileBase,
        tokens,
        prompt
      });
    }
  }, [fileBase, tokens, prompt]);

  return { asyncResponse, stream, loading };
};
