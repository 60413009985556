import { Box, Divider, styled } from '@material-ui/core';
// import { v4 as uuidv4 } from 'uuid';
// import { List } from 'rsuite';

import { SText } from '@setvi/shared/components';

import { GrayBox } from '../../styled';
// import { OverviewData } from '../../raw';

export const Flex = styled(Box)({
  marginTop: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 14
});

/* const listStyle = {
  marginLeft: 22
};

const itemStyle = {
  background: 'transparent'
}; */

const Overview = ({ overview }: { overview: string }) => (
  <>
    <Flex>
      <SText size="2xl" weight="bold" title="Brief Overview" />
      <GrayBox>
        <SText noLineClamp>{overview}</SText>
      </GrayBox>

      <Divider />
    </Flex>

    <Flex>
      {/* <SText size="2xl" weight="bold" title="Detailed Summary" /> */}

      {/* OverviewData?.map(({ title, description }, i) => (
        <Box key={title}>
          <SText size="xl" weight="medium" title={`${i + 1}. ${title}`} />
          <List divider={false} style={listStyle} size="sm">
            {description.map((item, index) => (
              <List.Item key={uuidv4()} index={index} style={itemStyle}>
                {typeof item === 'string' ? (
                  item
                ) : (
                  <>
                    <SText weight="medium" title={`• ${item?.subTitle}`} />
                    <List divider={false} style={listStyle} size="sm">
                      {item?.details?.map((subitem, subIndex) => (
                        <List.Item
                          style={itemStyle}
                          key={uuidv4()}
                          index={subIndex}>
                          {subitem}
                        </List.Item>
                      ))}
                    </List>
                  </>
                )}
              </List.Item>
            ))}
          </List>
        </Box>
                      )) */}
    </Flex>
  </>
);

export default Overview;
