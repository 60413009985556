import { Field, FieldProps, Form, Formik } from 'formik';

import { Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';

import {
  SText,
  SBase,
  SButton,
  SFormikTextfield
} from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { useCustomErrors } from '@setvi/shared/hooks';
import { SlackMutationKeys } from '@setvi/shared/services';

import { useMutationTracker } from 'hooksv2/useMutationTracker';

import {
  schema,
  MAX_TEXT,
  ratingValidation,
  feedbackValidation
} from './utils';
import { Content, Footer, Header, RatingItem, Ratings } from './styled';

export interface FeedbackValues {
  feedback: string;
  rating: string | null;
}

interface FeedbackProps {
  handleSubmit: ({ feedback, rating }: FeedbackValues) => void;
  onClose: () => void;
  loading?: boolean;
  title?: string;
}

export const FEEDBACK_DIALOG = 'feedback-dialog';

const Feedback = ({
  handleSubmit,
  onClose,
  loading = false,
  title = 'Send us your Feedback'
}: FeedbackProps) => {
  const { isLoading } = useMutationTracker({
    key: SlackMutationKeys.SEND_CHANNEL_MSG
  });
  const { customErrors, customValidate } = useCustomErrors({
    schemas: schema,
    values: {
      feedback: '',
      rating: null
    }
  });

  const ratings = [
    {
      value: 'Bad',
      color: '#FF4D4F',
      icon: <ThumbDownAltIcon />
    },
    { value: 'Good', color: '#23A566', icon: <ThumbUpAltIcon /> }
  ];

  return (
    <SBase width={500} disablePadding>
      <Header>
        <SText title={title} size="xl" weight="bold" />
        <IconButton
          aria-label="close"
          size="small"
          onClick={onClose}
          style={{
            padding: 2
          }}>
          <CloseIcon />
        </IconButton>
      </Header>
      <Divider />
      <Formik
        initialValues={{
          feedback: '',
          rating: null
        }}
        onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <Content>
              <Field name="rating">
                {({ meta, form }: FieldProps) => (
                  <Ratings>
                    {ratings?.map(r => (
                      <RatingItem
                        key={r?.value}
                        active={meta.value === r?.value ? r?.color : 'gray'}
                        onClick={() => {
                          form.setFieldValue('rating', r.value);
                          customValidate({
                            schema: ratingValidation,
                            value: r.value,
                            name: 'rating'
                          });
                        }}>
                        {r?.icon}
                      </RatingItem>
                    ))}
                  </Ratings>
                )}
              </Field>

              <SFormikTextfield
                name="feedback"
                label={
                  <SText
                    weight="medium"
                    style={{
                      marginBottom: 8
                    }}
                    fontColor={TextColor.DarkGrey}
                    title="Got feedbacks? We'd love to hear them!"
                  />
                }
                placeholder="Enter feedback..."
                required
                multiline
                maxLength={MAX_TEXT}
                onChange={(value: string) =>
                  customValidate({
                    schema: feedbackValidation,
                    value,
                    name: 'feedback'
                  })
                }
                customErrors={customErrors}
              />
            </Content>

            <Divider />
            <Footer>
              <SButton
                fullWidth
                color="default"
                variant="outlined"
                onClick={onClose}
                disabled={loading || isLoading}>
                Cancel
              </SButton>
              <SButton
                fullWidth
                type="submit"
                loading={loading || isLoading}
                disabled={
                  // @ts-ignore
                  customErrors?.feedback?.length > 0 ||
                  !values?.feedback?.trim() ||
                  loading ||
                  isLoading
                }>
                Send Feedback
              </SButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </SBase>
  );
};

export default Feedback;
