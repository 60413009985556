import { useState } from 'react';
import {
  Box,
  Divider as MUIDivider,
  makeStyles,
  withStyles
} from '@material-ui/core';
import {
  SButton,
  SCheckbox,
  SETVIImage,
  SHighlightText,
  SText
} from '@setvi/shared/components';
import Card from 'componentsV2/cards/grid/card';
import { Customer } from 'Services/MongoRealm/Types';
import { TextColor } from '@setvi/shared/enums';
import {
  ProductDetails,
  ProductGridItem,
  ProductPriceType,
  Product as ProductType
} from '@setvi/shared/interfaces';
import { getProductStatus } from 'componentsV2/product/product-status';
import { PrivateLabel } from 'componentsV2/product/private-label';
import { Stock } from '../../../stock';
import { PriceButton } from '../../../price-button';
import { PromotionBadge } from '../../useProductColumns/contents';
import { FullPrice, ListPrice } from './price';
import { FullContent } from './price/full/components';

const Divider = withStyles(({ spacing }) => ({
  root: {
    margin: spacing(2, 0)
  }
}))(MUIDivider);

const useStyles = makeStyles(() => ({
  wrapper: {
    borderRadius: 12,
    width: 289
    // height: 339
  },
  img: {
    width: '100%',
    height: '100%',
    aspectRatio: '1.34/1',
    objectFit: 'scale-down',
    objectPosition: 'center'
  },
  topRightIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  topLeftIcon: {
    position: 'absolute',
    top: 10,
    left: 10
  }
}));

interface ProductProp {
  product: ProductType;
  priceType: ProductPriceType;
  gridConfig: ProductGridItem;
  selectedCustomer?: Customer;
  checkboxTooltip?: string;
  searchQuery?: string;
  checked: boolean;
  productPricesLoading?: boolean;
  stock: ProductDetails['stock'];
  onProductClick?: (product: ProductType) => void;
  checkboxDisabled?: (product: ProductType) => boolean;
  onPromotionClick?: (product: ProductType) => void;
  onGetProductPrice?: (product: ProductType) => Promise<void>;
  onStockClick?: (product: ProductType) => void;
  handleCheck: (isChecked: boolean, product: ProductType[]) => void;
}

export const leadTimeFontSize = 'sm';

export const Product = ({
  product,
  priceType,
  selectedCustomer,
  gridConfig,
  stock,
  productPricesLoading,
  checkboxTooltip,
  searchQuery = '',
  checked,
  onProductClick,
  onPromotionClick,
  onGetProductPrice,
  onStockClick,
  handleCheck,
  checkboxDisabled
}: ProductProp) => {
  const [singlePriceLoading, setSinglePriceLoading] = useState(false);
  const classes = useStyles();

  return (
    <Card
      item={product}
      onCardClick={onProductClick}
      className={classes.wrapper}>
      <Box m={2}>
        <Box position="relative">
          <SETVIImage
            alt={product.name}
            src={
              product.imageUrl ||
              product.images[0]?.path ||
              '/images/product-placeholder.svg'
            }
            className={classes.img}
          />
          <Box className={classes.topRightIcon}>
            {product.promotions?.length > 0 && (
              <PromotionBadge onClick={() => onPromotionClick(product)} />
            )}
          </Box>
          <Box
            className={classes.topLeftIcon}
            onClick={e => e.stopPropagation()}>
            <SCheckbox
              checked={checked}
              disabled={checkboxDisabled?.(product)}
              tooltip={checkboxTooltip}
              onChange={e => handleCheck(e.target.checked, [product])}
            />
          </Box>
        </Box>
        <Box m={2} mb={0}>
          <Box height={40} display="flex" alignItems="center">
            <SHighlightText
              maxLines={2}
              value={product.name}
              size="sm"
              searchQuery={searchQuery}
            />
          </Box>
          <Box display="flex">
            <SHighlightText
              size="sm"
              interactive
              fontColor={TextColor.Grey}
              value={`SKU: ${product.sku}`}
              searchQuery={searchQuery}
              tooltipContent={<>{product.sku}</>}
            />
            <Box ml={1} minWidth="max-content">
              <SText
                fontColor={TextColor.Grey}
                size="sm"
                title={`(${getProductStatus(product)})`}
              />
            </Box>
          </Box>
          <Divider />

          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Box display="grid" width="100%">
              <Box
                height={20}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gridGap="4px">
                <SHighlightText
                  size="sm"
                  value={`MFG: ${product?.vendor?.name || 'Not Available'}`}
                  searchQuery={searchQuery}
                  style={{ marginRight: '10px' }}
                />
                {product.privateLabel && <PrivateLabel />}
              </Box>

              {priceType !== ProductPriceType.List && (
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    height={19}
                    gridGap="6px">
                    <SText
                      style={{ maxWidth: '170px' }}
                      size="sm"
                      color="textSecondary"
                      title={product?.manufacturerSku || '-'}
                    />
                    {stock && (
                      <Stock
                        variant="body2"
                        inventory={product.inventory}
                        isDropship={product.dropship}
                        selectedCustomer={selectedCustomer}
                        onClick={() => onStockClick(product)}
                      />
                    )}
                  </Box>
                  {gridConfig?.manufacturerLeadTime && (
                    <SText
                      title={`Lead time: ${product?.custom?.leadTime ? `${product?.custom?.leadTime} Days` : '-'}`}
                      size={leadTimeFontSize}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {priceType === ProductPriceType.Full && (
            <>
              <Divider />
              <FullPrice
                product={product}
                selectedCustomer={selectedCustomer}
                loading={productPricesLoading}
              />
            </>
          )}
          {priceType === ProductPriceType.Get && !!selectedCustomer && (
            <>
              <Divider />
              <Box display="flex" justifyContent="center" height={40}>
                <PriceButton
                  price={product.price}
                  onGetPrice={() => onGetProductPrice(product)}
                />
              </Box>
            </>
          )}
          {priceType === ProductPriceType.GetFull && !!selectedCustomer && (
            <>
              <Divider />
              <Box display="flex" justifyContent="center" height={44}>
                {product.price ? (
                  <FullContent product={product} />
                ) : (
                  <SButton
                    loading={singlePriceLoading}
                    onClick={async e => {
                      e.stopPropagation();
                      setSinglePriceLoading(true);
                      await onGetProductPrice(product);
                      setSinglePriceLoading(false);
                    }}
                    style={{ width: 'max-content' }}
                    variant="outlined">
                    Get Price
                  </SButton>
                )}
              </Box>
            </>
          )}

          {priceType === ProductPriceType.List && (
            <>
              <Divider />
              <ListPrice product={product} />
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};
