import { Box, styled } from '@material-ui/core';
import { SBase, SButton, SFormikTextfield } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { AIIcon } from '@setvi/shared/components/sicons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useCustomErrors } from 'Hooks/useCustomErrors';
import { Product } from '@setvi/shared/interfaces';
import { RecommendationAnalysis } from '../analysis';

interface RecommendationRequestProps {
  products: Product[];
}

const AIButton = styled(SButton)({
  background: 'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)',
  '&.Mui-disabled': {
    opacity: 0.6,
    color: 'white'
  }
});

const maxLength = 2048;
const name = 'purpose';

const purposeSchema = Yup.object({
  [name]: Yup.string().max(maxLength, `Limit is ${maxLength} characters`)
});

const initialValues = {
  [name]: ''
};

export const RecommendationRequest = ({
  products
}: RecommendationRequestProps) => {
  const { openDialog, closeDialog } = useDialog();

  const { customErrors, customValidate } = useCustomErrors({
    schemas: [
      {
        name,
        schema: purposeSchema
      }
    ],
    values: {
      value: initialValues
    }
  });

  const handleSubmit = (pupose: string) => {
    closeDialog();
    openDialog(<RecommendationAnalysis purpose={pupose} products={products} />);
  };

  return (
    <SBase
      width={500}
      header={{
        title: 'Recommendation Analysis',
        onClose: closeDialog
      }}>
      <Formik
        onSubmit={values => handleSubmit(values[name])}
        validateOnChange={false}
        initialValues={initialValues}>
        {({ values }) => (
          <Form>
            <SFormikTextfield
              name={name}
              label="To ensure AI delivers the most accurate and effective
            recommendation analysis, please specify the purpose,
            application, or type of busniess you are ariming to recommend.
            The more detailed the information, the better the
            recommendations will align with your goals."
              placeholder="EX: For cleaning table or For hospital surgery room"
              onChange={(val: string) => {
                customValidate({
                  schema: purposeSchema,
                  value: val,
                  name
                });
              }}
              multiline
              minRows={2}
              maxRows={2}
              customErrors={customErrors}
            />
            <Box width="100%" display="flex" gridGap={16} mt={3}>
              <SButton fullWidth onClick={() => closeDialog()}>
                Cancel
              </SButton>
              <AIButton
                type="submit"
                // @ts-ignore
                disabled={!values[name].length || customErrors[name]}
                fullWidth
                startIcon={<AIIcon htmlColor="#fff" />}>
                Go
              </AIButton>
            </Box>
          </Form>
        )}
      </Formik>
    </SBase>
  );
};
