import { makeStyles } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    height: 65,
    backgroundColor: '#FAFAFB',
    padding: `${distances.px.base} ${distances.px.xlarge}`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 4px 32px 0px #00000040',
    borderRadius: 65
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      paddingRight: distances.px.base,
      marginRight: distances.px.base,
      borderRight: '1px solid #E0E0E0'
    }
  },
  count: {
    height: 25,
    minWidth: 25,
    borderRadius: 50,
    padding: spacing(1),
    marginRight: spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.primary.main,
    color: palette.common.white
  },
  button: {
    textDecoration: 'underline',
    color: palette.primary.main,
    padding: spacing(1)
  }
}));
