import { Box, styled } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column-reverse', // Textarea expands upwards
  alignItems: 'flex-start',
  width: '60%',
  minWidth: 300,
  minHeight: 40,
  border: ({ error }: { error: boolean }) =>
    `1px solid  ${error ? 'red' : '#ccc'}`,
  padding: 5,
  borderRadius: 12,
  overflow: 'hidden',
  position: 'relative',
  background: 'white'
});

export const Actions = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: ({ error }: { error: boolean }) =>
    error ? 'space-between' : 'flex-end'
});

export const SubmitButton = styled(SButton)({
  background: 'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)',
  fontWeight: 500,

  '&.Mui-disabled': {
    opacity: 0.6,
    color: 'white'
  }
});
