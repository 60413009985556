import SInfiniteScroll, {
  SInfiniteScrollProps
} from '@setvi/shared/components/sinfinite-scroll';
import { Table, Theme, makeStyles } from '@material-ui/core';
import { CSSProperties, memo } from 'react';
import clsx from 'clsx';
import TableHeader, {
  TableCheckboxStyle,
  TableHeaderProps
} from '../components/table/header';
import { SPaginatedTableProps } from '../paginated';
import TableBodyCells, { STableCell } from '../components/table/cells';
import { SCheckbox } from '../../scheckbox';

const useStyles = makeStyles<Theme, { cursor: 'pointer' | 'default' }>({
  table: {
    borderCollapse: 'collapse'
  },
  wrapper: {
    borderBottom: `1px solid #E6E7E9`
  },
  staticTableRow: {
    borderBottom: '1px solid #E6E7E9',
    '& :hover': {
      cursor: ({ cursor }) => cursor
    }
  }
});

export interface SInfinteTableProps<T>
  extends Omit<
    SPaginatedTableProps<T>,
    'pagination' | 'showPagination' | 'customEmptyState'
  > {
  infiniteScroll?: Omit<
    SInfiniteScrollProps<T>,
    | 'containerComponent'
    | 'wrapperComponent'
    | 'onWrapperClick'
    | 'list'
    | 'keyGetter'
    | 'render'
  >;
  header?: Partial<TableHeaderProps>;
  tableStyle?: CSSProperties;
}

export const InfiniteTable = <T,>({
  list,
  row = {},
  keyGetter,
  isLoading,
  searchQuery,
  tableStyle,
  infiniteScroll = {},
  stickyHeader = true,
  header,
  columns
}: SInfinteTableProps<T>) => {
  const classes = useStyles({
    cursor: row?.onClick ? 'pointer' : 'default'
  });

  return (
    <Table
      stickyHeader={stickyHeader}
      className={classes.table}
      style={tableStyle}>
      <TableHeader
        columns={header?.columns || columns}
        checkboxFreeSpace={!header?.checkbox && !!row?.checkbox}
        {...header}
      />
      <SInfiniteScroll
        list={list}
        keyGetter={keyGetter}
        render={(element, index) => {
          const { checkbox, rowStyleGetter } = row;
          return (
            <>
              {checkbox && (
                <STableCell
                  style={{
                    minWidth: 45,
                    maxWidth: 45,
                    ...TableCheckboxStyle,
                    ...rowStyleGetter?.(element, index)
                  }}
                  onClick={(e: any) => e.stopPropagation()}>
                  <SCheckbox {...checkbox(element)} />
                </STableCell>
              )}
              <TableBodyCells
                rowData={element}
                columns={columns}
                loading={isLoading}
                searchQuery={searchQuery}
                style={{
                  cursor: row?.onClick ? 'pointer' : 'default',
                  ...rowStyleGetter?.(element, index)
                }}
              />
            </>
          );
        }}
        containerComponent="tbody"
        wrapperComponent="tr"
        wrapperClass={clsx(classes.wrapper, infiniteScroll.wrapperClass)}
        onWrapperClick={element => row?.onClick?.(element)}
        {...infiniteScroll}
      />
    </Table>
  );
};

export const SInfiniteTable = memo(InfiniteTable) as typeof InfiniteTable;
