import { CompanySettingsTypeID } from '@setvi/shared/services/react-query/query/user/types';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { MONGO_REALM_COLLECTIONS } from 'Services/MongoRealm';
import { Promotion } from 'Services/MongoRealm/Types';
import { Product } from '@setvi/shared/interfaces';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

interface usePromotionDetailsProps {
  product: Product;
  client: any;
}

export const usePromotionDetails = ({
  product,
  client
}: usePromotionDetailsProps) => {
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [activePromotion, setActivePromotion] = useState<Promotion | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const { companyData } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const fetchPromotions = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client
        .db(
          companyData.Settings[CompanySettingsTypeID.AtlasMongoProductDBName]
            ?.Value
        )
        .collection(MONGO_REALM_COLLECTIONS.PROMOTIONS)
        .find({ _id: { $in: product.promotions } });

      setPromotions(response);
      response.length === 1 && setActivePromotion(response[0]);
    } catch (error) {
      enqueueSnackbar('Error occured while fetching promotion details.', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, [client, companyData, product, enqueueSnackbar]);

  useEffect(() => {
    fetchPromotions();
  }, [fetchPromotions]);

  return {
    promotions,
    activePromotion,
    loading,
    setActivePromotion
  };
};
