import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { getThoughspotUser, getUserMetadata } from 'Services/Query/Thoughspot';

export const useThoughtspot = () => {
  const { user: loggedUser } = useAppContext();
  const { data: tsUser } = useQuery(getThoughspotUser(loggedUser.Email));
  const { data: liveboards } = useQuery(
    getUserMetadata({
      type: 'LIVEBOARD',
      thoughspotUserAccessToken: tsUser?.token
    })
  );

  return {
    user: tsUser,
    liveboards
  };
};
