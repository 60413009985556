import { Box, Typography, styled } from '@material-ui/core';

export const AIContainer = styled(Box)({
  flex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: 'url(/images/SETVIAI.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right'
});

export const AITitle = styled(Typography)({
  width: 'fit-content',
  fontWeight: 'bold',
  background:
    'linear-gradient(155deg, #001222 2%, #003A6C  25%,  #3896E0  85%, #1E96FC  100%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: 6
});
