export enum SalesEnablementApi {
  GetSalesEnablementContent = '/sales-enablement/content/:productId',
  GetSalesEnablementItems = '/sales-enablement/items/:categoryId',
  GenerateSalesEnablement = '/sales-enablement/generate/:promptId',
  SaveSalesEnablement = '/sales-enablement',
  Comparison = '/sales-enablement/comparison',
  Recommendation = '/sales-enablement/recommendation',
  GeneratePrompt = '/sales-enablement/generate/prompt'
}

export enum SalesEnablementAdminApi {
  GetSalesEnablement = '/sales-enablement-prompts/',
  SaveSalesEnablement = '/sales-enablement-prompts/',
  DeleteSalesEnablement = '/sales-enablement-prompts/:id',
  GetSalesEnablementById = '/sales-enablement-prompts/:id',
  UpdateSalesEnablement = '/sales-enablement-prompts/:id',
  ReorderSalesEnablement = '/sales-enablement-prompts/reorder',
  UpdateStatusSalesEnablement = '/sales-enablement-prompts/status/:id',
  InitiatePrompts = '/sales-enablement-prompts/initiate/prompts/:id'
}
