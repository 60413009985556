import { Box } from '@material-ui/core';
import { SETVIImage, SHighlightText } from '@setvi/shared/components';
import { ColumnsType, ColumnType } from '@setvi/shared/types';
import { GetResourceTypeIcon } from '@setvi/shared/utils';
import DropdownMenu from 'Components/Shared/Resources/DropdownMenu';
import {
  DropdownItemsTypes,
  useResourcesDropdownItems
} from 'Components/Shared/Resources/DropdownMenu/hooks';
import { GetResourceTypeThumbnail } from 'Utils/getResourceTypeThumbnail';
import { useTableStyles } from 'Components/Shared';

export const useAssociatedResourceColumns = ({
  closeDialog,
  readOnly
}: {
  closeDialog?: () => void;
  readOnly?: boolean;
}) => {
  const { resourcesItems } = useResourcesDropdownItems([
    DropdownItemsTypes.favorites
  ]);
  const classes = useTableStyles();

  const columns: ColumnsType = [
    {
      id: 'name',
      width: readOnly ? '85%' : '75%',
      label: 'Resource Name',
      sortBy: 'Name',
      cellContent: (rowData, searchQuery) => (
        <Box display="flex" alignItems="center">
          <SETVIImage
            alt="image"
            className={classes.tableImage}
            src={
              rowData.ThumbURLWithSas ||
              rowData.ThumbURL ||
              GetResourceTypeThumbnail(rowData.ResourceTypeID)
            }
          />
          <Box ml={2} display="flex" flexDirection="column">
            <SHighlightText value={rowData?.Name} searchQuery={searchQuery} />
          </Box>
        </Box>
      )
    },
    {
      id: 'resourceType',
      width: '15%',
      label: 'File Type',
      cellContent: (rowData: any) => (
        <Box display="flex" alignItems="center">
          <SETVIImage
            style={{ height: 20 }}
            src={GetResourceTypeIcon(rowData.ResourceTypeID)}
            alt="File icon"
          />
          <Box ml={2}>{rowData.ResourceTypeName}</Box>
        </Box>
      )
    },

    ...(!readOnly
      ? [
          {
            id: 'actions',
            width: '10%',
            align: 'right',
            label: 'Action',
            cellContent: (rowData, _, loading) => (
              <Box onClick={e => e.stopPropagation()}>
                <DropdownMenu
                  item={rowData}
                  refetchLoading={loading}
                  dropdownItems={resourcesItems}
                  onAction={({ type }) => type === 'Email' && closeDialog?.()}
                />
              </Box>
            )
          } as ColumnType
        ]
      : [])
  ];

  return {
    columns
  };
};
