import {
  withStyles,
  Card as MuiCard,
  CardMedia,
  makeStyles,
  Theme
} from '@material-ui/core';
import { CARD_DIMENSIONS, CardSizes } from 'componentsV2/cards/grid/card';

export const StyledCard = withStyles({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E6E7E9',
    borderRadius: 6,
    overflow: 'hidden'
  }
})(MuiCard);

export const StyledCardContent = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  }
}))(CardMedia);

export const useCardStyles = makeStyles<Theme, { size: CardSizes }>(() => ({
  customCardMediaHeight: {
    height: 186,
    backgroundSize: 'contain'
  },
  cardWrapper: {
    width: ({ size }) =>
      CARD_DIMENSIONS[size as keyof typeof CARD_DIMENSIONS].width,
    minHeight: 'min-content'
  },
  clickable: {
    cursor: 'pointer'
  }
}));
