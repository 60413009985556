import { Resource } from '@setvi/shared/interfaces';
import { daysRemaining } from '@setvi/shared/utils';

import { AdminResourceData } from 'Services/Query/Admin/Resources/Types';

export const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// Use this funciton to get the days remaining for a campaign and the color of the text
export const ValidUntilDateGen = (validUntilDate: string) => {
  const daysLeft = daysRemaining(validUntilDate);
  const colors = {
    default: '#44444F',
    overdue: '#FF0000',
    warning: '#FF9F29'
  };

  let text = `${daysLeft} days left`;
  let colorTxt = colors.default;

  if (daysLeft < 0) {
    colorTxt = colors.overdue;
    text = 'Overdue';
  } else if (daysLeft === 0) text = 'Last day';
  else if (daysLeft === 1) text = `${daysLeft} day left`;
  else if (daysLeft <= 3) colorTxt = colors.warning;

  return {
    text,
    colorTxt
  };
};

export const generateStaticAdminResource = (
  resource: AdminResourceData
): Resource => ({
  ID: resource.Id,
  ResourceID: resource.ResourceID,
  Name: resource.Name,
  MaterilaLastChangeDate: resource.MaterilaLastChangeDate,
  CategoryID: resource.CategoryID,
  CategoryName: resource.CategoryName,
  DateAdded: resource.DateAdded,
  ResourceTypeID: resource.ResourceTypeID,
  IsDownloadable: resource.IsDownloadable,
  IsShareable: resource.IsShareable,
  ResourceTypeName: resource.ResourceTypeName,
  CompanyID: resource.CompanyID,
  Active: resource.Active,
  ValidFromDate: resource.ValidFromDate,
  ExpireOnDate: resource.ExpireOnDate,
  MaterialSize: resource.MaterialSize,
  Comment: resource.Comment,
  ResourceLastChangedDate: resource.ResourceLastChangedDate,
  ResourceClass: resource.ResourceClass,
  UserID: resource.UserID,
  IsArchived: resource.IsArchived,
  ArchivedTime: resource.ArchivedTime,
  FileName: resource.FileName,
  BoxV2Id: resource.BoxV2Id,
  isInternallyDownloadable: resource.IsInternallyDownloadable,
  Pages: resource.Pages,
  PageNumber: 1,
  IsPublic: resource.IsPublic,
  ThumbURL: resource.ThumbURL,
  ThumbURLWithSas: resource.ThumbURL,
  DownloadURL: resource.DownloadURL,
  DownloadURLWithSas: resource.DownloadURL,
  IsFavorite: resource.IsFavorite,
  ItemID: '',
  Breadcrumbs: resource.ResourceCategoryBreadcrumbs?.map(b => ({
    Name: b?.ResourceCategoryName,
    ID: b?.ResourceCategoryId,
    ResourceCategoryClass: b?.ResourceCategoryClass
  })),
  ChildResources: resource.ChildResources?.map(c => ({
    Id: c?.Id,
    ID: c?.Id,
    Name: resource?.Name,
    CategoryID: resource?.CategoryID,
    ResourceTypeID: resource?.ResourceTypeID,
    ResourceID: c?.ResourceID,
    Material: c?.Material,
    ItemID: c?.ItemID,
    ThumbURL: c?.ThumbURL,
    ThumbURLWithSas: c?.ThumbURL,
    DownloadURL: c?.DownloadURL,
    SortOrder: c?.SortOrder,
    DownloadURLWithSas: c?.DownloadURL
  })),
  ObjectType: 1
});

export const downloadFile = (blob: Blob, name: string) => {
  try {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name || 'Download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return true;
  } catch (error) {
    return false;
  }
};

export const getColor = (value: number) => {
  const red = '#FF4D4F';
  const green = '#23A566';
  const yellow = '#FF9F29';

  const transparent = ['FF', 'BF', '80', '40'];

  if (value < 40) {
    const transparency = transparent[Math.floor(value / 10)];
    return `${red}${transparency}`;
  }

  if (value <= 70) {
    const transparency = transparent.reverse()[Math.floor(value / 2 / 10)];
    return `${yellow}${transparency}`;
  }

  const transparency = transparent.reverse()[Math.floor(value / 4 / 10)];
  return `${green}${transparency}`;
};

export const fetchPdfAsBase64 = async (pdfUrl: string) => {
  try {
    const response = await fetch(pdfUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64 = btoa(
      new Uint8Array(arrayBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
    return base64;
  } catch (error) {
    return null;
  }
};
