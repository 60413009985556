import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, useTheme } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';
import { Email } from 'Components/Shared/Icons';
import { useDialog } from '@setvi/shared/providers';
import { AIIcon } from '@setvi/shared/components/sicons';
import { Product } from '@setvi/shared/interfaces';
import { useStyles } from './styles';
import { CompareDialogContent } from './dialogs';
import { RecommendationRequest } from './dialogs/recommend/components/request';

export interface SelectedOptionsProps {
  selectedProducts: Product[];
  buttonLabel: string;
  handleSelected: () => void;
  handleClose: () => void;
  handleEmail: () => void;
}

export const SelectedOptions = ({
  selectedProducts = [],
  buttonLabel,
  handleSelected,
  handleEmail,
  handleClose
}: SelectedOptionsProps) => {
  const flags = useFlags();
  const { openDialog } = useDialog();
  const classes = useStyles();
  const { palette } = useTheme();
  const count = selectedProducts.length;
  const disableRecommendAndCompare = count < 2 || count > 4;

  const disabledTooltipMessage = (val: string) =>
    `To ${val} products, please select between 2 and 4 products`;

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.wrapper}>
          <Box className={classes.count}>{count}</Box>
          <SButton
            variant="text"
            className={classes.button}
            onClick={handleSelected}>
            Selected
          </SButton>
        </Box>
        <Box className={classes.wrapper}>
          <SButton
            tooltipTitle={`Email Product${count > 1 ? 's' : ''}`}
            startIcon={<Email fill={palette.primary.main} />}
            variant="outlined"
            color="primary"
            onClick={handleEmail}>
            {buttonLabel}
          </SButton>
        </Box>
        {!!flags?.salesEnablement && (
          <>
            <Box className={classes.wrapper}>
              <SButton
                variant="outlined"
                startIcon={
                  <AIIcon
                    htmlColor={
                      disableRecommendAndCompare
                        ? palette.text.disabled
                        : palette.primary.main
                    }
                  />
                }
                onClick={() =>
                  openDialog(
                    <CompareDialogContent products={selectedProducts} />
                  )
                }
                tooltipTitle={
                  disableRecommendAndCompare
                    ? disabledTooltipMessage('compare')
                    : 'AI can perform a comparasion analysis'
                }
                disabled={disableRecommendAndCompare}>
                Compare
              </SButton>
            </Box>
            <Box className={classes.wrapper}>
              <SButton
                variant="outlined"
                startIcon={
                  <AIIcon
                    htmlColor={
                      disableRecommendAndCompare
                        ? palette.text.disabled
                        : palette.primary.main
                    }
                  />
                }
                onClick={() =>
                  openDialog(
                    <RecommendationRequest products={selectedProducts} />
                  )
                }
                tooltipTitle={
                  disableRecommendAndCompare
                    ? disabledTooltipMessage('recommend')
                    : 'AI can perform a recommendation analysis based on a use case, application or segment'
                }
                disabled={disableRecommendAndCompare}>
                Help me Recommend
              </SButton>
            </Box>
          </>
        )}

        <Box className={classes.wrapper}>
          <SButton
            variant="text"
            className={classes.button}
            onClick={handleClose}>
            Cancel
          </SButton>
        </Box>
      </Box>
    </>
  );
};
