export const locations = [
  {
    id: 1,
    label: 'Convenience Store'
  },
  {
    id: 2,
    label: 'Business & Industry'
  },
  {
    id: 3,
    label: 'Pizzeria'
  },
  {
    id: 4,
    label: 'Restaurant'
  },
  {
    id: 5,
    label: 'Experiential'
  },
  {
    id: 6,
    label: 'Pizza Quick Serve Restaurant'
  },
  {
    id: 7,
    label: 'Marine'
  }
];

export const pizzaSizes = [
  {
    value: 9,
    label: '9”'
  },
  {
    value: 20,
    label: '20”'
  }
];

export const pizzaHours = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 100,
    label: '100'
  }
];

export const mehanicalRequirements = [
  {
    value: '1',
    label: 'Gas'
  },
  {
    value: '2',
    label: 'Electric'
  }
];
