import { PageLoader, SBase, SEmptyState } from '@setvi/shared/components';
import { CompanyProductUserType } from '@setvi/shared/services/react-query/query/user/types';
import { Box } from '@material-ui/core';
import { Customer } from 'Services/MongoRealm/Types';
import {
  Product,
  ProductDetailsTabsIds,
  TempProductType
} from '@setvi/shared/interfaces';
import { DetailsContent } from './content';
import { useProductDetails } from './hooks';

export interface DetailsDialogProps {
  selectedProduct: Product | TempProductType;
  selectedProducts?: Product[];
  selectedCustomerId?: Customer['_id'];
  defaultOpenedTab?: ProductDetailsTabsIds;
  userType?: CompanyProductUserType;
  client: any;
  readOnly?: boolean;
  canSelectProducts?: boolean;
  hideProductPrice?: boolean;
  onClose: () => void;
  handleProductSelect?: (isChecked: boolean, product: Product[]) => void;
  updateProduct?: (product: Product, newValues: Partial<Product>) => void;
  captureResourceAnalytics?: () => void;
  openProductDetails?: (
    product: any,
    defaultOpenedTab?: ProductDetailsTabsIds
  ) => void;
  handleEmail?: () => void;
  handleSelected?: () => void;
  handleCloseActionBar?: () => void;
}

export const ProductDetails = ({
  selectedProduct,
  selectedProducts,
  selectedCustomerId,
  defaultOpenedTab,
  client,
  userType,
  readOnly = false,
  canSelectProducts,
  hideProductPrice = false,
  handleProductSelect,
  onClose,
  updateProduct,
  captureResourceAnalytics,
  openProductDetails,
  handleEmail,
  handleSelected,
  handleCloseActionBar
}: DetailsDialogProps) => {
  const { product, customer, isLoading, handleProdctPrice } = useProductDetails(
    {
      selectedProduct,
      selectedCustomerId,
      client,
      userType
    }
  );

  return (
    <SBase
      height={800}
      disablePadding
      header={{ title: 'Product Details', onClose }}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          {product ? (
            <DetailsContent
              product={product}
              client={client}
              selectedCustomer={customer}
              readOnly={readOnly}
              defaultOpenedTab={defaultOpenedTab}
              selectedProducts={selectedProducts}
              canSelectProducts={canSelectProducts}
              hideProductPrice={hideProductPrice}
              handleProductSelect={handleProductSelect}
              captureResourceAnalytics={captureResourceAnalytics}
              updateProduct={(prod, newValues) => {
                handleProdctPrice(newValues);
                updateProduct(prod, newValues);
              }}
              openProductDetails={openProductDetails}
              closeDialog={onClose}
              handleEmail={handleEmail}
              handleSelected={handleSelected}
              handleCloseActionBar={handleCloseActionBar}
            />
          ) : (
            <Box width="100%">
              <SEmptyState
                title="Product Not Found"
                subTitle="There is no product found with specified ID."
              />
            </Box>
          )}
        </>
      )}
    </SBase>
  );
};
