import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SText } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';

interface ProfitProps {
  price: Product['price'];
  cost: Product['cost'];
  loading: boolean;
}

export const Profit = ({ price, cost, loading }: ProfitProps) => {
  if (loading) return <Skeleton />;

  if (!Number.isFinite(cost) || price <= cost) return '-';

  return (
    <Box display="flex" flexDirection="column">
      <SText
        style={{ marginBottom: '3px' }}
        title={`${(price - cost).toLocaleString('en-US', {
          currency: 'USD',
          style: 'currency'
        })}`}
      />
      <SText
        size="sm"
        fontColor="#23A566"
        title={`(${(((price - cost) / price) * 100).toFixed(2).toString()}%)`}
      />
    </Box>
  );
};
