import { useRef } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import {
  SText,
  SButton,
  TableLoader,
  SEmptyState,
  SVirtualTable
} from '@setvi/shared/components';
import { ColumnsType } from '@setvi/shared/types';
import { Product } from '@setvi/shared/interfaces';
import { useVirtualizeData } from '@setvi/shared/components/s-table/hooks/useVirtualizeData';

import {
  LoadingContainer,
  LoadingContent,
  TableContainer,
  Content,
  Header
} from './styled';

interface ProductsProps {
  products: Product[];
  columns: ColumnsType;
  loading: boolean;
  title?: string;
  empty: boolean;
  rowConfigs?: any;

  onProductClick?: (product: Product, type: 'stock' | 'product') => void;
  onFeedbackClick?: () => void;
}

const ITEM_SIZE = 90;
const keyGetter = (product: Product) => product._id;

const AIProducts = ({
  rowConfigs,
  columns,
  products,
  loading,
  title,
  empty,
  onFeedbackClick,
  onProductClick
}: ProductsProps) => {
  const containerRef = useRef<HTMLDivElement>();

  const { listVirtualizer } = useVirtualizeData<Product>({
    elements: products || [],
    count: products?.length || 0,
    parentRef: containerRef,
    gap: 0,
    overscan: 5,
    itemSize: ITEM_SIZE,
    gapY: 0,
    gapX: 16
  });

  if (loading)
    return (
      <LoadingContainer>
        <LoadingContent>
          <DotLottieReact
            loop
            autoplay
            height={1}
            src="/images/lottie/animation.lottie"
          />

          <SText noLineClamp fontColor="#44444F">
            Looking for Products...
          </SText>
        </LoadingContent>
      </LoadingContainer>
    );

  if (empty)
    return (
      <SEmptyState
        imageSrc="/images/empty/empty-pc-search.png"
        imageAlt="Empty search"
        additionalContent={
          <>
            <SText
              noLineClamp
              fontColor="#696974"
              style={{
                marginTop: 6
              }}>
              Enter your requirement on left to search
            </SText>
            <SButton variant="text" onClick={onFeedbackClick}>
              Send Feedback
            </SButton>
          </>
        }
      />
    );

  return (
    <Content>
      <Header>
        <SText
          weight="semibold"
          title={`Product Match results for: ${title}`}
          maxLines={1}
        />

        <SButton variant="text" onClick={onFeedbackClick}>
          Send Feedback
        </SButton>
      </Header>

      <TableContainer
        // @ts-ignore
        ref={containerRef}>
        <SVirtualTable
          searchQuery=""
          list={products}
          columns={columns}
          keyGetter={keyGetter}
          vList={listVirtualizer?.getVirtualItems()}
          height={listVirtualizer?.getTotalSize()}
          row={{
            onClick: product => onProductClick(product, 'product'),
            rowStyleGetter: () => ({ maxHeight: ITEM_SIZE }),
            fallback: <TableLoader rows={1} height={ITEM_SIZE} />,
            ...rowConfigs
          }}
        />
      </TableContainer>
    </Content>
  );
};

export default AIProducts;
