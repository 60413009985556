import { styled, Box, OutlinedInput } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';

export const AITextSearch = styled(Box)({
  height: 200,
  background: 'white',
  border: '0.5px solid #92929D',
  borderRadius: 8,
  position: 'relative',
  backgroundImage: 'url(/images/SETVIAI.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 120,
  backgroundPosition: 'top right'
});

export const AIField = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: 6,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'start',
  padding: theme.spacing(3, 2, 3, 3),

  '& textarea': {
    height: '75% !important',
    position: 'relative',
    resize: 'none',
    background: 'transparent',
    color: '#000',
    zIndex: 1,
    textIndent: 60
  },

  '&::before': {
    content: '"Ask AI: "',
    position: 'absolute',
    top: 25,
    left: 16,
    transform: 'translateY(-50%)',
    fontSize: 18,
    background:
      'linear-gradient(135deg,  #256EE4 5%, #003A6C 20%, #001222 90%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    pointerEvents: 'none'
  }
}));

export const CancelButton = styled(SButton)({
  position: 'absolute',
  fontWeight: 500,
  textDecoration: 'underline',
  bottom: 15,
  left: 15
});

export const SubmitButton = styled(SButton)({
  background: 'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)',
  position: 'absolute',
  fontWeight: 500,
  bottom: 15,
  right: 15,

  '&.Mui-disabled': {
    opacity: 0.6,
    color: 'white'
  }
});

export const Placeholder = styled(Box)({
  top: 16,
  left: 80,
  fontSize: 16,
  fontWeight: 400,
  position: 'absolute',
  color: '#92929D',
  marginRight: 6,
  height: '80%',
  overflow: 'auto'
});
