import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  makeStyles,
  styled,
  withStyles
} from '@material-ui/core';

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3)
}));

export const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gridGap: 8
});

export const BackButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}));

export const Form = styled(Grid)({
  backgroundColor: '#FAFAFB'
});

export const ImagePreview = withStyles({
  tooltip: {
    padding: '2px !important',
    borderRadius: 6
  }
})(Tooltip);

export const useStyles = makeStyles({
  dialogPaper: {
    width: '1150px',
    maxWidth: '1150px'
  }
});
