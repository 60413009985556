import { SortType } from '@setvi/shared/enums';
import { Resource, Product } from '@setvi/shared/interfaces';
import { useQuery } from '@tanstack/react-query';
import { productAssociatedResources } from 'Services';
import { useState } from 'react';

interface useAssociatedResourceProps {
  product: Product;
}
// @ts-ignore
export const useAssociatedResource = ({
  product
}: useAssociatedResourceProps) => {
  const [sort, setSort] = useState<{
    orderBy: SortType;
    sortBy: string;
  }>();

  const { data, isLoading, isFetching } = useQuery(
    productAssociatedResources(product.sku)
  );

  return {
    resources: (data?.Resources as Resource[]) || [],
    totalResources: data?.Count || 0,
    isLoading: isLoading || isFetching,
    setSort,
    sort
  };
};
