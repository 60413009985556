import { SETVIImage, SText } from '@setvi/shared/components';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { DefaultImages } from '@setvi/shared/enums';

import Card, { CardSizes, CardWrapperProps } from '../../card';
import { GridCardFooter } from '../../footer';
import { useResourceCardStyles } from './style';
import CustomCheckbox, { CustomCheckboxProps } from './checkbox';
import { IconsOptions } from '../interfaces';
import { IconPosition } from '../enums';

interface GridResourceCardProps<T>
  extends Omit<CardWrapperProps<T>, 'children'> {
  item: T;
  url: string;
  title: string;
  icons?: IconsOptions;
  actions?: JSX.Element;
  resourceIcon: string;
  fileName: string;
  checkbox?: CustomCheckboxProps<T>;
}

export const ResourceCard = <T,>({
  item,
  url,
  title,
  icons,
  actions,
  resourceIcon,
  fileName,
  checkbox,
  size = CardSizes.MEDIUM,
  ...rest
}: GridResourceCardProps<T>) => {
  const classes = useResourceCardStyles({ size });

  return (
    <Card<T>
      item={item}
      className={classes.wrapper}
      {...rest}
      onCardClick={actions ? rest.onCardClick : undefined}>
      <Box position="relative">
        <SETVIImage
          alt={title}
          src={url}
          className={classes.img}
          errorImage={DefaultImages.RESOURCE}
        />
        {icons?.rightBottomIcon && (
          <Box
            onClick={e => e.stopPropagation()}
            className={clsx(
              classes.cornerIcon,
              classes[IconPosition.bottomRight as keyof typeof classes]
            )}>
            {icons?.rightBottomIcon}
          </Box>
        )}
        {icons?.rightTopIcon && (
          <Box
            onClick={e => e.stopPropagation()}
            className={clsx(
              classes.cornerIcon,
              classes[IconPosition.topRight as keyof typeof classes]
            )}>
            {icons?.rightTopIcon}
          </Box>
        )}
        {icons?.leftBottomIcon && (
          <Box
            onClick={e => e.stopPropagation()}
            className={clsx(
              classes.cornerIcon,
              classes[IconPosition.bottomLeft as keyof typeof classes]
            )}>
            {icons?.leftBottomIcon}
          </Box>
        )}

        <Box
          onClick={e => e.stopPropagation()}
          className={clsx(
            classes.cornerIcon,
            classes[IconPosition.topLeft as keyof typeof classes]
          )}>
          {!!checkbox && <CustomCheckbox<T> {...checkbox} />}
          {icons?.leftTopIcon}
        </Box>
      </Box>
      <Box className={classes.textContainer}>
        <Box flex={1}>
          <SText showTooltip title={title} weight="medium" maxLines={1} />
        </Box>

        <GridCardFooter
          iconUrl={resourceIcon}
          fileName={fileName}
          actions={actions}
        />
      </Box>
    </Card>
  );
};
