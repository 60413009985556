import { SText } from '@setvi/shared/components';
import { styled, Accordion as MUIAccordion } from '@material-ui/core';

export const AccordionTitle = styled(SText)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 20,
  margin: theme.spacing(2)
}));

export const StyledAccordion = styled(MUIAccordion)({
  boderColor: '#E2E2EA !important',
  borderBottom: '1px solid #E2E2EA',
  borderRadius: '0 !important',
  boxShadow: 'none !important',
  margin: '0 !important'
});
