export enum ResourceThumbnail {
  PdfDocument = '/images/thumbnails/Pdf.png',
  WordDocument = '/images/thumbnails/Word.png',
  Video = '/images/thumbnails/Video.png',
  Audio = '/images/thumbnails/Video.png',
  Images = '/images/thumbnails/Image.png',
  WebPage = '/images/thumbnails/Web.png',
  YoutubeVideos = '/images/thumbnails/YouTube.png',
  VimeoVideos = '/images/thumbnails/Vimeo.png',
  Spreadsheet = '/images/thumbnails/Excel.png',
  Presentation = '/images/thumbnails/Powerpoint.png',
  HTML5 = '/images/thumbnails/App.png',
  PhotoAlbum = '/images/thumbnails/Image Gallery.png',
  KeyNote = '/images/thumbnails/Image Gallery.png',
  Slides = '/images/thumbnails/Powerpoint.png',
  PptVar = '/images/thumbnails/Powerpoint.png'
}
