import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  TypographyProps,
  TooltipProps,
  Tooltip,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { LineHeightType, sTextStyles } from './style';

export type maxLinesType = number | 'unset';

export interface STextProps extends TypographyProps {
  // tooltip title, if children is not provided, title will be shown as children
  title?: string;
  // Does toolttip should be with arrow
  arrow?: boolean;
  // If this prop is provided, user is able to use tooltip content
  interactive?: boolean;
  // Text color
  fontColor?: string;
  // Children can be text, JSX Element/s
  children?: ReactNode;
  // If this prop is provided tooltip will be shown on every hover over text
  showTooltip?: boolean;
  // Max width of paragraph
  maxWidth?: number;
  // How many lines before text overflow
  maxLines?: maxLinesType;
  // If this prop is provided, className lineClamp will not be added
  noLineClamp?: boolean;
  // Height of each row of the text
  lineHeight?: LineHeightType;
  // If this prop is provided tooltip will be disabled even on hover over text
  disableTooltip?: boolean;
  // Tooltip place
  placement?: TooltipProps['placement'];
  // Font weight
  weight?: 'thin' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  // Font size
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'base'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '3.5xl'
    | '4xl'
    | '5xl';
  // If this prop is provided, this content will be shown inner tooltip instead default "title"
  tooltipContent?: JSX.Element;
  // ...all Typography props
}

const SText = ({
  noWrap,
  children,
  className,
  placement,
  fontColor,
  title = '',
  noLineClamp,
  maxWidth,
  maxLines = 1,
  arrow = true,
  interactive = false,
  size = 'base',
  lineHeight = 'base',
  disableTooltip = false,
  weight = 'normal',
  showTooltip = false,
  tooltipContent,

  ...rest
}: STextProps) => {
  const classes = sTextStyles({ maxLines, maxWidth, fontColor, lineHeight });
  const [overflow, setOverflow] = useState(false);
  const ref = useRef(null);

  const classNames = clsx(classes.text, className, {
    [classes[weight]]: weight,
    [classes[size]]: size,
    [classes.noWrap]: noWrap,
    [classes.lineClamp]: !noLineClamp
  });

  const longText = title?.length > 1000;

  useEffect(() => {
    if (ref?.current && !disableTooltip)
      setOverflow(ref.current?.scrollHeight > ref.current?.clientHeight);
  }, [disableTooltip, ref, title]);

  // If text is long, show tooltip and if disableTooltip is not provided
  // This imeplementation is done because of performance issues when this component is used in react complex tree
  if (overflow && !disableTooltip)
    return (
      <Tooltip
        enterDelay={500}
        title={tooltipContent || title}
        placement={placement}
        classes={{
          tooltip: `${(tooltipContent || longText) && classes.customTooltip} ${
            classes.whiteSpace
          }`
        }}
        interactive={interactive}
        arrow={arrow}>
        <Typography
          // Temporary fix for MUI styles issue not being properly applied in MUI v5 on digital showroom project
          // requires MUI update to V5 and style refactoring (removing makeStyles)
          ref={ref}
          style={{ WebkitLineClamp: maxLines }}
          {...rest}
          className={classNames}>
          {children || title}
        </Typography>
      </Tooltip>
    );

  return (
    <Typography
      // Temporary fix for MUI styles issue not being properly applied in MUI v5 on digital showroom project
      // requires MUI update to V5 and style refactoring (removing makeStyles)
      ref={ref}
      style={{ WebkitLineClamp: maxLines }}
      {...rest}
      className={classNames}>
      {children || title}
    </Typography>
  );
};

export default SText;
