import { ReactNode } from 'react';
import {
  Box,
  BoxProps,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core';
import SHeader, { SHeaderProps } from '../sheader';
import SFooter from '../sfooter';

interface ContainerProps {
  hasFooter: boolean;
  disablePadding: boolean;
}

const Base = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  }
})(Box);

const useStyles = makeStyles<Theme, ContainerProps>(({ spacing }) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: ({ hasFooter, disablePadding }: ContainerProps) => {
      if (disablePadding) {
        return 0;
      }

      const bottom = hasFooter ? 2 : 4;

      return spacing(2, 4, bottom, 4);
    },
    overflowY: 'auto',
    flex: 1
  }
}));

const loader = (
  <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
    <Box display="flex">
      <Box marginRight={2}>
        <CircularProgress size={20} />
      </Box>
      <Typography variant="subtitle2">Loading...</Typography>
    </Box>
  </Box>
);

interface SBaseProps extends BoxProps {
  header?: SHeaderProps;
  footer?: {
    onConfirm: () => void;
    onClose?: () => void;
    confirmLabel?: string;
    closeLabel?: string;
    divider?: boolean;
    disableConfirm?: boolean;
    disableClose?: boolean;
    confirmLoading?: boolean;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element | null;
  };
  container?: {
    style: React.CSSProperties;
  };
  loading?: boolean;
  loadingBody?: JSX.Element;
  children: ReactNode;
  disablePadding?: boolean;
}

const SBase = ({
  loading,
  loadingBody,
  children,
  header,
  footer,
  container,
  disablePadding = false,
  ...rest
}: SBaseProps) => {
  const classes = useStyles({ hasFooter: !!footer?.onConfirm, disablePadding });
  if (loading)
    return (
      <Base overflow="hidden" {...rest}>
        {loadingBody || loader}
      </Base>
    );

  return (
    <Base overflow="hidden" {...rest}>
      {header?.title ? <SHeader {...header} /> : null}
      <Box style={container?.style} className={classes.contentContainer}>
        {children}
      </Box>
      {footer?.onConfirm && <SFooter {...footer} />}
    </Base>
  );
};

export default SBase;
