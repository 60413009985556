import {
  ProductDetailsColumnsIds,
  ProductInventory
} from '@setvi/shared/interfaces';
import { Customer } from 'Services/MongoRealm/Types';
import { Location, Quantity } from './components';

interface WarehouseCellContentProps {
  id: ProductDetailsColumnsIds;
  row: ProductInventory;
  selectedCustomer?: Customer;
  getWarehouseLocation: (warehouse: ProductInventory['warehouse']) => string;
}

export const getWarehouseCellContent = ({
  id,
  row,
  selectedCustomer,
  getWarehouseLocation
}: WarehouseCellContentProps) => {
  const contents = {
    [ProductDetailsColumnsIds.Location]: (
      <Location
        row={row}
        selectedCustomer={selectedCustomer}
        getWarehouseLocation={getWarehouseLocation}
      />
    ),
    [ProductDetailsColumnsIds.OrderedQuantity]: (
      <Quantity row={row} property="orderedQuantity" />
    ),
    [ProductDetailsColumnsIds.OnOrderQuantity]: (
      <Quantity row={row} property="onOrderQuantity" />
    ),
    [ProductDetailsColumnsIds.OnHandQuantity]: (
      <Quantity row={row} property="onHandQuantity" />
    ),
    [ProductDetailsColumnsIds.OnTransferQuantity]: (
      <Quantity row={row} property="onTransferQuantity" />
    ),
    [ProductDetailsColumnsIds.Stock]: (
      <Quantity row={row} property="availableOnHand" />
    )
  };

  return contents[id];
};
