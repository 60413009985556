import { CSSProperties, useEffect, useState } from 'react';
import { IntersectionOptions } from 'react-intersection-observer';
import { Table, TableBody, Theme, makeStyles } from '@material-ui/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges
} from '@dnd-kit/modifiers';

import DragHandleIcon from '@material-ui/icons/DragHandle';

import TableHeader, { TableHeaderProps } from '../components/table/header';
import TableBodyCells, { STableCell } from '../components/table/cells';
import { SPaginatedTableProps } from '../paginated';
import SortableItem from '../components/sort-item';
import { SCheckbox } from '../../scheckbox';

const useStyles = makeStyles<Theme, { wrapperClick: boolean }>({
  table: {
    borderCollapse: 'collapse'
  },
  wrapper: {
    borderBottom: `1px solid #E6E7E9`
  },
  staticTableRow: {
    borderBottom: '1px solid #E6E7E9',
    '& :hover': {
      cursor: 'pointer'
    }
  },
  bodyWrapper: {
    cursor: ({ wrapperClick }) => wrapperClick && 'pointer'
  }
});

interface SSortableTable<T>
  extends Omit<
    SPaginatedTableProps<T>,
    'pagination' | 'showPagination' | 'customEmptyState'
  > {
  stickyHeader?: boolean;
  tableStyle?: CSSProperties;
  header?: Partial<TableHeaderProps>;
  isLoading: boolean;
  searchQuery?: string;
  saveOrder?: boolean;
  disabled?: boolean;
  handle?: boolean;
  fetchMore?: () => void;
  handleDrag?: (id: number, order: number, array: T[]) => void;

  bodyprops?: {
    class?: string;
    options?: IntersectionOptions;
    onWrapperClick?: (element: T) => void;
  };
}

export const SSortableTable = <T,>({
  list,
  row = {},
  handle,
  header,
  columns,
  bodyprops,
  tableStyle,
  stickyHeader = true,
  isLoading,
  searchQuery,
  saveOrder = true,
  disabled = false,

  keyGetter,
  fetchMore,
  handleDrag
}: SSortableTable<T>) => {
  const classes = useStyles({ wrapperClick: !!bodyprops?.onWrapperClick });
  const [items, setItems] = useState([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    // With save order when new elements appear inside the list old ones save their order
    if (saveOrder) setItems(prev => [...prev, ...list.slice(prev?.length)]);
    else setItems(list);
  }, [list, saveOrder]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!!active?.id && !!over?.id && active?.id !== over?.id) {
      const oldIndex = items?.findIndex(i => keyGetter(i) === active.id);
      const newIndex = items?.findIndex(i => keyGetter(i) === over.id);

      const newArr = arrayMove(items, oldIndex, newIndex);
      setItems(newArr);
      handleDrag?.(active.id, newIndex, newArr);
    }
  };

  return (
    <Table
      stickyHeader={stickyHeader}
      className={classes.table}
      style={tableStyle}>
      <TableHeader
        columns={header?.columns || columns}
        checkboxFreeSpace={!!row?.checkbox}
        handle={handle}
        {...header}
      />
      <TableBody className={bodyprops?.class}>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          modifiers={[
            restrictToVerticalAxis,
            restrictToWindowEdges,
            restrictToFirstScrollableAncestor
          ]}
          collisionDetection={closestCenter}>
          <SortableContext
            items={items?.map(l => keyGetter(l))}
            strategy={verticalListSortingStrategy}>
            {items?.map((item, index, array) => {
              const identificator = keyGetter(item);
              if (!item) return null;

              return (
                <SortableItem
                  key={identificator}
                  id={identificator}
                  index={index}
                  array={array}
                  fetchMore={fetchMore}
                  options={bodyprops?.options}
                  element={item}
                  disabled={disabled}
                  rowStyleGetter={row?.rowStyleGetter}
                  render={(el, _, attributes, listeners) => {
                    const { checkbox } = row;

                    return (
                      <>
                        {!disabled && handle && (
                          <STableCell
                            style={{
                              textAlign: 'center'
                            }}
                            onClick={(ev: any) => ev.stopPropagation()}>
                            <DragHandleIcon
                              htmlColor="#696974"
                              {...attributes}
                              {...listeners}
                            />
                          </STableCell>
                        )}

                        {checkbox && (
                          <STableCell
                            style={{
                              textAlign: 'center'
                            }}
                            onClick={(e: any) => e.stopPropagation()}>
                            <SCheckbox {...checkbox(el)} />
                          </STableCell>
                        )}
                        <TableBodyCells
                          rowData={item}
                          columns={columns}
                          loading={isLoading}
                          searchQuery={searchQuery}
                        />
                      </>
                    );
                  }}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </TableBody>
    </Table>
  );
};
