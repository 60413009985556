import { Box, Slider, styled } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';
import { Form } from 'formik';

export const Container = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gridGap: 8,
  height: '100%'
});

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gridGap: 8,
  padding: theme.spacing(3, 0)
}));

export const SubmitButton = styled(SButton)({
  background: 'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)',
  fontWeight: 500,

  '&.Mui-disabled': {
    opacity: 0.6,
    color: 'white'
  }
});

export const StyledSlider = styled(Slider)({});

export const inputStyle = {
  backgroundColor: '#fff'
};
