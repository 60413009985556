import { Box, makeStyles, withStyles } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

export const DotsWrapper = withStyles({
  root: {
    display: 'flex',
    margin: '15px auto',
    justifyContent: 'center',
    gap: distances.px.xsmall,
    padding: distances.xxsmall
  }
})(Box);

export const carouselStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: 340,
    alignItems: 'center',
    gap: distances.px.small,
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  scrollButton: {
    width: 45,
    maxWidth: 45,
    minWidth: 45,
    height: 45,
    border: '2px solid #E2E2EA',
    background: 'transparent',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    '&.MuiButton-contained:hover': {
      border: 'none'
    }
  },
  buttonWrapper: {
    minWidth: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonImage: {
    minWidth: 'fit-content',
    borderRadius: 8,
    overflow: 'hidden',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    transform: 'scale(0.965)',
    transition: 'all 0.25s ease',
    '& img': {
      display: 'block',
      borderRadius: 8,
      border: '1px solid #E6E7E9',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto'
    }
  },
  activeImg: {
    transform: 'scale(1)'
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 8,
    backgroundColor: '#e6e6e7',
    transition: 'all 0.25s ease',
    cursor: 'pointer',
    boxShadow: '1px 1px 2px rgb(0, 0, 0, 0.8)',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  active: {
    backgroundColor: '#000'
  },
  firstSlide: {
    paddingLeft: '20%',
    minWidth: '80%'
  },
  lastSlide: {
    paddingRight: '20%',
    minWidth: '80%'
  }
});
