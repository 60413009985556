import { Box } from '@material-ui/core';
import { ErrorPage } from 'router/error-page';

export const InvalidConfiguration = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
    <ErrorPage
      src="/images/empty/empty-pc-search.png"
      title="Uh oh, Something went wrong!"
      description="This user does not appear to have a catalog configuration, please contact administrator!"
    />
  </Box>
);
