import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { resourceView } from '@setvi/shared/utils/viewer';
import { Analytics } from '@setvi/shared/enums';

import { BannersQuery_banners } from 'Types/api/BannersQuery';

import { ROUTES } from 'enumsV2';
import { bannersQuery } from 'Services';

export const useCarousel = () => {
  const navigate = useNavigate();

  const { data, isLoading: loading } = useQuery(bannersQuery());

  const items = useMemo(
    () =>
      data?.map((banner: any) => ({
        id: banner.ID,
        label: banner.Name,
        thumbUrl: banner.WebAppiPadBannerURL
      })),
    [data]
  );

  const openResourceBanner = useCallback(
    (banner: BannersQuery_banners) =>
      navigate(
        `${ROUTES.RESOURCES}?categoryId=${banner.LinkedContentID}&bannerId=${banner.ID}`
      ),
    [navigate]
  );

  const openWebViewerBanner = useCallback(
    (banner: BannersQuery_banners) =>
      resourceView({
        name: banner.Name,
        resourceTypeId: Number(banner.ResourceTypeID),
        resourceId: banner.ResourceID,
        banner: {
          ResourceID: banner.ID,
          starttime: Date.now(),
          endtime: Date.now(),
          TypeID: Analytics.HomeBannerClicked
        }
      }),
    []
  );

  const handleBannerClick = useCallback(
    (id: number) => {
      const banner = data?.find((bnr: any) => bnr.ID === id);
      if (banner.LinkedContentType === 0) return openResourceBanner(banner);
      if (banner.LinkedContentType === 1) return openWebViewerBanner(banner);
    },
    [data, openResourceBanner, openWebViewerBanner]
  );

  return {
    items,
    loading,
    handleBannerClick
  };
};
