import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { SETVIImage, SText } from '@setvi/shared/components';
import { resourceView } from '@setvi/shared/utils/viewer';

import { AskPdfProps } from 'componentsV2/ai/modules/ask-pdf';

import { Container, PDFName, TitleBox } from './styled';
import QuestionSwiper from '../swiper';

interface PlaceholderProps extends Partial<AskPdfProps> {
  onClick: (q: string) => void;
  questions: string[];
}

const Placeholder = ({
  id,
  name,
  typeId,
  thumbnail,
  questions,
  onClick
}: PlaceholderProps) => {
  const qMiddle = questions?.length / 2;

  return (
    <Container>
      <SETVIImage
        radius={8}
        width={180}
        height={180}
        src={thumbnail}
        alt="PDF thumbnail"
        style={{
          border: `1px solid #d1cfcf`,
          boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)'
        }}
      />

      <TitleBox>
        <SText
          size="xl"
          weight="medium"
          title="Some Frequently asked questions about"
        />

        <PDFName
          onClick={() =>
            resourceView({
              name,
              resourceTypeId: typeId,
              resourceId: id
            })
          }>
          <SText size="xl" weight="medium" title={name} maxWidth={350} />
          <OpenInNewIcon color="primary" />
        </PDFName>
      </TitleBox>

      <QuestionSwiper
        questions={questions.slice(0, qMiddle)}
        onClick={onClick}
      />
      <QuestionSwiper
        questions={questions.slice(qMiddle, questions?.length)}
        direction="right"
        onClick={onClick}
      />
    </Container>
  );
};

export default Placeholder;
