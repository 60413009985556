import { Box, withStyles } from '@material-ui/core';
import { Product } from '@setvi/shared/interfaces';
import { SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { Skeleton } from '@material-ui/lab';
import { useCallback, useEffect, useMemo, useState } from 'react';

const Wrapper = withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))(Box);

interface SingerAmountsProps {
  product: Product;
  selectedCustomer: boolean;
  onGetPrice: any;
}

export const FullPrice = ({
  product,
  selectedCustomer,
  onGetPrice
}: SingerAmountsProps) => {
  const shouldAutoLoadPrice = useMemo(
    () => product?.price === null && selectedCustomer,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCustomer]
  );
  const [loading, setLoading] = useState(shouldAutoLoadPrice);
  const { price, cost } = product;

  const handleGetPrice = useCallback(async () => {
    setLoading(true);
    try {
      await onGetPrice();
    } finally {
      setLoading(false);
    }
  }, [onGetPrice]);

  useEffect(() => {
    shouldAutoLoadPrice && handleGetPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAutoLoadPrice]);

  if (loading)
    return (
      <Wrapper>
        <Skeleton variant="rect" height={20} width={150} />
        <Skeleton variant="rect" height={20} width={200} />
        <Skeleton variant="rect" height={20} width={150} />
      </Wrapper>
    );

  return (
    <Wrapper>
      {selectedCustomer && (
        <>
          <SText
            title={`Sell: ${
              price >= 0 && price !== null
                ? price.toLocaleString('en-US', {
                    currency: 'USD',
                    style: 'currency'
                  })
                : '-'
            }`}
            weight="semibold"
            fontColor={TextColor.Black}
            style={{ marginRight: 5 }}
          />
          <Box display="flex">
            <SText
              title={`GP: ${
                price > cost
                  ? (price - cost).toLocaleString('en-US', {
                      currency: 'USD',
                      style: 'currency'
                    })
                  : '-'
              }`}
              fontColor={TextColor.DarkGrey}
            />
            {price > cost && (
              <SText
                title={`(${(((price - cost) / price) * 100).toFixed(2).toString()}%)`}
                weight="normal"
                fontColor="#23A566"
                style={{ marginLeft: '5px' }}
              />
            )}
          </Box>
        </>
      )}
      <SText
        title={`Cost: ${
          cost > 0
            ? product?.cost.toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency'
              })
            : '-'
        }`}
        fontColor={TextColor.DarkGrey}
      />
    </Wrapper>
  );
};
