import { Box, styled } from '@material-ui/core';
import { Skeleton as MUISkeleton, SkeletonProps } from '@material-ui/lab';

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  gridGap: 12
});

const Skeleton = styled((props: SkeletonProps) => (
  <MUISkeleton {...props} variant="rect" />
))({
  borderRadius: 2,
  height: 17
});

export const Loader = () => (
  <Wrapper>
    <Box width="100%" display="flex" justifyContent="space-between">
      <Skeleton width="40%" />
      <Skeleton width="40%" />
    </Box>
    <Skeleton width="50%" />
  </Wrapper>
);
