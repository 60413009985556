import { axiosHelper } from '@setvi/shared/services';
import { AxiosMethods, SalesEnablementApi } from '@setvi/shared/enums';

export enum SalesEnablementQueryKey {
  GET_CONTENT = 'GET_SALES_ENABLEMENT_CONTENT',
  GET_ITEMS = 'GET_SALES_ENABLEMENT_ITEMS'
}

export const getSalesEnablementContentQuery = (
  productId: string,
  categoryId?: string
) => ({
  queryKey: [SalesEnablementQueryKey.GET_CONTENT],
  queryFn: () =>
    axiosHelper({
      endpoint: SalesEnablementApi.GetSalesEnablementContent.replace(
        ':productId',
        productId
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      params: { ...(categoryId ? { categoryId } : {}) }
    }),
  cacheTime: 0
});

export const getSalesEnablementItemsQuery = (categoryId: string) => ({
  queryKey: [SalesEnablementQueryKey.GET_ITEMS],
  queryFn: () =>
    axiosHelper({
      endpoint: SalesEnablementApi.GetSalesEnablementItems.replace(
        ':categoryId',
        categoryId
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_INTELLIGENCE_API
    }),
  cacheTime: 0
});
