import { Box } from '@material-ui/core';
import { SInfiniteTable } from '@setvi/shared/components/s-table/infinite';
import { Promotion } from 'Services/MongoRealm/Types';
import { usePromotionListColumns } from './usePromotionListColumns';

interface PromotionListProps {
  promotions: Promotion[];
  onPromotionClick: (promotion: Promotion) => void;
}

export const PromotionList = ({
  promotions,
  onPromotionClick
}: PromotionListProps) => {
  const { promotionListColumns } = usePromotionListColumns();

  return (
    <Box overflow="auto" mt={2}>
      <SInfiniteTable
        header={{ columns: promotionListColumns }}
        list={promotions}
        columns={promotionListColumns}
        keyGetter={p => p._id}
        row={{
          onClick: onPromotionClick
        }}
      />
    </Box>
  );
};
