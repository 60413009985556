import { Grid } from '@material-ui/core';

import EquipmentCard, {
  EQUIPMENT_TYPE,
  EquipmentCardProps
} from './components/card';

import { PizzaForm } from './components';
import { SearchFormProps } from '..';

const Equipment = ({
  values,
  expanded,
  setValues,
  ...rest
}: SearchFormProps) => {
  const cards: EquipmentCardProps[] = [
    {
      title: 'Pizza Oven Finder',
      type: EQUIPMENT_TYPE.PIZZA,
      tag: 'Oven',
      onClick: (type: EQUIPMENT_TYPE) => {
        const v = {
          type,
          location: '',
          size: '',
          hours: '',
          requirements: '',
          comments: ''
        };

        setValues(prev => ({
          ...prev,
          equipment: JSON.stringify(v)
        }));
      }
    }
  ];

  if (!values?.equipment) {
    return (
      <Grid container spacing={1}>
        {cards.map(({ title, type, tag, onClick }) => (
          <Grid item xs={12} md={expanded ? 'auto' : 12} key={type}>
            <EquipmentCard
              title={title}
              type={type}
              tag={tag}
              onClick={onClick}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return <PizzaForm values={values} setValues={setValues} {...rest} />;
};

export default Equipment;
