import { useEffect, useState } from 'react';

import { fetchPdfAsBase64 } from 'utilsV2';
import { useClaudeMessages } from 'hooksv2/useAnthropic';

import { AskPdfProps, ConversationProps, Tabs } from '..';

enum Prompts {
  OVERVIEW = 'Generate me bref overview data of this file. It must be between 5 and 10 lines.',
  QUESTIONS = 'Give me some questions readers will have about this pdf in string array. Respose needs to be exactly array of strings nothing more.',
  FAQ = 'Give me the top 10 FAQ on this PDF in array of objects where as this [{question: "", answer: ""}]. Answer must have atleast 2 lines. Respose needs to be exactly array of obejcts nothing more.'
}

export const useAskPdf = ({ url }: Partial<AskPdfProps>) => {
  const [base64, setBase64] = useState('');
  const [faq, setFaq] = useState<
    {
      question: string;
      answer: string;
    }[]
  >([]);
  const [overview, setOverview] = useState('');
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<Tabs>(Tabs.AskT);
  const [initLoading, setInitLoading] = useState(true);
  const [questions, setQuestions] = useState<string[]>([]);

  const [conversation, setConversation] = useState<ConversationProps[]>([]);

  const { stream, asyncResponse } = useClaudeMessages({});
  const { stream: questionsStream } = useClaudeMessages({
    fileBase: base64,
    prompt: Prompts.QUESTIONS
  });
  const { stream: faqStream } = useClaudeMessages({
    fileBase: base64,
    prompt: Prompts.FAQ,
    tokens: 2048
  });
  const { stream: overviewStream } = useClaudeMessages({
    fileBase: base64,
    prompt: Prompts.OVERVIEW,
    tokens: 2048
  });

  const tabsList = Object.values(Tabs)?.map(v => ({
    label: v,
    value: v
  }));

  const returnParseArray = (val: string) => {
    const start = val.indexOf('['); // Find the index where the array starts
    const end = val.indexOf(']'); // Find the index where the array ends

    if (!end || end === -1) return '';

    const arrayString = val.substring(start, end + 1); // Extract the array string

    return JSON.parse(arrayString);
  };

  const getBase64 = async (newUrl: string) => {
    const res = await fetchPdfAsBase64(newUrl);
    setBase64(res);
  };

  useEffect(() => {
    if (url) getBase64(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const onSubmit = async (val: string) => {
    setLoading(true);
    setConversation(prev => [
      ...prev,
      {
        question: val,
        answer: ''
      }
    ]);
    asyncResponse({
      fileBase: base64,
      prompt: val
    });
  };

  useEffect(() => {
    if (stream) {
      if (loading) setLoading(false);
      const lastConvo = conversation?.[conversation?.length - 1];
      const convo = {
        question: lastConvo?.question,
        answer: stream
      };

      setConversation(prev => prev.slice(0, -1).concat(convo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);

  useEffect(() => {
    if (questionsStream) {
      try {
        const res = returnParseArray(questionsStream);
        setQuestions(prev => res || prev);
        if (res?.length) setInitLoading(false);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsStream]);

  useEffect(() => {
    if (faqStream) {
      try {
        const res = returnParseArray(faqStream);
        setFaq(prev => res || prev);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqStream]);

  useEffect(() => {
    if (overviewStream) {
      setOverview(overviewStream);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewStream]);

  return {
    tab,
    faq,
    loading,
    tabsList,
    overview,
    questions,
    initLoading,
    conversation,

    setTab,
    onSubmit
  };
};
