import { Box, makeStyles } from '@material-ui/core';
import { SText, TableLoader } from '@setvi/shared/components';
import { SInfiniteTable } from '@setvi/shared/components/s-table/infinite';
import { Product, RecordType } from '@setvi/shared/interfaces';

import { useSpecificationColumns } from './useSpecificationColumns';
import { useProductSpecifications } from './useProductSpecifications';

interface ProductSpecificationsProps {
  product: Product;
  client: any;
}

const specificationTableStyles = makeStyles(theme => ({
  tableWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2)
  }
}));

export const ProductSpecifications = ({
  product,
  client
}: ProductSpecificationsProps) => {
  const { specificationColumns } = useSpecificationColumns();
  const classes = specificationTableStyles();
  const { specifications, isLoading } = useProductSpecifications(
    product,
    client
  );

  if (isLoading) return <TableLoader columns={specificationColumns} rows={4} />;

  return (
    <>
      <Box mb={1}>
        <SText title="Specifications" weight="bold" />
      </Box>
      {specifications?.length > 0 ? (
        <Box className={classes.tableWrapper}>
          <SInfiniteTable<RecordType>
            header={{ columns: [] }}
            list={specifications}
            row={{
              rowStyleGetter: (_, index) =>
                index % 2 === 0 && { backgroundColor: '#F0F0F4' }
            }}
            keyGetter={specification => specification.label}
            columns={specificationColumns}
          />
        </Box>
      ) : (
        <>-</>
      )}
    </>
  );
};
