import { ClickAwayListener, Popper } from '@material-ui/core';

import { AIIcon } from '@setvi/shared/components/sicons';

import {
  AIField,
  AITextSearch,
  CancelButton,
  Placeholder,
  SubmitButton
} from './style';

interface AISearchProps {
  anchorEl: HTMLElement | null;
  value: string;
  width?: string | number;

  setFocusAiSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (value: string) => void;
}

const AISearch = ({
  anchorEl,
  value,
  width = 365,
  setFocusAiSearch,
  setAnchorEl,
  setValue,
  onSubmit
}: AISearchProps) => (
  <Popper
    open={!!anchorEl}
    anchorEl={anchorEl}
    style={{
      marginTop: -40,
      marginLeft: -120
    }}>
    <ClickAwayListener
      onClickAway={() => {
        setAnchorEl(null);
        setFocusAiSearch(false);
      }}>
      <AITextSearch
        style={{
          width: anchorEl?.clientWidth + 120 || width
        }}>
        <AIField
          multiline
          minRows={6}
          maxRows={6}
          autoFocus
          value={value}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault(); // Prevent new line in textarea
              onSubmit(value);
            }
          }}
          onChange={e => setValue(e.target.value)}
          endAdornment={
            <>
              <CancelButton
                variant="text"
                onClick={() => {
                  setValue('');
                  setAnchorEl(null);
                  setFocusAiSearch(false);
                }}>
                Cancel
              </CancelButton>
              <SubmitButton
                disabled={!value?.trim()}
                onClick={() => onSubmit(value)}
                startIcon={<AIIcon htmlColor="#fff" />}>
                Ask
              </SubmitButton>
            </>
          }
          startAdornment={
            !value ? (
              <Placeholder>
                <p>Ex: I am looking for a steamless wine plastic glass 120oz</p>
              </Placeholder>
            ) : null
          }
        />
      </AITextSearch>
    </ClickAwayListener>
  </Popper>
);

export default AISearch;
