import { useEffect, useState, useMemo, useCallback } from 'react';
import { VirtualizerOptions, useVirtualizer } from '@tanstack/react-virtual';

enum Views {
  GRID_VIEW = 'grid',
  LIST_VIEW = 'list'
}

interface VirtualizeDataProps<T> extends Partial<VirtualizerOptions<any, any>> {
  elements: T[];
  view?: Views;
  gapX?: number;
  gapY?: number;
  itemSize?: number;
  offsetSize?: number;
  gridItemWidth?: number;
  gridItemHeight?: number;
  parentRef: React.RefObject<HTMLDivElement>;
}

export const useVirtualizeData = <T>({
  view = Views.LIST_VIEW,
  elements,
  count,
  gapX = 0,
  gapY = 0,

  overscan = 5,
  itemSize = 55,
  gridItemHeight = 255,
  gridItemWidth = 270,

  offsetSize = 0,
  parentRef,
  ...rest
}: VirtualizeDataProps<T>) => {
  const [parentWidth, setParentWidth] = useState<number>(0);

  useEffect(() => {
    if (parentRef?.current === null || parentRef?.current === undefined) return;

    if (parentRef?.current?.offsetWidth) {
      setParentWidth(parentRef?.current?.offsetWidth || 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef?.current?.offsetWidth]);

  const columns = useMemo(
    () => Math.floor(parentWidth / (gridItemWidth + gapX - offsetSize)) || 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentWidth]
  );

  const matrix = useMemo(() => {
    if (columns === 0) return elements;

    const mat = [];
    for (let i = 0; i < elements.length; i += columns) {
      mat.push(elements.slice(i, i + columns));
    }
    return mat;
  }, [columns, elements]);

  const listVirtualizer = useVirtualizer({
    ...rest,
    getScrollElement: () => parentRef?.current,
    count: view === Views.GRID_VIEW ? Math.ceil(count / columns) : count,
    overscan,
    gap: gapY,
    estimateSize: useCallback(
      () => (view === Views.GRID_VIEW ? gridItemHeight : itemSize),
      [gridItemHeight, itemSize, view]
    )
  });

  const columnVirtualizer = useVirtualizer({
    ...rest,
    horizontal: true,
    count: columns || 0,
    gap: gapX,
    getScrollElement: () => parentRef?.current,
    estimateSize: () => gridItemWidth,
    scrollMargin: 0
  });

  useEffect(() => {
    listVirtualizer?.measure();
    columnVirtualizer?.measure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, gridItemHeight, gridItemWidth, itemSize]);

  return {
    matrix,
    columns,
    listVirtualizer,
    columnVirtualizer
  };
};
