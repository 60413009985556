import { Box, Typography } from '@material-ui/core';

interface ErrorProps {
  value: string;
}
export const Error = ({
  value = 'Something went wrong, please try again later!'
}: ErrorProps) => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="h6">{value}</Typography>
  </Box>
);
