import { Skeleton } from '@material-ui/lab';
import { SText } from '@setvi/shared/components';
import { STextProps } from '@setvi/shared/components/sui/stext';

interface PriceProps extends Pick<STextProps, 'weight' | 'size' | 'style'> {
  value: number;
  loading?: boolean;
  highlight?: boolean;
  prefix?: string;
}

export const Price = ({
  value,
  loading,
  highlight,
  prefix = '',
  ...rest
}: PriceProps) => {
  if (loading) return <Skeleton />;

  if (!Number.isFinite(value)) return <>-</>;

  return (
    <SText
      title={`${prefix}${value.toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency'
      })}`}
      color={highlight ? 'primary' : 'textPrimary'}
      {...rest}
    />
  );
};
