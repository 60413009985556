import { Box, withStyles } from '@material-ui/core';
import { useCarousel } from './hooks/useCarousel';
import { CustomCarousel } from './custom';
import { Loader } from './loader';

const CarouselWrapper = withStyles({
  root: {
    width: '100%',
    height: '100%'
  }
})(Box);

const Carousel = () => {
  const { items, loading, handleBannerClick } = useCarousel();

  return (
    <CarouselWrapper>
      {loading ? (
        <Loader />
      ) : (
        <CustomCarousel items={items} onItemClick={handleBannerClick} />
      )}
    </CarouselWrapper>
  );
};

export default Carousel;
