import { Box } from '@material-ui/core';
import { SETVIImage, SHighlightText, SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { Product } from '@setvi/shared/interfaces';
import { ColumnsType } from '@setvi/shared/types';
import { useMemo } from 'react';
import { getProductStatus } from 'componentsV2/product/product-status';

interface AssociatedProductProps {
  manufacturer: boolean;
  searchQuery?: string;
}

export const useAssociatedProductColumns = ({
  manufacturer,
  searchQuery = ''
}: AssociatedProductProps) => {
  const productColums: ColumnsType = useMemo(
    () => [
      {
        id: 'productName',
        width: '85%',
        label: 'Product Name',
        cellContent: (product: Product) => (
          <Box display="flex" alignItems="center">
            <SETVIImage
              style={{ height: '20px' }}
              src={
                product.imageUrl ||
                product.images[0]?.path ||
                '/images/product-placeholder.svg'
              }
              alt={product.name}
            />
            <Box ml={4} display="flex" flexDirection="column" gridGap={4}>
              <Box display="flex" alignItems="center">
                <Box>
                  <SHighlightText
                    value={product.name}
                    searchQuery={searchQuery}
                  />
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <SHighlightText
                  style={{ color: TextColor.Grey }}
                  size="xs"
                  value={`SKU: ${product.sku}`}
                  searchQuery={searchQuery}
                />
                <Box ml={1}>
                  <SText
                    fontColor={TextColor.Grey}
                    size="xs"
                    weight="normal"
                    title={getProductStatus(product)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )
      },
      // {
      //   id: 'manufacturer',
      //   width: '35%',
      //   label: 'Manufacturer',
      //   cellContent: (product: Product) => (
      //     <Box display="flex" flexDirection="column">
      //       <Box display="flex" alignItems="center">
      //         <SText
      //           fontColor={TextColor.Black}
      //           weight="normal"
      //           title={product?.vendor?.name || 'Not Available'}
      //           style={{ marginBottom: '4px', marginRight: '20px' }}
      //         />
      //         {product?.privateLabel && (
      //           <PrivateLabel style={{ marginBottom: '5px' }} />
      //         )}
      //       </Box>
      //       <SText
      //         size="xs"
      //         weight="normal"
      //         title={product.sku}
      //         fontColor={TextColor.Grey}
      //       />
      //       {!manufacturer && (
      //         <SText
      //           fontColor={TextColor.Grey}
      //           size="xs"
      //           title={product?.manufacturerSku}
      //         />
      //       )}
      //     </Box>
      //   )
      // },
      // ...(manufacturer
      //   ? [
      //       {
      //         id: '#',
      //         width: '15%',
      //         label: 'Lead Time',
      //         cellContent: (product: Product) => {
      //           const title =
      //             // @ts-ignore
      //             product.custom?.ship_lead_time__business_days?.value ||
      //             'Not Available';
      //           return (
      //             <Box display="flex" alignItems="center">
      //               <SText weight="normal" title={title} />
      //             </Box>
      //           );
      //         }
      //       }
      //     ]
      //   : []),
      ...(manufacturer
        ? [
            {
              id: 'price',
              width: '15%',
              label: manufacturer ? 'List Price' : 'Sell',
              cellContent: (product: Product) => (
                <Box display="flex" alignItems="center">
                  <SText
                    weight="normal"
                    title={product.price.toLocaleString('en-US', {
                      currency: 'USD',
                      style: 'currency'
                    })}
                  />
                </Box>
              )
            }
          ]
        : [])
    ],
    [manufacturer, searchQuery]
  );

  return { productColums };
};
