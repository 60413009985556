import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { useFilestack } from '@setvi/shared/hooks';

import { SearchFormProps } from '../..';

export const useImageInput = ({ setValues }: Partial<SearchFormProps>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { fileStackUpload, filestackDelete } = useFilestack();

  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [handle, setHandle] = useState('');

  const onDrop = async (acceptedFiles: File[]) => {
    setLoading(true);

    const file = acceptedFiles[0];
    if (!file) return;

    const res = await fileStackUpload({
      file,
      getProgress: () => {},
      error: {
        show: false
      }
    });

    setLoading(false);

    if (!res?.url) {
      enqueueSnackbar('File upload failed', { variant: 'error' });
      return;
    }

    setValues(prev => ({ ...prev, image: res?.url }));
    setHandle(res?.handle);
  };

  const onDropRejected = () => {
    enqueueSnackbar('File type not supported', { variant: 'error' });
  };

  const onRemoveImage = async () => {
    setValues(prev => ({
      ...prev,
      image: ''
    }));

    await filestackDelete(handle);
    setHandle('');
  };

  return {
    focus,
    loading,
    onDrop,
    setFocus,
    onRemoveImage,
    onDropRejected
  };
};
