import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getSalesEnablementContentQuery,
  generateSalesEnablementMutation,
  getSalesEnablementProductPromptsMutation
} from 'Services';

import { SalesEnablementProps } from '..';

export interface ContentProps {
  id: string;
  name: string;
  content: string;
}

export interface SalesEnablementItemProps {
  _id: string;
  name: string;
  order: number;
  isFAQ: boolean;
  content: ContentProps[];
  loading: boolean;
}

export const useSalesEnablement = ({ product }: SalesEnablementProps) => {
  const [items, setItems] = useState<SalesEnablementItemProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState('');

  const params = useMemo(
    () => ({
      _id: product?._id,
      name: product?.name,
      manufacturer: product?.vendor?.name,
      manufacturerSku: product?.manufacturerSku
    }),
    [product]
  );

  // if its root category, use 1 as default
  const productCategoryId = useMemo(
    () => product?.categories?.[0]?._id || '1',
    [product]
  );

  const { data, isFetching } = useQuery({
    ...getSalesEnablementContentQuery(
      product?._id,
      product?.categories?.[0]?._id
    ),
    enabled: !!product?._id,
    retry: false
  });

  const { mutateAsync: getPrompts, isLoading: arePromptsLoading } = useMutation(
    getSalesEnablementProductPromptsMutation(productCategoryId)
  );

  const { mutateAsync: generateSalesEnablement } = useMutation(
    generateSalesEnablementMutation()
  );

  const generateContent = async (id: string) => {
    const res = await generateSalesEnablement({ promptId: id, ...params });

    if (!res) return;
    let contentData: ContentProps[] = [];

    try {
      contentData =
        typeof res?.data === 'string'
          ? JSON.parse(
              res?.data?.map((i: ContentProps) => ({ ...i, id: uuidv4() }))
            )
          : res?.data;
    } catch (
      e
      // eslint-disable-next-line no-empty
    ) {}

    setItems(prev =>
      prev?.map(item =>
        item._id === id
          ? {
              ...item,
              content: contentData,
              isFAQ: res?.isFAQ,
              loading: false
            }
          : item
      )
    );
  };

  const handleItems = async () => {
    if (!loading) setLoading(true);
    const res = await getPrompts();

    const savedPrompts = data?.data?.map(
      ({ promptId }: { promptId: string }) => promptId
    );

    const missingPrompts = res?.filter(
      ({ _id }: { _id: string }) => !savedPrompts?.includes(_id)
    );

    if (missingPrompts?.length) {
      const tItems = missingPrompts?.map(
        (item: Partial<SalesEnablementItemProps>) => ({
          _id: item._id,
          name: item.name,
          order: item.order,
          loading: true
        })
      );

      tItems?.forEach(({ _id }: { _id: string }) => {
        generateContent(_id);
      });

      setItems(prev => [...prev, ...tItems]);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (!isFetching && !!data) {
      const formatedItems = data?.data?.map((item: any) => ({
        _id: item?.prompt?._id,
        name: item?.prompt?.name,
        order: item?.prompt?.order,
        content:
          item?.data?.map((i: ContentProps) => ({ ...i, id: uuidv4() })) || [],
        isFAQ: item?.prompt?.isFAQ || false,
        loading: false
      }));

      setItems(formatedItems);

      if (data?.itemTotal !== data?.promptTotal) {
        handleItems();
      } else {
        setLoading(false);
      }
    }

    if (!isFetching && !data) {
      handleItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  const handleRegeneration = () => {
    const active = items?.find(item => item._id === activeItem);
    if (!active) return;

    setItems(prev =>
      prev?.map(item =>
        item._id === active?._id
          ? {
              ...item,
              loading: true
            }
          : item
      )
    );
    generateContent(active?._id);
  };

  useEffect(() => {
    if (items.length) {
      if (!activeItem?.trim()) setActiveItem(items[0]?._id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const initalLoading = useMemo(() => {
    if (isFetching || arePromptsLoading || loading) return true;
    if (!items?.length) return false;

    return items?.[0]?.loading;
  }, [isFetching, loading, arePromptsLoading, items]);

  return {
    items,
    activeItem,
    initalLoading,

    setActiveItem,
    handleRegeneration
  };
};
