import { Box, styled } from '@material-ui/core';

export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 8, 8, 8),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  flex: 1
}));

export const Content = styled(Box)({
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'relative'
});

export const GrayBox = styled(Box)({
  background: '#F0F0F4',
  borderRadius: 12,
  padding: 12
});

export const LoadContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gridGap: 18,
  width: '100%',
  height: '100%'
});

export const LoadContent = styled(Box)({
  width: 320,
  height: 320,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});
