import {
  IconButtonProps,
  IconButton,
  withStyles,
  makeStyles,
  Theme,
  Box
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SText from '../sui/stext';
import { TextColor } from '../../enums';

enum Direction {
  up = 90,
  down = -90,
  left = 0,
  right = 180
}

type TitleLiteralType =
  | 'sm'
  | 'md'
  | 'lg'
  | 'base'
  | 'xs'
  | 'xl'
  | '2xl'
  | '3xl'
  | '3.5xl'
  | '4xl'
  | '5xl';

interface SNavigateButton extends Omit<IconButtonProps, 'size'> {
  size?: TitleLiteralType;
  titleSize?: TitleLiteralType;
  titleWeight?: 'thin' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  titleColor?: string;
  direction?: keyof typeof Direction;
  title?: string;
  children?: React.ReactNode;
}

const RoundedButton = withStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.primary.main,
    color: '#fff',
    '&.Mui-disabled': {
      color: '#fff',
      backgroundColor: palette.primary.light
    },
    '&:hover': {
      backgroundColor: palette.primary.dark
    }
  }
}))(IconButton);

const useStyles = makeStyles<Theme, { direction: keyof typeof Direction }>(
  theme => ({
    sm: {
      padding: theme.spacing(1)
    },
    md: {
      padding: theme.spacing(2)
    },
    lg: {
      padding: theme.spacing(3)
    },
    arrow: {
      transform: ({ direction }) => `rotate(${Direction[direction]}deg)`
    }
  })
);

const SNavigateButton = ({
  size = 'md',
  titleSize = 'sm',
  titleWeight = 'bold',
  titleColor = TextColor.LightGrey,
  direction = 'left',
  title,
  children,
  ...rest
}: SNavigateButton) => {
  const classes = useStyles({ direction });

  return (
    <>
      <Box display="flex" alignItems="center" gridGap={7}>
        <RoundedButton aria-label="back" className={classes[size]} {...rest}>
          <ArrowBackIcon fontSize="small" className={classes.arrow} />
        </RoundedButton>
        {title && (
          <SText
            title={title}
            fontColor={titleColor}
            size={titleSize}
            weight={titleWeight}
            style={{ marginRight: '7px' }}
          />
        )}
        {children}
      </Box>
    </>
  );
};

export default SNavigateButton;
