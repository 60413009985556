import { GetProductPrice } from 'Services/MongoRealm/Types';
import { Product } from '@setvi/shared/interfaces';
import { enqueueSnackbar } from 'notistack';

interface BaseProperties {
  loba?: string;
  quantity?: number;
  user: any;
}

interface getProductPrice extends BaseProperties {
  product: Product;
}
interface getProductsPrice extends BaseProperties {
  skus: Product['sku'][];
}

export const getProductPrice = async ({
  product,
  loba,
  quantity = 1,
  user
}: getProductPrice) => {
  const targetProduct = [{ sku: product.sku, quantity }];
  try {
    const price: GetProductPrice = await user.functions.getProductPrices(
      loba || '',
      targetProduct
    );

    return price.pricelists.pricelist[0].bulkpriceList[0].price;
  } catch (e) {
    enqueueSnackbar('Error occured while fetching product price', {
      variant: 'error'
    });
  }
  return null;
};

export const getProductPrices = async ({
  skus,
  loba,
  quantity = 1,
  user
}: getProductsPrice) => {
  const targetProduct = skus.map(sku => ({
    sku,
    quantity
  }));
  try {
    const price: GetProductPrice = await user.functions.getProductPrices(
      loba || '',
      targetProduct
    );

    return price.pricelists.pricelist.map(i => ({
      sku: i.sku,
      price: i.bulkpriceList[0].price
    }));
  } catch (e) {
    enqueueSnackbar('Error occured while fetching product price', {
      variant: 'error'
    });
  }
  return null;
};
