import { Form } from 'formik';
import { Box, styled } from '@material-ui/core';

import { SButton } from '@setvi/shared/components';

export const FormikContainer = styled(Box)({
  flex: 1,
  height: '100%'
});

export const InputContainer = styled(Form)({
  flex: 1,
  height: '100%',
  position: 'relative'
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: 8
});

export const LabelContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gridGap: 8
});

export const ClearButton = styled(SButton)({
  textDecoration: 'underline',
  fontWeight: 'normal',
  visibility: ({ hide }: { hide: boolean }) => (hide ? 'hidden' : 'visible')
});

export const SubmitButton = styled(SButton)({
  background: 'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)',
  position: 'absolute',
  fontWeight: 500,
  bottom: 15,
  right: 15,

  '&.Mui-disabled': {
    opacity: 0.6,
    color: 'white'
  }
});

export const Placeholder = styled(Box)({
  top: 18,
  left: 15,
  right: 10,
  fontSize: 16,
  fontWeight: 400,
  position: 'absolute',
  color: '#92929D',
  marginRight: 6,
  height: '80%',
  overflow: 'auto'
});

export const inputStyle = {
  backgroundColor: '#fff',
  wordBreak: 'break-all',
  paddingBottom: 55
};
