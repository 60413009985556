import { Divider } from '@material-ui/core';

import { createPortal } from 'react-dom';
import {
  Container,
  ActionMenu,
  StyledIcon,
  DeselectButton,
  NumberWrapper,
  SelectedWrapper,
  StyledTooltip
} from './style';

interface BulkMenuProps<T> {
  selected: T[];
  actions: {
    color?: string;
    icon?: JSX.Element;
    label: string;
    onClick: () => void;
  }[];
  onCancel: () => void;
}

export const SBulkMenu = <T,>({
  selected,
  actions,
  onCancel
}: BulkMenuProps<T>) => {
  const content = (
    <Container>
      <SelectedWrapper noWrap fontColor="#4F4F4F">
        <NumberWrapper>{selected.length}</NumberWrapper>
        Selected
      </SelectedWrapper>

      <Divider flexItem orientation="vertical" />

      <ActionMenu>
        {actions?.map(({ color, icon, label, onClick }) => (
          <StyledTooltip title={label} key={label} arrow>
            <StyledIcon
              key={label}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                e.bubbles = false;
                onClick();
              }}
              style={{
                borderColor: color
              }}>
              {icon}
            </StyledIcon>
          </StyledTooltip>
        ))}
      </ActionMenu>

      <Divider flexItem orientation="vertical" />

      <DeselectButton variant="text" onClick={onCancel} color="primary">
        Cancel
      </DeselectButton>
    </Container>
  );

  return createPortal(content, document.getElementsByTagName('main')[0]);
};
