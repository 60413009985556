import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'enumsV2';
import { useAppContext } from 'Providers/AppProvider/AppContext';

export const AuthorizedRoutes = () => {
  const { token, user } = useAppContext();
  const location = useLocation();

  if (user && !user?.EmailVerified)
    return (
      <Navigate to={`${ROUTES.VERIFY_EMAIL}?email=${user.Email}`} replace />
    );

  if (token) return <Outlet />;

  return (
    <Navigate
      to={ROUTES.LOGIN}
      replace
      state={
        location?.pathname || location?.search || location?.hash
          ? location
          : null
      }
    />
  );
};

export const ModuleAuthorizedRoutes = ({ module }: { module: string }) => {
  const { companyData } = useAppContext();

  if (companyData[module as keyof typeof companyData]) return <Outlet />;
  return <Navigate to={ROUTES.NOT_FOUND} replace />;
};
