import { Box, withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { distances } from '@setvi/shared/styles';
import { DotsWrapper } from '../style';

const ImageSkeleton = withStyles({
  root: {
    height: 342,
    width: 650,
    minWidth: 650,
    marginRight: distances.px.large,
    maxWidth: 850,
    borderRadius: 12
  }
})(Skeleton);

export const Loader = () => {
  const dots = [1, 2, 3, 4];
  return (
    <>
      <Box display="flex" overflow="hidden">
        <ImageSkeleton animation="wave" variant="rect" />
        <ImageSkeleton animation="wave" variant="rect" />
        <ImageSkeleton animation="wave" variant="rect" />
      </Box>
      <DotsWrapper>
        {dots.map(dot => (
          <Skeleton key={dot} variant="circle" width={10} height={10} />
        ))}
      </DotsWrapper>
    </>
  );
};
