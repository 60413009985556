import { Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';
import { Customer } from 'Services/MongoRealm/Types';
import { Loader } from './components';
import { FullContent } from './components/full-content';

interface FullPriceProps {
  product: Product;
  selectedCustomer: Customer;
  loading: boolean;
}

export const FullPrice = ({
  product,
  selectedCustomer,
  loading
}: FullPriceProps) => {
  if (loading) return <Loader />;

  if (!selectedCustomer)
    return (
      <Box width="100%" display="flex" justifyContent="end">
        <SText
          title={`Cost: ${
            product?.cost > 0
              ? product.cost.toLocaleString('en-US', {
                  currency: 'USD',
                  style: 'currency'
                })
              : '-'
          }`}
        />
      </Box>
    );

  return <FullContent product={product} />;
};
