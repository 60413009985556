import { useState } from 'react';
import { AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StyledAccordion } from './styled-components';

interface SalesFAQProps {
  accordions: {
    name: string;
    content: string;
  }[];
}

export const Accordions = ({ accordions }: SalesFAQProps) => {
  const [expanded, setExpanded] = useState<number>(null);

  return (
    <Box width="100%">
      {accordions?.map(({ name, content }, index) => (
        <>
          <StyledAccordion
            id={`acc-${index}`}
            expanded={expanded === index}
            onChange={() => setExpanded(expanded === index ? null : index)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}>
              <SText weight="medium" title={name} />
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: 0 }}>
              <Box
                width="100%"
                style={{
                  background: '#F0F0F4',
                  borderRadius: 12,
                  padding: 8
                }}>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </Box>
            </AccordionDetails>
          </StyledAccordion>
        </>
      ))}
    </Box>
  );
};
