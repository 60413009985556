import { useEffect, useRef, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { TextColor } from '@setvi/shared/enums';

const Container = styled('div')({
  display: 'flex',
  gap: 8,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  position: 'relative',
  transition: 'all 0.2'
});

const Item = styled('div')({
  backgroundColor: '#f5f5f5',
  padding: '10px',
  borderRadius: '8px',
  fontSize: '15px',
  flexShrink: 0,
  color: TextColor.DarkGrey,
  transition: 'all 0.2s',
  cursor: 'pointer',
  border: '1px solid transparent',

  '&:hover': {
    borderColor: TextColor.DarkGrey
  }
});

interface QuestionSwiperProps {
  questions: string[];
  direction?: 'left' | 'right';
}

interface QuestionSwiperProps {
  questions: string[];
  direction?: 'left' | 'right';

  onClick: (q: string) => void;
}

const QuestionSwiper = ({
  questions,
  direction = 'left',

  onClick
}: QuestionSwiperProps) => {
  const sliderRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isHovered) return () => {};
    const slider = sliderRef.current;
    const scrollAmount = direction === 'left' ? 1 : -1;

    const autoScroll = () => {
      if (slider) {
        if (
          direction === 'left' &&
          slider.scrollLeft >= slider.scrollWidth - slider.clientWidth
        ) {
          slider.scrollLeft = 0;
        } else if (direction === 'right' && slider.scrollLeft <= 0) {
          slider.scrollLeft = slider.scrollWidth - slider.clientWidth;
        } else {
          slider.scrollLeft += scrollAmount;
        }
      }
    };

    const interval = setInterval(autoScroll, 60);
    return () => clearInterval(interval);
  }, [direction, isHovered]);

  return (
    <Container ref={sliderRef}>
      {questions.concat(questions).map(q => (
        <Item
          key={q}
          onClick={() => onClick(q)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          {q}
        </Item>
      ))}
    </Container>
  );
};

export default QuestionSwiper;
