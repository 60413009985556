import { useEffect, useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { enqueueSnackbar } from 'notistack';
import { buildFacets, getFilters } from 'Hooks/useAlgoliaProducts';
import { CustomRefinmentListProps } from '.';

enum CompanySettingsTypeID {
  AtlasMongoProductAppId = 42,
  AtlasMongoProductDBName = 43,
  AlgoliaProductAppId = 44,
  AlgoliaProductIndex = 45,
  AlgoliaProductAdminApiKey = 46,
  AlgoliaProductSearchApiKey = 47
}
export default CompanySettingsTypeID;

export const useFacet = ({
  globalSearch,
  selectedFacets,
  selectedCategory,
  setModifiedFacets
}: Pick<
  CustomRefinmentListProps,
  'globalSearch' | 'selectedFacets' | 'selectedCategory' | 'setModifiedFacets'
>) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<any>(null);
  const { companyData } = useAppContext();

  const productAppId = useMemo(
    () =>
      companyData.Settings[CompanySettingsTypeID.AlgoliaProductAppId]?.Value,
    [companyData]
  );
  const apiKey = useMemo(
    () =>
      companyData.Settings[CompanySettingsTypeID.AlgoliaProductSearchApiKey]
        ?.Value,
    [companyData]
  );
  const productIndex = useMemo(
    () =>
      companyData.Settings[CompanySettingsTypeID.AlgoliaProductIndex]?.Value,
    [companyData]
  );
  const client = useMemo(
    () => algoliasearch(productAppId, apiKey),
    [productAppId, apiKey]
  );
  const index = useMemo(
    () => client.initIndex(productIndex),
    [client, productIndex]
  );

  const handleSearch = async (searchQuery: string, facetName: string) => {
    const setFacetResult = (res: null | number = null) =>
      setModifiedFacets(prev =>
        prev.map(i =>
          i.name === facetName
            ? { ...i, searchedResult: res, expanded: false }
            : i
        )
      );
    if (!searchQuery.length) {
      setSearch(searchQuery);
      setFacetResult();
      setSearchResult(null);
      return;
    }

    try {
      setLoading(true);

      const response = await index.searchForFacetValues(
        facetName,
        searchQuery,
        {
          query: globalSearch,
          facetFilters: buildFacets(
            selectedFacets.filter(f => !f.includes(facetName))
          ),
          filters: getFilters(selectedCategory?._id)
        }
      );

      setSearchResult({
        name: facetName,
        values: response.facetHits.map(({ value, count }) => ({
          value,
          count
        }))
      });
      setFacetResult(response.facetHits.length);
    } catch (e) {
      enqueueSnackbar('Error occured while searching facets', {
        variant: 'error'
      });
      setFacetResult();
    } finally {
      setLoading(false);
      setSearch(searchQuery);
    }
  };

  useEffect(() => {
    setSearch('');
  }, [selectedFacets]);

  useEffect(() => {
    setSearch('');
    setSearchResult(null);
  }, [selectedCategory]);

  return {
    search,
    setSearch,
    loading,
    handleSearch,
    searchResult
  };
};
