import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const PromotionDetaolsLoader = () => (
  <>
    <Box>
      <Skeleton width="30%" />
      <Skeleton width="80%" />
      <Skeleton width="20%" />
    </Box>
    <Box mt={5} display="flex">
      <Skeleton width="70%" />
      <Skeleton style={{ marginLeft: 10 }} width="30%" />
    </Box>
    <Box display="flex">
      <Skeleton width="70%" />
      <Skeleton style={{ marginLeft: 10 }} width="30%" />
    </Box>
    <Box display="flex">
      <Skeleton width="70%" />
      <Skeleton style={{ marginLeft: 10 }} width="30%" />
    </Box>
    <Box display="flex">
      <Skeleton width="70%" />
      <Skeleton style={{ marginLeft: 10 }} width="30%" />
    </Box>
    <Box display="flex">
      <Skeleton width="70%" />
      <Skeleton style={{ marginLeft: 10 }} width="30%" />
    </Box>
    <Box display="flex">
      <Skeleton width="70%" />
      <Skeleton style={{ marginLeft: 10 }} width="30%" />
    </Box>
  </>
);
