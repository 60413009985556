import { Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { Product } from '@setvi/shared/interfaces';
import { PrivateLabel } from 'componentsV2/product/private-label';

interface ManufacturerProps {
  product: Product;
}

export const Manufacturer = ({ product }: ManufacturerProps) => (
  <Box display="flex" flexDirection="column">
    <Box display="flex" gridGap="4px">
      <SText title={product?.vendor?.name || 'Not Available'} />
      {product.privateLabel && <PrivateLabel />}
    </Box>

    {product?.manufacturerSku && (
      <SText
        title={product?.manufacturerSku}
        size="sm"
        fontColor={TextColor.Grey}
      />
    )}
  </Box>
);
