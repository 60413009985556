import { Box, IconButton, useTheme } from '@material-ui/core';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

import { SBase, SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';

import AILogo from '../logo';
import { AITitle } from '../styled';
import { XContainer } from './styled';

interface AIDialogProps {
  style?: React.CSSProperties;
  title: string;
  children: JSX.Element;
  description?: string;
  onClose: () => void;
}

const AIDialog = ({
  onClose,
  title,
  style,
  children,
  description
}: AIDialogProps) => {
  const { spacing } = useTheme();

  return (
    <SBase width="90vw" height="90vh" disablePadding bgcolor="#FAFAFB">
      <XContainer>
        <IconButton onClick={onClose}>
          <CloseSharpIcon />
        </IconButton>
      </XContainer>

      <Box
        style={{
          padding: spacing(0, 8),

          ...style
        }}>
        <AILogo />

        <AITitle variant="h3">{title}</AITitle>
        {!!description?.trim() && (
          <SText fontColor={TextColor.Grey} noLineClamp>
            {description}
          </SText>
        )}
      </Box>

      {children}
    </SBase>
  );
};

export default AIDialog;
