import { useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';

import { AIIcon, ResourceIcon } from '@setvi/shared/components/sicons';
import { SFormikTextfield } from '@setvi/shared/components';
import SLabel from '@setvi/shared/components/sui/slabel';
import { useCustomErrors } from '@setvi/shared/hooks';

import {
  Header,
  Placeholder,
  ClearButton,
  SubmitButton,
  InputContainer,
  LabelContainer,
  FormikContainer,
  inputStyle
} from './styled';
import { VALIDATORS, schema, textValidation } from './utils';

const MIN_ROWS = 4;
const ROW_SIZE = 21;
const AREA_PADDING = 37;

export interface AITextSearchProps {
  text: string;
  disableButton?: boolean;
  setText: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (value: string) => void;
}

const AITextSearch = ({
  text,
  disableButton,
  setText,
  onSubmit
}: AITextSearchProps) => {
  const ref = useRef(null);
  const [rows, setRows] = useState(MIN_ROWS);

  useEffect(() => {
    if (ref?.current) {
      const height = ref?.current?.clientHeight;
      const currRows = Math.floor((height - AREA_PADDING) / ROW_SIZE);
      setRows(currRows > MIN_ROWS ? currRows : MIN_ROWS);
    }
  }, [ref]);

  const { customErrors, customValidate } = useCustomErrors({
    schemas: schema,
    values: { text }
  });

  const initialValues = useMemo(
    () => ({
      text
    }),
    [text]
  );

  const onPlaceholderClick = () => {
    const textarea = ref?.current?.querySelector('textarea');
    if (textarea) textarea?.focus();
  };

  return (
    <FormikContainer>
      <Formik
        onSubmit={() => onSubmit?.(text)}
        validateOnChange={false}
        initialValues={initialValues}>
        {({ values: formikValues, resetForm }) => (
          <InputContainer
            // @ts-ignore
            ref={ref}>
            <SFormikTextfield
              name="text"
              placeholder=""
              label={
                <Header>
                  <LabelContainer>
                    <ResourceIcon
                      color="primary"
                      style={{
                        width: 16
                      }}
                    />
                    <SLabel title="Enter Product Specs" variant="body2" />
                  </LabelContainer>

                  <ClearButton
                    variant="text"
                    disabled={disableButton}
                    hide={!formikValues?.text}
                    onClick={() => {
                      setText('');
                      resetForm({
                        values: { text: '' }
                      });
                    }}>
                    Clear
                  </ClearButton>
                </Header>
              }
              maxLength={VALIDATORS.TEXT}
              onChange={(value: string) => {
                customValidate({
                  schema: textValidation,
                  value,
                  name: 'text'
                });
                setText(value);
              }}
              multiline
              minRows={rows}
              maxRows={rows}
              InputProps={{
                style: inputStyle,
                ...(!formikValues?.text
                  ? {
                      startAdornment: (
                        <Placeholder onClick={onPlaceholderClick}>
                          <p
                            style={{
                              textDecoration: 'underline'
                            }}>
                            Example:
                          </p>
                          <p
                            style={{
                              wordBreak: 'break-word'
                            }}>
                            Zep Neutral pH Floor Cleaner cleans and restores
                            shine to floor surfaces. Utilize this floor cleaner
                            in Step 4 of the Zep 4-Step Pro Floor Maintenance
                            System. Concentrate works great on marble, granite,
                            vinyl and stone floors and can be applied using an
                            automatic scrubber or a mop and bucket.
                          </p>

                          <ul
                            style={{
                              marginTop: 8,
                              wordBreak: 'break-word'
                            }}>
                            <li>
                              For use on interior vinyl floors, asphalt tile,
                              rubber, sealed concrete, marble, granite, natural
                              stone and more
                            </li>
                            <li>Not for use on wood floors</li>
                          </ul>
                        </Placeholder>
                      )
                    }
                  : {}),
                endAdornment: (
                  <SubmitButton
                    type="submit"
                    disabled={
                      !formikValues?.text?.trim() ||
                      // @ts-ignore
                      !!customErrors?.text ||
                      disableButton
                    }
                    startIcon={<AIIcon htmlColor="#fff" />}>
                    Find
                  </SubmitButton>
                )
              }}
              customErrors={customErrors}
            />
          </InputContainer>
        )}
      </Formik>
    </FormikContainer>
  );
};

export default AITextSearch;
