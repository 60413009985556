import { useEffect, useState } from 'react';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useSnackbar } from 'notistack';
import { getProductPrices } from 'pages/products/realm-functions';
import { useRealmContext } from 'Providers/MongoRealmProvider/context';
import { Product } from '@setvi/shared/interfaces';
import { SimilarProductsProps } from '../..';

export const useSimilarProducts = ({
  product,
  selectedCustomer
}: Pick<SimilarProductsProps, 'product' | 'selectedCustomer'>) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAppContext();
  const { user } = useRealmContext();

  const fetchProducts = async () => {
    try {
      const params = new URLSearchParams({
        sku: product.sku,
        topK: '10'
      });
      const response: Product[] = await fetch(
        `${process.env.INTELLIGENCE_API_BASE_URL}/api/1.0/products/similar?${params.toString()}`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${token}`
          })
        }
      )
        .then(res => res.json())
        .then(res => res.products);

      let simProducts = response.map(i => ({
        ...i,
        price: null,
        // ! Remove this when the API response is fixed because it returns id for prduct id instead of _id to match Algolia Product interface
        // @ts-ignore
        _id: i?._id || i.id
      }));

      if (selectedCustomer && simProducts.length) {
        const prices = await getProductPrices({
          // This filter needs to be used here because we have some issues on the BC side and for some products we get null as a sku value
          skus: simProducts
            .filter(i => typeof i.sku === 'string')
            .map(i => i.sku),
          loba: selectedCustomer.accountNumber,
          quantity: 1,
          user
        });

        simProducts = simProducts.map(i => {
          const currentProduct = prices.find(j => j.sku === i.sku);
          return {
            ...i,
            price: currentProduct ? currentProduct.price : null
          };
        });
      }
      setProducts(simProducts);
    } catch (e) {
      enqueueSnackbar('Error occured while fetching product data.', {
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    products,
    isLoading
  };
};
