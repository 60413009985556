import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Box,
  IconButton,
  Tooltip,
  useTheme,
  withStyles
} from '@material-ui/core';
import {
  SETVIImage,
  SHighlightText,
  SText,
  STooltip
} from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { Product } from '@setvi/shared/interfaces';
import { useTableStyles } from 'Components/Shared';
import { getProductStatus } from 'componentsV2/product/product-status';
import { IsStock } from 'componentsV2/ask-ai/product-match/components/result/components/products/styled';

import { SalesEnablementButton } from './components';

const ImagePreview = withStyles({
  tooltip: {
    padding: '2px !important',
    borderRadius: 6
  }
})(Tooltip);

export const PromotionBadge = ({ onClick }: { onClick?: () => void }) => (
  <STooltip title="Promotions">
    <IconButton
      size="small"
      style={{ padding: 0 }}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}>
      <img style={{ width: 20 }} src="/images/product-promotion.png" />
    </IconButton>
  </STooltip>
);

interface ProductNameProps {
  product: Product;
  searchQuery?: string;
  onPromotionClick?: (product: Product) => void;
  onSalesEnablementClick: (product: Product) => void;
}

export const ProductName = ({
  product,
  searchQuery,
  onPromotionClick,
  onSalesEnablementClick
}: ProductNameProps) => {
  const flags = useFlags();

  const classes = useTableStyles();
  const { palette } = useTheme();

  const src =
    product.imageUrl ||
    product.images?.[0]?.path ||
    '/images/product-placeholder.svg';

  return (
    <Box display="flex" alignItems="center">
      <ImagePreview
        interactive
        title={
          <img
            src={src}
            alt="product-img"
            style={{
              width: 200,
              height: 200,
              objectFit: 'contain',
              backgroundColor: palette.common.white,
              borderRadius: '4px',
              padding: '4px'
            }}
          />
        }
        placement="right"
        arrow>
        <Box>
          <SETVIImage
            className={classes.tableImage}
            src={src}
            alt={product.name}
          />
        </Box>
      </ImagePreview>
      <Box ml={2} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gridGap="4px">
          <SHighlightText
            value={product.name}
            searchQuery={searchQuery}
            maxLines={2}
          />
          {product?.promotions?.length > 0 && (
            <Box ml={1}>
              <PromotionBadge onClick={() => onPromotionClick(product)} />
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" gridGap="4px">
          <SHighlightText
            style={{ color: TextColor.Grey }}
            size="sm"
            value={`SKU: ${product.sku}`}
            searchQuery={searchQuery}
          />
          <SText
            fontColor={TextColor.Grey}
            size="sm"
            title={`(${getProductStatus(product)})`}
          />
          {!!product?.isStockProduct && (
            <Tooltip arrow title="This product can be restocked quickly.">
              <IsStock>Stock</IsStock>
            </Tooltip>
          )}
          {flags?.salesEnablement && (
            <SalesEnablementButton
              onClick={() => onSalesEnablementClick(product)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
