import * as Yup from 'yup';

export const MAX_TEXT = 300;

export const feedbackValidation = Yup.object({
  feedback: Yup.string().trim().required('Feedback is required.').max(MAX_TEXT)
});

export const ratingValidation = Yup.object({
  rating: Yup.string().nullable()
});

export const schema = [
  {
    name: 'feedback',
    schema: feedbackValidation
  },
  {
    name: 'rating',
    schema: ratingValidation
  }
];
