import { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

import { style } from '../../style';
import { SEditorType } from '../../interfaces';

enum VARIABLE_TYPE {
  firstName = '{{First Name}}',
  lastName = '{{Last Name}}',
  companyName = '{{Company Name}}'
}

interface SEditorProps {
  id?: string;
  height?: number | string;
  content: string | any;
  variables?: string[];
  recipient?: any;
  customClass?: string;
  showVariableValues?: boolean;
}

const useStyles = makeStyles({
  root: {
    '& .tox-tinymce': {
      border: 'none',
      height: 'auto'
    }
  }
});

const SPreviewEditor = ({
  id = `editor-preview-${new Date().getTime()}`,
  height,
  content,
  variables,
  recipient,
  customClass,
  showVariableValues = false
}: SEditorProps) => {
  const classes = useStyles();
  const [editorNode, setEditorNode] = useState<SEditorType>(null);

  useEffect(() => {
    if (!editorNode || !showVariableValues || !recipient) return;
    let result = content;

    const regex =
      /<span id="[^"]+" class="mce-mergetag" contenteditable="false">{{[^}]+}}<\/span>/g;

    const matches = result.match(regex);

    for (let i = 0; i < matches?.length; i += 1) {
      let toReplace = '';
      if (variables[i] === VARIABLE_TYPE.firstName)
        toReplace = recipient.FirstName;

      if (variables[i] === VARIABLE_TYPE.lastName)
        toReplace = recipient.LastName;

      if (variables[i] === VARIABLE_TYPE.companyName)
        toReplace = recipient.CompanyName;

      result = result.replace(matches[i], toReplace);
      editorNode.execCommand('mceSetContent', false, result);
    }
  }, [editorNode, variables, recipient, showVariableValues, content]);

  return (
    <Box className={`${classes.root} ${customClass}`}>
      <Editor
        tinymceScriptSrc="/vendors/tinymce/tinymce.min.js"
        onInit={(_evt, editor) => {
          editor?.getBody().setAttribute('contenteditable', 'false');
          setEditorNode?.(editor);
        }}
        id={id}
        initialValue={content}
        init={{
          height,
          readonly: true,
          menubar: false,
          toolbar: false,
          statusbar: false,
          automatic_uploads: true,
          force_br_newlines: false,
          invalid_elements: 'pre',
          forced_root_block: 'p',
          relative_urls: false,
          content_style: style,
          convert_urls: false,
          setup: editor => {
            editor.on('click', e => {
              const node = e?.target;
              const parent = node.parentNode;
              if (
                node.nodeName === 'A' &&
                node?.hasAttribute('data-preview-href')
              )
                window.open(
                  e.target.getAttribute?.('data-preview-href'),
                  '_blank'
                );
              if (
                parent.nodeName === 'A' &&
                parent?.hasAttribute('data-preview-href')
              )
                window.open(
                  parent.getAttribute?.('data-preview-href'),
                  '_blank'
                );
            });
          }
        }}
      />
    </Box>
  );
};

export default SPreviewEditor;
