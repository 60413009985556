import { Box, styled } from '@material-ui/core';

export const Content = styled(Box)({
  width: '100%',
  height: '82%',
  overflow: 'auto'
});

export const ChatContent = styled(Box)(({ theme }) => ({
  width: '60%',
  height: '100%',
  minWidth: 300,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  padding: theme.spacing(3, 0)
}));

export const UserBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2)
}));

export const AIBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 8,
  padding: theme.spacing(0, 2)
}));

export const IconBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #d9d9d9',
  borderRadius: '50%',
  width: 35,
  height: 35,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)'
});

export const Message = styled(Box)(({ theme }) => ({
  minHeight: 36,
  background: '#fff',
  borderRadius: 12,
  maxWidth: '60%',
  wordBreak: 'break-word',
  padding: theme.spacing(2),
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  transition: 'all 0.3s ease'
}));

export const TextareaBox = styled(Box)({
  position: 'absolute',
  bottom: 0,
  margin: 'auto',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
