import { Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';
import { ProductSpecifications } from './specifications';

interface ProductInfoProps {
  product: Product;
  client: any;
}

export const ProductInfo = ({ product, client }: ProductInfoProps) => (
  <Box flex={1}>
    <Box>
      <Box mb={1}>
        <SText title="Description" weight="bold" />
      </Box>
      {/* eslint-disable-next-line */}
      <div dangerouslySetInnerHTML={{ __html: product.description || '-' }} />
    </Box>
    <Box mt={3}>
      <ProductSpecifications product={product} client={client} />
    </Box>
  </Box>
);
