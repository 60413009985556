import { Box, BoxProps, Typography, styled } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

export const ContentContainer = styled(Box)({
  background: '#F0F0F4',
  borderRadius: 12,
  padding: 12
});

export const QuestionTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 500,
  marginBottom: 8
});

export const EmptyContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gridGap: 12
});

interface ScrollableWrapperProps extends BoxProps {
  children: React.ReactNode;
}

export const ScrollableWrapper = ({
  children,
  ...rest
}: ScrollableWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollTop = 0;
    }
  }, [children]);

  return (
    // @ts-ignore
    <Box ref={ref} overflow="hidden auto" width="100%" {...rest}>
      {children}
    </Box>
  );
};
