import { Box, Dialog, Grid } from '@material-ui/core';

import { SAILogo, SDrawer } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';

import { Feedback } from 'componentsV2/ai';
import { FEEDBACK_DIALOG } from 'componentsV2/ai/feedback';
import { ActionBar } from 'componentsV2/product/action-bar';
import { ProductDetails } from 'componentsV2/product/product-details';
import { ProductActionType, captureAnalytics } from 'pages/products/utils';
import { ProductDrawerContent } from 'pages/products/components/products-list/components';

import { ProductPriceType } from '@setvi/shared/interfaces';
import { Customer } from 'Services/MongoRealm/Types';

import SearchForm, { SearchFormTabs } from '../form';
import { useResults } from './hook';
import { Products } from './components';
import { ProductMatchProps } from '../..';
import { Form, useStyles } from './styled';

export interface ResultProps extends ProductMatchProps {
  setValues: React.Dispatch<
    React.SetStateAction<{
      url: string;
      text: string;
      equipment: string;
      image: string;
      imageText: string;
    }>
  >;
  setType: React.Dispatch<React.SetStateAction<SearchFormTabs>>;
  priceType: ProductPriceType;
  type: SearchFormTabs;
  values: {
    url: string;
    text: string;
    equipment: string;
    image: string;
    imageText: string;
  };
  selectedCustomer: Customer;
  priceLoading?: boolean;
}

const Result = ({
  tabs,
  type,
  values,
  mongoUser,
  priceType,
  mongoClient,
  selectedCustomer,
  priceLoading,
  onClose,
  setType,
  setValues,
  handleEmail
}: ResultProps) => {
  const { dialogPaper } = useStyles();
  const { openDialog, closeDialog } = useDialog();

  const {
    empty,
    title,
    loading,
    products,
    userType,
    selected,
    detailsTab,
    drawerOpen,
    description,
    dialogOpened,
    selectedProduct,
    defaultAnalytics,
    isFeedbackLoading,

    openProductDetails,
    setSelectedProduct,
    onFeedbackSubmit,
    setDialogOpened,
    handlePromotion,
    onProductClick,
    setDrawerOpen,
    handleSelect,
    onSubmit
  } = useResults({
    setType,
    type,
    values,
    selectedCustomer,
    mongoClient,
    mongoUser
  });

  return (
    <>
      <Grid container style={{ height: '100%', overflow: 'auto' }}>
        <Form item xs={12} md={4} lg={3}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              height: '100%'
            }}>
            <Box>
              <SearchForm
                disableButton={loading}
                setValues={setValues}
                onSubmit={onSubmit}
                values={values}
                type={type}
                tabs={tabs}
              />
            </Box>
            <Box p={3} display="flex" justifyContent="center">
              <SAILogo />
            </Box>
          </Box>
        </Form>
        <Grid
          item
          xs={12}
          md={8}
          lg={9}
          style={{
            height: '100%',
            position: 'relative'
          }}>
          <Products
            title={title}
            empty={empty}
            loading={loading}
            selected={selected}
            products={products}
            description={description}
            onFeedbackClick={() =>
              openDialog(
                <Feedback
                  onClose={() => closeDialog(FEEDBACK_DIALOG)}
                  handleSubmit={onFeedbackSubmit}
                  loading={isFeedbackLoading}
                />,
                {
                  id: FEEDBACK_DIALOG
                }
              )
            }
            handleSelect={handleSelect}
            onStockClick={onProductClick}
            onProductClick={onProductClick}
            onPromotionClick={handlePromotion}
          />

          {!loading && selected.length > 0 && !dialogOpened && (
            <ActionBar
              selectedProducts={selected}
              buttonLabel="Email"
              handleEmail={() => {
                handleEmail(selected);
                onClose();
              }}
              handleSelected={() => setDrawerOpen(!drawerOpen)}
              handleClose={() => handleSelect(false, selected)}
            />
          )}
        </Grid>
      </Grid>

      <SDrawer
        open={drawerOpen}
        style={{ position: 'relative', zIndex: '1500' }}>
        <ProductDrawerContent
          products={selected}
          priceType={priceType}
          selectedCustomer={selectedCustomer}
          priceLoading={priceLoading}
          handleClose={() => setDrawerOpen(false)}
          getProductPrice={null}
          buttonLabel="Email"
          handleEmail={() => {
            handleEmail(selected);
            setDrawerOpen(false);
            onClose();
          }}
          handleRemoveProduct={product => {
            selected.length === 1 && setDrawerOpen(false);
            handleSelect(false, [product]);
          }}
        />
      </SDrawer>

      <Dialog
        fullWidth
        maxWidth="md"
        aria-labelledby="product-details"
        open={!!selectedProduct && dialogOpened}
        style={{ zIndex: '1300' }}
        classes={{ paper: dialogPaper }}>
        <ProductDetails
          client={mongoClient}
          selectedCustomerId={selectedCustomer?._id}
          selectedProduct={selectedProduct}
          defaultOpenedTab={detailsTab}
          userType={userType}
          canSelectProducts={false}
          captureResourceAnalytics={() =>
            captureAnalytics({
              ...defaultAnalytics,
              productSKU: selectedProduct.sku,
              actionType: ProductActionType.AssetView
            })
          }
          openProductDetails={openProductDetails}
          onClose={() => setDialogOpened(false)}
          handleCloseActionBar={() => setSelectedProduct(null)}
          readOnly
        />
      </Dialog>
    </>
  );
};

export default Result;
