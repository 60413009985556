import { IconButton, styled } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export const BackButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,

  '&:hover': {
    background: theme.palette.primary.dark
  }
}));

enum ButtonSize {
  small = 4,
  base = 8
}

interface SBackButtonProps {
  onClick: () => void;
  size?: keyof typeof ButtonSize;
}

const SBackButton = ({ size = 'base', onClick }: SBackButtonProps) => (
  <BackButton onClick={onClick} style={{ padding: `${ButtonSize[size]}px` }}>
    <KeyboardBackspaceIcon htmlColor="#fff" />
  </BackButton>
);

export default SBackButton;
