import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Box, styled } from '@material-ui/core';
import SText from '../../sui/stext';

export const LoadContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gridGap: 18,
  width: '100%',
  height: '100%'
});

export const LoadContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const Text = styled(SText)({
  background: 'linear-gradient(115deg, #256EE4 5%, #003A6C 35%, #001222 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent'
});

interface AILoaderProps {
  title: string;
  width?: number;
  height?: number;
  children?: JSX.Element;
}

const AILoader = ({
  title,
  width = 320,
  height = 320,
  children
}: AILoaderProps) => (
  <LoadContainer>
    <LoadContent width={width} height={height}>
      <DotLottieReact
        loop
        autoplay
        height={1}
        src="/images/lottie/processing.lottie"
      />
    </LoadContent>
    <Text size="xl" color="primary" weight="medium" title={title} />
    {children}
  </LoadContainer>
);

export default AILoader;
