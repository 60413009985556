import { Box, IconButton, styled } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';

export const Container = styled(Box)({
  height: '100%',
  width: '100%',
  gap: 8,
  display: 'flex',
  flexDirection: 'column'
});

export const Input = styled(Box)({
  height: '100%',
  width: '100%',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 12,
  padding: 20,
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
});

export const InputContent = styled(Box)({
  position: 'relative',
  flex: 1
});

export const InputPlaceholder = styled(Box)({
  position: 'relative',
  padding: 10,
  borderRadius: 8,
  background: '#FAFAFB',
  color: '#92929D',
  fontSize: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 10
});

export const Image = styled('img')({
  border: '1px solid #E2E2EA',
  borderRadius: 12
});

export const RemoveImage = styled(IconButton)({
  position: 'absolute',
  top: -15,
  right: -15,
  padding: 6,
  borderRadius: '50%',
  border: '1px solid #E2E2EA',
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)'
});

export const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: 12
});

export const SubmitButton = styled(SButton)({
  background: 'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)',
  fontWeight: 500,

  '&.Mui-disabled': {
    opacity: 0.6,
    color: 'white'
  }
});
