export enum SlackChannels {
  DueToReorderFeedback = 'C08FCAM9V5G',
  GeneralFeedback = 'C086FFTMZJ8'
}

export enum SlackMessageTypes {
  ProductMatch = 'Product Match',
  AutoRFQ = 'Auto RFQ',
  ReorderIntelligence = 'Reorder Intelligence',
  SalesEnablement = 'Sales Enablement'
}
