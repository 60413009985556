import { Box, styled } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { UploadFileIcon } from '@setvi/shared/components/sicons';

const Container = styled(Box)({
  top: '0%',
  left: '0%',
  width: '100%',
  height: '95%',
  position: 'absolute',
  backgroundColor: '#fff',
  border: '1px dashed gray',
  borderRadius: 6,
  zIndex: 2,
  transition: 'all 0.5s ease'
});

const Content = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gridGap: 12
});

const DNDOverlay = ({ visible }: { visible: boolean }) => (
  <Container
    style={{
      visibility: visible ? 'visible' : 'hidden',
      opacity: visible ? 1 : 0
    }}>
    <Content>
      <UploadFileIcon
        style={{
          height: 60,
          width: 60
        }}
        fontSize="large"
        color="primary"
      />

      <SText weight="bold" size="lg" title="Drag & Drop" />
      <SText title="Drag 'n' drop files here to Upload Image" />
    </Content>
  </Container>
);

export default DNDOverlay;
