import { styled, Menu, MenuItem, CircularProgress } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';

export const iconColor = TextColor.Grey;

export const Loader = styled(CircularProgress)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: iconColor
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(1),

  '& .MuiPaper-root': {
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.main}`,

    '& ul': {
      padding: 0
    }
  }
}));

export const StyledButton = styled(SButton)({
  minWidth: 'fit-content',
  minHeight: 40,
  borderColor: '#92929D'
});

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  gridGap: theme.spacing(2)
}));
