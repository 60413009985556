import { SETVIImage, SText } from '@setvi/shared/components';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './style';
import Card, { CardSizes, CardWrapperProps } from '../../card';
import CustomCheckbox, { CustomCheckboxProps } from '../resource/checkbox';
import { IconsOptions } from '../interfaces';
import { IconPosition } from '../enums';

interface GridCategoryCardProps<T>
  extends Omit<CardWrapperProps<T>, 'children'> {
  item: T;
  url: string;
  title: string;
  icons?: IconsOptions;
  description: string;
  actions?: JSX.Element;
  checkbox?: CustomCheckboxProps<T>;
}

export const CategoryCard = <T,>({
  item,
  url,
  title,
  icons,
  description,
  actions,
  checkbox,
  size = CardSizes.MEDIUM,
  ...rest
}: GridCategoryCardProps<T>) => {
  const classes = useStyles({ size });

  return (
    <Card<T> item={item} className={classes.wrapper} {...rest}>
      <Box position="relative">
        <SETVIImage
          alt={title}
          src={url}
          errorImage="/images/resource-category-error-img.png"
          className={classes.img}
        />

        {icons?.rightBottomIcon && (
          <Box
            onClick={e => e.stopPropagation()}
            className={clsx(
              classes.cornerIcon,
              classes[IconPosition.bottomRight as keyof typeof classes]
            )}>
            {icons?.rightBottomIcon}
          </Box>
        )}
        {icons?.rightTopIcon && (
          <Box
            onClick={e => e.stopPropagation()}
            className={clsx(
              classes.cornerIcon,
              classes[IconPosition.topRight as keyof typeof classes]
            )}>
            {icons?.rightTopIcon}
          </Box>
        )}
        {icons?.leftBottomIcon && (
          <Box
            onClick={e => e.stopPropagation()}
            className={clsx(
              classes.cornerIcon,
              classes[IconPosition.bottomLeft as keyof typeof classes]
            )}>
            {icons?.leftBottomIcon}
          </Box>
        )}

        <Box
          onClick={e => e.stopPropagation()}
          className={clsx(
            classes.cornerIcon,
            classes[IconPosition.topLeft as keyof typeof classes]
          )}>
          {!!checkbox && <CustomCheckbox<T> {...checkbox} />}
          {icons?.leftTopIcon}
        </Box>
      </Box>

      <Box className={classes.info}>
        <SText showTooltip title={title} weight="medium" />
        <Box display="flex" justifyContent="space-between" flex={1}>
          <Box overflow="hidden" width="90%">
            <SText
              maxLines={2}
              title={description}
              size="xs"
              className={classes.desc}
              showTooltip
            />
          </Box>

          {actions && (
            <Box className={classes.action} onClick={e => e.stopPropagation()}>
              {actions}
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};
