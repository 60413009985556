import { Box, ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SButton, SETVIImage } from '@setvi/shared/components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { carouselStyles, DotsWrapper } from '../style';

type Item = {
  id: number;
  label: string;
  thumbUrl: string;
};

interface CustomCarouselProps {
  items: Item[];
  onItemClick?: (id: number) => void;
}

export const CustomCarousel = ({ items, onItemClick }: CustomCarouselProps) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const [active, setActive] = useState<number>();
  const classes = carouselStyles();
  const imgsRef = useRef<HTMLDivElement>();

  const handleScroll = useCallback(
    (index: number) => {
      document
        .querySelector(`#carousel-img-${items[index].id}`)
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      setActive(index);
    },
    [items]
  );

  const handleResize = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const element = imgsRef.current!;

    setIsOverflow(element.scrollWidth > element.clientWidth);

    if (!isOverflow) element.scrollLeft = 0;
    handleScroll(0);
  }, [handleScroll, isOverflow]);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box position="relative">
      {items.length > 1 && (
        <SButton
          color="inherit"
          variant="outlined"
          className={classes.scrollButton}
          disabled={active === 0}
          onClick={e => {
            e.stopPropagation();
            handleScroll(active - 1);
          }}>
          <ArrowBackIosIcon
            fontSize="small"
            viewBox="0 0 17 24"
            color="inherit"
          />
        </SButton>
      )}
      <div
        className={classes.wrapper}
        style={{ justifyContent: items.length === 1 ? 'center' : 'inherit' }}
        ref={imgsRef}>
        {items?.map((item, index) => (
          <Box
            key={item.id}
            className={clsx(classes.buttonWrapper, {
              [classes.firstSlide]: items.length > 1 && index === 0,
              [classes.lastSlide]:
                items.length > 1 && index === items.length - 1
            })}>
            <ButtonBase
              className={clsx(classes.buttonImage, {
                [classes.activeImg]: index === active
              })}
              onClick={() =>
                onItemClick ? onItemClick(item.id) : handleScroll(index)
              }>
              <SETVIImage
                id={`carousel-img-${item.id}`}
                src={item.thumbUrl}
                alt={item.label}
              />
            </ButtonBase>
          </Box>
        ))}
      </div>
      {isOverflow && (
        <DotsWrapper marginTop={2}>
          {items?.map((item, index) => (
            <span
              key={item.id}
              className={clsx(classes.dot, {
                [classes.active]: index === active
              })}
              onClick={() => handleScroll(index)}
            />
          ))}
        </DotsWrapper>
      )}
      {items.length > 1 && (
        <SButton
          color="inherit"
          variant="outlined"
          disabled={active === items.length - 1}
          onClick={() => {
            handleScroll(active + 1);
          }}
          className={classes.scrollButton}
          style={{ right: 0 }}>
          <ArrowForwardIosIcon fontSize="small" />
        </SButton>
      )}
    </Box>
  );
};
