import { useState } from 'react';
import { Box, FormControlLabel } from '@material-ui/core';
import { SBase, SCheckbox, SText } from '@setvi/shared/components';

interface RankingValues {
  enablePrivateLabelRanking: boolean;
  enableStockProductRanking: boolean;
  enableVendorRanking: boolean;
}

interface RankingProps {
  rankings: RankingValues;
  onClose: () => void;
  onConfirm: (values: RankingValues) => void;
}

const Ranking = ({ onConfirm, onClose, rankings }: RankingProps) => {
  const [values, setValues] = useState<RankingValues>(rankings);

  return (
    <SBase
      header={{
        title: `Rankings`,
        onClose
      }}
      footer={{
        onConfirm: () => {
          onConfirm(values);
        },
        onClose,
        confirmLabel: 'Save',
        closeLabel: 'Cancel'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridGap: 8
        }}>
        <FormControlLabel
          control={
            <SCheckbox
              name="enablePrivateLabelRanking"
              checked={values.enablePrivateLabelRanking}
              onChange={(_, checked: boolean) =>
                setValues(prev => ({
                  ...prev,
                  enablePrivateLabelRanking: checked
                }))
              }
            />
          }
          label={
            <Box
              sx={{
                width: '100%',
                marginLeft: 6
              }}>
              <SText title="Private Label Ranking" />
            </Box>
          }
        />

        <FormControlLabel
          control={
            <SCheckbox
              name="enableStockProductRanking"
              checked={values.enableStockProductRanking}
              onChange={(_, checked: boolean) =>
                setValues(prev => ({
                  ...prev,
                  enableStockProductRanking: checked
                }))
              }
            />
          }
          label={
            <Box
              sx={{
                width: '100%',
                marginLeft: 6
              }}>
              <SText title="Stock Product Ranking" />
            </Box>
          }
        />

        <FormControlLabel
          control={
            <SCheckbox
              name="enableVendorRanking"
              checked={values.enableVendorRanking}
              onChange={(_, checked: boolean) =>
                setValues(prev => ({
                  ...prev,
                  enableVendorRanking: checked
                }))
              }
            />
          }
          label={
            <Box
              sx={{
                width: '100%',
                marginLeft: 6
              }}>
              <SText title="Vendor Ranking" />
            </Box>
          }
        />
      </Box>
    </SBase>
  );
};

export default Ranking;
