import { ColumnType } from '../../types';

export interface Product {
  _id: string;
  productCode: string;
  name: string;
  active: boolean;
  cost?: number;
  description: string;
  discontinued: boolean;
  dropship: boolean;
  manufacturerProductNumber: string;
  price?: number | null;
  sell?: number | null;
  imageUrl: string;
  isPriceEditable?: boolean;
  packSize: string;
  reasoning?: string;
  percentage?: number;
  unit: string;
  special: boolean;
  procurementStopped: boolean;
  salesStopped: boolean;
  created_on: string;
  updated_on: string;
  last_modified: string;
  images: ProductImage[] | null;
  inventory: ProductInventory[] | null;
  sku: string | null;
  manufacturerSku: string | null;
  vendor: Vendor | null;
  categories: ProductCategory[] | null;
  custom?: DynamicAttribute;
  privateLabel?: boolean;
  promotions: string[] | null;
  productAssociations?: string[];
  isStockProduct?: boolean;
}

export interface TempProductType {
  _id: string;
  productCode: string;
  name: string;
  active: boolean;
  cost?: number;
  description: string;
  discontinued: boolean;
  dropship: boolean;
  manufacturerProductNumber: string;
  price: number | null;
  imageUrl: string;
  packSize: string;
  unit: string;
  special: boolean;
  procurementStopped: boolean;
  salesStopped: boolean;
  created_on: string;
  updated_on: string;
  last_modified: string;
  images: string;
  inventory: ProductInventory[] | null;
  sku: string | null;
  manufacturerSku: string | null;
  vendor: Vendor | null;
  categories: ProductCategory[] | null;
  custom?: DynamicAttribute;
  privateLabel?: boolean;
  promotions: string[] | null;
  productAssociations?: string[];
}

export interface ProductCategoryBreadcrumb {
  _id: string;
  name: string;
}

export interface ProductCategory {
  _id: string;
  parentId: string;
  name: string;
  lastModifiedDate: string;
  breadcrumbs: ProductCategoryBreadcrumb[];
}

export interface ProductImage {
  _id: string;
  path: string;
  size: string;
  name: string;
}

export interface ProductInventory {
  _id: string;
  productId: string;
  siteId: string;
  warehouseId: string;
  availableOnHand: number;
  availableOrderedQuantity: number;
  onHandQuantity: number;
  onOrderQuantity: number;
  orderedQuantity: number;
  onTransferQuantity: number;
  warehouse: ProductWarehouse | null;
}

export interface ProductWarehouse {
  _id: string;
  code: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zip: string;
}
export interface Vendor {
  _id: string;
  vendorId: string;
  name: string;
}

export interface DynamicAttribute {
  attribute: RecordType;
  ship_lead_time__business_days?: string;
  leadTime?: string;
}

export interface RecordType {
  value: string | boolean;
  label: string;
  type: CustomPropertyType;
  specification: boolean;
}

export enum ProductDetailsTabsIds {
  PRODUCT_INFO = 'productInfo',
  WAREHOUSE = 'warehouse',
  ASSOCIATED_RESOURCE = 'associatedResources',
  ASSOCIATED_PRODUCTS = 'associatedProducts',
  SIMILAR_PRODUCTS = 'similarProducts',
  SALES_ENABLEMENT = 'salesEnablement'
}

export enum ProductColumnsIds {
  ProductName = 'productName',
  Manufacturer = 'manufacturer',
  Stock = 'stock',
  LeadTime = 'leadTime',
  Cost = 'cost',
  GetCost = 'getCost',
  GP = 'gp',
  Price = 'price',
  ListPrice = 'listPrice',
  GetPrice = 'getPrice'
}

export enum ProductDetailsColumnsIds {
  Location = 'location',
  OrderedQuantity = 'orderedQuantity',
  OnOrderQuantity = 'onOrderQuantity',
  OnHandQuantity = 'onHandQuantity',
  OnTransferQuantity = 'onTransferQuantity',
  Stock = 'stock'
}

export type ProductColumn = Pick<
  ColumnType,
  'id' | 'label' | 'width' | 'minWidth' | 'maxWidth'
>;

export enum ProductPriceType {
  None = 'none', // Default
  List = 'list', // Middleby user(manufacturer)
  Get = 'get', // Covetrus user(distributor)
  Full = 'full', // Singer user
  GetFull = 'getFull' // PJP user
}

export interface ProductDetailsCustomer {
  show: boolean;
}
export interface ProductDetails {
  tabs: ProductDetailsTab[];
  stock: boolean;
}

export interface ProductDetailsTab {
  id: ProductDetailsTabsIds;
  columns?: ProductColumn[];
}

export interface ProductGridItem {
  manufacturerLeadTime: boolean;
  stock?: boolean;
}

export interface CatalogConfig {
  customer: ProductDetailsCustomer;
  product: {
    columns: ProductColumn[];
    grid: ProductGridItem;
  };
  productDetails: ProductDetails;
  price: ProductPriceType;
}

export enum CustomPropertyType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Link = 'link',
  HTML = 'html',
  ArrayString = 'arrayString',
  LabeledImageArray = 'LabeledImageArray'
}

export enum CompanyProductUserType {
  Distributor = '0',
  Manufacturer = '1'
}
