import { SText } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';

interface LeadTimeProps {
  product: Product;
}

export const LeadTime = ({ product }: LeadTimeProps) => (
  <SText
    title={
      // @ts-ignore
      product?.custom?.ship_lead_time__business_days || '-'
    }
  />
);
