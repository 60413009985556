import { memo } from 'react';
import { Box, Divider } from '@material-ui/core';
import { SETVIImage, SText } from '@setvi/shared/components';

interface GridCardFooterProps {
  iconUrl: string;
  fileName: string;
  actions?: JSX.Element;
}

export const GridCardFooter = memo(
  ({ iconUrl, fileName, actions }: GridCardFooterProps) => (
    <Box mt={2}>
      <Divider />
      <Box display="flex" justifyContent="space-between" pt={2}>
        <Box display="flex" alignItems="center" flex={1}>
          <SETVIImage src={iconUrl} alt="File Type" width={21} />
          <Box ml={2}>
            <SText
              title={fileName}
              fontColor="#919294"
              size="xs"
              weight="medium"
            />
          </Box>
        </Box>
        {actions}
      </Box>
    </Box>
  )
);
