import { Product } from '@setvi/shared/interfaces';
import { SInfiniteTable } from '@setvi/shared/components/s-table/infinite';
import { resourceView } from '@setvi/shared/utils/viewer';
import { SEmptyState, TableLoader } from '@setvi/shared/components';
import { useAssociatedResourceColumns } from './useAssociatedResourceColumns';
import { useAssociatedResource } from './useAssociatedResource';

interface AssociatedResourceProps {
  product: Product;
  closeDialog?: () => void;
  captureResourceAnalytics?: () => void;
  readOnly?: boolean;
}

export const AssociatedResource = ({
  product,
  readOnly,
  closeDialog,
  captureResourceAnalytics
}: AssociatedResourceProps) => {
  const { resources, isLoading } = useAssociatedResource({ product });
  const { columns } = useAssociatedResourceColumns({
    closeDialog,
    readOnly
  });

  if (isLoading) return <TableLoader columns={columns} />;

  if (!resources.length && !isLoading)
    return (
      <SEmptyState
        title="No Associated Resources"
        subTitle="There are no associated resources available for this product."
      />
    );

  return (
    <SInfiniteTable
      list={resources}
      columns={columns}
      keyGetter={resource => resource?.ID}
      isLoading={isLoading}
      row={{
        onClick: resource => {
          captureResourceAnalytics?.();
          resourceView({
            name: resource.Name,
            resourceTypeId: resource.ResourceTypeID,
            resourceId: resource.ResourceID
          });
        }
      }}
    />
  );
};
