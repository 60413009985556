import { ReactNode } from 'react';
import clsx from 'clsx';
import {
  useCardStyles,
  StyledCard,
  StyledCardContent
} from '../../resources/style';

export enum CardSizes {
  MEDIUM = 'md',
  SMALL = 'sm'
}

export const CARD_DIMENSIONS = {
  [CardSizes.MEDIUM]: {
    width: 250,
    height: 200
  },
  [CardSizes.SMALL]: {
    width: 210,
    height: 130
  }
};

export interface CardWrapperProps<T> {
  id?: string;
  item: T;
  size?: CardSizes;
  onDrop?: (item: T) => void;
  onCardClick?: (item: T) => void;
  onDragStart?: (item: T) => void;
  className?: string;
  children: ReactNode;
}

const Card = <T,>({
  id,
  item,
  size = CardSizes.MEDIUM,
  onDrop,
  onCardClick,
  onDragStart,
  className,
  children
}: CardWrapperProps<T>) => {
  const cardClasses = useCardStyles({ size });

  return (
    <StyledCard
      id={id}
      elevation={0}
      className={clsx(cardClasses.cardWrapper, className, {
        [cardClasses.clickable]: !!onCardClick || !!onDragStart || !!onDrop
      })}
      draggable={!!onDragStart}
      onDragStart={() => {
        onDragStart?.(item);
      }}
      onDragOver={e => e.preventDefault()}
      onDrop={ev => {
        ev.preventDefault();
        ev.stopPropagation();
        onDrop?.(item);
      }}
      onClick={() => onCardClick?.(item)}>
      <StyledCardContent>{children}</StyledCardContent>
    </StyledCard>
  );
};

export default Card;
