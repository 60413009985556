// import { useState } from 'react';
// import { PromotionTabs, PromotionTabsEnum } from '../tabs';
import { PromotionDetails, PromotionDetailsProps } from '../details';
// import { PromotionAttachment } from '../attachment';

// const [activeTab, setActiveTab] = useState<PromotionTabsEnum>(
//   PromotionTabsEnum.PROMOTION_DETAILS
// );

export const PromotionContent = ({
  promotions,
  activePromotion,
  loading,
  setActivePromotion
}: PromotionDetailsProps) => (
  <>
    {/* <PromotionTabs activeTab={activeTab} onTabChange={setActiveTab} />
      {activeTab === PromotionTabsEnum.PROMOTION_DETAILS ? ( */}
    <PromotionDetails
      promotions={promotions}
      activePromotion={activePromotion}
      setActivePromotion={setActivePromotion}
      loading={loading}
    />
    {/* ) : (
      <PromotionAttachment />
    )} */}
  </>
);
