import { CSSProperties, forwardRef, useImperativeHandle } from 'react';
import {
  OutlinedInputProps,
  InputAdornment,
  ButtonGroup,
  useTheme,
  Box
} from '@material-ui/core';
import clsx from 'clsx';

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { SText } from '@setvi/shared/components';
import {
  useStyles,
  SearchField
} from '@setvi/shared/components/sforms/ssearch/style';
import { SearchIcon } from '@setvi/shared/components/sicons';

import { AISearch } from './components';
import { SearchType, searchTypes, useAdvancedSearch } from './hook';
import { StyledButton, StyledMenu, StyledMenuItem } from './style';

export enum IconPosition {
  start = 'start',
  end = 'end'
}

export interface AdvancedSearchProps
  extends Omit<OutlinedInputProps, 'onChange'> {
  placeholder?: string;
  loading?: boolean;
  width?: number | string;
  height?: number | string;
  searchedValue?: string;
  defaultValue?: string;
  className?: string;
  style?: CSSProperties;
  focusAiSearch?: boolean;
  onChange: (val: string, type: 'regular' | 'ai') => void;
  iconPosition?: keyof typeof IconPosition;
  setFocusAiSearch: React.Dispatch<React.SetStateAction<boolean>>;
  activeSearch: SearchType;
  setActiveSearch: React.Dispatch<React.SetStateAction<SearchType>>;
}
const AdvancedSearch = forwardRef(
  (
    {
      placeholder = 'Search...',
      width = 320,
      height,
      focusAiSearch,
      loading = false,
      searchedValue,
      defaultValue,
      className,
      style,
      iconPosition = IconPosition.end,
      activeSearch,
      onChange,
      setFocusAiSearch,
      setActiveSearch,
      ...rest
    }: AdvancedSearchProps,
    ref
  ) => {
    const { palette } = useTheme();
    const classes = useStyles({ width, height });

    const {
      value,
      endIcon,
      focusEl,
      anchorEl,
      handleChange,
      setFocusEl,
      setValue,
      triggerMenu,
      setAnchorEl,
      onMenuItemClick
    } = useAdvancedSearch({
      focusAiSearch,
      searchedValue,
      defaultValue,
      iconPosition,
      loading,

      onChange,
      setActiveSearch
    });

    // This is used for clearing the search field from the parent component
    useImperativeHandle(ref, () => ({
      clear: () => setValue('')
    }));

    return (
      <Box>
        <ButtonGroup
          color="primary"
          onClick={e => e.stopPropagation()}
          style={{
            position: 'relative'
          }}>
          <StyledButton
            color="default"
            style={{
              color: anchorEl ? palette.primary.main : 'inherit'
            }}
            startIcon={activeSearch?.icon}
            endIcon={
              <ExpandMoreRoundedIcon
                style={{
                  transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease'
                }}
              />
            }
            onClick={triggerMenu}>
            {activeSearch?.label}
          </StyledButton>

          <SearchField
            onClick={e => {
              if (activeSearch === searchTypes[1] && !focusEl)
                setFocusEl(e.currentTarget);
            }}
            fullWidth
            value={value}
            style={style}
            placeholder={placeholder}
            className={clsx(classes.wrapper, className)}
            onChange={e => {
              handleChange(e.target.value);
              setValue(e.target.value);
            }}
            startAdornment={
              iconPosition === IconPosition.start && (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }
            endAdornment={
              <InputAdornment position="end">{endIcon}</InputAdornment>
            }
            {...rest}
          />

          {!!anchorEl && (
            <StyledMenu
              open
              keepMounted
              elevation={3}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}>
              {searchTypes?.map(item => (
                <StyledMenuItem onClick={() => onMenuItemClick(item)}>
                  {item?.icon}
                  <SText>{item?.label}</SText>
                </StyledMenuItem>
              ))}
            </StyledMenu>
          )}

          <AISearch
            anchorEl={focusEl}
            setAnchorEl={setFocusEl}
            value={value}
            width={width}
            setFocusAiSearch={setFocusAiSearch}
            setValue={setValue}
            onSubmit={v => {
              onChange(v, 'ai');
              setValue(v);
              setFocusEl(null);
            }}
          />
        </ButtonGroup>
      </Box>
    );
  }
);

export default AdvancedSearch;
