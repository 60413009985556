import { useRef } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import {
  SText,
  SButton,
  TableLoader,
  SEmptyState,
  SVirtualTable
} from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';
import { useVirtualizeData } from '@setvi/shared/components/s-table/hooks/useVirtualizeData';

import { Box } from '@material-ui/core';
import {
  LoadingContainer,
  LoadingContent,
  TableContainer,
  Content,
  Description
} from './styled';
import ExpandableText from '../expandable-text';
import { ProductColumnsProps, useProductColumns } from './columns';

interface ProductsProps extends ProductColumnsProps {
  products: Product[];
  selected: Product[];
  description?: string;
  loading: boolean;
  title?: string;
  empty: boolean;
  handleSelect: (isChecked: boolean, products: Product[]) => void;
  onProductClick?: (product: Product, type: 'stock' | 'product') => void;
  onFeedbackClick?: () => void;
}

const ITEM_SIZE = 100;
const keyGetter = (product: Product) => product._id;

const Products = ({
  selectedCustomer,
  description,
  products,
  selected,
  loading,
  title,
  empty,
  onPromotionClick,
  onFeedbackClick,
  onProductClick,
  handleSelect,
  onStockClick
}: ProductsProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const { columns } = useProductColumns({
    onPromotionClick,
    onStockClick,

    searchQuery: '',
    selectedCustomer
  });

  const { listVirtualizer } = useVirtualizeData<Product>({
    elements: products || [],
    count: products?.length || 0,
    parentRef: containerRef,
    gap: 0,
    overscan: 5,
    itemSize: ITEM_SIZE,
    gapY: 0,
    gapX: 16
  });

  if (loading)
    return (
      <LoadingContainer>
        <LoadingContent>
          <DotLottieReact
            loop
            autoplay
            height={1}
            src="/images/lottie/processing.lottie"
          />

          <SText noLineClamp fontColor="#44444F">
            Looking for Products...
          </SText>
        </LoadingContent>
      </LoadingContainer>
    );

  if (empty)
    return (
      <SEmptyState
        imageSrc="/images/empty/empty-pc-search.png"
        imageAlt="Empty search"
        additionalContent={
          <>
            <SText
              noLineClamp
              fontColor="#696974"
              style={{
                marginTop: 6
              }}>
              Enter your requirement on left to search
            </SText>
            <SButton variant="text" onClick={onFeedbackClick}>
              Send Feedback
            </SButton>
          </>
        }
      />
    );

  return (
    <Content style={{ padding: '8px 16px' }}>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <SText
            weight="semibold"
            title={`Product Match results for: ${title}`}
            maxLines={1}
          />

          <SButton variant="text" onClick={onFeedbackClick}>
            Send Feedback
          </SButton>
        </Box>

        {!!description?.trim() && (
          <Description>
            <ExpandableText text={description} prefix="Description:" />
          </Description>
        )}
      </Box>

      <TableContainer
        // @ts-ignore
        ref={containerRef}>
        <SVirtualTable
          list={products}
          vList={listVirtualizer?.getVirtualItems()}
          height={listVirtualizer?.getTotalSize()}
          columns={columns}
          keyGetter={keyGetter}
          searchQuery=""
          row={{
            onClick: product => onProductClick(product, 'product'),
            rowStyleGetter: () => ({ maxHeight: ITEM_SIZE }),
            fallback: <TableLoader rows={1} height={ITEM_SIZE} />,
            checkbox: product => {
              const checked = selected.some(
                i => keyGetter(i) === keyGetter(product)
              );
              return {
                checked,
                onChange: (_e, isChecked) => handleSelect(isChecked, [product])
              };
            }
          }}
        />
        {!loading && products.length === 0 && (
          <SEmptyState
            imageSrc="/images/no-results.svg"
            subTitle="No Results Found"
          />
        )}
      </TableContainer>
    </Content>
  );
};

export default Products;
