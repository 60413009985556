import { STooltip } from '@setvi/shared/components';
import { BookIcon } from '@setvi/shared/components/sicons';
import { IconButton, withStyles } from '@material-ui/core';

const SalesEnablement = withStyles(({ palette }) => ({
  root: {
    padding: 2,
    color: palette.common.white,
    background:
      'linear-gradient(135deg,  #256EE4 5%, #003A6C 30%, #001222 90%)',
    '& svg path': {
      fill: 'none !important'
    }
  }
}))(IconButton);

interface SalesEnablementButtonProps {
  onClick: () => void;
}

export const SalesEnablementButton = ({
  onClick
}: SalesEnablementButtonProps) => (
  <STooltip arrow title="Sales Enablement">
    <SalesEnablement
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}>
      <BookIcon htmlColor="inherit" width={14} height={14} />
    </SalesEnablement>
  </STooltip>
);
