import {
  ChildResource,
  Link,
  LinkItemItems,
  Presentation,
  PreviewLink,
  Resource,
  Template
} from '@setvi/shared/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { LinkObjectType } from '../../enums';
import { resourceWithChildResources } from '../old-quill';

export const normalizeResourceToLink = (
  resource: Resource,
  linkName = ''
): Link => ({
  Name: linkName || resource.Name,
  Placeholder: uuidv4(),
  Item: {
    Items:
      resourceWithChildResources.includes(resource.ResourceTypeID) &&
      resource.ChildResources.length > 0
        ? resource.ChildResources.map(childResource => ({
            ID: childResource.ID,
            ObjectType: LinkObjectType.ResourceChild,
            Name: resource.Name,
            ThumbURL: resource.ThumbURLWithSas || resource.ThumbURL,
            ResourceTypeID: resource.ResourceTypeID
          }))
        : [
            {
              ID: resource.ID,
              Name: resource.Name,
              ThumbURL: resource.ThumbURLWithSas || resource.ThumbURL,
              ResourceTypeID: resource.ResourceTypeID,
              ObjectType: LinkObjectType.Resources
            }
          ]
  }
});

export const normalizeChildResourcesToLink = (
  resources: ChildResource[],
  linkName = ''
): Link => ({
  Name: linkName || resources[0]?.Name,
  Placeholder: uuidv4(),
  Item: {
    Items: resources.map(resource => ({
      ID: resource.ID,
      Name: resource.Name,
      ThumbURL: resource.ThumbURLWithSas || resource.ThumbURL,
      ResourceTypeID: resource.ResourceTypeID,
      ObjectType: LinkObjectType.ResourceChild
    }))
  }
});
export const normalizePresentationToLink = (
  presentation: Presentation,
  linkName = presentation.Name
): Link => ({
  Name: linkName,
  ParentID: presentation.ID,
  Placeholder: uuidv4(),
  Item: {
    ID: presentation.ID,
    Name: presentation.Name,
    ThumbURL: presentation.ThumbURLWithSas || presentation.ThumbURL,
    Items: presentation.PresentationItems.map(item => ({
      ID: item.ID,
      Name: item.Name,
      ThumbURL: item.ThumbURLWithSas || item.ThumbURL,
      ResourceTypeID: item.ResourceTypeID,
      ObjectType: LinkObjectType.Presentation
    }))
  }
});

export const normalizeTemplateToLink = (
  presentation: Template,
  linkName = presentation.Name
): Link => ({
  Name: linkName,
  ParentID: presentation.ID,
  Placeholder: uuidv4(),
  Item: {
    ID: presentation.ID,
    Name: presentation.Name,
    ThumbURL: presentation.ThumbURL,
    Items: presentation.Items.map(item => ({
      ID: item.ID,
      Name: item.Name,
      ThumbURL: item.ThumbURLWithSas || item.ThumbURL,
      ResourceTypeID: item.ResourceTypeID,
      ObjectType: LinkObjectType.Presentation
    }))
  }
});

type ExtendedLink = Link & {
  Items?: LinkItemItems[];
};

export const normalizeLinkToPreviewLink = (link: ExtendedLink): PreviewLink => {
  const { Name, ParentID } = link;

  const Items = link?.Item?.Items || link.Items;

  return {
    Name,
    ParentID,
    Items: Items.map(({ ObjectType, ID }) => ({
      ObjectId: ID,
      ObjectType
    }))
  };
};
