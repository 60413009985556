import {
  CompanyProductUserType,
  Product,
  ProductDetailsTabsIds
} from '@setvi/shared/interfaces';
import { SInfiniteTable } from '@setvi/shared/components/s-table/infinite';
import { SEmptyState, TableLoader } from '@setvi/shared/components';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { Box } from '@material-ui/core';
import { ActionBar } from 'componentsV2/product/action-bar';
import { CompanySettingsTypeID } from '@setvi/shared/services/react-query/query/user/types';
import { useAssociatedProducts } from './useAssociatedProducts';
import { useAssociatedProductColumns } from './useAssociatedProductColumns';

interface AssociatedProductProps {
  product: Product;
  selectedProducts: Product[];
  canSelectProducts: boolean;
  readOnly: boolean;
  handleProductSelect: (isChecked: boolean, product: Product[]) => void;
  openProductDetails?: (
    product: Product,
    defaultOpenedTab?: ProductDetailsTabsIds
  ) => void;
  closeDialog?: () => void;
  handleEmail: () => void;
  handleSelected: () => void;
  handleCloseActionBar: () => void;
}

export const rowKeyGetter = (i: Product) => i._id;

export const AssociatedProducts = ({
  product,
  selectedProducts,
  canSelectProducts,
  readOnly,
  handleProductSelect,
  openProductDetails,
  closeDialog,
  handleEmail,
  handleSelected,
  handleCloseActionBar
}: AssociatedProductProps) => {
  const { companyData } = useAppContext();
  const userType =
    companyData?.Settings[CompanySettingsTypeID.CompanyType]?.Value;
  const manufacturer = userType === CompanyProductUserType.Manufacturer;
  const { associatedProducts, isLoading } = useAssociatedProducts({ product });

  const { productColums } = useAssociatedProductColumns({
    manufacturer,
    searchQuery: ''
  });

  if (isLoading) return <TableLoader columns={productColums} />;

  if (!associatedProducts.length && !isLoading)
    return (
      <SEmptyState
        title="No Options and Accessories"
        subTitle="There are no accessories available for this product."
      />
    );

  const openModal = (prod: Product) => {
    closeDialog?.();
    openProductDetails(prod);
  };

  return (
    <Box>
      <SInfiniteTable
        list={associatedProducts}
        columns={productColums}
        keyGetter={(item: Product) => item?._id}
        row={{
          onClick: (prod: Product) => openModal(prod),
          ...(!readOnly && {
            checkbox: (prod: Product) => {
              const checked = selectedProducts?.some(
                i => rowKeyGetter(i) === rowKeyGetter(prod)
              );
              return {
                checked,
                disabled: !canSelectProducts && !checked,
                tooltip:
                  !canSelectProducts &&
                  !checked &&
                  'The maximum number of product selected',
                onChange: e => handleProductSelect(e.target.checked, [prod])
              };
            }
          })
        }}
      />

      {handleEmail && selectedProducts?.length > 0 && (
        <ActionBar
          selectedProducts={selectedProducts}
          buttonLabel="Email"
          handleEmail={handleEmail}
          handleSelected={handleSelected}
          handleClose={handleCloseActionBar}
        />
      )}
    </Box>
  );
};
