import { useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { AIIcon } from '@setvi/shared/components/sicons';
import { SFormikTextfield } from '@setvi/shared/components';
import SLabel from '@setvi/shared/components/sui/slabel';
import { useCustomErrors } from '@setvi/shared/hooks';
import { linkAddress } from '@setvi/shared/utils';

import {
  Header,
  ClearButton,
  SubmitButton,
  InputContainer,
  LabelContainer,
  FormikContainer,
  Placeholder,
  inputStyle
} from './styled-components';

enum SearchFormTypes {
  url = 'url',
  text = 'text',
  equipment = 'equipment'
}

interface SearchFormProps {
  label?: string;
  icon?: JSX.Element;
  placeholder?: string;
  submitButtonLabel?: string;
  type?: keyof typeof SearchFormTypes;
  value?: string;
  disableButton?: boolean;
  rows?: number;
  onSubmit: ({
    value,
    type
  }: {
    type: keyof typeof SearchFormTypes;
    value: string;
  }) => void;
  handleChange: (value: string) => void;
}

const maxLength = 2048;

export const maxlengthValidation = (type: SearchFormTypes) =>
  Yup.object({
    [SearchFormTypes[type]]: Yup.string().max(
      maxLength,
      `Limit is ${maxLength} characters`
    )
  });

export const getSchema = (type: SearchFormTypes) => {
  const schemas = {
    [SearchFormTypes.url]: {
      name: 'url',
      schema: Yup.object({
        [SearchFormTypes.url]: Yup.string()
          .max(maxLength, `Limit is ${maxLength} characters`)
          .matches(linkAddress, 'Enter correct URL!')
      })
    },
    [SearchFormTypes.text]: {
      name: 'text',
      schema: maxlengthValidation(type)
    },
    [SearchFormTypes.equipment]: {
      name: 'equipment',
      schema: maxlengthValidation(type)
    }
  };

  return schemas[type];
};

const MIN_ROWS = 4;
const ROW_SIZE = 21;
const AREA_PADDING = 37;

export const SearchForm = ({
  type = SearchFormTypes.text,
  label = '',
  placeholder = '',
  submitButtonLabel = 'Submit',
  icon,
  value,
  disableButton,
  rows: defaultRows,
  onSubmit,
  handleChange
}: SearchFormProps) => {
  const ref = useRef(null);
  const [rows, setRows] = useState(defaultRows || MIN_ROWS);

  const { customErrors, customValidate } = useCustomErrors({
    schemas: [getSchema(type as SearchFormTypes)],
    values: { [type]: value }
  });

  const initialValues = useMemo(() => ({ [type]: value }), [type, value]);

  const onPlaceholderClick = () => {
    const textarea = ref?.current?.querySelector('textarea');
    if (textarea) textarea?.focus();
  };

  useEffect(() => {
    if (ref?.current && !defaultRows) {
      const height = ref?.current?.clientHeight;
      const currRows = Math.floor((height - AREA_PADDING) / ROW_SIZE);
      setRows(currRows > MIN_ROWS ? currRows : MIN_ROWS);
    }
  }, [defaultRows, ref]);

  return (
    <FormikContainer>
      <Formik
        onSubmit={() => onSubmit?.({ type, value })}
        validateOnChange={false}
        initialValues={initialValues}>
        {({ values: formikValues, resetForm }) => (
          <InputContainer
            // @ts-ignore
            ref={ref}>
            <SFormikTextfield
              name={type}
              label={
                <Header
                  style={{ flexDirection: label ? 'row' : 'row-reverse' }}>
                  {label && (
                    <LabelContainer>
                      {icon}
                      <SLabel title={label} variant="body2" />
                    </LabelContainer>
                  )}

                  <ClearButton
                    variant="text"
                    disabled={disableButton}
                    hide={!formikValues?.[type]}
                    onClick={() => {
                      handleChange('');
                      resetForm({
                        values: { [type]: '' }
                      });
                    }}>
                    Clear
                  </ClearButton>
                </Header>
              }
              placeholder=""
              maxLength={maxLength}
              onChange={(val: string) => {
                customValidate({
                  schema: getSchema(type as SearchFormTypes),
                  value: val,
                  name: type
                });
                handleChange(val);
              }}
              multiline
              minRows={rows}
              maxRows={rows}
              InputProps={{
                style: inputStyle,
                ...(!formikValues?.[type]
                  ? {
                      startAdornment: (
                        <Placeholder onClick={onPlaceholderClick}>
                          <p>{placeholder}</p>
                        </Placeholder>
                      )
                    }
                  : {}),
                endAdornment: (
                  <SubmitButton
                    type="submit"
                    disabled={
                      !formikValues?.[type] ||
                      // @ts-ignore
                      !!customErrors?.[type] ||
                      disableButton
                    }
                    startIcon={<AIIcon htmlColor="#fff" />}>
                    {submitButtonLabel}
                  </SubmitButton>
                )
              }}
              customErrors={customErrors}
            />
          </InputContainer>
        )}
      </Formik>
    </FormikContainer>
  );
};
