import { Box, Typography } from '@material-ui/core';
import { ReactNode } from 'react';

interface ExplanationProps {
  value: string;
  prefix?: string;
  badge?: ReactNode;
}

export const Explanation = ({ value, prefix, badge }: ExplanationProps) => (
  <Box display="flex" gridGap="4px">
    {badge || '●'}
    <Typography>
      {!!prefix && (
        <span style={{ textDecoration: 'underline', marginRight: 4 }}>
          {prefix}:
        </span>
      )}
      {value}
    </Typography>
  </Box>
);
