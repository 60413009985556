import { SText } from '@setvi/shared/components';

interface ArrayStringValue {
  values: string[];
}

export const ArrayStringValue = ({ values }: ArrayStringValue) => (
  <>
    {values?.map((value, index) => (
      <>
        <SText
          key={value}
          noLineClamp
          weight="bold"
          style={{ wordBreak: 'break-word', display: 'inline' }}
          title={value}
        />
        {index < values.length - 1 && ', '}
      </>
    ))}
  </>
);
