import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { ChildResource, Link } from '@setvi/shared/interfaces';

import {
  getAdminResourceQuery,
  createResourceMutation,
  resourceUploadFinishedMutation,
  getResourcesProcessStatusesQuery,
  ResourcesQueryKey
} from '../../../../services';
import { AcceptedFileTypes } from './accepted-files';
import { useSubscribedMutation } from '../../../../hooks';
import {
  LinkObjectType,
  RESOURCE_CLASS,
  RESOURCE_STATUS,
  RESOURCE_UPLOAD_STATUS
} from '../../../../enums';
import {
  ResourceExtensions,
  filterFilesByExtension,
  resourceWithChildResources
} from '../../../../utils';

export interface UploadResourcePanelProps {
  id: number;
  file: File;
  status: RESOURCE_UPLOAD_STATUS;
}

interface UseUploadProps {
  handleInsert?: (link: Link) => void;
  handleComplete?: () => void;
  handleCancel?: () => void;
  categoryId?: number | null;
}

export const useUpload = ({
  handleInsert,
  handleComplete,
  categoryId = 0
}: UseUploadProps) => {
  const [uploading, setUploading] = useState(false);
  const [link, setLink] = useState<number | null>();
  const [files, setFiles] = useState<UploadResourcePanelProps[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addResources } = useSubscribedMutation(
    createResourceMutation(),
    [ResourcesQueryKey.COMPANY]
  );

  const { mutateAsync: uploadFinished } = useSubscribedMutation(
    resourceUploadFinishedMutation(),
    []
  );

  const handleOnDrop = async (f: File[]) => {
    const extensions = ResourceExtensions.Extensions?.split(',')?.filter(
      e => e !== '.zip'
    );
    const { accepted, rejected } = filterFilesByExtension(f, extensions);

    if (rejected?.length) {
      enqueueSnackbar(
        `The following files are not allowed: ${rejected
          .map(file => file.name)
          .join(', ')}`,
        {
          variant: 'error'
        }
      );

      if (!accepted?.length) return;
    }

    setUploading(true);

    const body = {
      Resources: accepted?.map((resource: File) => ({
        FileName: resource?.name,
        CategoryId: categoryId,
        ResourceStatus: RESOURCE_STATUS.ACTIVE,
        ResourceClass: RESOURCE_CLASS.USER,
        ResourceName: resource?.name,
        isShareable: true,
        IsDownloadable: true
      }))
    };

    const { Data } = await addResources(body);

    const resources = accepted?.map((resource: File, i: number) => ({
      id: Data[i]?.Id,
      file: resource,
      status: RESOURCE_UPLOAD_STATUS.FILESTACK_UPLOADING
    }));

    setFiles(resources);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: uploading,
    accept: AcceptedFileTypes,
    onDropAccepted: handleOnDrop,
    multiple: false,
    onDropRejected: (fileRejections: FileRejection[]) => {
      enqueueSnackbar(
        fileRejections?.length > 1
          ? 'Some file types are not supported.'
          : 'File type not supported.',
        { variant: 'error' }
      );
    }
  });

  const activeResources = useMemo(
    () =>
      files?.filter(u =>
        [
          RESOURCE_UPLOAD_STATUS.UPLOADING,
          RESOURCE_UPLOAD_STATUS.PROCESSING,
          RESOURCE_UPLOAD_STATUS.UNPROCESSED
        ].includes(u?.status)
      ),
    [files]
  );

  const queryVariables = useMemo(
    () => activeResources?.map(({ id }) => id),
    [activeResources]
  );

  const processed = useMemo(
    () =>
      files?.filter(res =>
        [
          RESOURCE_UPLOAD_STATUS.ERROR,
          RESOURCE_UPLOAD_STATUS.PROCESSED
        ].includes(res?.status)
      )?.length,
    [files]
  );

  const triggerAPI = useMemo(() => {
    if (!activeResources?.length) return false;

    if (processed === files?.length) return false;

    return true;
  }, [activeResources?.length, files?.length, processed]);

  const { data, isFetching } = useQuery({
    ...getResourcesProcessStatusesQuery(queryVariables),
    enabled: triggerAPI,
    refetchInterval: 4000
  });

  const { data: singleResource } = useQuery({
    ...getAdminResourceQuery(link?.toString()),
    enabled: !!link
  });

  // We check here if all the resources are processed and then we insert the link
  useEffect(() => {
    if (!processed || processed !== files?.length || !singleResource) return;

    const parsedResource: any = {
      Name: files?.[0]?.file?.name || 'Link',
      Type: LinkObjectType.Resources,
      Placeholder: uuidv4(),
      Item: {
        Items: [
          {
            ...singleResource,
            ID: singleResource.Id,
            Name: singleResource.Name,
            ...(resourceWithChildResources.includes(
              singleResource.ResourceTypeID
            ) && singleResource.ChildResources.length > 0
              ? {
                  Children: singleResource.ChildResources.map(
                    (r: ChildResource) => ({
                      ...r,
                      ObjectType: LinkObjectType.ResourceChild,
                      ID: r.Id
                    })
                  )
                }
              : {}),
            ResourceTypeID: singleResource.ResourceTypeID,
            ResourceTypeName: singleResource.ResourceTypeName,
            ObjectId: singleResource.Id,
            CategoryID: singleResource?.CategoryID,
            ObjectType: LinkObjectType.Resources,
            ThumbURL: singleResource?.ThumbURL || '',
            ItemID: singleResource?.ResourceID
          }
        ]
      }
    };

    handleInsert?.(parsedResource);

    setUploading(false);
    handleComplete?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleResource, activeResources?.length, processed, files]);

  // With this we update the status of the resources and check if it has an error
  useEffect(() => {
    if (!data || isFetching) return;

    setFiles(prev =>
      prev?.map(resource => {
        const sameResource = data.find(
          ({ Id }: { Id: number }) => Id === resource.id
        );

        const status = sameResource?.Processing || resource?.status;

        if (
          sameResource?.Processing === RESOURCE_UPLOAD_STATUS.PROCESSED &&
          sameResource?.Processing !== resource?.status
        )
          setLink(sameResource.Id);

        return {
          ...resource,
          error:
            sameResource?.ErrorExceptionMessage || sameResource?.ErrorMessage,
          status
        };
      })
    );
  }, [data, isFetching, setLink]);

  const resetFiles = () => {
    setFiles([]);
  };

  const onUploadFinished = async (
    resourceId: number,
    filestackId: string,
    filename?: string
  ) => {
    setUploading(true);

    setFiles(prev => {
      const updatedData = prev?.map(item => {
        if (item.id === resourceId) {
          return {
            ...item,
            status: RESOURCE_UPLOAD_STATUS.UPLOADING
          };
        }
        return item;
      });
      return updatedData;
    });

    await uploadFinished({
      resources: [
        {
          resourceId,
          filestackId,
          material: filename || ''
        }
      ]
    });
  };

  const onUploadCancel = (resourceId: number) => {
    setFiles(prev => {
      const updatedData = prev?.map(item => {
        if (item.id === resourceId) {
          return {
            ...item,
            status: RESOURCE_UPLOAD_STATUS.FILESTACK_ERROR,
            error: 'Upload canceled by user.'
          };
        }
        return item;
      });
      return updatedData;
    });

    setUploading(false);
  };

  return {
    isDragActive,
    uploading,
    files,

    resetFiles,
    getRootProps,
    getInputProps,
    onUploadCancel,
    onUploadFinished
  };
};
