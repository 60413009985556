import { Box, Link, Tooltip } from '@material-ui/core';
import { Customer } from 'Services/MongoRealm/Types';
import { Product } from '@setvi/shared/interfaces';
import {
  getProductStock,
  getProductStockByCustomer
} from 'pages/products/utils';
import { Variant } from '@material-ui/core/styles/createTypography';

export enum StockColor {
  green = '#23A566',
  red = '#FA2020'
}

interface StockProps {
  inventory: Product['inventory'];
  isDropship: boolean;
  variant?: Variant;
  selectedCustomer?: Customer;
  onClick?: () => void;
}

export const Stock = ({
  inventory,
  isDropship,
  variant = 'body1',
  selectedCustomer,
  onClick
}: StockProps) => {
  const productStock = getProductStock(inventory);
  const stockForCustomer = getProductStockByCustomer(
    inventory,
    selectedCustomer
  );
  const stock = selectedCustomer ? stockForCustomer : productStock;

  if (isDropship)
    return (
      <Tooltip
        arrow
        title="It is not stocked in the distributor's warehouse; instead, it is shipped directly from the vendor.">
        <Link
          variant={variant}
          underline="always"
          color="primary"
          onClick={e => {
            e.stopPropagation();
            onClick?.();
          }}>
          Drop ship
        </Link>
      </Tooltip>
    );

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <Box p={1}>
          <Box pb={1}>
            <b>Available in Warehouse</b>
          </Box>
          <ul
            style={{
              padding: 0,
              margin: 0,
              listStyle: 'none'
            }}>
            {inventory?.length === 0 && (
              <li>No stock information available. </li>
            )}
            {inventory?.map(({ warehouse, availableOnHand }) => (
              <li key={warehouse?._id}>
                {warehouse?.name} - In stock: {availableOnHand}
              </li>
            ))}
          </ul>
        </Box>
      }>
      <Link
        underline="always"
        variant={variant}
        style={{
          wordBreak: 'break-word',
          color: stock > 0 ? '#23A566' : '#FA2020'
        }}
        onClick={e => {
          e.stopPropagation();
          onClick?.();
        }}>
        {stock > 0 ? `In stock: ${stock}` : 'Out of stock'}
      </Link>
    </Tooltip>
  );
};
