import { makeStyles } from '@material-ui/core';

interface LinkProps {
  children: React.ReactNode;
  href: string;
}

const useLinkStyles = makeStyles(() => ({
  link: {
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    textDecoration: 'underline'
  }
}));

export const Link = ({ children, href }: LinkProps) => {
  const classes = useLinkStyles();
  return (
    <a className={classes.link} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
