import { Box, styled } from '@material-ui/core';
import { SETVIImage, SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';

export const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 18,
  gap: 8
});

const AILogo = () => (
  <Container>
    <SETVIImage
      src="/images/logo.png"
      alt="Setvi Logo"
      height={15}
      width={15}
    />
    <SText size="xl" weight="semibold">
      SETVI
    </SText>
    <SText size="xl" weight="medium" fontColor={TextColor.Grey}>
      Intelligence
    </SText>
  </Container>
);

export default AILogo;
