import { axiosHelper } from '@setvi/shared/services';
import { AxiosMethods, SalesEnablementAdminApi } from '@setvi/shared/enums';

import {
  AdminSalesEnablementBody,
  AdminSalesEnablementReoderBody,
  AdminSalesEnablementStatusBody
} from './Types';

export const addAdminSalesEnablementMutation = () => ({
  mutationFn: (body: AdminSalesEnablementBody) =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.SaveSalesEnablement,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body
    })
});

interface AdminSalesEnablementUpdateBody {
  id: string;
  body: AdminSalesEnablementBody;
}

export const editAdminSalesEnablementMutation = () => ({
  mutationFn: ({ body, id }: AdminSalesEnablementUpdateBody) =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.UpdateSalesEnablement.replace(
        ':id',
        id
      ),
      method: AxiosMethods.PUT,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body
    })
});

export const changeAdminSalesEnablementStatusMutation = () => ({
  mutationFn: ({ id, ...rest }: AdminSalesEnablementStatusBody) =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.UpdateStatusSalesEnablement.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.PUT,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body: {
        ...rest
      }
    })
});

export const changeAdminSalesEnablementOrderMutation = () => ({
  mutationFn: (body: AdminSalesEnablementReoderBody) =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.ReorderSalesEnablement,
      method: AxiosMethods.PUT,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body
    })
});

export const deleteAdminSalesEnablementMutation = () => ({
  mutationFn: ({
    id,
    applyToChildren
  }: {
    id: string | number;
    applyToChildren: boolean;
  }) =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.DeleteSalesEnablement?.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.DELETE,
      baseURL: process.env.REVOPS_INTELLIGENCE_API,
      body: {
        applyToChildren
      }
    })
});

export const initiateSalesEnablementPromptsMutation = () => ({
  mutationFn: (categoryId: string) =>
    axiosHelper({
      endpoint: SalesEnablementAdminApi.InitiatePrompts.replace(
        ':id',
        categoryId
      ),
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_INTELLIGENCE_API
    })
});
