import { Box, Chip, styled } from '@material-ui/core';

export const Container = styled(Box)(({ theme }) => ({
  height: 170,
  minWidth: 260,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gridGap: 12,
  padding: theme.spacing(3),
  borderRadius: 14,
  border: '1px solid #E2E2EA',
  background: 'white'
}));

export const Footer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: 12
});

export const StyledChip = styled(Chip)({
  background: '#FAFAFB'
});
