/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import './range-slider.css';
import { Box, Typography } from '@material-ui/core';
import { debounce } from 'lodash';
import { SText } from '../..';
import { TextColor } from '../../../enums';

interface SRangeSliderProps {
  min?: number;
  max?: number;
  label: string;
  defaultRange?: [number, number];
  onChange: (value: [number, number]) => void;
}

export const SRangeSlider = ({
  min = 0,
  max = 10000,
  defaultRange,
  label,
  onChange
}: SRangeSliderProps) => {
  const [[minValue, maxValue], setValue] = useState([min, max]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedChange = useCallback(
    debounce((val: [number, number]) => onChange(val), 500),
    [onChange]
  );

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newMinValue = Math.min(Number(e.target.value), maxValue - 1);

    setValue([newMinValue, maxValue]);
    handleDebouncedChange([newMinValue, maxValue]);
  };

  const handleMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newMaxValue = Math.max(Number(e.target.value), minValue + 1);

    setValue([minValue, newMaxValue]);
    handleDebouncedChange([minValue, newMaxValue]);
  };

  const percent = (value: number) => ((value - min) / (max - min)) * 100;

  useEffect(() => {
    defaultRange && setValue(defaultRange);
  }, [defaultRange]);

  return (
    <Box className="price-filter">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography style={{ fontSize: 16 }}>{label}</Typography>
        {maxValue !== max && (
          <SText
            style={{ cursor: 'pointer' }}
            title="Clear"
            fontColor={TextColor.Blue}
            onClick={() => {
              onChange([min, max]);
            }}
          />
        )}
      </Box>

      <Box className="inputs">
        <span>
          <span>$</span>
          <input type="number" value={minValue} onChange={handleMinChange} />
        </span>
        <Typography style={{ fontSize: 18 }}>-</Typography>
        <span>
          <span>$</span>
          <input type="number" value={maxValue} onChange={handleMaxChange} />
        </span>
      </Box>
      <Box className="slider-container">
        <Box
          className="active-track"
          style={{
            left: `${percent(minValue)}%`,
            width: `${percent(maxValue) - percent(minValue)}%`
          }}
        />

        {/* Left Thumb */}
        <input
          type="range"
          min={min}
          max={max}
          value={minValue}
          onChange={handleMinChange}
          className="slider"
        />

        {/* Right Thumb */}
        <input
          type="range"
          min={min}
          max={max}
          value={maxValue}
          onChange={handleMaxChange}
          className="slider"
        />
      </Box>

      <Box className="slider-labels">
        <Typography>${min}</Typography>
        <Typography>${max.toLocaleString()}</Typography>
      </Box>
    </Box>
  );
};
