import { Box, styled } from '@material-ui/core';

export const LoadingContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const LoadingContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 400,
  height: 400
});

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 12,
  padding: theme.spacing(1, 3, 3, 3),
  width: '100%',
  height: '100%'
}));

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: 18
});

export const TableContainer = styled(Box)({
  flex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  scrollBehavior: 'smooth'
});
