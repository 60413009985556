import { Box } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { Product } from '@setvi/shared/interfaces';

interface ListPriceProps {
  product: Product;
}

export const ListPrice = ({ product }: ListPriceProps) => (
  <Box display="flex" flexDirection="column" gridGap={4} mt={2}>
    <SText
      size="sm"
      // @ts-ignore
      title={`Lead time: ${product?.custom?.ship_lead_time__business_days || '-'}`}
    />
    <SText
      size="sm"
      title={`List: ${product?.price?.toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency'
      })}`}
    />
  </Box>
);
