import { useMemo } from 'react';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

import { TextColor } from '@setvi/shared/enums';
import { ColumnsType } from '@setvi/shared/types';
import { Product } from '@setvi/shared/interfaces';
import { SETVIImage, SHighlightText, SText } from '@setvi/shared/components';

import { getColor } from 'utilsV2';
import { COMPANIES } from 'Enums/Companies';
import { useTableStyles } from 'Components/Shared';
import { Customer } from 'Services/MongoRealm/Types';
import { Stock } from 'pages/products/components/stock';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { PrivateLabel } from 'componentsV2/product/private-label';
import { getProductStatus } from 'componentsV2/product/product-status';
import { PromotionBadge } from 'pages/products/components/products-list/useProductColumns/contents';

import { ImagePreview } from '../../../styled';
import { IsStock, Precentage } from '../styled';

export interface ProductColumnsProps {
  onPromotionClick?: (product: Product) => void;
  onStockClick?: (product: Product, type: 'stock' | 'product') => void;

  searchQuery?: string;
  selectedCustomer?: Customer;
}

interface MatchedProduct extends Product {
  percentage: number;
  isPrivateLabel: boolean;
  isStockProduct: boolean;
}

export const useProductColumns = ({
  onPromotionClick,
  onStockClick,

  searchQuery = '',
  selectedCustomer
}: ProductColumnsProps) => {
  const classes = useTableStyles();
  const { palette } = useTheme();
  const { companyData } = useAppContext();

  const isDistributor = [COMPANIES.SINGER_DEV, COMPANIES.SINGER_PROD].includes(
    companyData.ID
  );
  const isManufacturer = [
    COMPANIES.MIDDLEBY_DEV,
    COMPANIES.MIDDLEBY_PROD
  ].includes(companyData.ID);

  const columns: ColumnsType = useMemo(
    () => [
      {
        id: 'productName',
        minWidth: '300px',
        label: 'Product Name & SKU',
        cellContent: (product: MatchedProduct) => {
          const productSrc =
            product.imageUrl ||
            product.images?.[0]?.path ||
            '/images/product-placeholder.svg';

          return (
            <Box display="flex" alignItems="center">
              <ImagePreview
                interactive
                title={
                  <img
                    src={productSrc}
                    alt="product-img"
                    style={{
                      width: 200,
                      height: 200,
                      objectFit: 'contain',
                      backgroundColor: palette.common.white,
                      borderRadius: '4px',
                      padding: '4px'
                    }}
                  />
                }
                placement="right"
                arrow>
                <Box>
                  <SETVIImage
                    className={classes.tableImage}
                    src={productSrc}
                    alt={product.name}
                    style={{
                      height: 55,
                      width: 62,
                      minWidth: 62
                    }}
                  />
                </Box>
              </ImagePreview>
              <Box ml={4} display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <SHighlightText
                    value={product.name}
                    searchQuery={searchQuery}
                    maxLines={2}
                  />
                  {product?.promotions?.length > 0 && (
                    <Box ml={1}>
                      <PromotionBadge
                        onClick={() => onPromotionClick(product)}
                      />
                    </Box>
                  )}
                </Box>
                <Box display="flex" alignItems="center" gridGap={2}>
                  <SHighlightText
                    style={{ color: TextColor.Grey }}
                    size="sm"
                    value={`SKU: ${product.sku}`}
                    searchQuery={searchQuery}
                  />

                  <SText
                    fontColor={TextColor.Grey}
                    size="sm"
                    title={`(${getProductStatus(product)})`}
                  />
                </Box>
                <Box display="flex" alignItems="center" gridGap={8} mt={1}>
                  {(!!product?.percentage || !!product?.reasoning) && (
                    <Tooltip
                      arrow
                      title={
                        product?.reasoning ||
                        'Percentage shows how closely the product matches with the searched one.'
                      }>
                      <Precentage
                        style={{
                          background: getColor(product?.percentage)
                        }}>
                        <p>{product?.percentage}%</p>
                        <HelpRoundedIcon htmlColor="#4D4D4D" fontSize="small" />
                      </Precentage>
                    </Tooltip>
                  )}
                  {!!product?.isStockProduct && (
                    <Tooltip
                      arrow
                      title="This product can be restocked quickly.">
                      <IsStock>Stock</IsStock>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>
          );
        }
      },
      {
        id: 'manufacturer',
        minWidth: '160px',
        maxWidth: '240px',
        label: 'Mfg. Name & SKU',
        cellContent: (product: MatchedProduct) => (
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gridGap={12}>
              <SText title={product?.vendor?.name || 'Not Available'} />
              {product.isPrivateLabel && <PrivateLabel />}
            </Box>

            <SText
              title={product?.manufacturerSku}
              size="sm"
              fontColor={TextColor.Grey}
            />
          </Box>
        )
      },
      ...(isDistributor
        ? [
            {
              id: 'stock',
              minWidth: '100px',
              maxWidth: '180px',
              label: 'Stock',
              cellContent: (product: MatchedProduct) => (
                <Box>
                  <Stock
                    inventory={product.inventory}
                    isDropship={product.dropship}
                    selectedCustomer={selectedCustomer}
                    onClick={() => onStockClick(product, 'stock')}
                  />
                  {!!product?.custom?.leadTime && (
                    <SText
                      title={`Lead time: ${product?.custom?.leadTime} Days`}
                      fontColor={TextColor.Grey}
                      size="sm"
                    />
                  )}
                </Box>
              )
            }
          ]
        : []),
      ...(isManufacturer
        ? [
            {
              id: 'leadTime',
              minWidth: '160px',
              maxWidth: '240px',
              label: 'Lead Time',
              cellContent: (product: MatchedProduct) => (
                <SText
                  title={product?.custom?.ship_lead_time__business_days || '-'}
                />
              )
            },
            {
              id: 'price',
              minWidth: '160px',
              maxWidth: '240px',
              label: 'List Price',
              cellContent: ({ price }: MatchedProduct) => {
                if (Number.isFinite(price))
                  return (
                    <SText
                      title={`${price.toLocaleString('en-US', {
                        currency: 'USD',
                        style: 'currency'
                      })}`}
                    />
                  );

                return '-';
              }
            }
          ]
        : []),
      ...(isDistributor
        ? [
            {
              id: 'cost',
              minWidth: '80px',
              maxWidth: '110px',
              label: 'Cost',
              cellContent: ({ cost }: MatchedProduct) => {
                if (cost > 0)
                  return (
                    <Box display="flex" flexDirection="column">
                      <SText
                        title={`${cost.toLocaleString('en-US', {
                          currency: 'USD',
                          style: 'currency'
                        })}`}
                      />
                    </Box>
                  );

                return '-';
              }
            }
          ]
        : [])
      // This will be used in future
      /* {
        id: '#',
        width: '9%',
        minWidth: 'min-content',
        label: 'GP',
        cellContent: (product: Product) => {
          const { price, cost } = product;
          return (
            <Box display="flex" flexDirection="column">
              {Number.isFinite(cost) && price > cost ? (
                <>
                  <SText
                    style={{ marginBottom: '3px' }}
                    title={`${(price - cost).toLocaleString('en-US', {
                      currency: 'USD',
                      style: 'currency'
                    })}`}
                  />
                  <SText
                    size="sm"
                    fontColor="#23A566"
                    title={`(${(((price - cost) / price) * 100).toFixed(2).toString()}%)`}
                  />
                </>
              ) : (
                '-'
              )}
            </Box>
          );
        }
      } */
    ],
    [
      classes.tableImage,
      palette.common.white,
      selectedCustomer,
      isManufacturer,
      isDistributor,
      searchQuery,
      onPromotionClick,
      onStockClick
    ]
  );

  return {
    columns
  };
};
